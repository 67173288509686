<div class="table-content-component-panel-monitoreo" style="position: relative;">

  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingGeopointsSpinner" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" > Cargando Geopuntos... </p></ngx-spinner>

  <div class="card-body p-fluid bg-gl-blue-dark" id="rowBusqueda">
    <div class="d-flex">
      <div class="d-flex flex-grow-1">
        <span class="p-input-icon-right flex-grow-1 me-2">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input type="text" (keyup)="onBusqueda($event)" [(ngModel)]="NomBusqueda"  class="form-control" placeholder="Buscar por nombre">
        </span>
      </div>

      <button type="button" *ngIf="geopointsService.showBtnAdd" class="btn-gl-blue btn-dark-hover btn-circle flex-none" (click)="clickAgregarGeopunto()" title="Crear geocerca">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="flex-none d-flex justify-content-center gap-5 resumen-tabla-geo">
    <div class="d-flex align-items-center">
      <span>Geopuntos visibles</span>
      <div class="p-1 geo-counter counter-visible">{{ geopointsService.geopointCounters.visible }}</div>
    </div>
    <div class="d-flex align-items-center">
      <span>Geopuntos ocultos</span>
      <div class="p-1 geo-counter counter-hidden">{{ geopointsService.geopointCounters.hidden }}</div>
    </div>
  </div>

  <!-- <div class="card-body p-fluid" id="rowBusqueda">
      <div class="row">
          <div class="col">
              <input type="text" (keyup)="onBusqueda($event)" [(ngModel)]="NomBusqueda"  class="form-control" placeholder="Buscar por nombre">
          </div>
          <div class="col-2">
              <button type="button" class="btn btn-primary" (click)="clickAgregarGeocerca()" title="Crear geocerca"><i class="fa fa-plus"></i></button>
          </div>
      </div>
  </div> -->

  <div class="panel-izq-table-container">
    <table id="tbl_geos">
      <colgroup>
        <col width="26%" />
        <col width="9%" />
        <col width="31%" />
        <!-- <col width="17%" />
        <col width="17%" /> -->

        <col *ngIf="geopointsService.showBtnEdit" width="17%" />
        <col *ngIf="geopointsService.showBtnEdit" width="17%" />
        <col *ngIf="!geopointsService.showBtnEdit" width="34%" />

      </colgroup>
      <thead class="visible-on-scroll">
          <tr>
              <th>
                <!-- <i class="fa fa-eye fa-lg"></i> -->
                <p-inputSwitch [(ngModel)]="geopointsService.eyeInputSwitch" (click)="onClickEye()" class="d-flex justify-content-center align-items-center" [disabled]="geopointsService.tblDataGeo.length == 0" ngDefaultControl></p-inputSwitch>
              </th>
              <!-- <td></td> -->
              <th colspan="2">Geopunto</th>
              <th>
                <i class="fa fa-id-card fa-lg mini-eye-container" (click)="onClickTagNamesEye()">
                <div>
                  <i *ngIf="geopointsService.tagNamesEyeState" class="fas fa-eye mini-eye"></i>
                  <i *ngIf="!geopointsService.tagNamesEyeState" class="far fa-eye-slash mini-eye"></i>
                </div>
                </i>
              </th>
              <!-- <td><span class="fa fa-car fa-lg"></span></td> -->
              <th *ngIf="geopointsService.showBtnEdit"><i class="fa fa-pencil fa-lg"></i></th>

          </tr>
      </thead>
      <tbody style="border-top: inherit;">

          <!-- <tr *ngFor="let geo of tblDataGeo;let indice=index" > -->
          <tr *ngFor="let geo of geopointsService.tblDataGeoFiltered;let indice=index" >

              <td >
                <button class="btn btn-xs btn-link p-n eye-column" (click)="clickShow(geo.trama.geopunto_id)">
                  <i [ngClass]="{'fas fa-eye': geo.trama.geopunto_visible === 'true', 'far fa-eye-slash' : geo.trama.geopunto_visible === 'false'}"></i>
                </button>
              </td>

              <td class="text-center">
                <i [ngStyle]="{'color': geo.trama.geopunto_color}" (click)="clickLocate(geo.trama.geopunto_id)" class="fa fa-map-marker geopoint-marker"></i>
                <!-- <button class="btn btn-xs btn-link p-n geopoint-color" (click)="clickLocate(geo.trama.geopunto_id)" >
                  <i [ngStyle]="{'background-color': geo.trama.geopunto_color}" class=" fa fa-map-marker"></i>
                </button> -->
              </td>

              <td><span class="d-flex text-start">{{geo.trama.geopunto_name}}</span></td>

              
              <td class="inline-png-checkbox text-center">
                <p-checkbox [(ngModel)]="geo.trama.geopunto_nombre_visible_bol" (onChange)="clickShowNameGeocerca(geo.trama.geopunto_id)" [binary]="true"></p-checkbox>
              </td>


              <td *ngIf="geopointsService.showBtnEdit">
                <div ngbDropdown container="body" class="d-flex justify-content-center align-items-center edit-column" [autoClose]="true" placement="right-top right-bottom left-top left-bottom">
                  <i class="fa fa-pencil" ngbDropdownToggle></i>
                  <div ngbDropdownMenu class="gl-custom-dropdown geofences-table-dropdown">
                    <div ngbDropdownItem (click)="clickConfigurarGeopunto(geo.trama.geopunto_id)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Configurar</div>
                    <div ngbDropdownItem (click)="clickEliminarGeopunto($event, geo.trama.geopunto_id)"><i class="fa fa-trash-o" aria-hidden="true"></i> Eliminar</div>
                  </div>
                </div>
              </td>


          </tr>
          <tr *ngIf="noResults">
            <td colspan="5" class="text-center">Sin coinicidencias</td>
          </tr>
      </tbody>
    </table>
  </div>
</div>
