import {
  Component,
  ElementRef,
  OnInit,
  OnChanges,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { GeofencesService } from '../../services/geofences.service';
import { CircularGeofencesService } from '../../services/circular-geofences.service';
import { PolylineGeogencesService } from '../../services/polyline-geogences.service';
import { PanelService } from '../../../panel/services/panel.service';
import Swal from 'sweetalert2';
import { TreeNode } from 'primeng-lts/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { MapServicesService } from '../../../map/services/map-services.service';
import * as L from 'leaflet';
import 'leaflet-draw';
import { Subscription, forkJoin } from 'rxjs';
import { Geofences } from '../../models/geofences';
import {
  ABGR,
  DataGeofence,
  FileValidationData,
  GeoImp,
  IGeofence,
  ITag,
  ImportGeofence,
  LineEssential,
  ValidImport,
} from '../../models/interfaces';
import moment from 'moment';
import { GeocercaAddComponent } from '../geocerca-add/geocerca-add.component';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { GeofenceImportExportService } from '../../services/geofence-import-export.service';
import { GeofencesModalComponent } from '../geofences-modal/geofences-modal.component';
import { element } from 'protractor';
import { Point } from '@angular/cdk/drag-drop';
//import { AnyNaptrRecord } from 'dns';
//import { GeofencesModalComponent } from '../geofences-modal/geofences-modal.component';
interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-geofence-table',
  templateUrl: './geofence-table.component.html',
  styleUrls: ['./geofence-table.component.scss'],
  providers: [GeofencesModalComponent],
})
export class GeofenceTableComponent implements OnInit, OnDestroy {
  files!: TreeNode[];
  cols!: Column[];
  btnSelected: number = 1;
  viewOptions = 'viewGroup';
  datosCargados: boolean = false;
  NomBusqueda: string = '';
  searchValueGeo: string = '';
  noResults: boolean = false;
  geofences: TreeNode[] = [];
  geofencesFilter: any;
  objGeofences = new Geofences();
  objGeofencesFilter: any;
  loading: boolean = true;
  list1: any = [];
  list2: any = [];
  sortOrder: number = 1;
  private dataEdit: any = {
    id: -1,
    name: '',
    type: '',
  };
  visibleRow: any = {};
  displayEditTags: boolean = false;
  textHeaderEdit: string = '';
  selectedList1: any = [];
  selectedList2: any = [];
  geofencesTree: TreeNode[] = [];
  display: boolean = false;
  nameEdit: string = '';
  geoImpExp: string = ''; // ['export','import'] define que modal se abrira
  isFormName: boolean = false; //para validar NameObligatorio en Tag
  isExistTag: boolean = false; //para validar NameExistente en Tag
  listTagsEmpty: boolean = false; //para validar si el array de list2 esta vacio en la creacion
  nameTagInit: string = ''; //en el caso de que no se edite el nombre de tag
  alreadyLoaded: boolean = false;

  //
  dataGeo: DataGeofence[] = [];
  tempData: DataGeofence = {};
  @ViewChild('_modalChild') modalChild!: GeofencesModalComponent;
  map: L.Map | undefined;
  //estados de export dialog
  showExport: boolean = false; // variable para mostrat el dialogo de exportacion
  listTags: ITag[] = [];
  //
  @ViewChild('tt') tt!: any;
  @Output() eventDisplayTags = new EventEmitter<boolean>();
  @Output() onDeleteItem: EventEmitter<any> = new EventEmitter();
  @Output() onHideEvent = new EventEmitter<boolean>();

  treeGeofences: any;
  public column: number = 6; //posible order
  constructor(
    public geofencesService: GeofencesService,
    public mapService: MapServicesService,
    public panelService: PanelService,
    private circularGeofencesService: CircularGeofencesService,
    private polylineGeofenceService: PolylineGeogencesService,
    private spinner: NgxSpinnerService,
    private configDropdown: NgbDropdownConfig
  ) {
    this.geofencesService.tagAdded.subscribe(async () => {
      this.geofences = await this.geofencesService.createTreeNode();
    });
  }

  async ngOnInit(): Promise<void> {
    if (
      !this.geofencesService.initializingGeofences ||
      !this.circularGeofencesService.initializingCircularGeofences
    ) {
      this.geofencesService.spinner.show('loadingGeofencesSpinner');
    }
    // if(!this.polylineGeofenceService.initializingPolylineGeofences || !this.polylineGeofenceService.initializingUserPrivleges){
    //   this.geofencesService.spinner.show('loadingGeofencesSpinner');
    // }
    // if(!this.circularGeofencesService.initializingCircularGeofences || !this.circularGeofencesService.initializingUserPrivleges){
    //   // this.geofencesService.spinner.show('loadingGeofencesSpinner');
    // }
    if (this.geofencesService.initializingGeofences) {
      await this.objGeofences.setGeofences(
        this.geofencesService.geofences as IGeofence[],
        'polig'
      );
    } else {
      this.geofencesService.dataCompleted.subscribe(
        async (data: IGeofence[]) => {
          await this.objGeofences.setGeofences(data, 'polig');
        }
      );
    }
    if (this.circularGeofencesService.initializingCircularGeofences) {
      await this.objGeofences.setGeofences(
        this.circularGeofencesService.circular_geofences as IGeofence[],
        'circ'
      );
    } else {
      this.circularGeofencesService.dataCompleted.subscribe(
        async (data: IGeofence[]) => {
          await this.objGeofences.setGeofences(data, 'circ');
        }
      );
    }
    // if(this.polylineGeofenceService.initializingPolylineGeofences){
    //   this.objGeofences.setGeofences(this.polylineGeofenceService.polyline_geofences as IGeofence[], 'lin');
    // }else{
    //   this.polylineGeofenceService.dataCompleted.subscribe((data:IGeofence[])=>{
    //     this.objGeofences.setGeofences(data, 'lin');
    //   })
    // }
    this.geofencesService.listGeofences = this.objGeofences.geofences;
    this.geofences = await this.geofencesService.createTreeNode();
    this.loading = false;
    this.listTags = this.geofencesService.getTagss();
  }

  ngOnDestroy() {}
  async refresListFromChildren(event: Event) {}

  onBusqueda(gaaa?: any) {
    if (this.NomBusqueda == '') {
      this.geofencesService.tblDataGeoFiltered =
        this.geofencesService.getTableData();
      this.noResults = false;
    } else {
      this.geofencesService.tblDataGeoFiltered = this.geofencesService
        .getTableData()
        .filter((geocerca: any) => {
          return (
            geocerca.trama.orden != null &&
            geocerca.trama.orden
              .normalize('NFKD')
              .replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ0-9 -_.@]+/g, '')
              .toUpperCase()
              .includes(
                this.NomBusqueda.normalize('NFKD')
                  .replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ0-9 -_.@]+/g, '')
                  .toUpperCase()
              )
          );
        });
      this.noResults = this.geofencesService.tblDataGeoFiltered.length == 0;
    }
  }

  matchesSearch(rowData?: any): boolean {
    const searchFields = ['zone_name'];

    return searchFields.some((field) => {
      const cellValue = rowData[field].toString().toLowerCase();
      return cellValue.includes(this.searchValueGeo.toLowerCase());
    });
  }

  onQuickFilterChanged(data: any) {
    this.tt.filterGlobal(data.target.value, 'contains');
    this.tt.defaultSortOrder = -1;
  }

  onClickAddTags() {
    // this.geofencesService.compTags = 'MODAL TAG';
    // this.geofencesService.actionTags = 'add';
    this.eventDisplayTags.emit(true);
  }

  validateFormsInputs() {
    const inputElement = this.nameEdit;
    this.isFormName = inputElement.trim() !== '';
  }

  validateRepeatName(name: string) {
    this.isExistTag = false;
    if (this.nameTagInit !== this.nameEdit) {
      let aux = this.geofencesService.listTag.some(
        (tg: any) => tg.var_name == name
      );
      console.log('Si se repite el nombre', aux);
      return aux;
    }
    return false;
  }
  verifyEmptyList() {
    if (!this.list2 || this.list2.length === 0) {
      this.listTagsEmpty = true;
      return true;
    }
    return false;
  }

  onConfirmationEdit() {
    this.loading = true;
    let currName = this.nameEdit;
    //let currName = this.nameEdit.nativeElement.value;
    // if (!this.isFormName) {
    //   Swal.fire({
    //     title: 'Error',
    //     text: `Al editar la Etiqueta debe tener un nombre.`,
    //     icon: 'error',
    //   }).then(() => {
    //     this.loading = false; // Restablece el estado de carga en caso de error.
    //   });
    //   return;
    // }

    this.isExistTag = this.validateRepeatName(currName);
    if (this.isExistTag) {
      Swal.fire({
        title: 'Error',
        text: `Ya existe una etiqueta con ese nombre, ingrese otro distinto.`,
        icon: 'error',
      }).then(() => {
        this.loading = false; // Restablece el estado de carga en caso de error.
      });
      return;
    }
    this.listTagsEmpty = this.verifyEmptyList();
    if (this.listTagsEmpty) {
      Swal.fire({
        title: 'Error',
        text: `La lista debe contener mínimo una geocerca.`,
        icon: 'error',
      }).then(() => {
        this.loading = false; // Restablece el estado de carga en caso de error.
      });
      return;
    }
    let currType = this.dataEdit.type;
    const req = {
      type: this.dataEdit.type,
      id: this.dataEdit.id,
      list1: this.list1.map((item: { id: any; type: any }) => ({
        id: item.id,
        type: item.type,
      })),
      list2: this.list2.map((item: { id: any; type: any }) => ({
        id: item.id,
        type: item.type,
      })),
      var_name: this.nameEdit,
    };
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Se aplicarán los cambios',
      //icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      customClass: {
        actions: 'w-100',
        cancelButton: 'col-4',
        confirmButton: 'col-4',
      },
      preConfirm: async () => {
        this.geofencesService.updateTagAndAssingGeo(req).subscribe(
          async (response) => {
            // Manejar la respuesta del servidor si es necesario
            console.log('Actualización exitosa:', response);
            if (!response.success) {
              Swal.fire('Error', response.message, 'warning');
            } else if (response.success) {
              await this.geofencesService.updateListTags(response.tag);
              await this.geofencesService.updateListGeofences(response.geos);
              this.geofences = await this.geofencesService.createTreeNode();
              this.loading = false;
              Swal.fire('', response.message, 'success');
              this.displayEditTags = false;
            }
          },
          (error) => {
            // Manejar errores si la actualización falla
            console.error('Error al guardar la información:', error);
            Swal.fire('Error', 'Ocurrió un error...', 'warning');
          }
        );
      },
    }).then((data) => {
      console.log(`respuesta`, data);
      this.loading = false;
    });
  }

  showEditTag(data: any) {
    console.log('Data', data);
    this.dataEdit = data;
    this.displayEditTags = true;
    this.textHeaderEdit = data.type + ' ' + data.name;
    this.nameEdit = data.name;
    this.nameTagInit = data.name;
    console.log('name initial:', this.nameTagInit);
    let aux1: any = [];
    let aux2: any = [];
    //list 1
    const geos = this.geofencesService.listGeofences.map(
      (geo: {
        id: any;
        zone_name: any;
        type: any;
        idoperation: any;
        tags: any[];
        zone_color: any;
      }) => ({
        id: geo.id,
        zone_name: geo.zone_name,
        type: geo.type,
        idoperation: geo.idoperation,
        tags: geo.tags,
        zone_color: geo.zone_color,
      })
    );
    const geofences = geos.filter(
      (geofence: any) => geofence.idoperation == data.idOpe
    );
    for (const key in geofences) {
      const geoExist = geofences[key]['tags'].includes(data.id);
      if (geoExist) {
        aux2.push(geofences[key]);
      } else {
        aux1.push(geofences[key]);
      }
      //id 0 significa que no tiene
    }
    this.list2 = aux2;
    this.list1 = aux1;
    console.log('LISTA --> 1', this.list1);
    console.log('LISTA --> 2', this.list2);
  }

  upList1() {
    let aux: any = [];
    //recupero valores upList2
    for (const key in this.list1) {
      aux.push(this.list1[key]);
    }
    // inserto valores nuevos
    for (const key in this.selectedList2) {
      aux.push(this.selectedList2[key]);
    }
    //inserto valores en list1
    this.list1 = aux;
    console.log('list1', this.list1);
    //vacio valores de list 2
    let aux2: any = [];
    let aux_status = false;
    for (const key in this.list2) {
      let aux_status = false;
      for (const key2 in this.selectedList2) {
        if (this.list2[key] == this.selectedList2[key2]) {
          aux_status = true;
        }
      }
      if (!aux_status) {
        aux2.push(this.list2[key]);
      }
    }
    this.list2 = aux2;
    this.selectedList2 = [];
  }

  upList2() {
    let aux: any = [];
    //recupero valores upList2
    for (const key in this.list2) {
      aux.push(this.list2[key]);
    }
    // inserto valores nuevos
    for (const key in this.selectedList1) {
      aux.push(this.selectedList1[key]);
    }
    //inserto valores en list2
    this.list2 = aux;
    console.log('list2', this.list2);
    //vacio valores de list 1
    let aux2: any = [];
    let aux_status = false;
    for (const key in this.list1) {
      let aux_status = false;
      for (const key2 in this.selectedList1) {
        if (this.list1[key] == this.selectedList1[key2]) {
          aux_status = true;
        }
      }
      if (!aux_status) {
        aux2.push(this.list1[key]);
      }
    }
    this.list1 = aux2;
    this.selectedList1 = [];
  }

  showDelete(data: any) {
    var idOpe = data.idOpe;
    let existOtherOp: boolean = false;
    var idTag = data.id;
    var geosDelets: any[] = [];
    let geosOpe = this.geofencesService.listGeofences.filter(
      (geo: { idoperation: any }) => geo.idoperation == idOpe
    );
    let geosDontOpe = this.geofencesService.listGeofences.filter(
      (geo: { idoperation: any }) => geo.idoperation != idOpe
    );
    geosDontOpe.forEach((geo: { id: number; tags: string[] }) => {
      if (geo.tags.includes(idTag)) {
        existOtherOp = true;
      }
    });
    if (existOtherOp) {
      console.log('en otra operacion hay tag');
      Swal.fire({
        title: 'Error',
        text: `Etiqueta "${data.name}" exite en otra operación.`,
        icon: 'error',
      });
      return;
    }
    for (const geo of geosOpe) {
      const geoTag = geo.tags;
      const geoMap = {
        id: geo.id,
        type: geo.type,
      };
      if (geoTag.includes(idTag)) {
        geosDelets.push(geoMap);
      }
    }
    if (data['id'] == null) {
      console.log('no hay id');
      Swal.fire({
        title: 'Error',
        text: 'No existe Agrupación, recarge la página ...',
        icon: 'error',
      });
    }
    const req = {
      id: data.id,
      type: data.type,
      geofences: geosDelets,
    };
    console.log('eliminar', req);
    Swal.fire({
      title: 'Confirmación',
      text: `¿Está seguro de eliminar, Etiqueta: ${data.name}?`,
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'w-100',
        cancelButton: 'col-4',
        confirmButton: 'col-4',
      },
      preConfirm: async () => {
        this.geofencesService.deleteTagOfGeo(req).subscribe(
          async (response) => {
            // Manejar la respuesta del servidor si es necesario
            console.log('eliminación exitosa:', response);
            if (!response.success) {
              Swal.fire('Error', response.message, 'warning');
            } else if (response.success) {
              await this.geofencesService.removeListTag(response.tag);
              await this.geofencesService.updateListGeofences(response.geos);
              this.geofences = await this.geofencesService.createTreeNode();
              this.loading = false;
              Swal.fire(
                '',
                'Se eliminó la etiqueta correctamente!!',
                'success'
              );
            }
          },
          (error) => {
            // Manejar errores si la actualización falla
            console.error('Error al guardar la información:', error);
            Swal.fire('Error', 'Ocurrió un error...', 'warning');
          }
        );
      },
    }).then((data) => {
      // if(data.isConfirmed) {
      //   Swal.fire(
      //     'Eliminar',
      //     `Eliminación de ${this.typeDelete} exitosa`,
      //     'success'
      //   );
      // }
    });
  }

  //Tableee

  headerScrollHandler() {
    setTimeout(() => {
      const headerBox = document.querySelector(
        '.p-treetable-scrollable-header-box'
      ) as HTMLElement;
      const contentBox = document.querySelector(
        '.p-treetable-tbody'
      ) as HTMLElement;
      if (headerBox != null && contentBox != null) {
        if (headerBox!.offsetWidth > contentBox!.offsetWidth) {
          headerBox!.classList.remove('no-scroll');
        } else {
          headerBox!.classList.add('no-scroll');
        }
      }
    }, 1000);
  }
  get tblDataGeoFiltered() {
    return this.circularGeofencesService.tblDataGeoFiltered;
  }
  get showBtnAdd() {
    return this.circularGeofencesService.showBtnAdd;
  }
  get showBtnEdit() {
    return this.circularGeofencesService.showBtnEdit;
  }
  get eyeInputSwitch() {
    return this.circularGeofencesService.eyeInputSwitch;
  }
  set eyeInputSwitch(value: boolean) {
    this.circularGeofencesService.eyeInputSwitch = value;
  }
  get tagNamesEyeState() {
    return this.circularGeofencesService.tagNamesEyeState;
  }

  treeTableResizing(e: any) {
    const navbarHeight = Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--navbar-height')
        .replace('rem', '')
    );
    const rowBusquedaHeight = Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--row-busqueda-height')
        .replace('rem', '')
    );
    const panelMonitoreogeofencesHeaderHeight = Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--pm-vehiculos-header-height')
        .replace('rem', '')
    );
    const treetableHeaderHeight = Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--treetable-header-height')
        .replace('rem', '')
    );
    const rem_to_px = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    var treeTable_height_in_px =
      $('.map-area-app').height()! -
      rem_to_px *
        (rowBusquedaHeight +
          panelMonitoreogeofencesHeaderHeight +
          treetableHeaderHeight +
          ($('.map-area-app').width()! > 740 ? 0 : navbarHeight));
    $('p-treetable.geofence-treetable .cdk-virtual-scroll-viewport').attr(
      'style',
      'height: ' + treeTable_height_in_px + 'px !important'
    );
  }

  showGeosByTagOp(rowData: any) {
    console.log('rowData', rowData);
    //let displayGeos = this.geofencesService.listRows.find((item: { id: any; type: any; })=>item.id == rowData.id && item.type == rowData.type);
    let idOpe = rowData.idOpe;
    this.visibleRow.tempId = true;
    console.log('idOpe: ', rowData.idOpe);
    console.log('idTag: ', rowData.id);
    if (rowData.type == 'etiqueta') {
      var idTg = rowData.id;
      if (idTg == '0' && idOpe == '0') {
        const filteredGeos = this.geofencesService.listGeofences.filter(
          (geos: { idoperation: any }) => geos.idoperation == idOpe
        );
        filteredGeos.forEach(
          (geo: {
            id: number;
            type: string;
            zone_visible: string;
            tags: string[];
          }) => {
            if (geo.tags.length === 0 && geo.type == 'polig') {
              this.clickShowGeoPol(geo.id, true);
            } else if (geo.tags.length === 0 && geo.type == 'circ') {
              this.clickShowGeoCir(geo.id, true);
            }
          }
        );
      } else if (idTg == '0' && idOpe != '0') {
        const filteredGeos = this.geofencesService.listGeofences.filter(
          (geos: { idoperation: any }) => geos.idoperation == idOpe
        );
        filteredGeos.forEach(
          (geo: {
            id: number;
            type: string;
            zone_visible: string;
            tags: string[];
          }) => {
            if (geo.tags.length === 0 && geo.type == 'polig') {
              this.clickShowGeoPol(geo.id, true);
            } else if (geo.tags.length === 0 && geo.type == 'circ') {
              this.clickShowGeoCir(geo.id, true);
            }
          }
        );
      } else if (idTg != '0' && idOpe == '0') {
        const filteredGeos = this.geofencesService.listGeofences.filter(
          (geos: { idoperation: any }) => geos.idoperation == idOpe
        );
        filteredGeos.forEach(
          (geo: {
            id: number;
            type: string;
            zone_visible: string;
            tags: string[];
          }) => {
            if (
              geo.tags.includes(idTg) &&
              geo.tags.length == 1 &&
              geo.type == 'polig'
            ) {
              this.clickShowGeoPol(geo.id, true);
            } else if (
              geo.tags.includes(idTg) &&
              geo.tags.length == 1 &&
              geo.type == 'circ'
            ) {
              this.clickShowGeoCir(geo.id, true);
            }
          }
        );
      } else if (idTg != '0' && idOpe != '0') {
        const filteredGeos = this.geofencesService.listGeofences.filter(
          (geos: { idoperation: any }) => geos.idoperation == idOpe
        );
        filteredGeos.forEach(
          (geo: {
            type: string;
            id: number;
            tags: string | string[];
            zone_visible: string;
          }) => {
            if (
              geo.tags.includes(idTg) &&
              geo.tags.length == 1 &&
              geo.type == 'polig'
            ) {
              this.clickShowGeoPol(geo.id, true);
            } else if (
              geo.tags.includes(idTg) &&
              geo.tags.length == 1 &&
              geo.type == 'circ'
            ) {
              this.clickShowGeoCir(geo.id, true);
            }
          }
        );
      }
    } else if (rowData.type == 'operacion') {
      var idOp = rowData.id;
      this.geofencesService.listGeofences.forEach(
        (geo: {
          id: number;
          type: string;
          idoperation: string;
          zone_visible: string;
        }) => {
          if (geo.idoperation === idOp && geo.type == 'polig') {
            this.clickShowGeoPol(geo.id, true);
          } else if (geo.idoperation === idOp && geo.type == 'circ') {
            this.clickShowGeoCir(geo.id, true);
          }
        }
      );

      for (let i = 0; i < this.geofencesService.geofences.length; i++) {
        this.geofencesService.clearDrawingsOfGeofence(
          this.geofencesService.geofences[i]
        );
      }
      for (let i = 0; i < this.geofencesService.geofences.length; i++) {
        this.geofencesService.showDrawingsOfGeofence(
          this.geofencesService.geofences[i]
        );
      }
    }
  }

  onClickAllEyes() {
    this.onClickEyePol();
    this.onClickEyeCir();
    //this.clickShowGeoLin();
  }
  onClickEyePol() {
    var geofencesList = this.geofencesService.geofences.map(
      (geofence: { id: number; zone_visible: string }) => {
        return { id: geofence.id, visible: geofence.zone_visible };
      }
    );
    geofencesList.forEach((geofence: { id: number; visible: string }) => {
      if (
        (geofence.visible == 'true') !=
        this.geofencesService.eyeInputSwitch
      ) {
        this.clickShowGeoPol(geofence.id, true);
      }
    });

    for (let i = 0; i < this.geofencesService.geofences.length; i++) {
      this.geofencesService.clearDrawingsOfGeofence(
        this.geofencesService.geofences[i]
      );
    }
    for (let i = 0; i < this.geofencesService.geofences.length; i++) {
      this.geofencesService.showDrawingsOfGeofence(
        this.geofencesService.geofences[i]
      );
    }
  }
  onClickEyeCir() {
    let ciruclarGeofencesList =
      this.circularGeofencesService.circular_geofences.map(
        (circular_geofence: { id: number; zone_visible: string }) => {
          return {
            id: circular_geofence.id,
            visible: circular_geofence.zone_visible,
          };
        }
      );

    ciruclarGeofencesList.forEach(
      (circular_geofence: { id: number; visible: string }) => {
        if (
          (circular_geofence.visible == 'true') !=
          this.geofencesService.eyeInputSwitch
        ) {
          this.clickShowGeoCir(circular_geofence.id, true);
        }
      }
    );

    for (
      let i = 0;
      i < this.circularGeofencesService.circular_geofences.length;
      i++
    ) {
      this.circularGeofencesService.clearDrawingsOfGeofence(
        this.circularGeofencesService.circular_geofences[i]
      );
    }
    for (
      let i = 0;
      i < this.circularGeofencesService.circular_geofences.length;
      i++
    ) {
      this.circularGeofencesService.showDrawingsOfGeofence(
        this.circularGeofencesService.circular_geofences[i]
      );
    }
  }

  onClickShowAllNames() {
    this.onClickShowNamesPol();
    this.onClickShowNamesCir();
    this.onClickShowNamesLin();
  }
  onClickShowNamesPol() {
    this.geofencesService.tagNamesEyeState =
      !this.geofencesService.tagNamesEyeState;
    var geofencesList = this.geofencesService.geofences.map(
      (geofence: { id: number; zone_name_visible: string }) => {
        return { id: geofence.id, tag_visible: geofence.zone_name_visible };
      }
    );
    geofencesList.forEach((geofence: { id: number; tag_visible: string }) => {
      if (
        (geofence.tag_visible == 'true') !=
        this.geofencesService.tagNamesEyeState
      ) {
        this.clickShowGeoPolName(geofence.id, true);
      }
    });
  }
  onClickShowNamesCir() {
    this.circularGeofencesService.tagNamesEyeState = !this.tagNamesEyeState;
    let circularGeofencesList =
      this.circularGeofencesService.circular_geofences.map(
        (circular_geofence: { id: number; zone_name_visible: string }) => {
          return {
            id: circular_geofence.id,
            tag_visible: circular_geofence.zone_name_visible,
          };
        }
      );
    circularGeofencesList.forEach(
      (circular_geofence: { id: number; tag_visible: string }) => {
        if (
          (circular_geofence.tag_visible == 'true') !=
          this.tagNamesEyeState
        ) {
          this.clickShowGeoCirName(circular_geofence.id, true);
        }
      }
    );
  }
  onClickShowNamesLin() {
    this.polylineGeofenceService.tagNamesEyeState = !this.tagNamesEyeState;
    let polyGeofencesList = this.polylineGeofenceService.polyline_geofences.map(
      (circular_geofence: { id: number; zone_name_visible: string }) => {
        return {
          id: circular_geofence.id,
          tag_visible: circular_geofence.zone_name_visible,
        };
      }
    );
    polyGeofencesList.forEach(
      (circular_geofence: { id: number; tag_visible: string }) => {
        if (
          (circular_geofence.tag_visible == 'true') !=
          this.tagNamesEyeState
        ) {
          this.clickShowGeoLinName(circular_geofence.id, true);
        }
      }
    );
  }

  clickShowGeo(id: number, type: string) {
    if (type == 'polig') {
      this.clickShowGeoPol(id);
    } else if (type == 'circ') {
      this.clickShowGeoCir(id);
    } else if (type == 'line') {
      this.clickShowGeoLin(id);
    }
  }

  clickShowGeoPol(id: number, comesFromInputSwitch?: boolean) {
    var geo = this.geofencesService.geofences.filter(
      (item: any) => item.id == id
    )[0];
    let tempOld: string = '';
    if (geo.zone_visible == 'true') {
      geo.zone_visible = 'false';
      this.mapService.map.removeLayer(geo.geo_elemento);
      tempOld = geo.zone_name_visible_old;
      geo.zone_name_visible_old = geo.zone_name_visible;
      if (geo.zone_name_visible == 'true') {
        this.clickShowGeoPolName(id);
      }
    } else {
      geo.zone_visible = 'true';
      geo.geo_elemento.addTo(this.mapService.map);
      if (geo.zone_name_visible_old == 'true') {
        this.clickShowGeoPolName(id);
      }
    }
    this.geofencesService.updateGeoCounters();
    this.geofencesService.updateGeoTagCounters();

    if (typeof comesFromInputSwitch == 'undefined' || !comesFromInputSwitch) {
      let auxIndex = -1;
      this.geofencesService.eyeInputSwitch =
        this.geofencesService.geofenceCounters.visible != 0;

      if (geo.zone_visible == 'true') {
        console.log('Mostrar una geocerca' + id);
        //Redraw geofences in correct order
        for (let i = this.geofencesService.geofences.length - 1; i > -1; i--) {
          //Limpiar geocercas empezando desde la más pequeña, hasta la geocerca que se acaba de mandar show
          this.geofencesService.clearDrawingsOfGeofence(
            this.geofencesService.geofences[i]
          );
          if (this.geofencesService.geofences[i].id == id) {
            auxIndex = i;
            i = -1;
          }
        }
        //Redibujamos las geocercas empezando desde la que se debe mostrar, hasta la mas pequeña
        for (
          let i = auxIndex;
          i < this.geofencesService.geofences.length;
          i++
        ) {
          this.geofencesService.showDrawingsOfGeofence(
            this.geofencesService.geofences[i]
          );
        }
      }
    }
  }

  clickShowGeoCir(id: number, comesFromInputSwitch?: boolean) {
    let geo = this.circularGeofencesService.circular_geofences.filter(
      (item: any) => item.id == id
    )[0];
    let tempOld: string = '';
    if (geo.zone_visible == 'true') {
      geo.zone_visible = 'false';
      this.mapService.map.removeLayer(geo.geo_elemento);
      tempOld = geo.zone_name_visible_old;
      geo.zone_name_visible_old = geo.zone_name_visible;
      if (geo.zone_name_visible == 'true') {
        this.clickShowGeoCirName(id);
      }
    } else {
      geo.zone_visible = 'true';
      geo.geo_elemento.addTo(this.mapService.map);
      if (geo.zone_name_visible_old == 'true') {
        this.clickShowGeoCirName(id);
      }
    }

    this.circularGeofencesService.updateGeoCounters();
    this.circularGeofencesService.updateGeoTagCounters();

    if (typeof comesFromInputSwitch == 'undefined' || !comesFromInputSwitch) {
      let auxIndex = -1;
      this.circularGeofencesService.eyeInputSwitch =
        this.circularGeofencesService.circularGeofenceCounters.visible != 0;

      if (geo.zone_visible == 'true') {
        for (
          let i = this.circularGeofencesService.circular_geofences.length - 1;
          i > -1;
          i--
        ) {
          this.circularGeofencesService.clearDrawingsOfGeofence(
            this.circularGeofencesService.circular_geofences[i]
          );
          if (this.circularGeofencesService.circular_geofences[i].id == id) {
            auxIndex = i;
            i = -1;
          }
        }
        for (
          let i = auxIndex;
          i < this.circularGeofencesService.circular_geofences.length;
          i++
        ) {
          this.circularGeofencesService.showDrawingsOfGeofence(
            this.circularGeofencesService.circular_geofences[i]
          );
        }
      }
    }
  }

  clickShowGeoLin(id: number, comesFromInputSwitch?: boolean) {
    let geo = this.polylineGeofenceService.polyline_geofences.filter(
      (item: any) => item.id == id
    )[0];

    if (geo.zone_visible == 'true') {
      geo.zone_visible = 'false';
      this.mapService.map.removeLayer(geo.geo_elemento);
      if (geo.zone_name_visible == 'true') {
        this.clickShowGeoLinName(id);
      }
    } else {
      geo.zone_visible = 'true';
      geo.geo_elemento.addTo(this.mapService.map);
      this.clickShowGeoLinName(id);
    }

    this.polylineGeofenceService.updateGeoCounters();
    this.polylineGeofenceService.updateGeoTagCounters();

    if (typeof comesFromInputSwitch == 'undefined' || !comesFromInputSwitch) {
      let auxIndex = -1;

      this.polylineGeofenceService.eyeInputSwitch =
        this.polylineGeofenceService.polylineGeofenceCounters.visible != 0;

      if (geo.zone_visible == 'true') {
        for (
          let i = this.polylineGeofenceService.polyline_geofences.length - 1;
          i > -1;
          i--
        ) {
          this.polylineGeofenceService.clearDrawingsOfGeofence(
            this.polylineGeofenceService.polyline_geofences[i]
          );
          if (this.polylineGeofenceService.polyline_geofences[i].id == id) {
            auxIndex = i;
            i = -1;
          }
        }
        for (
          let i = auxIndex;
          i < this.polylineGeofenceService.polyline_geofences.length;
          i++
        ) {
          this.polylineGeofenceService.showDrawingsOfGeofence(
            this.polylineGeofenceService.polyline_geofences[i]
          );
        }
      }
    }
  }

  clickShowGeoName(id: number, type: string) {
    if (type == 'polig') {
      this.clickShowGeoPolName(id);
    } else if (type == 'circ') {
      this.clickShowGeoCirName(id);
    } else if (type == 'line') {
      this.clickShowGeoLinName(id);
    }
  }

  clickShowGeoPolName(id: number, comesFromInputSwitch?: boolean) {
    var geo = this.geofencesService.geofences.filter(
      (item: any) => item.id == id
    )[0];
    if (geo.zone_name_visible == 'true') {
      geo.zone_name_visible = 'false';
      geo.zone_name_visible_bol = false;

      this.mapService.map.removeLayer(geo.marker_name);
    } else {
      geo.zone_name_visible = 'true';
      geo.zone_name_visible_bol = true;

      geo.marker_name.addTo(this.mapService.map);
    }
    //geo.zone_name_visible_old = geo.zone_name_visible;
    this.geofencesService.updateGeoTagCounters();
    if (typeof comesFromInputSwitch == 'undefined' || !comesFromInputSwitch) {
      this.geofencesService.tagNamesEyeState =
        this.geofencesService.geofenceTagCounters.visible != 0;
    }
    //console.log('geoZone', geo.zone_name_visible,geo.zone_name_visible_old);
  }

  clickShowGeoCirName(id: number, comesFromInputSwitch?: boolean) {
    var geo = this.circularGeofencesService.circular_geofences.filter(
      (item: any) => item.id == id
    )[0];

    if (geo.zone_name_visible == 'true') {
      geo.zone_name_visible = 'false';
      geo.zone_name_visible_bol = false;
      this.mapService.map.removeLayer(geo.marker_name);
    } else {
      geo.zone_name_visible = 'true';
      geo.zone_name_visible_bol = true;
      geo.marker_name.addTo(this.mapService.map);
    }
    this.circularGeofencesService.updateGeoTagCounters();
    if (typeof comesFromInputSwitch == 'undefined' || !comesFromInputSwitch) {
      this.circularGeofencesService.tagNamesEyeState =
        this.circularGeofencesService.circularGeofenceTagCounters.visible != 0;
    }
  }

  clickShowGeoLinName(id: number, comesFromInputSwitch?: boolean) {
    var geo = this.polylineGeofenceService.polyline_geofences.filter(
      (item: any) => item.id == id
    )[0];

    if (geo.zone_name_visible == 'true') {
      geo.zone_name_visible = 'false';
      geo.zone_name_visible_bol = false;
      this.mapService.map.removeLayer(geo.marker_name);
    } else {
      geo.zone_name_visible = 'true';
      geo.zone_name_visible_bol = true;
      geo.marker_name.addTo(this.mapService.map);
    }
    this.polylineGeofenceService.updateGeoTagCounters();
    if (typeof comesFromInputSwitch == 'undefined' || !comesFromInputSwitch) {
      this.polylineGeofenceService.tagNamesEyeState =
        this.polylineGeofenceService.polylineGeofenceTagCounters.visible != 0;
    }
  }

  clickLocate(id: number, type: string) {
    if (type == 'polig') {
      this.clickLocatePol(id);
    } else if (type == 'circ') {
      this.clickLocateCir(id);
    } else if (type == 'line') {
      this.clickLocateLin(id);
    }
  }
  clickLocatePol(id: any) {
    var geo = this.geofencesService.geofences.filter(
      (item: any) => item.id == id
    )[0];
    this.mapService.map.fitBounds(geo.geo_elemento.getBounds(), {
      padding: [50, 50],
    });
  }
  clickLocateCir(id: number) {
    console.log('geo service id', id);

    var geo = this.circularGeofencesService.circular_geofences.filter(
      (item: any) => item.id == id
    )[0];
    console.log();
    console.log('geo service geo', geo);

    this.mapService.map.fitBounds(geo.geo_elemento.getBounds(), {
      padding: [50, 50],
    });
  }
  clickLocateLin(id: any) {
    const geo = this.polylineGeofenceService.polyline_geofences.filter(
      (item: any) => item.id == id
    )[0];
    this.mapService.map.fitBounds(geo.geo_elemento.getBounds(), {
      padding: [50, 50],
    });
  }
  clickGeoView(id: number, type: string){
    if (type=='polig') {
      this.clickGeoViewPol(id);
    }else if (type == 'circ') {
      this.clickGeoViewCir(id);
    }else if (type == 'line') {
      this.clickGeoViewLin(id);
    }
  }
  clickGeoViewPol(id: number){
    this.geofencesService.nameComponentPol = 'ADD GEO';
    this.geofencesService.action = 'view pol';
    this.geofencesService.idGeocercaEdit = id;
    this.geofencesService.setDivEnabled(false);
  }
  clickGeoViewCir(id: number){
    this.geofencesService.nameComponentPol = 'ADD GEO';
    this.geofencesService.action = 'view cir';
    this.geofencesService.idGeocercaEdit = id;
    this.geofencesService.setDivEnabled(false);
  }
  clickGeoViewLin(id: number){
    this.geofencesService.nameComponentPol = 'ADD GEO';
    this.geofencesService.action = 'view lin';
    this.geofencesService.idGeocercaEdit = id;
    this.geofencesService.setDivEnabled(false);
  }

  clickAddGeoPol() {
    this.geofencesService.nameComponentPol = 'ADD GEO';
    this.geofencesService.action = 'add';
  }

  clickConfigGeocerca(id: number, type: string) {
    if (type == 'polig') {
      this.clickConfigGeocercaPol(id);
    } else if (type == 'circ') {
      this.clickConfigGeocercaCir(id);
    } else if (type == 'line') {
      //this.clickConfigurarGeocercaLin(id);
    }
  }

  clickConfigGeocercaPol(id: number) {
    this.geofencesService.nameComponentPol = 'ADD GEO';
    this.geofencesService.action = 'edit pol';
    this.geofencesService.idGeocercaEdit = id;
    this.geofencesService.setDivEnabled(false);
  }

  clickConfigGeocercaCir(id: number) {
    this.geofencesService.nameComponentPol = 'ADD GEO';
    this.geofencesService.action = 'edit cir';
    this.geofencesService.idGeocercaEdit = id;
    this.geofencesService.setDivEnabled(false);
  }

  clickDeleteGeo(id: number, type: string) {
    if (type == 'polig') {
      this.clickDeleteGeoPol(id);
    } else if (type == 'circ') {
      this.clickDeleteGeoCir(id);
    } else if (type == 'line') {
      //this.clickDeleteGeoLin(id);
    }
  }

  async clickDeleteGeoPol(id: number) {
    this.geofencesService.action = 'delete';
    var geo = this.geofencesService.geofences.filter(
      (item: any) => item.id == id
    )[0];

    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar "${geo.zone_name}"?`,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      preConfirm: async () => {
        const res = await this.geofencesService.delete(id);
        // this.deleteAlert.emit();
        // this.clickShowPanel(this.nameComponent);
        this.mapService.map.removeLayer(geo.geo_elemento);
        this.mapService.map.removeLayer(geo.marker_name);

        for (var i = 0; i < this.geofencesService.geofences.length; i++) {
          if (this.geofencesService.geofences[i].id === id) {
            this.geofencesService.geofences.splice(i, 1);
            i--;
          }
        }
        this.geofencesService.updateGeoCounters();
        this.geofencesService.updateGeoTagCounters();
        this.geofencesService.eyeInputSwitch =
          this.geofencesService.geofenceCounters.visible != 0;
        this.geofencesService.tagNamesEyeState =
          this.geofencesService.geofenceTagCounters.visible != 0;
        this.onBusqueda();
        this.objGeofences.geofences = [];
        this.ngOnInit();
      },
    }).then(async (data) => {
      if (data.isConfirmed) {
        Swal.fire(
          'Eliminado',
          'Los datos se eliminaron correctamente!!',
          'success'
        );
      }
    });
  }

  clickDeleteGeoCir(id: number) {
    this.circularGeofencesService.action = 'delete';
    var geo = this.circularGeofencesService.circular_geofences.filter(
      (item: any) => item.id == id
    )[0];

    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar "${geo.zone_name}"?`,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      preConfirm: async () => {
        const res = await this.circularGeofencesService.delete(id);
        this.mapService.map.removeLayer(geo.geo_elemento);
        this.mapService.map.removeLayer(geo.marker_name);

        for (
          var i = 0;
          i < this.circularGeofencesService.circular_geofences.length;
          i++
        ) {
          if (this.circularGeofencesService.circular_geofences[i].id === id) {
            this.circularGeofencesService.circular_geofences.splice(i, 1);
            i--;
          }
        }
        this.circularGeofencesService.initializeTable();
        this.circularGeofencesService.updateGeoCounters();
        this.circularGeofencesService.updateGeoTagCounters();
        this.circularGeofencesService.eyeInputSwitch =
          this.circularGeofencesService.circularGeofenceCounters.visible != 0;
        this.circularGeofencesService.tagNamesEyeState =
          this.circularGeofencesService.circularGeofenceTagCounters.visible !=
          0;
        this.onBusqueda();
        this.objGeofences.geofences = [];
        this.ngOnInit();
      },
    }).then((data) => {
      if (data.isConfirmed) {
        Swal.fire(
          'Eliminado',
          'Los datos se eliminaron correctamente!!',
          'success'
        );
      }
    });
  }

  tableView(btn: number): void {
    this.btnSelected = btn;
    if (btn == 1) {
      this.viewOptions = 'viewGroup';
    } else if (btn == 2) {
      this.viewOptions = 'viewGen';
    }
  }
  clickAgregarZona() {
    console.log('aver');
    this.geofencesService.modalActive = true;
    this.geofencesService.action = 'add';
  }
  /**
   *
   */
  showDialogExport() {
    this.showExport = true;
    this.geoImpExp = 'export';
    this.geofencesService.modalActive = true;
  }
  /**
   *
   * @param e :boolean, cierra el dialogo de importacion
   */
  closeShowDialog(e: boolean) {
    this.showExport = e;
  }
  /**
   *
   * @param hex :string, color of #aabbggrr or #bbggrr
   * @returns :string, color to #rrggbb
   */
  colorHexToRGB(hex: string): string {
    const cleanedHex = hex.startsWith('#') ? hex.slice(1) : hex;
    const filterHex = (s: string): string => {
      return parseInt(s, 16).toString(16).padStart(2, '0');
    };
    const directoryHex: Record<number, string> = {
      6:
        '#' +
        filterHex(cleanedHex.slice(4, 6)) +
        filterHex(cleanedHex.slice(2, 4)) +
        filterHex(cleanedHex.slice(0, 2)),
      8:
        '#' +
        filterHex(cleanedHex.slice(6, 8)) +
        filterHex(cleanedHex.slice(4, 6)) +
        filterHex(cleanedHex.slice(2, 4)),
    };

    return directoryHex[cleanedHex.length] ?? '#000000';
  }
  /**
   * @deprecated Funcion que retorna un color hexadecimal
   * @param color hexadecimal color
   * @param alfa numero hasta 255 recomendado
   * @returns string en #RRGGBB
   */
  colorHexABGRToHexRGB(color: string, alfa?: number): string {
    color = color.replace('#', '');
    let abgr: ABGR = {
      A: 'ff',
      B: '00',
      G: '00',
      R: '00',
    };
    if (color.length === 8 || color.length === 6) {
      alfa
        ? (abgr.A = alfa.toString(16).padStart(2, '0'))
        : color.length === 8
        ? (abgr.A = parseInt(color.substring(0, 2), 16)
            .toString(16)
            .padStart(2, '0'))
        : (abgr.A = (255).toString(16).padStart(2, '0')),
        (abgr.B = parseInt(color.substring(2, 4), 16)
          .toString(16)
          .padStart(2, '0'));
      abgr.G = parseInt(color.substring(4, 6), 16)
        .toString(16)
        .padStart(2, '0');
      abgr.R = parseInt(color.substring(6, 8), 16)
        .toString(16)
        .padStart(2, '0');
    }
    return `#${abgr.R}${abgr.G}${abgr.B}`;
  }
  /**
   * Funcion de importacion de archivos kml/kmz y csv
   * @param event :any, archivo del input
   */
  async importFile(event: any) {
    const fileInput = event.target;
    const fileValidity: FileValidationData = {
      mimetype: [
        'application/vnd.google-earth.kml+xml',
        'application/vnd.google-earth.kmz',
        'application/xml',
        'text/csv',
        'text/plain',
      ],
      type: ['kml', 'kmz', 'csv', 'xml'],
      size: 5120000, //bytes
    };
    if (fileInput.files.length == 1) {
      const file: File = fileInput.files[0]; // carga del archivo
      const importDictionary: Record<string, () => Promise<GeoImp> | GeoImp> = {
        kml: () => this.importKML(file),
        kmz: () => this.importKMZ(file),
        csv: () => this.importCSV(file),
        xml: () => this.importKML(file),
      };
      const importFuncion =
        importDictionary[file.name.split('.').pop() ?? ''].bind(this);
      const handleImport = async () => {
        try {
          this.geofencesService.modalActive = true; // boolean [true,false]
          this.geofencesService.action = 'add'; // string [add,edit.delete]
          this.geoImpExp = 'import';
          const importData = await importFuncion();
          const validators: Record<string, ValidImport> = {
            mimetype: {
              isValid: fileValidity.mimetype.includes(file.type),
              errorMessage: 'Formato de archivo no válido',
            },
            type: {
              isValid: fileValidity.type.includes(
                file.name.split('.').pop() || ''
              ),
              errorMessage: 'Tipo de archivo no válido',
            },
            size: {
              isValid: file.size < fileValidity.size,
              errorMessage: 'Tamaño de archivo supero el limite de 5 MB',
            },
          };
          console.log(
            'geoImp: validation mimetype, type, size',
            file.type,
            fileValidity.mimetype.includes(file.type),
            file.name,
            fileValidity.type.includes(file.name.split('.').pop() || ''),
            file.size,
            fileValidity.size > file.size
          );
          if (
            (fileValidity.mimetype.includes(file.type) ||
              fileValidity.type.includes(file.name.split('.').pop() || '')) &&
            fileValidity.size > file.size
          ) {
            const handleModal = async () => {
              if (this.modalChild) {
                await this.modalChild.dataFromFather(importData, file.name);
              } else {
                this.geofencesService.modalActive = false;
                throw new Error(
                  'Hubo un error al momento de realizar la importación de geocercas'
                );
              }
            };
            handleModal();
          } else {
            Object.keys(validators).map((v) => {
              if (!validators[v].isValid) {
                throw new Error(validators[v].errorMessage);
              }
            });
          }
        } catch (error) {
          console.error('geoImp: error in import', error);
          error = error instanceof Error ? error.message : error;
          Swal.fire({
            icon: 'error',
            title: '¡Vaya!',
            text: `${error}`,
          });
        }
      };
      handleImport();
    }
    fileInput.value = '';
  }
  async importKML(f: File): Promise<GeoImp> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const kmlData = event.target.result as string;
          const parsedKML = this.parseKML(kmlData);
          resolve(parsedKML);
        } else {
          reject('Hubo un problema al obtener el contenido del archivo .kml');
        }
      };
      reader.onerror = (error) => {
        console.error(error);

        reject('Hubo un problema al procesar el archivo .kml');
      };
      reader.readAsText(f);
    });
  }
  async importKMZ(f: File): Promise<GeoImp> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          // const kmzData = event.target.result as string;
          // const parsedCSV = this.parseKMZ(kmzData);
          // resolve(parsedCSV);
          reject('Formato en implementación ...');
        } else {
          reject('Hubo un problema al obtener el contenido del archivo .kmz');
        }
      };
      reader.onerror = (error) => {
        console.error(error);

        reject('Hubo un problema al procesar el archivo .kmz');
      };
      reader.readAsText(f);
    });
  } // No implementado
  async importCSV(f: File): Promise<GeoImp> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const csvData = event.target.result as string;
          const firstLine = csvData.split('\n')[0];
          if (!firstLine.includes('|')) {
            reject(
              'El archivo .csv proporcionado no cuenta con el formato adecuado'
            );
          }
          const parsedCSV = this.parseCSV(csvData);
          resolve(parsedCSV);
        } else {
          reject('Hubo un problema al obtener el contenido del archivo .csv');
        }
      };
      reader.onerror = (error) => {
        console.error(error);

        reject('Hubo un problema al procesar el archivo .csv');
      };
      reader.readAsText(f);
    });
  }
  async parseCSV(csvData: string): Promise<GeoImp> {
    const importGeofencesOfCsv: GeoImp = {
      geo: {
        Document: {
          name: '',
          importGeofences: [],
        },
      },
    };
    const lines = csvData.split('\n');
    const headers = lines[0].split('|');
    const dataLines = lines.splice(1);
    importGeofencesOfCsv.geo.Document.importGeofences = Object.entries(
      dataLines
    ).map(([_, line], i) => {
      const columns = line.split('|');
      const geoImport: ImportGeofence = {
        id: columns[headers.indexOf('id')] ?? this.generateUUID(),
        type: columns[headers.indexOf('type')] ?? '',
        geo_coordenadas: columns[headers.indexOf('geoCoordinate')] ?? '',
        zone_vertices: columns[headers.indexOf('geoVertices')] ?? '',
        zone_name: columns[headers.indexOf('geoName')].trim() || 'No Name',
        zone_perimetro: columns[headers.indexOf('perimetry')] ?? '',
        zone_area: columns[headers.indexOf('area')] ?? '',
        zone_color: columns[headers.indexOf('colorGeofence')] ?? '',
        tag_name_color: columns[headers.indexOf('colorText')] ?? '',
        tag_name_font_size: parseInt(columns[headers.indexOf('sizeText')] ?? 0),
        vel_act_zone:
          headers.indexOf('hasSpeed') !== -1
            ? columns[headers.indexOf('hasSpeed')] === 'true'
            : false,
        vel_max: parseInt(columns[headers.indexOf('maxSpeed')]) ?? 0,
        vel_zona: parseInt(columns[headers.indexOf('tolerableSpeed')]) ?? 0,
        vel2_zona: parseInt(columns[headers.indexOf('seriousSpeed')]) ?? 0,
        vel3_zona: parseInt(columns[headers.indexOf('verySeriousSpeed')]) ?? 0,
        zone_visible:
          headers.indexOf('zone_visible') !== -1
            ? columns[headers.indexOf('zone_visible')] === 'true'
            : false,
        zone_name_visible:
          headers.indexOf('zone_name_visible') !== -1
            ? columns[headers.indexOf('zone_name_visible')] === 'true'
            : false,
        idoperation: parseInt(columns[headers.indexOf('idOperation')] ?? 0),
        nameoperation: columns[headers.indexOf('nameOperation')] ?? '',
        tags: [],
        descripcion: columns[headers.indexOf('description')] ?? '',
        orden: columns[headers.indexOf('description')] ?? '',
        origin: 'csv',
        errors: [],
        hasStandardGeofences: false,
        StandardGeofences: {
          name: '',
          description: '',
          Style: {
            LineStyle: {
              width: '',
              color: '',
            },
            PolyStyle: {
              color: '',
            },
          },
          Polygon: {
            outerBoundaryIs: {
              LinearRing: {
                coordinates: '',
              },
            },
          },
        },
      };
      geoImport.hasStandardGeofences =
        this.hasDataInStandardGeofence(geoImport);
      return geoImport;
    });
    console.log('geoImp: csv', importGeofencesOfCsv);
    return importGeofencesOfCsv;
  }
  async parseKML(kmlData: string): Promise<GeoImp> {
    let importGeofencesOfKml: GeoImp = {
      geo: {
        Document: {
          name: '',
          importGeofences: [],
        },
      },
    };
    const getTextContent = (Element: Element, tagName: string) => {
      const element = Element.getElementsByTagName(tagName)[0];
      return element ? element.textContent?.toString().trim() ?? '' : '';
    };
    const NameSpace: Record<string, string> = {
      xmlns: 'http://www.opengis.net/kml/2.2',
      'xmlns:gx': 'http://www.google.com/kml/ext/2.2',
      'xmlns:kml': 'http://www.opengis.net/kml/2.2',
      'xmlns:atom': 'http://www.w3.org/2005/Atom',
    };
    const parse = new DOMParser();
    const xmlDoc = parse.parseFromString(kmlData, 'application/xhtml+xml');
    const StyleMapElemnets = xmlDoc.getElementsByTagNameNS(
      NameSpace['xmlns'],
      'StyleMap'
    );
    const StyleMapCascadingStyleElements = xmlDoc.getElementsByTagNameNS(
      NameSpace['xmlns:gx'],
      'CascadingStyle'
    );
    const placemark = xmlDoc.getElementsByTagName('Placemark');
    importGeofencesOfKml.geo.Document.name =
      xmlDoc.getElementsByTagName('name')[0].textContent?.toString().trim() ??
      '';
    importGeofencesOfKml.geo.Document.importGeofences = Object.entries(
      placemark
    ).map(([_, placemarkElement], i) => {
      const geoImport: ImportGeofence = {
        id: this.generateUUID(),
        type: 'polig',
        geo_coordenadas: '',
        zone_vertices: '',
        zone_name: '',
        zone_perimetro: '',
        zone_area: '',
        zone_color: '',
        tag_name_color: '',
        tag_name_font_size: 0,
        vel_act_zone: false,
        vel_max: 0,
        vel_zona: 0,
        vel2_zona: 0,
        vel3_zona: 0,
        zone_visible: false,
        zone_name_visible: false,
        idoperation: 0,
        nameoperation: '',
        tags: [],
        descripcion: '',
        orden: '',
        origin: 'kml',
        errors: [],
        hasStandardGeofences: false,
        StandardGeofences: {
          name: '',
          description: '',
          Style: {
            LineStyle: {
              width: '',
              color: '',
            },
            PolyStyle: {
              color: '',
            },
          },
          Polygon: {
            outerBoundaryIs: {
              LinearRing: {
                coordinates: '',
              },
            },
          },
        },
      };
      const GLTracker = placemarkElement.getElementsByTagName('GLTracker')[0];
      const placemarkStyleUrl = getTextContent(placemarkElement, 'styleUrl');
      const placemarkStyle = placemarkElement.getElementsByTagName('Style')[0];
      const geoPoint = placemarkElement.getElementsByTagName('Point')[0];
      const geoLine = placemarkElement.getElementsByTagName('LineString')[0];
      if (GLTracker) {
        geoImport.id = getTextContent(GLTracker, 'id');
        geoImport.type = getTextContent(GLTracker, 'type');
        geoImport.geo_coordenadas = getTextContent(GLTracker, 'geoCoordinate');
        geoImport.zone_vertices = getTextContent(GLTracker, 'geoVertices');
        geoImport.zone_name =
          getTextContent(GLTracker, 'geoName').trim() || 'No Name';
        geoImport.zone_perimetro = getTextContent(GLTracker, 'perimetry');
        geoImport.zone_area = getTextContent(GLTracker, 'area');
        geoImport.zone_color = getTextContent(GLTracker, 'colorGeofence');
        geoImport.zone_visible =
          getTextContent(GLTracker, 'zone_visible') === 'true' ?? false;
        geoImport.zone_name_visible =
          getTextContent(GLTracker, 'zone_name_visible') === 'true' ?? false;
        geoImport.tag_name_color = getTextContent(GLTracker, 'colorText');
        geoImport.tag_name_font_size = parseInt(
          getTextContent(GLTracker, 'sizeText')
        );
        geoImport.vel_act_zone =
          getTextContent(GLTracker, 'hasSpeed') === 'true' ?? false;
        geoImport.vel_max = parseInt(getTextContent(GLTracker, 'maxSpeed'));
        geoImport.vel_zona = parseInt(
          getTextContent(GLTracker, 'tolerableSpeed')
        );
        geoImport.vel2_zona = parseInt(
          getTextContent(GLTracker, 'seriousSpeed')
        );
        geoImport.vel3_zona = parseInt(
          getTextContent(GLTracker, 'verySeriousSpeed')
        );
        geoImport.idoperation = parseInt(
          getTextContent(GLTracker, 'idOperation') ?? 0
        );
        geoImport.nameoperation = getTextContent(GLTracker, 'nameOperation');
        geoImport.tags = [];
        geoImport.descripcion = getTextContent(GLTracker, 'description');
        geoImport.orden = getTextContent(GLTracker, 'description');
      }
      GLTracker ? placemarkElement.removeChild(GLTracker) : placemarkElement;
      geoImport.StandardGeofences.name = getTextContent(
        placemarkElement,
        'name'
      );
      geoImport.StandardGeofences.description = getTextContent(
        placemarkElement,
        'description'
      );
      geoImport.StandardGeofences.Polygon.outerBoundaryIs.LinearRing.coordinates =
        getTextContent(placemarkElement, 'coordinates');
      // geoImport.type = placemarkElement.getElementsByTagName('Point')[0]
      //   ? 'point'
      //   : geoImport.type;
      // geoImport.type = placemarkElement.getElementsByTagName('LineString')[0]
      //   ? 'line'
      //   : geoImport.type;
      if (geoPoint) {
        geoImport.type = 'polig';
        const color = placemarkElement
          .getElementsByTagName('description')[0]
          ?.getAttribute('color');
        const width =
          placemarkElement
            .getElementsByTagName('description')[0]
            ?.getAttribute('width') ?? '40';
        if (color) {
          geoImport.StandardGeofences.Style.PolyStyle.color =
            this.colorHexToRGB(color);
          geoImport.StandardGeofences.Style.LineStyle.color =
            this.colorHexToRGB(color);
        }

        const points =
          geoImport.StandardGeofences.Polygon.outerBoundaryIs.LinearRing.coordinates
            .trim()
            .split(',');
        if (width && points) {
          console.log('width', width);
          geoImport.StandardGeofences.Polygon.outerBoundaryIs.LinearRing.coordinates =
            this.genCircCoords(
              parseFloat(points[1]),
              parseFloat(points[0]),
              parseFloat(width)
            );
        }
      }
      if (geoLine) {
        geoImport.type = 'polig';
        geoImport.StandardGeofences.Polygon.outerBoundaryIs.LinearRing.coordinates =
          this.genPoligCoords(
            geoImport.StandardGeofences.Polygon.outerBoundaryIs.LinearRing
              .coordinates
          );
        geoImport.errors.push(
          'Revise y corrija la geocerca después de importar, ya que la conversión de lineal a poligonal puede no ser precisa'
        );
      }
      if (placemarkStyleUrl) {
        Object.entries(StyleMapElemnets).map(([_, styleMapElement], j) => {
          if (
            styleMapElement.getAttribute('id') ==
            placemarkStyleUrl.replace('#', '')
          ) {
            const pairs = styleMapElement.getElementsByTagName('Pair');
            Object.entries(pairs).map(([_, pair], k) => {
              if (getTextContent(pair, 'key') == 'normal') {
                const pairStyleUrlNormal = getTextContent(pair, 'styleUrl');
                if (pairStyleUrlNormal) {
                  Object.entries(StyleMapCascadingStyleElements).map(
                    ([_, styleMapCascadingStyleElement], l) => {
                      if (
                        styleMapCascadingStyleElement.getAttribute('id') ==
                          pairStyleUrlNormal.replace('#', '') ||
                        styleMapCascadingStyleElement.getAttribute('kml:id') ==
                          pairStyleUrlNormal.replace('#', '')
                      ) {
                        const lineStyleElement =
                          styleMapCascadingStyleElement.getElementsByTagName(
                            'LineStyle'
                          )[0];
                        const polyStyleElement =
                          styleMapCascadingStyleElement.getElementsByTagName(
                            'PolyStyle'
                          )[0];
                        if (lineStyleElement) {
                          geoImport.StandardGeofences.Style.LineStyle.color =
                            this.colorHexToRGB(
                              getTextContent(lineStyleElement, 'color')
                            );
                          geoImport.StandardGeofences.Style.LineStyle.width =
                            getTextContent(lineStyleElement, 'width');
                        }
                        if (polyStyleElement) {
                          geoImport.StandardGeofences.Style.PolyStyle.color =
                            this.colorHexToRGB(
                              getTextContent(polyStyleElement, 'color')
                            );
                        }
                      }
                    }
                  );
                }
              }
            });
          }
        });
      } else {
        if (placemarkStyle) {
          const lineStyle = placemarkStyle.getElementsByTagName('LineStyle')[0];
          const polyStyle = placemarkStyle.getElementsByTagName('PolyStyle')[0];
          if (lineStyle) {
            geoImport.StandardGeofences.Style.LineStyle.color =
              this.colorHexToRGB(getTextContent(lineStyle, 'color'));
            geoImport.StandardGeofences.Style.LineStyle.width = getTextContent(
              lineStyle,
              'width'
            );
            geoImport.StandardGeofences.Style.PolyStyle.color =
              geoImport.StandardGeofences.Style.LineStyle.color;
          } else {
          }
          if (polyStyle) {
            geoImport.StandardGeofences.Style.PolyStyle.color =
              this.colorHexToRGB(getTextContent(polyStyle, 'color'));
          } else {
          }
        }
      }
      geoImport.hasStandardGeofences =
        this.hasDataInStandardGeofence(geoImport);
      return geoImport;
    });
    console.log('geoImp: kml', importGeofencesOfKml);
    return importGeofencesOfKml;
  }
  hasDataInStandardGeofence(geoImport: ImportGeofence): boolean {
    const hasDataInStandardGeofence: Record<string, boolean> = {
      coordinates:
        !geoImport.StandardGeofences.Polygon.outerBoundaryIs.LinearRing.coordinates.trim()
          ? false
          : geoImport.StandardGeofences.Polygon.outerBoundaryIs.LinearRing.coordinates.trim() !==
            geoImport.zone_vertices.trim(),
      lineColor: !geoImport.StandardGeofences.Style.LineStyle.color.trim()
        ? false
        : geoImport.StandardGeofences.Style.LineStyle.color !==
          geoImport.zone_color,
      polyColor: !geoImport.StandardGeofences.Style.PolyStyle.color.trim()
        ? false
        : geoImport.StandardGeofences.Style.PolyStyle.color !==
          geoImport.zone_color,
    };

    return Object.values(hasDataInStandardGeofence).some((value) => value); //busca al menos un true
  }

  geofenceImported() {
    this.onBusqueda();
    this.objGeofences.geofences = [];
    this.ngOnInit();
    this.geofencesService.geofences.sort(function (a: any, b: any) {
      return a.orden.localeCompare(b.orden, 'en', {
        numeric: true,
      });
    });
  }
  checkBoolean(input: string | number | boolean): boolean {
    if (typeof input === 'boolean') {
      return input;
    }
    const booleanValues: Record<string, boolean> = {
      true: true,
      false: false,
      '1': true,
      '0': false,
    };
    if (typeof input === 'number') {
      return booleanValues[input.toString()] ?? false;
    }
    if (typeof input === 'string') {
      const trimmedInput = input.trim().toLowerCase();
      return booleanValues[trimmedInput] ?? false;
    }
    return false;
  }
  getSpeedHexColor(value: any): string {
    const colors: Record<string, string> = {
      noSpeed: '#ffffff',
      withSpeed: '#7bfb7b63',
      withDirection: '#f479fb63',
    };
    let color: string = colors['noSpeed'];
    if (value.vel_act_zona && value.vel_max) {
      color = this.checkBoolean(value.vel_act_zona)
        ? colors['withSpeed']
        : color;
    }
    if (
      value.vel_max &&
      value.hasDirection &&
      value.polygonLines &&
      value.polygonLines.length
    ) {
      value.polygonLines.map((p: LineEssential) => {
        if (p.hasSpeed) {
          color = colors['withDirection'];
        }
      });
    }

    return color;
  }
  getMaxSpeed(value: any): number {
    let speed: number = 0;
    speed = value.vel_max ? value.vel_zona : speed;
    speed = value.vel_max ? value.vel2_zona : speed;
    speed = value.vel_max ? value.vel3_zona : speed;
    speed = value.vel_max ? value.vel_max : speed;
    if (
      value.vel_max &&
      value.hasDirection &&
      value.polygonLines &&
      value.polygonLines.length
    ) {
      if (value.polygonLines.some((d: LineEssential) => d.hasSpeed == true)) {
        speed =
          value.polygonLines.reduce(
            (max: LineEssential, obj: LineEssential) =>
              obj.speed > max.speed ? obj : max,
            value.polygonLines[0]
          )?.speed ?? speed;
      }
    }
    return speed;
  }
  generateUUID(): string {
    let template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return template.replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0;
      let v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  /**
   *
   * @param longitud :number, longitud de una coordenada
   * @param latitud :number, latitud de una coordenada
   * @param radio :number, radio en metros
   * @returns :string, coordenadas(lat, lng, alt) en formato kml
   */
  genCircCoords(longitud: number, latitud: number, radio: number): string {
    let geoPoints: string = '';
    let x0 = 0;
    let y0 = 0;
    const points = Math.max(
      Math.min(((radio - 1) * (100 - 20)) / (1000000 - 1) + 20, 100),
      20
    );
    radio = (radio / 6371000) * (180 / Math.PI);

    for (let i = 0; i < points; i++) {
      const angulo = (i * (2 * Math.PI)) / points;
      const x = longitud + radio * Math.cos(angulo);
      const y = latitud + radio * Math.sin(angulo);
      if (i == 0) {
        x0 = x;
        y0 = y;
      }
      geoPoints += `${y},${x},0 `;
    }
    geoPoints += `${y0},${x0},0 `;
    return geoPoints;
  }
  /**
   * @deprecated
   * @param c geocoordenates
   * @param w (optional) width of geofence
   * @returns geocoodenates
   */
  genPoligCoordsOfLine(c: string, w: number = 8): string {
    const offset = w / 2;
    let slope: number = 0;
    let newCoordinate: string[] = [];
    const toRadians = (degrees: number): number => {
      return degrees * (Math.PI / 180);
    };
    const toDegrees = (radians: number): number => {
      return radians * (180 / Math.PI);
    };
    const toSlope = (a: Point, b: Point): number => {
      return ((b.x - a.x) * R) / ((b.y - a.y) * R * Math.cos((a.x + b.x) / 2));
    };
    const averageSlop = (p: Point[]): number => {
      if (p.length < 2) {
        throw new Error(
          'Coordenadas insuficientes para tranformar a un pofigono'
        );
      }
      let s: number = 0;
      let c: number = 0;
      for (let i = 0; i < p.length - 1; i++) {
        const slope = toSlope(p[i], p[i + 1]);
        s += slope;
        c++;
      }
      return s / c;
    };
    const moveGeoPointReverse = (p: Point, s: number, d: number): Point => {
      const dx = -d / Math.sqrt(1 + s * s);
      const dy = -s * dx;
      return { x: p.x + dy / R, y: p.y + dx / (R * Math.cos(p.x)) };
    };
    const moveGeoPoint = (p: Point, s: number, d: number): Point => {
      const dx = d / Math.sqrt(1 + s * s);
      const dy = s * dx;
      return { x: p.x + dy / R, y: p.y + dx / (R * Math.cos(p.x)) };
    };
    const R: number = 6371000;
    let coords = Object.entries(c.split(' ')).map(([_, coord]) => {
      let [x, y] = coord.split(',').map(Number);
      return { x, y };
    });
    coords = Object.entries(coords).map(([_, c]) => {
      return { x: toRadians(c.x), y: toRadians(c.y) };
    });
    slope = 1; //averageSlop(coords);
    let coordinatesA = coords.map((p) => moveGeoPoint(p, slope, offset));
    let coordinatesB = coords.map((p) => moveGeoPointReverse(p, slope, offset));
    coordinatesB = coordinatesB.reverse();
    let coordinates = [...coordinatesA, ...coordinatesB];
    coordinates = Object.entries(coordinates).map(([_, c]) => {
      return { x: toDegrees(c.x), y: toDegrees(c.y) };
    });
    newCoordinate = coordinates.map((p) => `${p.x},${p.y},0`);
    return newCoordinate.join(' ');
  }
  genPoligCoords(c: string, w: number = 16): string {
    let coordinates: string = '';
    let coordinatesA: Point[] = [];
    let coordinatesB: Point[] = [];
    let _c: Point[];
    let m: number = 0;
    const R: number = 6371000;
    const offset = (d: number): number => {
      return d / R;
    };
    const toRadians = (degrees: number): number => {
      return degrees * (Math.PI / 180);
    };
    const toDegrees = (radians: number): number => {
      return radians * (180 / Math.PI);
    };
    const distance = (a: Point, b: Point): number => {
      return (
        R *
        (2 *
          Math.atan2(
            Math.sqrt(
              Math.sin((b.y - a.y) / 2) * Math.sin((b.y - a.y) / 2) +
                Math.cos(a.y) *
                  Math.cos(b.y) *
                  Math.sin((b.x - a.x) / 2) *
                  Math.sin((b.x - a.x) / 2)
            ),
            Math.sqrt(
              1 -
                Math.sin((b.y - a.y) / 2) * Math.sin((b.y - a.y) / 2) +
                Math.cos(a.y) *
                  Math.cos(b.y) *
                  Math.sin((b.x - a.x) / 2) *
                  Math.sin((b.x - a.x) / 2)
            )
          ))
      );
    };
    const toSlope = (a: Point, b: Point): number => {
      return a === b
        ? 0
        : ((b.y - a.y) * R) / ((b.x - a.x) * R * Math.cos((a.y + b.y) / 2));
    };
    const genSquare = (p: Point, m: number) => {
      const halfSize = offset(w / 2);
      const vertices = [
        { x: -halfSize, y: -halfSize }, //tercer cuadrante
        { x: halfSize, y: -halfSize }, //cuarto cuadrante
        { x: halfSize, y: halfSize }, //primer cuadrante
        { x: -halfSize, y: halfSize }, //segundo cuadrante
      ];
      const zeta = Math.atan(m);
      return vertices.map((v) => ({
        x: p.x + v.x * Math.cos(zeta) - v.y * Math.sin(zeta),
        y: p.y + v.x * Math.sin(zeta) + v.y * Math.cos(zeta),
      }));
    };
    _c = c
      .trim()
      .split(' ')
      .map((coord) => {
        const [y, x] = coord.split(',').map(Number);
        return { x: toRadians(x), y: toRadians(y) };
      });
    for (let i = 0; i < _c.length - 1; i++) {
      m = toSlope(_c[i], _c[i + 1]);
      const ncA = genSquare(_c[i], m > 0 ? m * -1 : m);
      const ncB = genSquare(_c[i + 1], m > 0 ? m * -1 : m);
      coordinatesA.push(i == 0 ? ncA[2] : ncB[3]);
      i == 0 ? coordinatesB.push(ncA[2]) : null;
      coordinatesB.push(i == 0 ? ncA[1] : ncB[0]);
    }
    _c = [...coordinatesA, ...coordinatesB.reverse()];
    _c = Object.entries(_c).map(([_, c]) => {
      return { x: toDegrees(c.y), y: toDegrees(c.x) };
    });
    coordinates = _c.map((p) => `${p.x},${p.y},0`).join(' ');
    return coordinates;
  }
  genPoligCoordsv2(c: string, w: number = 16): string {
    let coordinates: string = '';
    let coordinatesA: Point[] = [];
    let coordinatesB: Point[] = [];
    let _c: Point[];
    let m: number = 0;
    const R: number = 6371000;
    const offset = (d: number): number => {
      return d / R;
    };
    const toRadians = (degrees: number): number => {
      return degrees * (Math.PI / 180);
    };
    const toDegrees = (radians: number): number => {
      return radians * (180 / Math.PI);
    };
    const distance = (a: Point, b: Point): number => {
      return (
        R *
        (2 *
          Math.atan2(
            Math.sqrt(
              Math.sin((b.y - a.y) / 2) * Math.sin((b.y - a.y) / 2) +
                Math.cos(a.y) *
                  Math.cos(b.y) *
                  Math.sin((b.x - a.x) / 2) *
                  Math.sin((b.x - a.x) / 2)
            ),
            Math.sqrt(
              1 -
                Math.sin((b.y - a.y) / 2) * Math.sin((b.y - a.y) / 2) +
                Math.cos(a.y) *
                  Math.cos(b.y) *
                  Math.sin((b.x - a.x) / 2) *
                  Math.sin((b.x - a.x) / 2)
            )
          ))
      );
    };
    const toSlope = (a: Point, b: Point): number => {
      return a === b
        ? 0
        : ((b.y - a.y) * R) / ((b.x - a.x) * R * Math.cos((a.y + b.y) / 2));
    };
    const genSquare = (p: Point, m: number) => {
      const halfSize = offset(w / 2);
      const vertices = [
        { x: -halfSize, y: -halfSize }, //tercer cuadrante
        { x: halfSize, y: -halfSize }, //cuarto cuadrante
        { x: halfSize, y: halfSize }, //primer cuadrante
        { x: -halfSize, y: halfSize }, //segundo cuadrante
      ];
      const zeta = Math.atan(m);
      return vertices.map((v) => ({
        x: p.x + v.x * Math.cos(zeta) - v.y * Math.sin(zeta),
        y: p.y + v.x * Math.sin(zeta) + v.y * Math.cos(zeta),
      }));
    };
    const genTriangleToResultVector = (p: Point, v: number) => {};
    _c = c
      .trim()
      .split(' ')
      .map((coord) => {
        const [y, x] = coord.split(',').map(Number);
        return { x: toRadians(x), y: toRadians(y) };
      });
    for (let i = 0; i < _c.length - 1; i++) {
      m = toSlope(_c[i], _c[i + 1]);
      const ncA = genSquare(_c[i], m > 0 ? m * -1 : m);
      const ncB = genSquare(_c[i + 1], m > 0 ? m * -1 : m);
      coordinatesA.push(i == 0 ? ncA[2] : ncB[3]);
      i == 0 ? coordinatesB.push(ncA[2]) : null;
      coordinatesB.push(i == 0 ? ncA[1] : ncB[0]);
    }
    _c = [...coordinatesA, ...coordinatesB.reverse()];
    _c = Object.entries(_c).map(([_, c]) => {
      return { x: toDegrees(c.y), y: toDegrees(c.x) };
    });
    coordinates = _c.map((p) => `${p.x},${p.y},0`).join(' ');
    return coordinates;
  }
}
