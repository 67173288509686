import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from './permission.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  @Input() appHasPermission: string='';
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private permissionService: PermissionService) {}

  async ngOnInit() {
    console.log(this.appHasPermission);
    // this.appHasPermission = await this.verifyDirective(this.appHasPermission);
    const isNegative = await this.verifyNegative(this.appHasPermission);
    const hasPermission = await this.hasPermiss();
    if ((hasPermission && !isNegative) || (!hasPermission && isNegative)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      console.log('Permiso Valido');
    } else {
      this.viewContainer.clear();
      console.log('Permiso Invalido');
    }
  }

  private async hasPermiss():Promise<boolean>{
    return await this.permissionService.hasPermission(this.appHasPermission);
  }
  private async verifyNegative(permission:string):Promise<boolean>{
    if (permission.charAt(0) == '!') {
      this.appHasPermission = await this.subString(permission);
      return true;
    }else{
      return false;
    }
  }
  private async subString(permiss:string):Promise<string>{
    return permiss.substring(1);
  }
  
}
