export const environment = {
  production: false,
  // apiUrl: 'https://apiprueba.glmonitoreo.com',
  // idClient: '84',
  // secretClient: 'LUTj1k8Tley9SvQ1Jwmrha1HPHJs87ShfiQVB18S',
  apiUrl: 'https://apitest.glmonitoreo.com',
  idClient: '106',
  secretClient: 'au2vdKGrRK013Sy1rlgu5H7yb9k1LOzTx5wXz9Uu',
  socketUrls: [
    'https://escucha.glmonitoreo.com/',
    'https://escucha2.glmonitoreo.com/',
  ],
  //socketEvent: 'http://23.29.124.173',
  grant_type: 'client_credentials',
  socketEvent: 'https://eventos.glmonitoreo.com/',
  WhatsAppKey:
    'EAAMfi3Y1NgkBOy7BNdNj27zgDrTjHdzB3FU7Aonhs1TPMnqgMwOfvPoPXwN9I2Ew00pjeF9gYzfGJd9iOXmADobQOgGhVisei7NxrJS0mZA3n2P9jNiocPQox7l7b9ARDPR5sWwCLqw6ANocJb2ZAkIpurbaz03ZBIC2zoHSAZAaSgiNperePiJ1kGrZCvZCgjiwZDZD',
  WhatsAppUrl: 'https://graph.facebook.com/v20.0/369363369597010/messages',
};
