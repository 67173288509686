<nav class="navbar navbar-expand-sm">
  <div
    class="container-fluid no-x-padding"
    style="align-items: stretch; height: 100%"
  >
    <div class="d-flex align-items-center">
      <!-- BURGER BUTTON -->
      <!-- <div class="d-none justify-content-end align-items-center collapse-toggle" style="height: 100%; width: 2rem; color: #fff; background-color: var(--gl-blue-dark);">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" stroke-width="2" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </div> -->
      <div class="gl-logo">
        <a class="navbar-brand d-grid" style="margin: 0px">
          <img [src]="logo" />
        </a>
      </div>
    </div>

    <!-- EXPANDIDO -->
    <div
      class="collapse navbar-collapse justify-content-start"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav h-100">
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'VEHICLES' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('VEHICLES')"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="24px" height="24px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.906,25.923v2.564h-4.213c0.081,0.166,0.07,0.113,0.09,0.367
                  h3.574c0,0.598-0.092,0.888-0.092,1.557h-3.573c-0.331,1.418-1.844,2.929-3.574,2.929c-0.694,0-1.187-0.003-1.833-0.272
                  c-1.107-0.46-2.006-1.442-2.291-2.657h-1.924c-0.231-0.484-0.146-0.787-0.491-0.973c-0.389-0.212-0.422,0.05-0.422-0.583h2.749
                  v-0.367h-2.841v-14.75h8.613c0.295,0,0.903,0.989,1.072,1.217l3.481,4.487c1.134,1.519,0.944,0.915,0.944,2.54
                  c-0.002,0.764,0.007,1.531-0.002,2.293c-0.008,0.561-0.093,0.679,0.199,0.991C46.535,25.438,46.906,25.643,46.906,25.923
                  M39.762,15.388h-5.955v4.948h9.618c-0.076-0.298-1.544-2.232-1.758-2.548l-1.342-1.867C40.09,15.605,40.015,15.388,39.762,15.388z
                  M40.678,29.404c0-1.729-2.084-2.441-3.191-1.265c-1.067,1.137-0.233,3.098,1.357,3.098C39.778,31.237,40.678,30.349,40.678,29.404z
                  M18.873,26.196c-1.82,0-3.459-0.273-5.315-0.273v2.932h7.332c-0.311-0.463-0.647-0.856-0.996-1.296
                  C19.67,27.279,18.949,26.478,18.873,26.196z M11.542,25.923c-1.942,0-3.777,0.183-5.221,0.183c-0.569,0.848-1.824,2.189-2.197,2.749
                  h7.418V25.923z M26.843,34.259c-1.717,0-1.717,2.107,0,2.107c0.15,0,0.479-0.242,0.578-0.34
                  C28.179,35.256,27.298,34.259,26.843,34.259z M0,32.316v3.316h0.459v0.827c2.928,0,0.955,0.055,2.574,1.27
                  c0.864,0.648,1.212,0.651,2.282,0.651c1.209,0,2.623-1.26,2.745-2.749h15.486c0.426,1.826,1.327,2.749,3.297,2.749
                  c1.198,0,2.433-0.96,2.657-1.921h2.749v-0.827h0.55c0.058-0.668,0.446-2.066,0.458-2.564c-0.521-0.139-0.705-0.628-0.838-0.994
                  c-0.535-1.468-0.301-1.46-1.024-1.63l-2.131-0.53c-0.967-0.275-1.848-0.482-2.839-0.732c-0.546-0.135-0.918-0.254-1.449-0.384
                  c-0.896-0.215-0.571-0.131-1.156-0.587c-0.807-0.628-1.818-1.615-2.928-2.471c-0.293-0.229-0.701-0.519-1.099-0.647
                  c-2.354-0.732-9.247-0.552-12.375-0.456c-0.838,0.026-2.263,0.131-2.841,0.548l-3.798,4.263L0.22,31.178
                  C0.105,31.512,0.041,31.901,0,32.316z M6.046,35.357c0-0.805-0.56-1.098-1.281-1.098c-0.285,0-0.826,0.541-0.826,0.825v0.458
                  c0,0.209,0.325,0.825,1.098,0.825C5.531,36.367,6.046,35.848,6.046,35.357z M30.28,28.832l-4.83-1.241
                  c-0.781-0.192-0.582-0.088-1.109-0.54c-1.298-1.111-3.218-3.029-4.791-3.365c-2.051-0.441-5.856-0.513-8.097-0.513
                  c-1.238,0-2.245,0.1-3.393,0.09c-0.578-0.002-2.789,0.131-3.299,0.273c-0.463,0.128-0.813,0.323-1.172,0.568
                  c-0.325,0.218-0.958,1.004-1.233,1.332L1.01,26.837V9.982H30.28V28.832z"/>
              </svg> -->

              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="721.000000pt"
                height="503.000000pt"
                viewBox="0 0 721.000000 503.000000"
                preserveAspectRatio="xMidYMid meet"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
              >
                <g
                  transform="translate(0.000000,503.000000) scale(0.100000,-0.100000)"
                  stroke="none"
                >
                  <path
                    d="M1663 4788 c-11 -13 -28 -38 -37 -56 -9 -17 -263 -607 -565 -1310
-302 -702 -646 -1504 -766 -1782 -120 -278 -221 -520 -225 -538 -11 -43 4 -86
37 -107 14 -9 89 -39 167 -67 155 -54 1245 -433 1421 -494 l110 -37 140 17
c77 10 540 71 1028 136 818 108 892 117 935 105 42 -11 353 -119 1300 -449
167 -58 316 -106 333 -106 65 0 73 16 315 625 286 722 447 1127 667 1680 96
242 268 673 381 958 139 349 206 530 206 555 0 39 -21 73 -55 90 -11 5 -348
114 -750 242 -401 127 -750 238 -774 246 -60 19 -147 18 -396 -6 -372 -35
-599 -52 -1630 -124 l-180 -12 -220 62 c-738 208 -951 270 -1135 329 -224 71
-275 78 -307 43z m465 -322 c180 -57 478 -145 662 -196 184 -50 341 -93 347
-96 14 -4 17 5 -48 -159 l-39 -100 -60 -28 c-96 -44 -150 -108 -292 -342 -165
-274 -147 -251 -187 -230 -28 14 -58 16 -201 13 -213 -4 -210 -2 -210 -133 0
-131 -2 -129 164 -135 l135 -5 -50 -85 c-57 -97 -100 -204 -131 -323 -20 -75
-22 -116 -27 -507 l-6 -425 -204 -510 c-236 -590 -221 -555 -236 -555 -9 0
-397 134 -885 305 -52 18 -195 68 -318 111 -133 46 -221 82 -218 88 2 6 115
270 251 586 136 316 460 1070 720 1675 512 1190 496 1155 503 1155 2 0 151
-47 330 -104z m3142 -190 c0 -14 -259 -601 -266 -603 -5 -1 -22 20 -39 48 -64
108 -184 188 -315 208 -40 7 -301 11 -667 11 -388 0 -603 4 -603 10 0 5 14 38
31 73 l31 63 332 33 c182 18 392 41 466 51 318 44 535 70 675 80 83 7 177 15
210 19 67 9 145 13 145 7z m316 -25 c32 -11 329 -106 659 -211 330 -105 603
-194 607 -198 4 -4 -2 -27 -12 -52 -17 -40 -361 -903 -635 -1595 -57 -143
-210 -528 -340 -855 -130 -327 -270 -680 -312 -785 -41 -104 -79 -194 -83
-199 -7 -7 -146 39 -630 209 -52 18 -254 88 -447 156 -194 67 -353 127 -353
133 0 6 75 185 167 399 l166 387 329 0 328 0 0 -120 c0 -182 2 -184 308 -178
l192 3 27 28 28 27 3 488 c2 303 -1 527 -8 594 -18 184 -66 333 -154 481 -25
43 -46 83 -46 88 0 5 55 9 124 9 166 0 171 4 171 129 0 81 -2 93 -23 113 -21
22 -31 23 -186 26 -131 3 -171 0 -201 -12 -21 -9 -39 -16 -40 -16 -7 0 -75
123 -75 135 0 27 353 835 365 835 6 0 38 -9 71 -19z m-871 -472 c71 -38 146
-135 317 -410 80 -129 150 -244 157 -256 14 -28 14 -66 -1 -81 -16 -16 -2569
-18 -2599 -2 -39 21 -22 58 156 344 171 276 235 360 300 394 79 41 80 41 865
39 l755 -2 50 -26z m687 -1186 c26 -58 34 -144 18 -182 -13 -33 -82 -82 -146
-105 -63 -22 -156 -27 -316 -18 -146 8 -146 8 -201 44 -64 43 -137 110 -137
126 0 25 57 43 344 107 438 97 408 96 438 28z m-2614 -23 c174 -39 327 -75
340 -80 16 -7 22 -17 20 -32 -4 -28 -140 -143 -188 -160 -23 -9 -98 -13 -225
-13 l-190 1 -75 36 c-110 52 -120 65 -120 146 0 122 25 172 86 172 19 0 177
-32 352 -70z m1347 -939 c-3 -5 -45 -103 -94 -218 -158 -369 -222 -518 -230
-525 -7 -8 -100 -22 -491 -73 -151 -20 -322 -42 -380 -50 -119 -17 -364 -49
-520 -70 -58 -8 -176 -24 -263 -36 -87 -11 -160 -19 -162 -17 -3 2 57 160 133
351 l137 346 212 3 c212 3 213 3 238 28 23 23 25 35 31 148 l7 122 694 0 c420
0 692 -4 688 -9z"
                  />
                </g>
              </svg>

              <!-- <i class="fa fa-truck" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Vehículos</span>
        </li>
        <!-- <li class="nav-item" [ngClass]="panelService.nombreComponente == 'EVENT-USER'? 'active': ''" (click)="panelService.clickShowPanel('EVENT-USER');">
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <i class="fa fa-bolt" aria-hidden="true"></i>
            </button>
          </div>
          <span>Eventos</span>
        </li> -->
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente.includes('ALERTS') ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('ALERTS')"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.543,11.004c1.107,0,1.646,0.002,2.65,0.929
                  c1.325,1.225,1.394,2.711,1.271,4.527l-0.749,10.177c-0.024,0.361-0.061,1.03-0.112,1.477c-0.094,0.809-0.099,2.146-0.257,2.921
                  c-0.903-0.085-1.439-0.839-3.67-0.353c-0.493,0.107-0.754,0.311-1.188,0.353c-0.173-0.857-0.119-1.872-0.239-2.829
                  c-0.057-0.444-0.068-1.034-0.107-1.375l-0.307-4.363c-0.097-4.372-1.771-9.417,1.604-11.123
                  C22.698,11.212,23.215,11.004,23.543,11.004 M47.837,39.605v0.865c-0.011,0.717-0.09,1.197-0.518,2.075
                  c-0.838,1.716-2.327,2.522-4.249,2.522H4.858c-1.51,0-2.336-0.278-3.353-1.192C0.18,42.682,0,41.356,0,39.605
                  c0-1.711,1.6-3.761,2.485-5.29L18.482,7.879c0.677-1.144,2.316-3.983,2.982-4.725c1.376-1.525,3.68-1.444,5.139-0.046
                  c0.745,0.715,6.87,11.265,7.293,11.991l7.906,13.216c0.252,0.436,0.462,0.822,0.71,1.22l2.167,3.62
                  C45.602,34.751,47.837,37.809,47.837,39.605z M20.927,37.784c0-1.266,0.472-2.132,1.19-2.816c0.193-0.181,0.208-0.192,0.458-0.331
                  c1.019-0.567,2.18-0.539,3.092,0.283l0.392,0.41c2.687,3.195-1.505,7.804-4.177,4.788c-0.27-0.307-0.445-0.574-0.638-0.984
                  C21.129,38.884,20.927,38.096,20.927,37.784z"
                />
              </svg>
              <!-- <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Alertas</span>
        </li>
        <!-- <li class="nav-item dropdown" [ngClass]="(panelService.nombreComponente == 'GEOFENCES' || panelService.nombreComponente == 'CIRCULAR-GEOFENCE' || panelService.nombreComponente == 'POLYLINE-GEOFENCE') ? 'active': ''" >

          <div role="button" data-bs-toggle="dropdown" aria-expanded="false">

            <div class="d-flex justify-content-center">
              <button class="btn btn-navbar" >
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                    c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                    c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                    c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                    c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                    c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                    c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                    c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                    c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                    c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                    C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                    c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                    c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                    c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                    c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                    c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                    c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                    C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                    c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                    c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                    c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                    c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                    l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                    c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                    l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                    c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                    c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                    c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                    c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                    c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                    c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"/>
                </svg>
              </button>
            </div>
            <span>Geocercas</span>
          </div>

          <ul class="dropdown-menu">
            <li (click)="panelService.clickShowPanel('GEOFENCES');"><span class="dropdown-item" >Geocercas Poligonales here</span></li>
            <li (click)="panelService.clickShowPanel('CIRCULAR-GEOFENCE');"><span class="dropdown-item" >Geocercas Circulares</span></li>
            <li (click)="panelService.clickShowPanel('POLYLINE-GEOFENCE');"><span class="dropdown-item" >Geocercas Polilineas</span></li>
          </ul>
        </li> -->

        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'GEOFENCES' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('GEOFENCES')"
        >
          <div role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex justify-content-center">
              <button class="btn btn-navbar">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                    c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                    c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                    c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                    c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                    c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                    c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                    c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                    c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                    c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                    C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                    c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                    c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                    c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                    c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                    c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                    c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                    C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                    c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                    c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                    c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                    c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                    l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                    c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                    l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                    c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                    c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                    c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                    c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                    c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                    c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"
                  />
                </svg>
              </button>
            </div>
            <span>Geocercas</span>
          </div>
        </li>

        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'GEOPOINTS' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('GEOPOINTS')"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.944,13.854c0.387,1,0.603,2.089,0.603,3.226
                  c0,4.934-4.001,8.935-8.938,8.935c-4.936,0-8.937-4.001-8.937-8.935c0-0.493,0.042-0.976,0.118-1.446
                  c2.071-1.342,5.885-0.791,8.995,0.564C26.893,17.551,30.171,15.741,31.944,13.854 M23.609,6.283c5.962,0,10.796,4.835,10.796,10.797
                  s-4.833,10.794-10.796,10.794c-5.962,0-10.795-4.832-10.795-10.794S17.647,6.283,23.609,6.283z M23.609,1.138
                  c8.804,0,15.943,7.137,15.943,15.942c0,5.983-3.297,11.195-8.172,13.923c-0.424,0.667-1.632,2.707-3.721,7.237
                  C24.993,44.021,23.722,48,23.722,48s-1.988-5.086-3.794-9.267c-1.526-3.538-3.521-6.811-4.099-7.736
                  c-4.87-2.729-8.162-7.939-8.162-13.917C7.667,8.275,14.804,1.138,23.609,1.138z"
                />
              </svg>
              <!-- <i class="fa fa-thumb-tack" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Geopuntos</span>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'HISTORIAL' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('HISTORIAL')"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <!--               <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.127,34.691c0,1.739,0.252,2.298,0.997,3.997
                  c0.813,1.851,1.887,3.722,2.913,5.438c0.672,1.119,1.082,2.197,1.956,2.13c0.797-0.063,1.292-1.241,1.903-2.255
                  c1.354-2.249,3.851-6.521,3.851-9.036c0-3.875-4.103-6.844-8.222-5.134C8.734,30.57,7.127,32.666,7.127,34.691 M13.12,37.96
                  c-1.684,0-2.724-1.039-2.724-2.724c0-1.836,2.539-3.274,4.291-1.658C16.363,35.119,15.117,37.96,13.12,37.96z M36.326,0.655h0.983
                  c0.808,0.06,1.585,0.268,2.222,0.638c1.765,1.026,3.087,2.819,3.087,5.172c0,2.129-2.742,6.922-3.919,8.876
                  c-0.276,0.454-0.537,0.855-0.795,1.293c-0.285,0.479-0.422,0.902-1.185,0.902c-0.706,0-0.879-0.506-1.209-1.06
                  c-0.885-1.487-2.316-3.785-3.028-5.325c-0.597-1.293-1.571-3.333-1.571-4.779c0-2.253,1.434-4.243,3.146-5.114
                  C34.718,0.917,35.514,0.714,36.326,0.655z M36.719,9.276c-1.935,0-3.398-2.469-1.881-4.243c0.933-1.094,2.817-1.151,3.789-0.024
                  C40.144,6.77,38.846,9.276,36.719,9.276z M2.86,19.08c0,2.271-0.399,4.957,2.036,5.59C6.008,24.959,8.479,24.8,9.852,24.8
                  l11.438-0.006c1.088-0.018,0.815-0.294,0.815,3c0,1.441-0.144,3.045,1.074,4.01c1.109,0.877,2.471,0.71,4.372,0.71l4.883,0.018
                  c0.349,0.069,0.563,0.113,0.563,0.889v9.621c0,0.563-0.15,0.593-0.363,0.907H16.568c-0.298,0.57-0.598,1.039-0.935,1.606
                  c-0.579,0.965-0.608,0.897-1.062,1.571c4.326,0,8.652,0,12.979,0h5.131c0.45-0.043,0.832-0.112,1.098-0.217
                  c0.449-0.179,0.693-0.324,1.013-0.616c1.041-0.955,1.381-1.718,1.381-3.523v-9.076c0-0.74-0.075-1.442-0.283-1.984
                  c-0.443-1.14-1.162-1.82-2.311-2.229c-1.099-0.392-6.752-0.235-8.207-0.235c0-1.302,0.105-4.042-0.179-5.179
                  c-0.328-1.322-1.763-2.444-2.907-2.444H6.04V19.08l30.771-0.089c-1.535,0-2.048-0.935-2.697-2.026
                  c-0.182-0.309-0.497-0.734-0.572-1.063c-8.44,0-16.882,0-25.322,0c-1.477,0-2.918-0.148-3.909,0.359
                  C3.389,16.733,2.86,17.683,2.86,19.08z"
                />
              </svg> -->
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 637.000000 710.000000"
                preserveAspectRatio="xMidYMid meet"
                width="25px"
                height="25px"
              >
                <g
                  transform="translate(0.000000,710.000000) scale(0.100000,-0.100000)"
                  stroke="none"
                >
                  <path
                    d="M1880 6770 c-159 -34 -293 -115 -388 -234 -27 -34 -135 -204 -241
           -378 -106 -175 -195 -318 -197 -318 -2 0 -18 9 -36 20 -31 19 -51 20 -289 20
           -249 0 -257 -1 -290 -23 -41 -28 -47 -47 -48 -170 -2 -185 14 -197 274 -197
           96 0 175 -2 175 -4 0 -3 -26 -47 -57 -98 -126 -207 -201 -415 -239 -661 -14
           -87 -16 -219 -15 -883 0 -445 5 -792 10 -805 5 -13 21 -35 36 -49 l27 -25 290
           -3 c287 -3 290 -3 273 -22 -17 -19 -15 -23 70 -115 84 -92 100 -105 107 -86 2
           4 37 45 78 91 41 45 75 86 75 90 0 4 -33 42 -72 85 l-73 78 0 158 0 159 1685
           0 1685 0 0 -165 c0 -187 8 -222 62 -255 31 -19 50 -20 349 -20 312 0 316 0
           350 23 18 12 39 34 46 47 10 20 13 199 10 855 -2 823 -3 831 -25 935 -45 207
           -114 380 -227 568 -30 51 -55 95 -55 97 0 3 80 5 178 5 148 0 183 3 212 18 49
           24 63 70 58 197 -8 176 -5 175 -340 175 -239 0 -255 -1 -287 -21 -34 -20 -35
           -20 -48 -3 -8 11 -98 158 -201 329 -171 282 -195 317 -277 401 -71 72 -106 99
           -165 128 -148 72 -100 69 -1310 72 -1024 3 -1090 2 -1170 -16z m2258 -181
           c154 -23 257 -119 440 -406 185 -291 366 -589 385 -634 24 -56 18 -91 -18
           -107 -35 -16 -3770 -17 -3812 -1 -34 13 -43 26 -43 62 0 39 -17 11 334 571
           201 322 272 410 376 465 111 59 88 58 1230 59 609 0 1071 -4 1108 -9z m-3180
           -1724 c99 -21 593 -131 772 -171 222 -49 245 -64 204 -123 -32 -45 -207 -181
           -263 -203 -53 -22 -70 -23 -316 -23 -245 0 -264 1 -335 23 -137 43 -245 118
           -256 178 -8 46 12 214 33 265 11 28 26 45 47 55 39 17 35 17 114 -1z m4280 -9
           c39 -34 64 -112 69 -221 4 -82 3 -93 -19 -125 -29 -43 -123 -102 -219 -138
           l-74 -27 -260 3 c-296 2 -326 8 -426 74 -94 63 -189 155 -189 184 0 33 32 46
           225 89 88 20 216 49 285 65 69 15 188 42 265 59 77 17 169 37 205 45 91 20
           105 19 138 -8z"
                  />
                  <path
                    d="M1621 2679 c-29 -61 -51 -112 -49 -114 2 -1 58 -21 126 -43 86 -29
           124 -37 127 -29 9 26 46 239 42 243 -5 6 -169 54 -183 54 -6 0 -35 -50 -63
           -111z"
                  />
                  <path
                    d="M3612 2723 l-33 -4 3 -122 3 -122 108 -3 107 -3 0 131 0 130 -77 -2
           c-43 0 -93 -3 -111 -5z"
                  />
                  <path
                    d="M4570 2612 c0 -84 4 -123 13 -130 21 -17 109 -22 147 -9 49 17 71 61
           77 156 l6 78 -69 7 c-38 4 -93 9 -121 12 l-53 6 0 -120z"
                  />
                  <path
                    d="M2110 2600 l0 -120 110 0 110 0 0 120 0 120 -110 0 -110 0 0 -120z"
                  />
                  <path
                    d="M2600 2600 l0 -120 110 0 111 0 -3 118 -3 117 -107 3 -108 3 0 -121z"
                  />
                  <path
                    d="M3102 2598 l3 -123 108 -3 107 -3 0 126 0 125 -110 0 -111 0 3 -122z"
                  />
                  <path
                    d="M4080 2600 l0 -120 105 0 105 0 0 120 0 120 -105 0 -105 0 0 -120z"
                  />
                  <path
                    d="M5068 2547 c-52 -77 -68 -113 -68 -146 0 -39 45 -91 99 -116 l40 -18
           68 61 c37 33 78 71 90 84 l22 24 -99 82 c-55 45 -104 82 -109 82 -4 0 -24 -24
           -43 -53z"
                  />
                  <path
                    d="M5365 2133 c-55 -24 -103 -47 -108 -52 -8 -8 25 -177 37 -188 10 -10
           248 20 252 31 5 13 -61 236 -73 246 -4 4 -53 -12 -108 -37z"
                  />
                  <path
                    d="M5296 1598 c-3 -24 -8 -70 -12 -104 l-6 -60 113 -24 c63 -12 120 -25
           127 -28 9 -3 12 26 12 127 l0 131 -114 0 -114 0 -6 -42z"
                  />
                  <path
                    d="M5158 1142 c-26 -43 -48 -83 -50 -89 -2 -6 39 -47 91 -91 l94 -81 17
           22 c29 38 120 176 126 192 4 10 -25 29 -105 69 -61 31 -114 56 -118 56 -5 0
           -29 -35 -55 -78z"
                  />
                  <path
                    d="M1210 1199 c-62 -12 -176 -70 -233 -120 -60 -52 -128 -147 -161 -226
           -127 -302 37 -650 356 -757 90 -31 227 -35 318 -11 131 35 268 134 350 254 21
           31 74 91 119 135 69 67 85 77 106 72 l25 -6 0 101 0 101 -27 -6 c-24 -5 -38 3
           -103 66 -42 40 -94 100 -117 133 -89 132 -236 236 -371 264 -61 13 -197 13
           -262 0z m265 -230 c71 -27 144 -93 186 -171 32 -59 34 -69 34 -158 0 -79 -4
           -103 -24 -142 -37 -75 -95 -134 -169 -170 -60 -30 -75 -33 -157 -33 -79 0 -97
           4 -149 29 -73 36 -131 94 -165 166 -22 47 -26 69 -26 150 0 85 3 101 29 154
           83 169 265 241 441 175z"
                  />
                  <path
                    d="M4837 856 c-42 -23 -79 -45 -82 -48 -6 -6 72 -219 85 -232 7 -7 207
           90 232 113 10 9 -130 211 -145 210 -7 0 -47 -19 -90 -43z"
                  />
                  <path
                    d="M2350 640 l0 -121 113 3 112 3 3 118 3 117 -116 0 -115 0 0 -120z"
                  />
                  <path
                    d="M2840 640 l0 -120 110 0 110 0 0 120 0 120 -110 0 -110 0 0 -120z"
                  />
                  <path
                    d="M3340 635 l0 -125 105 0 105 0 0 125 0 125 -105 0 -105 0 0 -125z"
                  />
                  <path
                    d="M3830 630 l0 -129 98 5 c53 3 103 7 110 10 9 2 12 36 12 124 l0 120
           -110 0 -110 0 0 -130z"
                  />
                  <path
                    d="M4310 630 c0 -107 3 -130 15 -130 35 0 214 23 222 29 6 4 4 49 -5
           119 l-15 112 -108 0 -109 0 0 -130z"
                  />
                </g>
              </svg>
              <!-- <i class="fa fa-history" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Historial</span>
        </li>
        <li class="nav-item" (click)="windowAccess.open('/reports')">
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M38.283,46.174c0.165,0.833,0.057,1.26,1.521,1.258h5.489
                    c0.748,0,1.205,0.121,1.609-0.139c0.219-0.141,0.354-0.239,0.452-0.526c0.241-0.727,0.115-4.344,0.115-5.402V19.474
                    c0-1.773,0.067-3.673,0.002-5.429c-0.042-1.2-0.758-1.263-1.565-1.261H40.42c-0.604,0-1.389-0.118-1.796,0.269
                    c-0.114,0.109-0.237,0.234-0.275,0.398c-0.079,0.327-0.017,27.241-0.017,30.072L38.283,46.174z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.998,46.36c0,0.776,0.586,1.08,1.283,1.072
                    c2.157-0.018,4.933,0.091,6.91,0.005l0.397-0.12c0.266-0.161,0.485-0.361,0.581-0.722l0.024-23.483
                    c0.005-0.537,0.053-0.576-0.193-1c-0.111-0.194-0.396-0.477-0.629-0.477h-7.581c-0.441,0-0.791,0.575-0.791,1.043V46.36z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.05,15.169c-0.226,0.249-0.433,0.696-0.785,0.59
                    c-0.11-0.032-0.107-0.08-0.285-0.121c-0.907-0.208-1.837,0.07-2.555,0.472c-0.63,0.353-0.309,0.612-1.122,0.259
                    c-0.089-0.038-0.156-0.052-0.264-0.104l-3.613-1.693c-0.11-0.055-0.066-0.058-0.234-0.131l-2.384-1.122
                    c-0.005-0.869,0.037-0.979-0.234-1.681c-0.099-0.263-0.162-0.342-0.334-0.59c-1.101-1.568-2.932-1.732-4.51-0.79
                    c-0.429,0.251-0.645,0.521-0.904,0.94l-0.313,0.623c-0.427,1.214,0.09,1.827-0.013,2.412c-0.028,0.163-0.377,0.368-0.518,0.502
                    l-0.433,0.371c-0.847,0.672-1.628,1.463-2.47,2.152c-0.852,0.694-3.615,3.271-4.334,3.738c-0.254,0.164-0.864-0.268-1.181-0.326
                    c-3.303-0.581-5.266,3.669-2.689,5.709c1.455,1.151,3.505,0.928,4.556-0.436c0.218-0.278,0.418-0.527,0.546-0.88
                    c0.818-2.274-0.857-2.01,0.789-3.208c0.447-0.327,0.756-0.651,1.237-1.048l1.928-1.706c0.187-0.158,0.343-0.261,0.515-0.416
                    c0.155-0.14,0.32-0.291,0.483-0.438c0.167-0.147,0.308-0.291,0.485-0.444c0.155-0.134,0.331-0.267,0.51-0.414l1.852-1.596
                    c0.251-0.159,0.773,0.225,1.091,0.292c1.023,0.209,2.368-0.106,3.009-0.929c0.433,0.006,2.927,1.282,3.579,1.614
                    c0.167,0.084,0.311,0.106,0.486,0.192c0.153,0.077,0.279,0.125,0.418,0.198c0.256,0.137,0.601,0.289,0.881,0.423
                    c0.332,0.161,1.483,0.584,1.695,0.842c0.163,0.317-0.086,1.276,0.602,2.274c0.609,0.885,1.745,1.442,2.832,1.397
                    c1.103-0.044,2.088-0.724,2.635-1.594c0.215-0.347,0.293-0.649,0.392-1.037c0.124-0.496,0.1-0.81-0.007-1.269
                    c-0.155-0.644-0.354-0.886-0.32-1.127c0.052-0.323,3.255-3.768,3.576-4.274c0.517-0.815,3.312-3.863,4.244-5.005
                    c1.06-1.296,0.605-0.613,1.874-0.617c0.747-0.006,1.142-0.117,1.706-0.424c0.196-0.11,0.223-0.094,0.421-0.256
                    c0.17-0.139,0.534-0.52,0.678-0.747c0.604-0.967,0.76-2.32,0.163-3.364c-0.945-1.657-3.309-2.288-4.846-1.01
                    c-0.843,0.699-1.457,1.82-1.268,2.983c0.218,1.342,0.849,0.938-0.039,1.94l-5.232,6.173c-0.237,0.294-0.411,0.469-0.657,0.76
                    L32.05,15.169z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.607,46.36c0.062,0.459,0.261,0.871,0.782,1.034
                    c0.354,0.115,4.744,0.038,5.338,0.038c0.581,0,2.129,0.122,2.582-0.159c0.138-0.084,0.338-0.298,0.403-0.454
                    c0.194-0.476,0.088-9.572,0.088-10.513c0-1.505,0.077-5.943,0-7.086c-0.035-0.504-0.313-0.863-0.765-1.017
                    c-0.269-0.088-6.145-0.027-7.118-0.033c-1.763-0.016-1.311,1.771-1.311,4.006V46.36z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.34,46.235c0.091,0.474,0.069,0.709,0.407,0.958
                    c0.428,0.318,0.498,0.239,1.078,0.239h5.305c0.958,0,1.751,0.182,2.216-0.459c0.16-0.223,0.109-0.155,0.154-0.517l0.028-1.948
                    c0-2.254,0.102-5.96,0-7.891c-0.054-0.972-0.468-1.166-1.472-1.16l-5.304,0.001c-0.981,0-1.74-0.163-2.185,0.448
                    c-0.361,0.493-0.236,1.707-0.236,2.437L0.34,46.235z"
                  />
                </g>
              </svg>
              <!-- <i class="fa fa-line-chart" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Reportes</span>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'DASHBOARD' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('DASHBOARD')"
          *appHasPermission="'m-dashboard.show'"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.127,1.754h12.377c2.268,0,4.127,1.856,4.127,4.127v12.376
                  c0,2.273-1.859,4.129-4.127,4.129H4.127C1.859,22.387,0,20.531,0,18.258V5.881C0,3.611,1.859,1.754,4.127,1.754 M4.127,26.893
                  h12.377c2.268,0,4.127,1.856,4.127,4.127v12.379c0,2.268-1.859,4.125-4.127,4.125H4.127C1.859,47.524,0,45.667,0,43.399V31.02
                  C0,28.749,1.859,26.893,4.127,26.893z M29.264,1.754h14.633c2.268,0,4.125,1.856,4.125,4.127v37.513
                  c0,2.271-1.857,4.128-4.125,4.128H29.264c-2.271,0-4.127-1.857-4.127-4.128V5.881C25.136,3.611,26.993,1.754,29.264,1.754z"
                />
              </svg>
              <!-- <i class="fa fa-th-large" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Dashboard</span>
        </li>
        <!-- <li class="nav-item" [ngClass]="panelService.nombreComponente == 'AUDITORIA'? 'active': ''" (click)="panelService.clickShowPanel('AUDITORIA')">
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.372,33.348c-0.697-0.661-2.165-2.929-2.653-2.929
                  c-1.603,0,0.248,1.878,0.512,2.203c1.981,2.419,1.771,2.843,3.227,1.41c0.348-0.343,0.806-0.703,1.142-1.042
                  c0.436-0.451,0.659-0.673,1.125-1.053c0.251-0.208,0.923-0.844,1.049-1.173c-0.025-0.885-0.639-0.862-1.133-0.419
                  C22.086,30.84,19.782,33.225,19.372,33.348 M13.841,35.781l-2.426,2.547c-0.811,0.372-0.55-0.892-2.686,1.11
                  c-0.261,0.244-0.411,0.315-0.709,0.615l-2.803,2.503c-0.319,0.252-0.407,0.353-0.692,0.634l-1.427,1.223
                  c-0.294,0.22-0.453,0.338-0.65,0.676C1.65,46.451,2.9,48.161,4.499,47.986c0.638-0.068,1.179-0.698,1.591-1.053l4.201-3.754
                  c1.561-1.423,2.224-1.571,2.291-3.044c0.029-0.642,0.423-0.591,1.289-1.33c0.297-0.256,1.296-1.051,1.62-1.165
                  c0.759,0.371,0.817,0.685,2.211,1.144c4.537,1.492,9.25-1.817,9.4-6.697c0.168-5.55-6.534-9.591-11.52-5.776
                  c-2.127,1.626-3.113,4.148-2.673,7.049C13.072,34.428,13.53,35.011,13.841,35.781z M19.489,26.394
                  c1.879-0.354,4.373,1.024,5.392,2.938c1.493,2.812,0.444,6.047-2.283,7.588c-3.47,1.962-8.141-0.557-8.258-4.448
                  c-0.08-2.499,0.957-4.404,2.987-5.453C18.222,26.557,18.419,26.597,19.489,26.394z M3.35,46.01
                  c-0.063-0.503,0.403-1.022,0.877-1.063c0.533-0.044,1.011,0.411,1.053,0.883C5.393,47.084,3.492,47.189,3.35,46.01z M16.287,1.786
                  c-0.004,1.377-0.281,1.035,2.896,1.035l17.52-0.002c2.539-0.002,2.005,1.186,2.005,5.027c0,9.312,0,18.688,0,27.935l0.025,3.265
                  c1.721,0.13,2.526,0.258,2.518-1.842V2.261c0.004-1.336-0.143-1.987-1.515-1.996L18.331,0.269
                  C17.474,0.268,16.287,0.261,16.287,1.786z M11.566,6.048l0.009,21.979c0.461-0.317,0.852-1.943,3.072-3.504
                  c2.597-1.824,6.055-2.268,8.997-0.873c2.329,1.1,1.087,1.049,3.21,1.049l6.349-0.002c0.76,0.017,1.208,0.146,1.208,0.955
                  c0,0.617-0.528,0.7-1.121,0.7l-6.013,0.007c-0.008,0.162,0.801,1.441,1.099,2.132c0.113,0.262,0.201,0.6,0.327,0.803
                  c0.34,0.139,2.614,0.09,3.168,0.053c0.948-0.065,2.383-0.214,2.526,0.686c0.29,1.814-3.605,0.629-5.342,1.037
                  c-0.025,0.503,0.008,0.999-0.013,1.498c-0.017,0.497-0.139,0.954-0.168,1.452c1.129-0.008,2.246,0.008,3.375,0.004
                  c0.847-0.006,2.501-0.233,2.131,1.051c-0.239,0.841-1.762,0.581-2.698,0.581c-1.041,0-2.396-0.076-3.399,0.023
                  c-1.704,4.045-6.333,6.371-10.806,5.111c-0.604-0.17-1.578-0.675-2.019-0.745c-0.549,0.547-0.755,0.361-0.927,1.036
                  c-0.269,1.043-0.525,1.427-1.28,2.148c1.183,0.137,19.186,0.044,21.959,0.042c0.852,0,1.309-0.564,1.33-1.423L36.534,5.857
                  c-0.004-0.839-0.436-1.425-1.255-1.425H12.934C11.998,4.432,11.566,5.129,11.566,6.048z M30.341,8.58
                  c0.436-0.18,2.693-0.367,3.151-0.189c0.156,0.41-0.986,2.652-1.431,2.881c-0.31-0.161-0.395-0.371-0.579-0.654
                  c-0.39,0.061-3.584,1.876-4.381,2.302c-3.197,1.725-2.531,1.675-4.339,0.436c-0.538-0.367-2.208-1.638-2.623-1.781
                  c-0.881,0.59-1.75,1.291-2.652,1.962c-1.044,0.777-2.337,2.091-2.815,1.198c-0.185-0.774,0.302-0.93,0.818-1.301l2.492-1.862
                  c1.1-0.807,1.893-1.758,2.644-1.283c0.445,0.279,0.81,0.573,1.225,0.857c0.865,0.59,1.67,1.188,2.539,1.729
                  c0.461-0.147,2.589-1.349,3.164-1.672c0.482-0.271,2.891-1.506,3.118-1.748C30.488,8.988,30.361,9.149,30.341,8.58z M34.411,20.821
                  c0,1.299-1.708,0.904-2.916,0.904h-7.767c-1.591,0-3.043,0.292-3.118-0.726c-0.097-1.28,1.833-0.934,2.929-0.934h7.767
                  C32.309,20.066,34.411,19.737,34.411,20.821z"/>
              </svg>
            </button>
          </div>
          <span>Auditoría</span>
        </li> -->
        <li class="nav-item" (click)="windowAccess.open('/audit')">
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.372,33.348c-0.697-0.661-2.165-2.929-2.653-2.929
                  c-1.603,0,0.248,1.878,0.512,2.203c1.981,2.419,1.771,2.843,3.227,1.41c0.348-0.343,0.806-0.703,1.142-1.042
                  c0.436-0.451,0.659-0.673,1.125-1.053c0.251-0.208,0.923-0.844,1.049-1.173c-0.025-0.885-0.639-0.862-1.133-0.419
                  C22.086,30.84,19.782,33.225,19.372,33.348 M13.841,35.781l-2.426,2.547c-0.811,0.372-0.55-0.892-2.686,1.11
                  c-0.261,0.244-0.411,0.315-0.709,0.615l-2.803,2.503c-0.319,0.252-0.407,0.353-0.692,0.634l-1.427,1.223
                  c-0.294,0.22-0.453,0.338-0.65,0.676C1.65,46.451,2.9,48.161,4.499,47.986c0.638-0.068,1.179-0.698,1.591-1.053l4.201-3.754
                  c1.561-1.423,2.224-1.571,2.291-3.044c0.029-0.642,0.423-0.591,1.289-1.33c0.297-0.256,1.296-1.051,1.62-1.165
                  c0.759,0.371,0.817,0.685,2.211,1.144c4.537,1.492,9.25-1.817,9.4-6.697c0.168-5.55-6.534-9.591-11.52-5.776
                  c-2.127,1.626-3.113,4.148-2.673,7.049C13.072,34.428,13.53,35.011,13.841,35.781z M19.489,26.394
                  c1.879-0.354,4.373,1.024,5.392,2.938c1.493,2.812,0.444,6.047-2.283,7.588c-3.47,1.962-8.141-0.557-8.258-4.448
                  c-0.08-2.499,0.957-4.404,2.987-5.453C18.222,26.557,18.419,26.597,19.489,26.394z M3.35,46.01
                  c-0.063-0.503,0.403-1.022,0.877-1.063c0.533-0.044,1.011,0.411,1.053,0.883C5.393,47.084,3.492,47.189,3.35,46.01z M16.287,1.786
                  c-0.004,1.377-0.281,1.035,2.896,1.035l17.52-0.002c2.539-0.002,2.005,1.186,2.005,5.027c0,9.312,0,18.688,0,27.935l0.025,3.265
                  c1.721,0.13,2.526,0.258,2.518-1.842V2.261c0.004-1.336-0.143-1.987-1.515-1.996L18.331,0.269
                  C17.474,0.268,16.287,0.261,16.287,1.786z M11.566,6.048l0.009,21.979c0.461-0.317,0.852-1.943,3.072-3.504
                  c2.597-1.824,6.055-2.268,8.997-0.873c2.329,1.1,1.087,1.049,3.21,1.049l6.349-0.002c0.76,0.017,1.208,0.146,1.208,0.955
                  c0,0.617-0.528,0.7-1.121,0.7l-6.013,0.007c-0.008,0.162,0.801,1.441,1.099,2.132c0.113,0.262,0.201,0.6,0.327,0.803
                  c0.34,0.139,2.614,0.09,3.168,0.053c0.948-0.065,2.383-0.214,2.526,0.686c0.29,1.814-3.605,0.629-5.342,1.037
                  c-0.025,0.503,0.008,0.999-0.013,1.498c-0.017,0.497-0.139,0.954-0.168,1.452c1.129-0.008,2.246,0.008,3.375,0.004
                  c0.847-0.006,2.501-0.233,2.131,1.051c-0.239,0.841-1.762,0.581-2.698,0.581c-1.041,0-2.396-0.076-3.399,0.023
                  c-1.704,4.045-6.333,6.371-10.806,5.111c-0.604-0.17-1.578-0.675-2.019-0.745c-0.549,0.547-0.755,0.361-0.927,1.036
                  c-0.269,1.043-0.525,1.427-1.28,2.148c1.183,0.137,19.186,0.044,21.959,0.042c0.852,0,1.309-0.564,1.33-1.423L36.534,5.857
                  c-0.004-0.839-0.436-1.425-1.255-1.425H12.934C11.998,4.432,11.566,5.129,11.566,6.048z M30.341,8.58
                  c0.436-0.18,2.693-0.367,3.151-0.189c0.156,0.41-0.986,2.652-1.431,2.881c-0.31-0.161-0.395-0.371-0.579-0.654
                  c-0.39,0.061-3.584,1.876-4.381,2.302c-3.197,1.725-2.531,1.675-4.339,0.436c-0.538-0.367-2.208-1.638-2.623-1.781
                  c-0.881,0.59-1.75,1.291-2.652,1.962c-1.044,0.777-2.337,2.091-2.815,1.198c-0.185-0.774,0.302-0.93,0.818-1.301l2.492-1.862
                  c1.1-0.807,1.893-1.758,2.644-1.283c0.445,0.279,0.81,0.573,1.225,0.857c0.865,0.59,1.67,1.188,2.539,1.729
                  c0.461-0.147,2.589-1.349,3.164-1.672c0.482-0.271,2.891-1.506,3.118-1.748C30.488,8.988,30.361,9.149,30.341,8.58z M34.411,20.821
                  c0,1.299-1.708,0.904-2.916,0.904h-7.767c-1.591,0-3.043,0.292-3.118-0.726c-0.097-1.28,1.833-0.934,2.929-0.934h7.767
                  C32.309,20.066,34.411,19.737,34.411,20.821z"
                />
              </svg>
            </button>
          </div>
          <span>Auditoría</span>
        </li>
        <!-- <li
          class="nav-item"
          *ngIf="showBtnSubcuentas"
          [ngClass]="
            panelService.nombreComponente == 'SUBCUENTAS' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('SUBCUENTAS')"
        > -->
        <ng-container *ngIf="showBtnSubcuentas">
          <li
            *appHasPermission="'m-subaccount.show'"
            class="nav-item"
            [ngClass]="panelService.nombreComponente == 'SUBCUENTAS' ? 'active' : ''"
            (click)="panelService.clickShowPanel('SUBCUENTAS')"
          >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.216,41.228c0,0.271,0.096,0.495,0.166,0.785
                    c0.355,1.532,1.751,2.407,3.107,3.028l0.915,0.405c0.118,0.046,0.214,0.077,0.323,0.112c0.447,0.148,0.919,0.299,1.344,0.411
                    l0.699,0.177c1.203,0.287,2.49,0.59,3.694,0.764l1.584,0.173c0.271,0.029,0.402,0.124,0.735,0.138
                    c0.38,0.016,0.512,0.006,0.818,0.061c1.724,0.312,6.476,0.376,8.388,0.376c1.703,0,5.313-0.04,6.848-0.241
                    c0.486-0.063,1.081-0.055,1.606-0.149c0.258-0.043,0.485-0.027,0.823-0.051c0.292-0.021,0.446-0.121,0.739-0.142l3.054-0.45
                    c0.259-0.044,0.525-0.09,0.802-0.151l0.708-0.168c1.037-0.256,2.04-0.488,3.063-0.882l0.909-0.405
                    c0.442-0.199,0.718-0.359,1.138-0.612l0.989-0.769c0.166-0.151,0.267-0.26,0.411-0.466c0.351-0.507,0.683-1.309,0.683-1.944
                    c0-1.085-0.014-1.049-0.289-2.118c-0.031-0.123-0.04-0.175-0.08-0.291c-0.074-0.236-0.018-0.057-0.1-0.265
                    c-0.345-0.88-0.616-1.751-1.076-2.578c-0.096-0.178-0.14-0.289-0.245-0.483l-0.552-0.907c-0.034-0.053-0.048-0.073-0.087-0.134
                    c-0.008-0.014-0.021-0.031-0.031-0.046l-0.817-1.081c-0.417-0.523-0.976-1-1.527-1.394c-0.306-0.217-0.574-0.354-0.933-0.531
                    c-0.324-0.164-0.651-0.332-1.02-0.44l-1.793-0.396c-0.359-0.034-0.329-0.017-0.639-0.093c-0.381-0.091-3.023-0.162-3.409-0.243
                    c-0.892-0.196-0.644,0.054-1.247,0.805l-0.236,0.276c-0.267,0.285-0.696,0.741-1.002,0.975l-2.301,1.936
                    c-1.111,0.876-3.273,2.691-4.39,3.283c-0.428-0.228-0.809-0.556-1.198-0.846l-1.724-1.274c-1.151-0.821-2.196-1.808-3.294-2.693
                    l-1.493-1.432c-0.18-0.214-0.302-0.346-0.464-0.56c-0.472-0.64-0.254-0.617-1.059-0.458l-2.039,0.154
                    c-0.679,0.088-1.269,0.074-2.004,0.186c-1.791,0.271-2.709,0.684-4.144,1.704c-1.694,1.206-3.046,3.897-3.71,5.786
                    C2.409,39.334,2.216,39.851,2.216,41.228"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M35.319,13.462c0.139-1.098,0.008-1.952-0.07-3.002
                    c-0.027-0.37-0.004-0.416-0.079-0.724c-0.197-0.815-0.324-1.648-0.635-2.435l-0.218-0.508c-0.079-0.192-0.048-0.129-0.132-0.311
                    c-0.061-0.128-0.074-0.149-0.122-0.243c-0.306-0.608-0.674-1.208-1.116-1.73l-0.136-0.161c-0.135-0.172-0.166-0.243-0.324-0.405
                    c-0.712-0.737-1.535-1.38-2.441-1.87l-2.472-0.957c-2.092-0.604-5.361-0.508-7.632,0.258c-0.039,0.013-0.118,0.046-0.157,0.062
                    l-0.106,0.039c-0.131,0.048-0.131,0.043-0.271,0.096l-2.044,1.026c-0.437,0.259-0.778,0.565-1.159,0.887
                    c-1.557,1.307-2.551,3.279-2.823,5.288c-0.025,0.174-0.052,0.409-0.091,0.64l-0.157,1.373c-0.114,0.437-0.232,0.815-0.25,1.288
                    c-0.058,1.627-0.131,1.125-0.6,1.74c-0.533,0.703-0.52,2.582-0.315,3.413l0.166,0.639c0.171,0.602,0.385,1.181,0.701,1.708
                    c0.546,0.903,0.848,0.456,1.159,1.256l0.753,1.656c0.206,0.386,0.346,0.7,0.552,1.057c0.39,0.68,0.888,1.311,1.325,1.959
                    c0.268,0.401,0.626,0.74,0.937,1.112c0.734,0.875,1.882,1.768,2.888,2.3c0.224,0.12,0.302,0.181,0.525,0.28
                    c0.506,0.222,1.891,0.709,2.502,0.709h1.098c0.346,0,0.39-0.103,0.735-0.143c0.989-0.112,2.048-0.614,2.862-1.16l1.479-1.15
                    c0-0.003,0.075-0.064,0.08-0.066l1.001-1.041c0.149-0.167,0.254-0.233,0.39-0.418c0.078-0.105,0.11-0.163,0.183-0.256
                    c0.071-0.094,0.013-0.018,0.097-0.119l0.735-1.021c0.801-1.206,1.479-2.496,2.008-3.837l0.189-0.321
                    c0.013-0.012,0.026-0.025,0.04-0.034l0.621-0.549c0.009-0.013,0.021-0.028,0.03-0.042c0.363-0.468,0.59-1.07,0.753-1.658
                    c0.03-0.116,0.048-0.141,0.083-0.28l0.166-0.642C36.251,16.025,36.232,14.077,35.319,13.462"
                  />
                </g>
              </svg>
              <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Subcuentas</span>
        </li>
      </ng-container>
        <li
          class="nav-item"
          *ngIf="showBtnDrivers"
          [ngClass]="panelService.nombreComponente == 'DRIVERS' ? 'active' : ''"
          (click)="panelService.clickShowPanel('DRIVERS')"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="666.000000pt"
                height="632.000000pt"
                viewBox="0 0 666.000000 632.000000"
                preserveAspectRatio="xMidYMid meet"
                width="25px"
                height="25px"
              >
                <g
                  transform="translate(0.000000,632.000000) scale(0.100000,-0.100000)"
                  stroke="none"
                >
                  <path
                    d="M3215 6199 c-180 -22 -343 -104 -470 -234 -112 -114 -171 -219 -211
-376 -24 -93 -22 -281 5 -381 59 -226 226 -426 431 -520 126 -57 201 -73 350
-72 123 1 143 4 230 32 156 51 292 145 387 267 282 360 209 875 -163 1141
-158 113 -369 167 -559 143z"
                  />
                  <path
                    d="M3110 4449 c-281 -18 -288 -20 -498 -102 -106 -42 -232 -95 -280
-117 l-88 -41 -215 66 c-118 37 -248 77 -289 91 -99 32 -135 31 -168 -4 -15
-16 -145 -307 -317 -707 -160 -374 -477 -1111 -703 -1638 -301 -701 -412 -968
-412 -996 0 -50 27 -74 121 -107 213 -75 965 -337 1178 -410 160 -55 259 -84
288 -84 46 0 60 2 1098 140 374 49 701 90 726 90 25 0 84 -13 130 -29 46 -16
163 -57 259 -91 96 -34 193 -68 215 -75 22 -7 137 -47 255 -88 695 -243 677
-238 718 -195 17 20 241 573 737 1823 57 143 219 550 360 905 160 401 258 659
258 682 1 28 -5 44 -23 62 -25 25 -49 33 -525 184 -1032 329 -922 301 -1129
282 l-108 -11 -202 80 c-173 68 -540 208 -673 257 -23 8 -47 22 -53 30 -10 12
-56 14 -269 13 -141 -1 -317 -6 -391 -10z m-1265 -343 l130 -43 -70 -32 c-38
-18 -93 -43 -121 -56 -99 -45 -201 -191 -247 -352 -21 -77 -22 -87 -25 -938
-2 -624 0 -875 8 -913 7 -33 23 -66 42 -88 53 -60 80 -66 299 -72 l195 -4
-148 -372 c-81 -204 -169 -424 -195 -490 -26 -65 -52 -120 -58 -122 -9 -4
-1273 431 -1284 442 -2 2 73 183 167 401 215 502 811 1888 989 2303 75 173
142 330 150 348 7 17 19 32 26 31 6 0 71 -19 142 -43z m4282 -565 c126 -40
132 -42 124 -64 -10 -25 -199 -502 -506 -1272 -114 -286 -262 -659 -330 -830
-223 -563 -407 -1020 -410 -1024 -2 -2 -23 4 -47 12 -24 8 -297 103 -608 211
-662 231 -636 221 -632 237 3 12 267 627 322 751 l20 47 448 3 448 3 49 25
c39 21 55 37 78 80 l28 54 -3 900 c-4 995 0 938 -68 1084 -28 59 -29 66 -16
97 l14 33 479 -153 c263 -84 537 -171 610 -194z m-2486 -1930 l206 -6 -164
-380 c-90 -209 -170 -385 -178 -391 -9 -6 -43 -14 -78 -18 -58 -7 -390 -50
-727 -95 -80 -11 -217 -29 -305 -41 -88 -12 -223 -30 -300 -40 -200 -27 -221
-28 -217 -10 2 8 63 166 136 350 74 184 160 401 192 482 l59 148 440 3 c242 1
505 3 585 3 80 1 238 -2 351 -5z"
                  />
                </g>
              </svg>

              <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Conductores</span>
        </li>

        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'EVENT-USER' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('EVENT-USER')"
        >
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-navbar notif-counter"
              [attr.notif-count]="eventService.strUnreadCount"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.401,7.091c0.146-0.606-0.367-1.325-1.017-1.372
                  c-0.763-0.059-1.129,0.383-1.628,0.857l-0.632,0.626l-2.384,3.169c-0.078,0.155-0.105,0.251-0.201,0.377
                  c-0.132,0.184-0.142,0.167-0.237,0.39c-0.335,0.78-0.677,1.322-0.902,2.185c-0.096,0.364-0.224,0.554-0.312,0.898
                  c-0.104,0.406-0.109,0.624-0.265,0.991c-0.137,0.328-0.128,0.667-0.179,1.039c-0.058,0.444,0.046,0.641,0.257,0.936
                  c0.21,0.293,0.32,0.394,0.776,0.425c1.429,0.099,1.401-1.384,1.552-2.163c0.068-0.346,0.211-0.541,0.307-0.905
                  c0.105-0.396,0.1-0.633,0.297-0.953c0.239-0.38,0.179-0.581,0.334-0.874c0.069-0.129,0.146-0.188,0.243-0.392
                  c0.068-0.147,0.128-0.307,0.206-0.419c0.269-0.403,0.237-0.492,0.439-0.767l0.548-0.711c0.435-0.67,0.381-0.497,0.81-1.023
                  c0.348-0.42,1.122-1.194,1.519-1.571C15.182,7.599,15.305,7.487,15.401,7.091 M38.292,14.428c0.092,0.352,0.252,0.606,0.316,0.94
                  c0.127,0.658,0.15,1.32,0.553,1.908c0.252,0.366,1.299,0.585,1.771-0.016c0.293-0.379,0.283-0.268,0.283-0.901
                  c-0.004-1.155-0.293-1.403-0.416-2.027c-0.087-0.487-0.224-0.567-0.33-0.925c-0.114-0.386-0.102-0.59-0.297-0.908
                  c-0.214-0.341-0.142-0.485-0.408-0.847c-0.16-0.211-0.191-0.565-0.389-0.821l-0.695-1.185c-0.087-0.154-0.155-0.188-0.251-0.33
                  l-0.659-0.934c-0.417-0.389-0.16-0.213-0.395-0.476l-0.604-0.652c-0.526-0.56-1.317-1.523-2.154-1.54
                  c-0.362-0.007-0.778,0.169-0.896,0.382c-0.083,0.148-0.335,0.736-0.307,0.897c0.026,0.14,0.348,0.699,0.439,0.812l0.96,0.874
                  l1.41,1.683c0.152,0.24,0.152,0.101,0.284,0.344c0.242,0.439,0.366,0.481,0.476,0.729c0.127,0.291,0.479,1.037,0.627,1.206
                  c0.22,0.251,0.201,0.56,0.352,0.906C38.109,13.888,38.197,14.037,38.292,14.428z M13.09,2.021c0.321-1.002-0.801-1.94-1.619-1.462
                  l-0.509,0.361c-0.101,0.078-0.124,0.124-0.233,0.201L9.993,1.689C9.878,1.758,9.819,1.765,9.727,1.861
                  C9.595,1.998,9.701,1.953,9.549,2.065C9.394,2.178,9.416,2.09,9.306,2.259C9.169,2.465,9.325,2.36,9.105,2.49
                  c-0.179,0.105-0.11,0.005-0.243,0.195C8.615,3.039,8.675,2.811,8.424,3.112l-2.137,2.5c-0.22,0.325-0.508,0.629-0.714,1.022
                  C5.315,7.127,5.348,6.938,5.225,7.158L4.333,8.779C4.104,9.331,4.347,8.892,4.036,9.347c-0.124,0.178-0.115,0.38-0.22,0.599
                  c-0.124,0.241-0.201,0.295-0.293,0.581c-0.072,0.218-0.105,0.465-0.206,0.664c-0.105,0.226-0.169,0.325-0.248,0.618
                  c-0.069,0.249-0.101,0.412-0.205,0.622c-0.193,0.397-0.229,0.929-0.317,1.37c-0.091,0.446-0.472,1.295-0.297,2.178
                  c0.114,0.59,0.943,0.984,1.642,0.712c0.856-0.332,0.696-2,1.186-3.111c0.096-0.215,0.133-0.449,0.174-0.694
                  c0.083-0.517,0.274-0.791,0.426-1.267c0.082-0.244,0.196-0.344,0.288-0.579c0.279-0.713,1.015-2.222,1.427-2.823
                  C7.49,8.074,7.5,8.123,7.587,7.976c0.078-0.123,0.072-0.179,0.142-0.297L9,6.006c0.12-0.158,0.11-0.096,0.22-0.217l0.815-0.87
                  c0.229-0.302,1.195-1.084,1.327-1.235c0.21-0.24,0.285-0.191,0.49-0.379c0.366-0.348,0.237-0.185,0.458-0.366l0.466-0.398
                  C12.944,2.385,13.004,2.293,13.09,2.021z M30.414,43.59c0.059-0.151,0.156-0.647-0.283-0.687l-7.441,0.019
                  c-0.713-0.044-1.451,0.043-2.122-0.003c-0.353-0.024-1.877-0.084-2.046,0.077c-0.379,0.359,0.109,1.022,0.179,1.152
                  c0.068,0.124,0.105,0.251,0.164,0.369l0.883,1.239c0.216,0.209,0.354,0.308,0.572,0.495c0.092,0.085,0.169,0.185,0.265,0.265
                  c0.096,0.085,0.201,0.129,0.325,0.202c0.243,0.147,1.111,0.664,1.387,0.741c0.348,0.091,0.434,0.192,0.801,0.261
                  c0.347,0.064,0.589,0.103,0.942,0.11c0.631,0.013,1.404,0.004,1.968-0.15c0.782-0.216,1.08-0.491,1.469-0.658
                  c0.173-0.075,1.097-0.733,1.229-0.894c0.221-0.256,0.352-0.256,0.513-0.551c0.083-0.156,0.701-0.798,0.852-1.271
                  C30.194,43.931,30.3,43.883,30.414,43.59z M46.639,15.733v-0.386c0-0.352-0.521-2.649-0.586-2.784
                  c-0.278-0.595-0.247-0.931-0.43-1.26c-0.352-0.625-0.201-0.787-0.486-1.21C44.968,9.832,45,9.763,44.89,9.475
                  c-0.096-0.252-0.196-0.208-0.311-0.51c-0.114-0.305-0.11-0.334-0.289-0.581c-0.113-0.156-0.163-0.371-0.255-0.563l-1.328-2.006
                  c-0.137-0.126-0.083-0.027-0.187-0.247c-0.211-0.448-0.599-0.796-0.916-1.208L40.8,3.472c-0.164-0.191-0.234-0.14-0.417-0.401
                  c-0.206-0.279-0.54-0.515-0.828-0.821c-0.083-0.084-0.142-0.103-0.253-0.181l-1.647-1.298c-0.206-0.139-0.2-0.258-0.571-0.297
                  c-0.489-0.048-0.81,0.112-1.166,0.478c-0.201,0.208-0.184,0.68-0.184,0.97c0,0.467,0.582,0.749,0.751,0.938l2.668,2.356
                  c0.146,0.188,0.255,0.282,0.396,0.469c0.133,0.172,0.279,0.263,0.385,0.435c0.164,0.279,0.609,0.739,0.736,0.956
                  c0.161,0.282,0.224,0.306,0.371,0.5c0.146,0.199,0.151,0.347,0.307,0.512c0.141,0.144,0.512,0.841,0.607,1.033
                  c0.119,0.241,0.216,0.292,0.325,0.544l0.536,1.159c0.087,0.265,0.16,0.351,0.261,0.559c0.096,0.197,0.138,0.432,0.22,0.647
                  c0.091,0.24,0.178,0.304,0.256,0.563c0.068,0.217,0.082,0.462,0.132,0.686c0.065,0.272,0.138,0.385,0.221,0.607
                  c0.146,0.393,0.164,0.974,0.279,1.411c0.092,0.375,0.255,0.954,0.499,1.189C45.376,17.152,46.639,16.608,46.639,15.733z
                  M11.896,23.41c0.123,0.986-0.042,4.239-0.161,5.412c-0.022,0.218-0.42,1.456-0.562,1.664c-0.092,0.137-0.156,0.194-0.22,0.359
                  c-0.088,0.201-0.042,0.18-0.188,0.343c-0.229,0.254-0.27,0.473-0.458,0.699c-0.147,0.175-0.1,0.127-0.224,0.357
                  c-0.12,0.229-0.105,0.035-0.454,0.657c-0.114,0.199-0.124,0.135-0.243,0.293c-0.105,0.137-0.096,0.235-0.201,0.374l-0.683,1.01
                  c-0.073,0.156-0.085,0.251-0.177,0.397c-0.083,0.129-0.147,0.17-0.225,0.307l-0.366,0.749c-0.105,0.27-0.146,0.572-0.266,0.842
                  c-0.26,0.605-0.329,1.189-0.334,1.939c-0.018,2.515,0.751,1.796,2.38,1.911c0.677,0.046,1.51,0.004,2.209,0.004
                  c2.992,0,5.989,0,8.982,0c5.975,0,12.001,0.077,17.972,0.004c2.649-0.035,2.883,0.311,2.997-1.293
                  c0.129-1.837-0.184-2.564-0.892-4.085c-0.146-0.316-0.182-0.236-0.417-0.744l-0.658-0.983c-0.147-0.165-0.092-0.203-0.207-0.372
                  c-0.082-0.131-0.146-0.149-0.242-0.292c-0.077-0.117-0.137-0.241-0.22-0.359c-0.128-0.197-0.15-0.096-0.26-0.318
                  c-0.306-0.633-0.129-0.199-0.426-0.685c-0.147-0.235-0.045-0.148-0.224-0.352l-0.594-1.05c-0.098-0.337-0.152-0.549-0.285-0.872
                  c-0.449-1.092-0.283-6.283-0.283-7.463c0-0.76-0.024-1.483-0.028-2.219c-0.004-0.646-0.183-1.318-0.247-1.982
                  c-0.028-0.341-0.055-0.743-0.12-1.034l-0.475-1.745c-0.055-0.12-0.143-0.258-0.183-0.401c-0.239-0.763-0.197-0.393-0.522-1.167
                  c-0.289-0.688-0.586-0.874-0.686-1.005c-0.069-0.088-0.12-0.224-0.197-0.333l-0.521-0.591c-0.244-0.24-1.278-1.16-1.533-1.315
                  c-0.123-0.073-0.243-0.126-0.344-0.192c-0.685-0.466-0.343-0.179-0.723-0.382c-0.356-0.191,0.006-0.1-0.773-0.388
                  c-0.201-0.078-0.205-0.122-0.37-0.211c-0.118-0.064-0.248-0.104-0.379-0.146c-1.195-0.39-1.556-1.583-1.908-2.585
                  c-0.123-0.345-0.229-0.423-0.366-0.744c-0.128-0.315-0.288-0.441-0.457-0.652c-0.073-0.091-0.476-0.505-0.591-0.567
                  c-0.882-0.485-1.825-0.544-2.887-0.431c-0.658,0.069-1.218,0.281-1.6,0.66c-0.152,0.15-0.212,0.127-0.317,0.263
                  c-0.297,0.366-0.823,1.333-0.946,1.851c-0.105,0.43-0.193,0.441-0.325,0.789c-0.253,0.657-0.656,0.893-1.022,1.199
                  c-0.337,0.283-0.447,0.188-0.809,0.35c-0.17,0.078-0.166,0.131-0.371,0.213c-0.751,0.304-0.485,0.233-1.108,0.528
                  c-0.947,0.453-1.19,0.838-1.679,1.176c-0.128,0.089-0.179,0.121-0.284,0.242l-1.299,1.506c-0.074,0.116-0.115,0.254-0.202,0.379
                  c-0.357,0.548-0.229,0.474-0.38,0.726c-0.289,0.48-0.114-0.028-0.343,0.766c-0.095,0.337-0.265,0.41-0.348,0.771
                  c-0.082,0.368-0.086,0.569-0.201,0.952C11.992,17.608,11.759,22.31,11.896,23.41z"
                />
              </svg>
              <!-- <i class="fa fa-bell notif-counter" [attr.notif-count]="eventService.strUnreadCount" aria-hidden="true"></i> -->
            </button>
          </div>
          <span>Eventos</span>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'MULTIVIEW' ? 'active' : ''
          "
          (click)="clickShowModalMultiview()"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <ng-container
                *ngIf="panelService.nombreComponente == 'MULTIVIEW'"
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 -0.5 21 21"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#ffffff"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>grid [#ffffff]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-219.000000, -200.000000)"
                        fill="#ffffff"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                            id="grid-[#ffffff]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ng-container>
              <ng-container
                *ngIf="panelService.nombreComponente != 'MULTIVIEW'"
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 -0.5 21 21"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                  stroke="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>grid [#000000]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-219.000000, -200.000000)"
                        fill="#000000"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                            id="grid-[#000000]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ng-container>
            </button>
          </div>
          <span>Multipantalla</span>
        </li>
        <!-- <li class="nav-item">
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <i class="fa fa-book" aria-hidden="true"></i>
            </button>
          </div>
          <span>Auditoría</span>
        </li> -->
        <!-- <li class="nav-item">
          <button class="btn btn-navbar" [ngClass]="panelService.nombreComponente == 'CONFIG'? 'active': ''" (click)="panelService.clickShowPanel('CONFIG');">
            <i class="fa fa-history" aria-hidden="true"></i>
            <span>Configuración</span>
          </button>
        </li> -->

        <!-- <li class="nav-item">
          <a class="nav-link" aria-current="page" (click)="showHideSideBar()"  title="Menú" data-bs-toggle="tooltip" data-bs-placement="bottom">
            <i class="fa fa-list-ul" ></i>
          </a>
        </li> -->

        <!-- <li class="nav-item">
          <a class="nav-link" aria-current="page" title="Configurar" data-bs-toggle="tooltip" data-bs-placement="bottom" >
            <i class="fa fa-cog"></i>
          </a>
        </li> -->

        <!-- <li class="nav-item">
          <a routeLink href="reports" target="_blank" class="nav-link" aria-current="page" title="Reportes" data-bs-toggle="tooltip" data-bs-placement="bottom">
            <i class="fa fa-file-code-o" ></i>
          </a>
        </li> -->

        <!-- <li class="nav-item">
          <a class="nav-link" aria-current="page" title="Auditoría" data-bs-toggle="tooltip" data-bs-placement="bottom">
            <i class="fa fa-check-square"></i>
          </a>
        </li> -->
      </ul>
    </div>

    <!-- COLAPSADO -->
    <div
      class="collapse navbar-collapse justify-content-start"
      id="navbarSupportedContent_collapsed"
    >
      <ul class="navbar-nav h-100">
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'VEHICLES' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('VEHICLES')"
          title="Vehículos"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="721.000000pt"
              height="503.000000pt"
              viewBox="0 0 721.000000 503.000000"
              preserveAspectRatio="xMidYMid meet"
              x="0px"
              y="0px"
              width="28px"
              height="28px"
            >
              <g
                transform="translate(0.000000,503.000000) scale(0.100000,-0.100000)"
                stroke="none"
              >
                <path
                  d="M1663 4788 c-11 -13 -28 -38 -37 -56 -9 -17 -263 -607 -565 -1310
-302 -702 -646 -1504 -766 -1782 -120 -278 -221 -520 -225 -538 -11 -43 4 -86
37 -107 14 -9 89 -39 167 -67 155 -54 1245 -433 1421 -494 l110 -37 140 17
c77 10 540 71 1028 136 818 108 892 117 935 105 42 -11 353 -119 1300 -449
167 -58 316 -106 333 -106 65 0 73 16 315 625 286 722 447 1127 667 1680 96
242 268 673 381 958 139 349 206 530 206 555 0 39 -21 73 -55 90 -11 5 -348
114 -750 242 -401 127 -750 238 -774 246 -60 19 -147 18 -396 -6 -372 -35
-599 -52 -1630 -124 l-180 -12 -220 62 c-738 208 -951 270 -1135 329 -224 71
-275 78 -307 43z m465 -322 c180 -57 478 -145 662 -196 184 -50 341 -93 347
-96 14 -4 17 5 -48 -159 l-39 -100 -60 -28 c-96 -44 -150 -108 -292 -342 -165
-274 -147 -251 -187 -230 -28 14 -58 16 -201 13 -213 -4 -210 -2 -210 -133 0
-131 -2 -129 164 -135 l135 -5 -50 -85 c-57 -97 -100 -204 -131 -323 -20 -75
-22 -116 -27 -507 l-6 -425 -204 -510 c-236 -590 -221 -555 -236 -555 -9 0
-397 134 -885 305 -52 18 -195 68 -318 111 -133 46 -221 82 -218 88 2 6 115
270 251 586 136 316 460 1070 720 1675 512 1190 496 1155 503 1155 2 0 151
-47 330 -104z m3142 -190 c0 -14 -259 -601 -266 -603 -5 -1 -22 20 -39 48 -64
108 -184 188 -315 208 -40 7 -301 11 -667 11 -388 0 -603 4 -603 10 0 5 14 38
31 73 l31 63 332 33 c182 18 392 41 466 51 318 44 535 70 675 80 83 7 177 15
210 19 67 9 145 13 145 7z m316 -25 c32 -11 329 -106 659 -211 330 -105 603
-194 607 -198 4 -4 -2 -27 -12 -52 -17 -40 -361 -903 -635 -1595 -57 -143
-210 -528 -340 -855 -130 -327 -270 -680 -312 -785 -41 -104 -79 -194 -83
-199 -7 -7 -146 39 -630 209 -52 18 -254 88 -447 156 -194 67 -353 127 -353
133 0 6 75 185 167 399 l166 387 329 0 328 0 0 -120 c0 -182 2 -184 308 -178
l192 3 27 28 28 27 3 488 c2 303 -1 527 -8 594 -18 184 -66 333 -154 481 -25
43 -46 83 -46 88 0 5 55 9 124 9 166 0 171 4 171 129 0 81 -2 93 -23 113 -21
22 -31 23 -186 26 -131 3 -171 0 -201 -12 -21 -9 -39 -16 -40 -16 -7 0 -75
123 -75 135 0 27 353 835 365 835 6 0 38 -9 71 -19z m-871 -472 c71 -38 146
-135 317 -410 80 -129 150 -244 157 -256 14 -28 14 -66 -1 -81 -16 -16 -2569
-18 -2599 -2 -39 21 -22 58 156 344 171 276 235 360 300 394 79 41 80 41 865
39 l755 -2 50 -26z m687 -1186 c26 -58 34 -144 18 -182 -13 -33 -82 -82 -146
-105 -63 -22 -156 -27 -316 -18 -146 8 -146 8 -201 44 -64 43 -137 110 -137
126 0 25 57 43 344 107 438 97 408 96 438 28z m-2614 -23 c174 -39 327 -75
340 -80 16 -7 22 -17 20 -32 -4 -28 -140 -143 -188 -160 -23 -9 -98 -13 -225
-13 l-190 1 -75 36 c-110 52 -120 65 -120 146 0 122 25 172 86 172 19 0 177
-32 352 -70z m1347 -939 c-3 -5 -45 -103 -94 -218 -158 -369 -222 -518 -230
-525 -7 -8 -100 -22 -491 -73 -151 -20 -322 -42 -380 -50 -119 -17 -364 -49
-520 -70 -58 -8 -176 -24 -263 -36 -87 -11 -160 -19 -162 -17 -3 2 57 160 133
351 l137 346 212 3 c212 3 213 3 238 28 23 23 25 35 31 148 l7 122 694 0 c420
0 692 -4 688 -9z"
                />
              </g>
            </svg>

              <!-- <i class="fa fa-truck" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente.includes('ALERTS') ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('ALERTS')"
          title="Alertas"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.543,11.004c1.107,0,1.646,0.002,2.65,0.929
                  c1.325,1.225,1.394,2.711,1.271,4.527l-0.749,10.177c-0.024,0.361-0.061,1.03-0.112,1.477c-0.094,0.809-0.099,2.146-0.257,2.921
                  c-0.903-0.085-1.439-0.839-3.67-0.353c-0.493,0.107-0.754,0.311-1.188,0.353c-0.173-0.857-0.119-1.872-0.239-2.829
                  c-0.057-0.444-0.068-1.034-0.107-1.375l-0.307-4.363c-0.097-4.372-1.771-9.417,1.604-11.123
                  C22.698,11.212,23.215,11.004,23.543,11.004 M47.837,39.605v0.865c-0.011,0.717-0.09,1.197-0.518,2.075
                  c-0.838,1.716-2.327,2.522-4.249,2.522H4.858c-1.51,0-2.336-0.278-3.353-1.192C0.18,42.682,0,41.356,0,39.605
                  c0-1.711,1.6-3.761,2.485-5.29L18.482,7.879c0.677-1.144,2.316-3.983,2.982-4.725c1.376-1.525,3.68-1.444,5.139-0.046
                  c0.745,0.715,6.87,11.265,7.293,11.991l7.906,13.216c0.252,0.436,0.462,0.822,0.71,1.22l2.167,3.62
                  C45.602,34.751,47.837,37.809,47.837,39.605z M20.927,37.784c0-1.266,0.472-2.132,1.19-2.816c0.193-0.181,0.208-0.192,0.458-0.331
                  c1.019-0.567,2.18-0.539,3.092,0.283l0.392,0.41c2.687,3.195-1.505,7.804-4.177,4.788c-0.27-0.307-0.445-0.574-0.638-0.984
                  C21.129,38.884,20.927,38.096,20.927,37.784z"
                />
              </svg>
              <!-- <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>
        <!-- <li class="nav-item dropdown" [ngClass]="(panelService.nombreComponente == 'GEOFENCES' || panelService.nombreComponente == 'CIRCULAR-GEOFENCE'|| panelService.nombreComponente == 'POLYLINE-GEOFENCE') ? 'active': ''"  title="Geocercas" data-bs-toggle="tooltip" data-bs-placement="bottom" onclick="this.blur();" >

          <a role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex justify-content-center">
              <button class="btn btn-navbar">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                    c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                    c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                    c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                    c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                    c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                    c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                    c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                    c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                    c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                    C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                    c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                    c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                    c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                    c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                    c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                    c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                    C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                    c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                    c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                    c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                    c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                    l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                    c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                    l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                    c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                    c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                    c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                    c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                    c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                    c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"/>
                </svg>

              </button>
            </div>
          </a>

          <ul class="dropdown-menu">
            <li (click)="panelService.clickShowPanel('GEOFENCES');" class="dropdown-item">Geocercas Poligonales</li>
            <li (click)="panelService.clickShowPanel('CIRCULAR-GEOFENCE');" class="dropdown-item">Geocercas Circulares</li>
            <li (click)="panelService.clickShowPanel('POLYLINE-GEOFENCE');" class="dropdown-item">Geocercas Polilineas</li>
          </ul>


        </li> -->

        <!-- Here begins the change (unite everything)-->
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'GEOFENCES' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('GEOFENCES')"
          title="Geocercas"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"
                />
              </svg>
            </button>
          </div>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'GEOPOINTS' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('GEOPOINTS')"
          title="Geopuntos"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.944,13.854c0.387,1,0.603,2.089,0.603,3.226
                  c0,4.934-4.001,8.935-8.938,8.935c-4.936,0-8.937-4.001-8.937-8.935c0-0.493,0.042-0.976,0.118-1.446
                  c2.071-1.342,5.885-0.791,8.995,0.564C26.893,17.551,30.171,15.741,31.944,13.854 M23.609,6.283c5.962,0,10.796,4.835,10.796,10.797
                  s-4.833,10.794-10.796,10.794c-5.962,0-10.795-4.832-10.795-10.794S17.647,6.283,23.609,6.283z M23.609,1.138
                  c8.804,0,15.943,7.137,15.943,15.942c0,5.983-3.297,11.195-8.172,13.923c-0.424,0.667-1.632,2.707-3.721,7.237
                  C24.993,44.021,23.722,48,23.722,48s-1.988-5.086-3.794-9.267c-1.526-3.538-3.521-6.811-4.099-7.736
                  c-4.87-2.729-8.162-7.939-8.162-13.917C7.667,8.275,14.804,1.138,23.609,1.138z"
                />
              </svg>
              <!-- <i class="fa fa-thumb-tack" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'HISTORIAL' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('HISTORIAL')"
          title="Historial"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 637.000000 710.000000"
              preserveAspectRatio="xMidYMid meet"
              width="24px"
              height="24px"
            >
              <g
                transform="translate(0.000000,710.000000) scale(0.100000,-0.100000)"
                stroke="none"
              >
                <path
                  d="M1880 6770 c-159 -34 -293 -115 -388 -234 -27 -34 -135 -204 -241
         -378 -106 -175 -195 -318 -197 -318 -2 0 -18 9 -36 20 -31 19 -51 20 -289 20
         -249 0 -257 -1 -290 -23 -41 -28 -47 -47 -48 -170 -2 -185 14 -197 274 -197
         96 0 175 -2 175 -4 0 -3 -26 -47 -57 -98 -126 -207 -201 -415 -239 -661 -14
         -87 -16 -219 -15 -883 0 -445 5 -792 10 -805 5 -13 21 -35 36 -49 l27 -25 290
         -3 c287 -3 290 -3 273 -22 -17 -19 -15 -23 70 -115 84 -92 100 -105 107 -86 2
         4 37 45 78 91 41 45 75 86 75 90 0 4 -33 42 -72 85 l-73 78 0 158 0 159 1685
         0 1685 0 0 -165 c0 -187 8 -222 62 -255 31 -19 50 -20 349 -20 312 0 316 0
         350 23 18 12 39 34 46 47 10 20 13 199 10 855 -2 823 -3 831 -25 935 -45 207
         -114 380 -227 568 -30 51 -55 95 -55 97 0 3 80 5 178 5 148 0 183 3 212 18 49
         24 63 70 58 197 -8 176 -5 175 -340 175 -239 0 -255 -1 -287 -21 -34 -20 -35
         -20 -48 -3 -8 11 -98 158 -201 329 -171 282 -195 317 -277 401 -71 72 -106 99
         -165 128 -148 72 -100 69 -1310 72 -1024 3 -1090 2 -1170 -16z m2258 -181
         c154 -23 257 -119 440 -406 185 -291 366 -589 385 -634 24 -56 18 -91 -18
         -107 -35 -16 -3770 -17 -3812 -1 -34 13 -43 26 -43 62 0 39 -17 11 334 571
         201 322 272 410 376 465 111 59 88 58 1230 59 609 0 1071 -4 1108 -9z m-3180
         -1724 c99 -21 593 -131 772 -171 222 -49 245 -64 204 -123 -32 -45 -207 -181
         -263 -203 -53 -22 -70 -23 -316 -23 -245 0 -264 1 -335 23 -137 43 -245 118
         -256 178 -8 46 12 214 33 265 11 28 26 45 47 55 39 17 35 17 114 -1z m4280 -9
         c39 -34 64 -112 69 -221 4 -82 3 -93 -19 -125 -29 -43 -123 -102 -219 -138
         l-74 -27 -260 3 c-296 2 -326 8 -426 74 -94 63 -189 155 -189 184 0 33 32 46
         225 89 88 20 216 49 285 65 69 15 188 42 265 59 77 17 169 37 205 45 91 20
         105 19 138 -8z"
                />
                <path
                  d="M1621 2679 c-29 -61 -51 -112 -49 -114 2 -1 58 -21 126 -43 86 -29
         124 -37 127 -29 9 26 46 239 42 243 -5 6 -169 54 -183 54 -6 0 -35 -50 -63
         -111z"
                />
                <path
                  d="M3612 2723 l-33 -4 3 -122 3 -122 108 -3 107 -3 0 131 0 130 -77 -2
         c-43 0 -93 -3 -111 -5z"
                />
                <path
                  d="M4570 2612 c0 -84 4 -123 13 -130 21 -17 109 -22 147 -9 49 17 71 61
         77 156 l6 78 -69 7 c-38 4 -93 9 -121 12 l-53 6 0 -120z"
                />
                <path
                  d="M2110 2600 l0 -120 110 0 110 0 0 120 0 120 -110 0 -110 0 0 -120z"
                />
                <path
                  d="M2600 2600 l0 -120 110 0 111 0 -3 118 -3 117 -107 3 -108 3 0 -121z"
                />
                <path
                  d="M3102 2598 l3 -123 108 -3 107 -3 0 126 0 125 -110 0 -111 0 3 -122z"
                />
                <path
                  d="M4080 2600 l0 -120 105 0 105 0 0 120 0 120 -105 0 -105 0 0 -120z"
                />
                <path
                  d="M5068 2547 c-52 -77 -68 -113 -68 -146 0 -39 45 -91 99 -116 l40 -18
         68 61 c37 33 78 71 90 84 l22 24 -99 82 c-55 45 -104 82 -109 82 -4 0 -24 -24
         -43 -53z"
                />
                <path
                  d="M5365 2133 c-55 -24 -103 -47 -108 -52 -8 -8 25 -177 37 -188 10 -10
         248 20 252 31 5 13 -61 236 -73 246 -4 4 -53 -12 -108 -37z"
                />
                <path
                  d="M5296 1598 c-3 -24 -8 -70 -12 -104 l-6 -60 113 -24 c63 -12 120 -25
         127 -28 9 -3 12 26 12 127 l0 131 -114 0 -114 0 -6 -42z"
                />
                <path
                  d="M5158 1142 c-26 -43 -48 -83 -50 -89 -2 -6 39 -47 91 -91 l94 -81 17
         22 c29 38 120 176 126 192 4 10 -25 29 -105 69 -61 31 -114 56 -118 56 -5 0
         -29 -35 -55 -78z"
                />
                <path
                  d="M1210 1199 c-62 -12 -176 -70 -233 -120 -60 -52 -128 -147 -161 -226
         -127 -302 37 -650 356 -757 90 -31 227 -35 318 -11 131 35 268 134 350 254 21
         31 74 91 119 135 69 67 85 77 106 72 l25 -6 0 101 0 101 -27 -6 c-24 -5 -38 3
         -103 66 -42 40 -94 100 -117 133 -89 132 -236 236 -371 264 -61 13 -197 13
         -262 0z m265 -230 c71 -27 144 -93 186 -171 32 -59 34 -69 34 -158 0 -79 -4
         -103 -24 -142 -37 -75 -95 -134 -169 -170 -60 -30 -75 -33 -157 -33 -79 0 -97
         4 -149 29 -73 36 -131 94 -165 166 -22 47 -26 69 -26 150 0 85 3 101 29 154
         83 169 265 241 441 175z"
                />
                <path
                  d="M4837 856 c-42 -23 -79 -45 -82 -48 -6 -6 72 -219 85 -232 7 -7 207
         90 232 113 10 9 -130 211 -145 210 -7 0 -47 -19 -90 -43z"
                />
                <path
                  d="M2350 640 l0 -121 113 3 112 3 3 118 3 117 -116 0 -115 0 0 -120z"
                />
                <path
                  d="M2840 640 l0 -120 110 0 110 0 0 120 0 120 -110 0 -110 0 0 -120z"
                />
                <path
                  d="M3340 635 l0 -125 105 0 105 0 0 125 0 125 -105 0 -105 0 0 -125z"
                />
                <path
                  d="M3830 630 l0 -129 98 5 c53 3 103 7 110 10 9 2 12 36 12 124 l0 120
         -110 0 -110 0 0 -130z"
                />
                <path
                  d="M4310 630 c0 -107 3 -130 15 -130 35 0 214 23 222 29 6 4 4 49 -5
         119 l-15 112 -108 0 -109 0 0 -130z"
                />
              </g>
            </svg>
              <!-- <i class="fa fa-history" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>
        <li
          class="nav-item"
          (click)="windowAccess.open('/reports')"
          title="Reportes"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M38.283,46.174c0.165,0.833,0.057,1.26,1.521,1.258h5.489
                    c0.748,0,1.205,0.121,1.609-0.139c0.219-0.141,0.354-0.239,0.452-0.526c0.241-0.727,0.115-4.344,0.115-5.402V19.474
                    c0-1.773,0.067-3.673,0.002-5.429c-0.042-1.2-0.758-1.263-1.565-1.261H40.42c-0.604,0-1.389-0.118-1.796,0.269
                    c-0.114,0.109-0.237,0.234-0.275,0.398c-0.079,0.327-0.017,27.241-0.017,30.072L38.283,46.174z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.998,46.36c0,0.776,0.586,1.08,1.283,1.072
                    c2.157-0.018,4.933,0.091,6.91,0.005l0.397-0.12c0.266-0.161,0.485-0.361,0.581-0.722l0.024-23.483
                    c0.005-0.537,0.053-0.576-0.193-1c-0.111-0.194-0.396-0.477-0.629-0.477h-7.581c-0.441,0-0.791,0.575-0.791,1.043V46.36z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.05,15.169c-0.226,0.249-0.433,0.696-0.785,0.59
                    c-0.11-0.032-0.107-0.08-0.285-0.121c-0.907-0.208-1.837,0.07-2.555,0.472c-0.63,0.353-0.309,0.612-1.122,0.259
                    c-0.089-0.038-0.156-0.052-0.264-0.104l-3.613-1.693c-0.11-0.055-0.066-0.058-0.234-0.131l-2.384-1.122
                    c-0.005-0.869,0.037-0.979-0.234-1.681c-0.099-0.263-0.162-0.342-0.334-0.59c-1.101-1.568-2.932-1.732-4.51-0.79
                    c-0.429,0.251-0.645,0.521-0.904,0.94l-0.313,0.623c-0.427,1.214,0.09,1.827-0.013,2.412c-0.028,0.163-0.377,0.368-0.518,0.502
                    l-0.433,0.371c-0.847,0.672-1.628,1.463-2.47,2.152c-0.852,0.694-3.615,3.271-4.334,3.738c-0.254,0.164-0.864-0.268-1.181-0.326
                    c-3.303-0.581-5.266,3.669-2.689,5.709c1.455,1.151,3.505,0.928,4.556-0.436c0.218-0.278,0.418-0.527,0.546-0.88
                    c0.818-2.274-0.857-2.01,0.789-3.208c0.447-0.327,0.756-0.651,1.237-1.048l1.928-1.706c0.187-0.158,0.343-0.261,0.515-0.416
                    c0.155-0.14,0.32-0.291,0.483-0.438c0.167-0.147,0.308-0.291,0.485-0.444c0.155-0.134,0.331-0.267,0.51-0.414l1.852-1.596
                    c0.251-0.159,0.773,0.225,1.091,0.292c1.023,0.209,2.368-0.106,3.009-0.929c0.433,0.006,2.927,1.282,3.579,1.614
                    c0.167,0.084,0.311,0.106,0.486,0.192c0.153,0.077,0.279,0.125,0.418,0.198c0.256,0.137,0.601,0.289,0.881,0.423
                    c0.332,0.161,1.483,0.584,1.695,0.842c0.163,0.317-0.086,1.276,0.602,2.274c0.609,0.885,1.745,1.442,2.832,1.397
                    c1.103-0.044,2.088-0.724,2.635-1.594c0.215-0.347,0.293-0.649,0.392-1.037c0.124-0.496,0.1-0.81-0.007-1.269
                    c-0.155-0.644-0.354-0.886-0.32-1.127c0.052-0.323,3.255-3.768,3.576-4.274c0.517-0.815,3.312-3.863,4.244-5.005
                    c1.06-1.296,0.605-0.613,1.874-0.617c0.747-0.006,1.142-0.117,1.706-0.424c0.196-0.11,0.223-0.094,0.421-0.256
                    c0.17-0.139,0.534-0.52,0.678-0.747c0.604-0.967,0.76-2.32,0.163-3.364c-0.945-1.657-3.309-2.288-4.846-1.01
                    c-0.843,0.699-1.457,1.82-1.268,2.983c0.218,1.342,0.849,0.938-0.039,1.94l-5.232,6.173c-0.237,0.294-0.411,0.469-0.657,0.76
                    L32.05,15.169z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.607,46.36c0.062,0.459,0.261,0.871,0.782,1.034
                    c0.354,0.115,4.744,0.038,5.338,0.038c0.581,0,2.129,0.122,2.582-0.159c0.138-0.084,0.338-0.298,0.403-0.454
                    c0.194-0.476,0.088-9.572,0.088-10.513c0-1.505,0.077-5.943,0-7.086c-0.035-0.504-0.313-0.863-0.765-1.017
                    c-0.269-0.088-6.145-0.027-7.118-0.033c-1.763-0.016-1.311,1.771-1.311,4.006V46.36z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.34,46.235c0.091,0.474,0.069,0.709,0.407,0.958
                    c0.428,0.318,0.498,0.239,1.078,0.239h5.305c0.958,0,1.751,0.182,2.216-0.459c0.16-0.223,0.109-0.155,0.154-0.517l0.028-1.948
                    c0-2.254,0.102-5.96,0-7.891c-0.054-0.972-0.468-1.166-1.472-1.16l-5.304,0.001c-0.981,0-1.74-0.163-2.185,0.448
                    c-0.361,0.493-0.236,1.707-0.236,2.437L0.34,46.235z"
                  />
                </g>
              </svg>
              <!-- <i class="fa fa-line-chart" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'DASHBOARD' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('DASHBOARD')"
          title="Dashboard"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
          *appHasPermission="'m-dashboard.show'"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.127,1.754h12.377c2.268,0,4.127,1.856,4.127,4.127v12.376
                  c0,2.273-1.859,4.129-4.127,4.129H4.127C1.859,22.387,0,20.531,0,18.258V5.881C0,3.611,1.859,1.754,4.127,1.754 M4.127,26.893
                  h12.377c2.268,0,4.127,1.856,4.127,4.127v12.379c0,2.268-1.859,4.125-4.127,4.125H4.127C1.859,47.524,0,45.667,0,43.399V31.02
                  C0,28.749,1.859,26.893,4.127,26.893z M29.264,1.754h14.633c2.268,0,4.125,1.856,4.125,4.127v37.513
                  c0,2.271-1.857,4.128-4.125,4.128H29.264c-2.271,0-4.127-1.857-4.127-4.128V5.881C25.136,3.611,26.993,1.754,29.264,1.754z"
                />
              </svg>
              <!-- <i class="fa fa-th-large" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>
        <li
          class="nav-item"
          (click)="windowAccess.open('/audit')"
          title="Auditoría"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.372,33.348c-0.697-0.661-2.165-2.929-2.653-2.929
                  c-1.603,0,0.248,1.878,0.512,2.203c1.981,2.419,1.771,2.843,3.227,1.41c0.348-0.343,0.806-0.703,1.142-1.042
                  c0.436-0.451,0.659-0.673,1.125-1.053c0.251-0.208,0.923-0.844,1.049-1.173c-0.025-0.885-0.639-0.862-1.133-0.419
                  C22.086,30.84,19.782,33.225,19.372,33.348 M13.841,35.781l-2.426,2.547c-0.811,0.372-0.55-0.892-2.686,1.11
                  c-0.261,0.244-0.411,0.315-0.709,0.615l-2.803,2.503c-0.319,0.252-0.407,0.353-0.692,0.634l-1.427,1.223
                  c-0.294,0.22-0.453,0.338-0.65,0.676C1.65,46.451,2.9,48.161,4.499,47.986c0.638-0.068,1.179-0.698,1.591-1.053l4.201-3.754
                  c1.561-1.423,2.224-1.571,2.291-3.044c0.029-0.642,0.423-0.591,1.289-1.33c0.297-0.256,1.296-1.051,1.62-1.165
                  c0.759,0.371,0.817,0.685,2.211,1.144c4.537,1.492,9.25-1.817,9.4-6.697c0.168-5.55-6.534-9.591-11.52-5.776
                  c-2.127,1.626-3.113,4.148-2.673,7.049C13.072,34.428,13.53,35.011,13.841,35.781z M19.489,26.394
                  c1.879-0.354,4.373,1.024,5.392,2.938c1.493,2.812,0.444,6.047-2.283,7.588c-3.47,1.962-8.141-0.557-8.258-4.448
                  c-0.08-2.499,0.957-4.404,2.987-5.453C18.222,26.557,18.419,26.597,19.489,26.394z M3.35,46.01
                  c-0.063-0.503,0.403-1.022,0.877-1.063c0.533-0.044,1.011,0.411,1.053,0.883C5.393,47.084,3.492,47.189,3.35,46.01z M16.287,1.786
                  c-0.004,1.377-0.281,1.035,2.896,1.035l17.52-0.002c2.539-0.002,2.005,1.186,2.005,5.027c0,9.312,0,18.688,0,27.935l0.025,3.265
                  c1.721,0.13,2.526,0.258,2.518-1.842V2.261c0.004-1.336-0.143-1.987-1.515-1.996L18.331,0.269
                  C17.474,0.268,16.287,0.261,16.287,1.786z M11.566,6.048l0.009,21.979c0.461-0.317,0.852-1.943,3.072-3.504
                  c2.597-1.824,6.055-2.268,8.997-0.873c2.329,1.1,1.087,1.049,3.21,1.049l6.349-0.002c0.76,0.017,1.208,0.146,1.208,0.955
                  c0,0.617-0.528,0.7-1.121,0.7l-6.013,0.007c-0.008,0.162,0.801,1.441,1.099,2.132c0.113,0.262,0.201,0.6,0.327,0.803
                  c0.34,0.139,2.614,0.09,3.168,0.053c0.948-0.065,2.383-0.214,2.526,0.686c0.29,1.814-3.605,0.629-5.342,1.037
                  c-0.025,0.503,0.008,0.999-0.013,1.498c-0.017,0.497-0.139,0.954-0.168,1.452c1.129-0.008,2.246,0.008,3.375,0.004
                  c0.847-0.006,2.501-0.233,2.131,1.051c-0.239,0.841-1.762,0.581-2.698,0.581c-1.041,0-2.396-0.076-3.399,0.023
                  c-1.704,4.045-6.333,6.371-10.806,5.111c-0.604-0.17-1.578-0.675-2.019-0.745c-0.549,0.547-0.755,0.361-0.927,1.036
                  c-0.269,1.043-0.525,1.427-1.28,2.148c1.183,0.137,19.186,0.044,21.959,0.042c0.852,0,1.309-0.564,1.33-1.423L36.534,5.857
                  c-0.004-0.839-0.436-1.425-1.255-1.425H12.934C11.998,4.432,11.566,5.129,11.566,6.048z M30.341,8.58
                  c0.436-0.18,2.693-0.367,3.151-0.189c0.156,0.41-0.986,2.652-1.431,2.881c-0.31-0.161-0.395-0.371-0.579-0.654
                  c-0.39,0.061-3.584,1.876-4.381,2.302c-3.197,1.725-2.531,1.675-4.339,0.436c-0.538-0.367-2.208-1.638-2.623-1.781
                  c-0.881,0.59-1.75,1.291-2.652,1.962c-1.044,0.777-2.337,2.091-2.815,1.198c-0.185-0.774,0.302-0.93,0.818-1.301l2.492-1.862
                  c1.1-0.807,1.893-1.758,2.644-1.283c0.445,0.279,0.81,0.573,1.225,0.857c0.865,0.59,1.67,1.188,2.539,1.729
                  c0.461-0.147,2.589-1.349,3.164-1.672c0.482-0.271,2.891-1.506,3.118-1.748C30.488,8.988,30.361,9.149,30.341,8.58z M34.411,20.821
                  c0,1.299-1.708,0.904-2.916,0.904h-7.767c-1.591,0-3.043,0.292-3.118-0.726c-0.097-1.28,1.833-0.934,2.929-0.934h7.767
                  C32.309,20.066,34.411,19.737,34.411,20.821z"
                />
              </svg>
              <!-- <i class="fa fa-book" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>

        <ng-container *ngIf="showBtnSubcuentas">
            <li
            *appHasPermission="'m-subaccount.show'"
            class="nav-item"

            [ngClass]="
              panelService.nombreComponente == 'SUBCUENTAS' ? 'active' : ''
            "
            (click)="panelService.clickShowPanel('SUBCUENTAS')"
            title="Subcuentas"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            onclick="this.blur();"
            >
            <div class="d-flex justify-content-center">
              <button class="btn btn-navbar">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.216,41.228c0,0.271,0.096,0.495,0.166,0.785
                      c0.355,1.532,1.751,2.407,3.107,3.028l0.915,0.405c0.118,0.046,0.214,0.077,0.323,0.112c0.447,0.148,0.919,0.299,1.344,0.411
                      l0.699,0.177c1.203,0.287,2.49,0.59,3.694,0.764l1.584,0.173c0.271,0.029,0.402,0.124,0.735,0.138
                      c0.38,0.016,0.512,0.006,0.818,0.061c1.724,0.312,6.476,0.376,8.388,0.376c1.703,0,5.313-0.04,6.848-0.241
                      c0.486-0.063,1.081-0.055,1.606-0.149c0.258-0.043,0.485-0.027,0.823-0.051c0.292-0.021,0.446-0.121,0.739-0.142l3.054-0.45
                      c0.259-0.044,0.525-0.09,0.802-0.151l0.708-0.168c1.037-0.256,2.04-0.488,3.063-0.882l0.909-0.405
                      c0.442-0.199,0.718-0.359,1.138-0.612l0.989-0.769c0.166-0.151,0.267-0.26,0.411-0.466c0.351-0.507,0.683-1.309,0.683-1.944
                      c0-1.085-0.014-1.049-0.289-2.118c-0.031-0.123-0.04-0.175-0.08-0.291c-0.074-0.236-0.018-0.057-0.1-0.265
                      c-0.345-0.88-0.616-1.751-1.076-2.578c-0.096-0.178-0.14-0.289-0.245-0.483l-0.552-0.907c-0.034-0.053-0.048-0.073-0.087-0.134
                      c-0.008-0.014-0.021-0.031-0.031-0.046l-0.817-1.081c-0.417-0.523-0.976-1-1.527-1.394c-0.306-0.217-0.574-0.354-0.933-0.531
                      c-0.324-0.164-0.651-0.332-1.02-0.44l-1.793-0.396c-0.359-0.034-0.329-0.017-0.639-0.093c-0.381-0.091-3.023-0.162-3.409-0.243
                      c-0.892-0.196-0.644,0.054-1.247,0.805l-0.236,0.276c-0.267,0.285-0.696,0.741-1.002,0.975l-2.301,1.936
                      c-1.111,0.876-3.273,2.691-4.39,3.283c-0.428-0.228-0.809-0.556-1.198-0.846l-1.724-1.274c-1.151-0.821-2.196-1.808-3.294-2.693
                      l-1.493-1.432c-0.18-0.214-0.302-0.346-0.464-0.56c-0.472-0.64-0.254-0.617-1.059-0.458l-2.039,0.154
                      c-0.679,0.088-1.269,0.074-2.004,0.186c-1.791,0.271-2.709,0.684-4.144,1.704c-1.694,1.206-3.046,3.897-3.71,5.786
                      C2.409,39.334,2.216,39.851,2.216,41.228"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.319,13.462c0.139-1.098,0.008-1.952-0.07-3.002
                      c-0.027-0.37-0.004-0.416-0.079-0.724c-0.197-0.815-0.324-1.648-0.635-2.435l-0.218-0.508c-0.079-0.192-0.048-0.129-0.132-0.311
                      c-0.061-0.128-0.074-0.149-0.122-0.243c-0.306-0.608-0.674-1.208-1.116-1.73l-0.136-0.161c-0.135-0.172-0.166-0.243-0.324-0.405
                      c-0.712-0.737-1.535-1.38-2.441-1.87l-2.472-0.957c-2.092-0.604-5.361-0.508-7.632,0.258c-0.039,0.013-0.118,0.046-0.157,0.062
                      l-0.106,0.039c-0.131,0.048-0.131,0.043-0.271,0.096l-2.044,1.026c-0.437,0.259-0.778,0.565-1.159,0.887
                      c-1.557,1.307-2.551,3.279-2.823,5.288c-0.025,0.174-0.052,0.409-0.091,0.64l-0.157,1.373c-0.114,0.437-0.232,0.815-0.25,1.288
                      c-0.058,1.627-0.131,1.125-0.6,1.74c-0.533,0.703-0.52,2.582-0.315,3.413l0.166,0.639c0.171,0.602,0.385,1.181,0.701,1.708
                      c0.546,0.903,0.848,0.456,1.159,1.256l0.753,1.656c0.206,0.386,0.346,0.7,0.552,1.057c0.39,0.68,0.888,1.311,1.325,1.959
                      c0.268,0.401,0.626,0.74,0.937,1.112c0.734,0.875,1.882,1.768,2.888,2.3c0.224,0.12,0.302,0.181,0.525,0.28
                      c0.506,0.222,1.891,0.709,2.502,0.709h1.098c0.346,0,0.39-0.103,0.735-0.143c0.989-0.112,2.048-0.614,2.862-1.16l1.479-1.15
                      c0-0.003,0.075-0.064,0.08-0.066l1.001-1.041c0.149-0.167,0.254-0.233,0.39-0.418c0.078-0.105,0.11-0.163,0.183-0.256
                      c0.071-0.094,0.013-0.018,0.097-0.119l0.735-1.021c0.801-1.206,1.479-2.496,2.008-3.837l0.189-0.321
                      c0.013-0.012,0.026-0.025,0.04-0.034l0.621-0.549c0.009-0.013,0.021-0.028,0.03-0.042c0.363-0.468,0.59-1.07,0.753-1.658
                      c0.03-0.116,0.048-0.141,0.083-0.28l0.166-0.642C36.251,16.025,36.232,14.077,35.319,13.462"
                    />
                  </g>
                </svg>
                <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
              </button>
            </div>
          </li>
        </ng-container>


        <li
          class="nav-item"
          *ngIf="showBtnDrivers"
          [ngClass]="panelService.nombreComponente == 'DRIVERS' ? 'active' : ''"
          (click)="panelService.clickShowPanel('DRIVERS')"
          title="Conductores"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="666.000000pt"
                height="632.000000pt"
                viewBox="0 0 666.000000 632.000000"
                preserveAspectRatio="xMidYMid meet"
                width="25px"
                height="25px"
              >
                <g
                  transform="translate(0.000000,632.000000) scale(0.100000,-0.100000)"
                  stroke="none"
                >
                  <path
                    d="M3215 6199 c-180 -22 -343 -104 -470 -234 -112 -114 -171 -219 -211
-376 -24 -93 -22 -281 5 -381 59 -226 226 -426 431 -520 126 -57 201 -73 350
-72 123 1 143 4 230 32 156 51 292 145 387 267 282 360 209 875 -163 1141
-158 113 -369 167 -559 143z"
                  />
                  <path
                    d="M3110 4449 c-281 -18 -288 -20 -498 -102 -106 -42 -232 -95 -280
-117 l-88 -41 -215 66 c-118 37 -248 77 -289 91 -99 32 -135 31 -168 -4 -15
-16 -145 -307 -317 -707 -160 -374 -477 -1111 -703 -1638 -301 -701 -412 -968
-412 -996 0 -50 27 -74 121 -107 213 -75 965 -337 1178 -410 160 -55 259 -84
288 -84 46 0 60 2 1098 140 374 49 701 90 726 90 25 0 84 -13 130 -29 46 -16
163 -57 259 -91 96 -34 193 -68 215 -75 22 -7 137 -47 255 -88 695 -243 677
-238 718 -195 17 20 241 573 737 1823 57 143 219 550 360 905 160 401 258 659
258 682 1 28 -5 44 -23 62 -25 25 -49 33 -525 184 -1032 329 -922 301 -1129
282 l-108 -11 -202 80 c-173 68 -540 208 -673 257 -23 8 -47 22 -53 30 -10 12
-56 14 -269 13 -141 -1 -317 -6 -391 -10z m-1265 -343 l130 -43 -70 -32 c-38
-18 -93 -43 -121 -56 -99 -45 -201 -191 -247 -352 -21 -77 -22 -87 -25 -938
-2 -624 0 -875 8 -913 7 -33 23 -66 42 -88 53 -60 80 -66 299 -72 l195 -4
-148 -372 c-81 -204 -169 -424 -195 -490 -26 -65 -52 -120 -58 -122 -9 -4
-1273 431 -1284 442 -2 2 73 183 167 401 215 502 811 1888 989 2303 75 173
142 330 150 348 7 17 19 32 26 31 6 0 71 -19 142 -43z m4282 -565 c126 -40
132 -42 124 -64 -10 -25 -199 -502 -506 -1272 -114 -286 -262 -659 -330 -830
-223 -563 -407 -1020 -410 -1024 -2 -2 -23 4 -47 12 -24 8 -297 103 -608 211
-662 231 -636 221 -632 237 3 12 267 627 322 751 l20 47 448 3 448 3 49 25
c39 21 55 37 78 80 l28 54 -3 900 c-4 995 0 938 -68 1084 -28 59 -29 66 -16
97 l14 33 479 -153 c263 -84 537 -171 610 -194z m-2486 -1930 l206 -6 -164
-380 c-90 -209 -170 -385 -178 -391 -9 -6 -43 -14 -78 -18 -58 -7 -390 -50
-727 -95 -80 -11 -217 -29 -305 -41 -88 -12 -223 -30 -300 -40 -200 -27 -221
-28 -217 -10 2 8 63 166 136 350 74 184 160 401 192 482 l59 148 440 3 c242 1
505 3 585 3 80 1 238 -2 351 -5z"
                  />
                </g>
              </svg>
              <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>

        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'EVENT-USER' ? 'active' : ''
          "
          (click)="panelService.clickShowPanel('EVENT-USER')"
          title="Eventos"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          onclick="this.blur();"
        >
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-navbar notif-counter"
              [attr.notif-count]="eventService.strUnreadCount"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.401,7.091c0.146-0.606-0.367-1.325-1.017-1.372
                  c-0.763-0.059-1.129,0.383-1.628,0.857l-0.632,0.626l-2.384,3.169c-0.078,0.155-0.105,0.251-0.201,0.377
                  c-0.132,0.184-0.142,0.167-0.237,0.39c-0.335,0.78-0.677,1.322-0.902,2.185c-0.096,0.364-0.224,0.554-0.312,0.898
                  c-0.104,0.406-0.109,0.624-0.265,0.991c-0.137,0.328-0.128,0.667-0.179,1.039c-0.058,0.444,0.046,0.641,0.257,0.936
                  c0.21,0.293,0.32,0.394,0.776,0.425c1.429,0.099,1.401-1.384,1.552-2.163c0.068-0.346,0.211-0.541,0.307-0.905
                  c0.105-0.396,0.1-0.633,0.297-0.953c0.239-0.38,0.179-0.581,0.334-0.874c0.069-0.129,0.146-0.188,0.243-0.392
                  c0.068-0.147,0.128-0.307,0.206-0.419c0.269-0.403,0.237-0.492,0.439-0.767l0.548-0.711c0.435-0.67,0.381-0.497,0.81-1.023
                  c0.348-0.42,1.122-1.194,1.519-1.571C15.182,7.599,15.305,7.487,15.401,7.091 M38.292,14.428c0.092,0.352,0.252,0.606,0.316,0.94
                  c0.127,0.658,0.15,1.32,0.553,1.908c0.252,0.366,1.299,0.585,1.771-0.016c0.293-0.379,0.283-0.268,0.283-0.901
                  c-0.004-1.155-0.293-1.403-0.416-2.027c-0.087-0.487-0.224-0.567-0.33-0.925c-0.114-0.386-0.102-0.59-0.297-0.908
                  c-0.214-0.341-0.142-0.485-0.408-0.847c-0.16-0.211-0.191-0.565-0.389-0.821l-0.695-1.185c-0.087-0.154-0.155-0.188-0.251-0.33
                  l-0.659-0.934c-0.417-0.389-0.16-0.213-0.395-0.476l-0.604-0.652c-0.526-0.56-1.317-1.523-2.154-1.54
                  c-0.362-0.007-0.778,0.169-0.896,0.382c-0.083,0.148-0.335,0.736-0.307,0.897c0.026,0.14,0.348,0.699,0.439,0.812l0.96,0.874
                  l1.41,1.683c0.152,0.24,0.152,0.101,0.284,0.344c0.242,0.439,0.366,0.481,0.476,0.729c0.127,0.291,0.479,1.037,0.627,1.206
                  c0.22,0.251,0.201,0.56,0.352,0.906C38.109,13.888,38.197,14.037,38.292,14.428z M13.09,2.021c0.321-1.002-0.801-1.94-1.619-1.462
                  l-0.509,0.361c-0.101,0.078-0.124,0.124-0.233,0.201L9.993,1.689C9.878,1.758,9.819,1.765,9.727,1.861
                  C9.595,1.998,9.701,1.953,9.549,2.065C9.394,2.178,9.416,2.09,9.306,2.259C9.169,2.465,9.325,2.36,9.105,2.49
                  c-0.179,0.105-0.11,0.005-0.243,0.195C8.615,3.039,8.675,2.811,8.424,3.112l-2.137,2.5c-0.22,0.325-0.508,0.629-0.714,1.022
                  C5.315,7.127,5.348,6.938,5.225,7.158L4.333,8.779C4.104,9.331,4.347,8.892,4.036,9.347c-0.124,0.178-0.115,0.38-0.22,0.599
                  c-0.124,0.241-0.201,0.295-0.293,0.581c-0.072,0.218-0.105,0.465-0.206,0.664c-0.105,0.226-0.169,0.325-0.248,0.618
                  c-0.069,0.249-0.101,0.412-0.205,0.622c-0.193,0.397-0.229,0.929-0.317,1.37c-0.091,0.446-0.472,1.295-0.297,2.178
                  c0.114,0.59,0.943,0.984,1.642,0.712c0.856-0.332,0.696-2,1.186-3.111c0.096-0.215,0.133-0.449,0.174-0.694
                  c0.083-0.517,0.274-0.791,0.426-1.267c0.082-0.244,0.196-0.344,0.288-0.579c0.279-0.713,1.015-2.222,1.427-2.823
                  C7.49,8.074,7.5,8.123,7.587,7.976c0.078-0.123,0.072-0.179,0.142-0.297L9,6.006c0.12-0.158,0.11-0.096,0.22-0.217l0.815-0.87
                  c0.229-0.302,1.195-1.084,1.327-1.235c0.21-0.24,0.285-0.191,0.49-0.379c0.366-0.348,0.237-0.185,0.458-0.366l0.466-0.398
                  C12.944,2.385,13.004,2.293,13.09,2.021z M30.414,43.59c0.059-0.151,0.156-0.647-0.283-0.687l-7.441,0.019
                  c-0.713-0.044-1.451,0.043-2.122-0.003c-0.353-0.024-1.877-0.084-2.046,0.077c-0.379,0.359,0.109,1.022,0.179,1.152
                  c0.068,0.124,0.105,0.251,0.164,0.369l0.883,1.239c0.216,0.209,0.354,0.308,0.572,0.495c0.092,0.085,0.169,0.185,0.265,0.265
                  c0.096,0.085,0.201,0.129,0.325,0.202c0.243,0.147,1.111,0.664,1.387,0.741c0.348,0.091,0.434,0.192,0.801,0.261
                  c0.347,0.064,0.589,0.103,0.942,0.11c0.631,0.013,1.404,0.004,1.968-0.15c0.782-0.216,1.08-0.491,1.469-0.658
                  c0.173-0.075,1.097-0.733,1.229-0.894c0.221-0.256,0.352-0.256,0.513-0.551c0.083-0.156,0.701-0.798,0.852-1.271
                  C30.194,43.931,30.3,43.883,30.414,43.59z M46.639,15.733v-0.386c0-0.352-0.521-2.649-0.586-2.784
                  c-0.278-0.595-0.247-0.931-0.43-1.26c-0.352-0.625-0.201-0.787-0.486-1.21C44.968,9.832,45,9.763,44.89,9.475
                  c-0.096-0.252-0.196-0.208-0.311-0.51c-0.114-0.305-0.11-0.334-0.289-0.581c-0.113-0.156-0.163-0.371-0.255-0.563l-1.328-2.006
                  c-0.137-0.126-0.083-0.027-0.187-0.247c-0.211-0.448-0.599-0.796-0.916-1.208L40.8,3.472c-0.164-0.191-0.234-0.14-0.417-0.401
                  c-0.206-0.279-0.54-0.515-0.828-0.821c-0.083-0.084-0.142-0.103-0.253-0.181l-1.647-1.298c-0.206-0.139-0.2-0.258-0.571-0.297
                  c-0.489-0.048-0.81,0.112-1.166,0.478c-0.201,0.208-0.184,0.68-0.184,0.97c0,0.467,0.582,0.749,0.751,0.938l2.668,2.356
                  c0.146,0.188,0.255,0.282,0.396,0.469c0.133,0.172,0.279,0.263,0.385,0.435c0.164,0.279,0.609,0.739,0.736,0.956
                  c0.161,0.282,0.224,0.306,0.371,0.5c0.146,0.199,0.151,0.347,0.307,0.512c0.141,0.144,0.512,0.841,0.607,1.033
                  c0.119,0.241,0.216,0.292,0.325,0.544l0.536,1.159c0.087,0.265,0.16,0.351,0.261,0.559c0.096,0.197,0.138,0.432,0.22,0.647
                  c0.091,0.24,0.178,0.304,0.256,0.563c0.068,0.217,0.082,0.462,0.132,0.686c0.065,0.272,0.138,0.385,0.221,0.607
                  c0.146,0.393,0.164,0.974,0.279,1.411c0.092,0.375,0.255,0.954,0.499,1.189C45.376,17.152,46.639,16.608,46.639,15.733z
                  M11.896,23.41c0.123,0.986-0.042,4.239-0.161,5.412c-0.022,0.218-0.42,1.456-0.562,1.664c-0.092,0.137-0.156,0.194-0.22,0.359
                  c-0.088,0.201-0.042,0.18-0.188,0.343c-0.229,0.254-0.27,0.473-0.458,0.699c-0.147,0.175-0.1,0.127-0.224,0.357
                  c-0.12,0.229-0.105,0.035-0.454,0.657c-0.114,0.199-0.124,0.135-0.243,0.293c-0.105,0.137-0.096,0.235-0.201,0.374l-0.683,1.01
                  c-0.073,0.156-0.085,0.251-0.177,0.397c-0.083,0.129-0.147,0.17-0.225,0.307l-0.366,0.749c-0.105,0.27-0.146,0.572-0.266,0.842
                  c-0.26,0.605-0.329,1.189-0.334,1.939c-0.018,2.515,0.751,1.796,2.38,1.911c0.677,0.046,1.51,0.004,2.209,0.004
                  c2.992,0,5.989,0,8.982,0c5.975,0,12.001,0.077,17.972,0.004c2.649-0.035,2.883,0.311,2.997-1.293
                  c0.129-1.837-0.184-2.564-0.892-4.085c-0.146-0.316-0.182-0.236-0.417-0.744l-0.658-0.983c-0.147-0.165-0.092-0.203-0.207-0.372
                  c-0.082-0.131-0.146-0.149-0.242-0.292c-0.077-0.117-0.137-0.241-0.22-0.359c-0.128-0.197-0.15-0.096-0.26-0.318
                  c-0.306-0.633-0.129-0.199-0.426-0.685c-0.147-0.235-0.045-0.148-0.224-0.352l-0.594-1.05c-0.098-0.337-0.152-0.549-0.285-0.872
                  c-0.449-1.092-0.283-6.283-0.283-7.463c0-0.76-0.024-1.483-0.028-2.219c-0.004-0.646-0.183-1.318-0.247-1.982
                  c-0.028-0.341-0.055-0.743-0.12-1.034l-0.475-1.745c-0.055-0.12-0.143-0.258-0.183-0.401c-0.239-0.763-0.197-0.393-0.522-1.167
                  c-0.289-0.688-0.586-0.874-0.686-1.005c-0.069-0.088-0.12-0.224-0.197-0.333l-0.521-0.591c-0.244-0.24-1.278-1.16-1.533-1.315
                  c-0.123-0.073-0.243-0.126-0.344-0.192c-0.685-0.466-0.343-0.179-0.723-0.382c-0.356-0.191,0.006-0.1-0.773-0.388
                  c-0.201-0.078-0.205-0.122-0.37-0.211c-0.118-0.064-0.248-0.104-0.379-0.146c-1.195-0.39-1.556-1.583-1.908-2.585
                  c-0.123-0.345-0.229-0.423-0.366-0.744c-0.128-0.315-0.288-0.441-0.457-0.652c-0.073-0.091-0.476-0.505-0.591-0.567
                  c-0.882-0.485-1.825-0.544-2.887-0.431c-0.658,0.069-1.218,0.281-1.6,0.66c-0.152,0.15-0.212,0.127-0.317,0.263
                  c-0.297,0.366-0.823,1.333-0.946,1.851c-0.105,0.43-0.193,0.441-0.325,0.789c-0.253,0.657-0.656,0.893-1.022,1.199
                  c-0.337,0.283-0.447,0.188-0.809,0.35c-0.17,0.078-0.166,0.131-0.371,0.213c-0.751,0.304-0.485,0.233-1.108,0.528
                  c-0.947,0.453-1.19,0.838-1.679,1.176c-0.128,0.089-0.179,0.121-0.284,0.242l-1.299,1.506c-0.074,0.116-0.115,0.254-0.202,0.379
                  c-0.357,0.548-0.229,0.474-0.38,0.726c-0.289,0.48-0.114-0.028-0.343,0.766c-0.095,0.337-0.265,0.41-0.348,0.771
                  c-0.082,0.368-0.086,0.569-0.201,0.952C11.992,17.608,11.759,22.31,11.896,23.41z"
                />
              </svg>
              <!-- <i class="fa fa-bell notif-counter" [attr.notif-count]="eventService.strUnreadCount" aria-hidden="true"></i> -->
            </button>
          </div>
        </li>
        <li
          class="nav-item"
          [ngClass]="
            panelService.nombreComponente == 'MULTIVIEW' ? 'active' : ''
          "
          (click)="clickShowModalMultiview()"
        >
          <div class="d-flex justify-content-center">
            <button class="btn btn-navbar">
              <ng-container
                *ngIf="panelService.nombreComponente == 'MULTIVIEW'"
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 -0.5 21 21"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#ffffff"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>grid [#ffffff]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-219.000000, -200.000000)"
                        fill="#ffffff"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                            id="grid-[#ffffff]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ng-container>
              <ng-container
                *ngIf="panelService.nombreComponente != 'MULTIVIEW'"
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 -0.5 21 21"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                  stroke="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>grid [#000000]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-219.000000, -200.000000)"
                        fill="#000000"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                            id="grid-[#000000]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ng-container>
            </button>
          </div>
        </li>
      </ul>
    </div>

    <div
      class="d-flex flex-column justify-content-center navbar-last-container"
    >
      <div ngbDropdown>
        <div
          ngbDropdownToggle
          class="user-menu-container d-flex justify-content-between align-items-center text-unselectable"
          style="cursor: pointer"
        >
          <div class="profile-picture-mini">
            <!-- <img src="assets/images/login/iso.png" alt="profile-pic"> -->
            <div class="one-character-circled" *ngIf="userDataInitialized">
              {{ userName[0] }}
            </div>
            <div class="one-character-circled" *ngIf="!userDataInitialized">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
          <div class="user-menu-name text-ellipsis me-3">
            <span *ngIf="userDataInitialized">{{ userName }}</span>
          </div>
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
        <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
          <div class="dropdown-user-info">
            <div
              class="px-3 text-ellipsis text-bold"
              style="max-width: 22ch; font-size: 1.125rem"
              *ngIf="userDataInitialized"
            >
              <span>{{ userName }}</span>
            </div>
            <!-- <div class="px-3 text-ellipsis" style="max-width: 22ch;" *ngIf="userDataInitialized">
              <span>{{ userEmail }}</span>
            </div> -->
            <div class="py-2 d-flex" *ngIf="!userDataInitialized">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
            <div class="dropdown-divider"></div>
          </div>

          <div class="dropdown-navbar-items">
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'VEHICLES' ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('VEHICLES')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.906,25.923v2.564h-4.213c0.081,0.166,0.07,0.113,0.09,0.367
                  h3.574c0,0.598-0.092,0.888-0.092,1.557h-3.573c-0.331,1.418-1.844,2.929-3.574,2.929c-0.694,0-1.187-0.003-1.833-0.272
                  c-1.107-0.46-2.006-1.442-2.291-2.657h-1.924c-0.231-0.484-0.146-0.787-0.491-0.973c-0.389-0.212-0.422,0.05-0.422-0.583h2.749
                  v-0.367h-2.841v-14.75h8.613c0.295,0,0.903,0.989,1.072,1.217l3.481,4.487c1.134,1.519,0.944,0.915,0.944,2.54
                  c-0.002,0.764,0.007,1.531-0.002,2.293c-0.008,0.561-0.093,0.679,0.199,0.991C46.535,25.438,46.906,25.643,46.906,25.923
                  M39.762,15.388h-5.955v4.948h9.618c-0.076-0.298-1.544-2.232-1.758-2.548l-1.342-1.867C40.09,15.605,40.015,15.388,39.762,15.388z
                  M40.678,29.404c0-1.729-2.084-2.441-3.191-1.265c-1.067,1.137-0.233,3.098,1.357,3.098C39.778,31.237,40.678,30.349,40.678,29.404z
                  M18.873,26.196c-1.82,0-3.459-0.273-5.315-0.273v2.932h7.332c-0.311-0.463-0.647-0.856-0.996-1.296
                  C19.67,27.279,18.949,26.478,18.873,26.196z M11.542,25.923c-1.942,0-3.777,0.183-5.221,0.183c-0.569,0.848-1.824,2.189-2.197,2.749
                  h7.418V25.923z M26.843,34.259c-1.717,0-1.717,2.107,0,2.107c0.15,0,0.479-0.242,0.578-0.34
                  C28.179,35.256,27.298,34.259,26.843,34.259z M0,32.316v3.316h0.459v0.827c2.928,0,0.955,0.055,2.574,1.27
                  c0.864,0.648,1.212,0.651,2.282,0.651c1.209,0,2.623-1.26,2.745-2.749h15.486c0.426,1.826,1.327,2.749,3.297,2.749
                  c1.198,0,2.433-0.96,2.657-1.921h2.749v-0.827h0.55c0.058-0.668,0.446-2.066,0.458-2.564c-0.521-0.139-0.705-0.628-0.838-0.994
                  c-0.535-1.468-0.301-1.46-1.024-1.63l-2.131-0.53c-0.967-0.275-1.848-0.482-2.839-0.732c-0.546-0.135-0.918-0.254-1.449-0.384
                  c-0.896-0.215-0.571-0.131-1.156-0.587c-0.807-0.628-1.818-1.615-2.928-2.471c-0.293-0.229-0.701-0.519-1.099-0.647
                  c-2.354-0.732-9.247-0.552-12.375-0.456c-0.838,0.026-2.263,0.131-2.841,0.548l-3.798,4.263L0.22,31.178
                  C0.105,31.512,0.041,31.901,0,32.316z M6.046,35.357c0-0.805-0.56-1.098-1.281-1.098c-0.285,0-0.826,0.541-0.826,0.825v0.458
                  c0,0.209,0.325,0.825,1.098,0.825C5.531,36.367,6.046,35.848,6.046,35.357z M30.28,28.832l-4.83-1.241
                  c-0.781-0.192-0.582-0.088-1.109-0.54c-1.298-1.111-3.218-3.029-4.791-3.365c-2.051-0.441-5.856-0.513-8.097-0.513
                  c-1.238,0-2.245,0.1-3.393,0.09c-0.578-0.002-2.789,0.131-3.299,0.273c-0.463,0.128-0.813,0.323-1.172,0.568
                  c-0.325,0.218-0.958,1.004-1.233,1.332L1.01,26.837V9.982H30.28V28.832z"
                  />
                </svg>
              </div>
              <!-- <i class="fa fa-truck" aria-hidden="true"></i> -->
              <span>Vehículos</span>
            </button>
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente.includes('ALERTS') ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('ALERTS')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.543,11.004c1.107,0,1.646,0.002,2.65,0.929
                  c1.325,1.225,1.394,2.711,1.271,4.527l-0.749,10.177c-0.024,0.361-0.061,1.03-0.112,1.477c-0.094,0.809-0.099,2.146-0.257,2.921
                  c-0.903-0.085-1.439-0.839-3.67-0.353c-0.493,0.107-0.754,0.311-1.188,0.353c-0.173-0.857-0.119-1.872-0.239-2.829
                  c-0.057-0.444-0.068-1.034-0.107-1.375l-0.307-4.363c-0.097-4.372-1.771-9.417,1.604-11.123
                  C22.698,11.212,23.215,11.004,23.543,11.004 M47.837,39.605v0.865c-0.011,0.717-0.09,1.197-0.518,2.075
                  c-0.838,1.716-2.327,2.522-4.249,2.522H4.858c-1.51,0-2.336-0.278-3.353-1.192C0.18,42.682,0,41.356,0,39.605
                  c0-1.711,1.6-3.761,2.485-5.29L18.482,7.879c0.677-1.144,2.316-3.983,2.982-4.725c1.376-1.525,3.68-1.444,5.139-0.046
                  c0.745,0.715,6.87,11.265,7.293,11.991l7.906,13.216c0.252,0.436,0.462,0.822,0.71,1.22l2.167,3.62
                  C45.602,34.751,47.837,37.809,47.837,39.605z M20.927,37.784c0-1.266,0.472-2.132,1.19-2.816c0.193-0.181,0.208-0.192,0.458-0.331
                  c1.019-0.567,2.18-0.539,3.092,0.283l0.392,0.41c2.687,3.195-1.505,7.804-4.177,4.788c-0.27-0.307-0.445-0.574-0.638-0.984
                  C21.129,38.884,20.927,38.096,20.927,37.784z"
                  />
                </svg>
              </div>
              <!-- <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> -->
              <span>Alertas</span>
            </button>
            <!-- <button ngbDropdownItem class="btn-navbar d-flex align-items-center" [ngClass]="(panelService.nombreComponente == 'GEOFENCES' || panelService.nombreComponente == 'CIRCULAR-GEOFENCE')? 'active': ''" (click)="panelService.clickShowPanel('GEOFENCES');">
              <div class="svg-container">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                  c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                  c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                  c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                  c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                  c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                  c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                  c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                  c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                  c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                  C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                  c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                  c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                  c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                  c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                  c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                  c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                  C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                  c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                  c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                  c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                  c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                  l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                  c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                  l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                  c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                  c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                  c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                  c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                  c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                  c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"/>
                </svg>
              </div>
            <span>Geocercas</span>
            </button> -->

            <!-- <div ngbDropdown class="btn-navbar" style="cursor: pointer;">

              <div ngbDropdownToggle class="geofence-menu-container btn-navbar d-flex justify-content-between align-items-center" style="padding-left: 1rem; padding-right: 1rem; padding-top: 0.28rem; padding-bottom: 0.28rem;" >

                <div class="d-flex justify-content-start">

                  <div class="svg-container">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                        c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                        c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                        c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                        c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                        c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                        c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                        c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                        c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                        c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                        C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                        c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                        c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                        c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                        c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                        c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                        c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                        C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                        c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                        c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                        c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                        c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                        l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                        c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                        l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                        c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                        c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                        c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                        c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                        c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                        c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"/>
                      </svg>
                  </div>
                  <span>Geocercas</span>
                </div>
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </div>

              <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                <div class="dropdown-navbar-items">
                  <button ngbDropdownItem class="btn-navbar d-flex align-items-center" [ngClass]="(panelService.nombreComponente == 'GEOFENCES')? 'active': ''" (click)="panelService.clickShowPanel('GEOFENCES');">
                    <div class="svg-container">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                        c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                        c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                        c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                        c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                        c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                        c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                        c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                        c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                        c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                        C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                        c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                        c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                        c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                        c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                        c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                        c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                        C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                        c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                        c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                        c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                        c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                        l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                        c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                        l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                        c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                        c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                        c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                        c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                        c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                        c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"/>
                      </svg>
                    </div>
                  <span>Geocercas Poligonales</span>
                  </button>
                  <button ngbDropdownItem class="btn-navbar d-flex align-items-center" [ngClass]="(panelService.nombreComponente == 'CIRCULAR-GEOFENCE')? 'active': ''" (click)="panelService.clickShowPanel('CIRCULAR-GEOFENCE');">
                    <div class="svg-container">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                        c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                        c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                        c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                        c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                        c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                        c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                        c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                        c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                        c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                        C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                        c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                        c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                        c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                        c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                        c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                        c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                        C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                        c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                        c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                        c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                        c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                        l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                        c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                        l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                        c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                        c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                        c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                        c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                        c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                        c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"/>
                      </svg>
                    </div>
                  <span>Geocercas Circulares</span>
                  </button>

                  <button ngbDropdownItem class="btn-navbar d-flex align-items-center" [ngClass]="(panelService.nombreComponente == 'POLYLINE-GEOFENCE')? 'active': ''" (click)="panelService.clickShowPanel('POLYLINE-GEOFENCE');">
                    <div class="svg-container">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="20px" height="20px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                        c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                        c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                        c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                        c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                        c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                        c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                        c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                        c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                        c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                        C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                        c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                        c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                        c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                        c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                        c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                        c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                        C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                        c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                        c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                        c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                        c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                        l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                        c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                        l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                        c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                        c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                        c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                        c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                        c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                        c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"/>
                      </svg>
                    </div>
                  <span>Geocercas Polilineas </span>
                  </button>
                </div>
              </ul>

            </div> -->

            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'GEOFENCES' ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('GEOFENCES')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.268,13.919c0,1.125,0.376,1.97,0.855,2.77
                c0.441,0.724,1.208,1.358,1.953,1.743c2.302,1.188,5.332,0.227,6.613-2.271c0.666-1.297,0.643-2.917,0.051-4.242
                c-0.248-0.559-0.51-0.916-0.947-1.351c-0.592-0.585-0.47-0.553-1.29-1.001c-1.32-0.723-3.149-0.723-4.483,0.002
                c-0.605,0.329-0.897,0.531-1.365,0.999C18.889,11.333,18.268,12.796,18.268,13.919 M20.266,13.622c0-0.277,0.209-0.877,0.324-1.081
                c1.071-1.887,3.546-2.049,4.837-0.679c0.176,0.187,0.459,0.642,0.581,0.899c0.162,0.358,0.25,0.748,0.25,1.229
                c0,2.783-3.389,3.903-5.161,2.13C20.348,15.37,20.266,14.625,20.266,13.622z M40.658,36.161l2.306,6.466
                c0.144,0.394,0.725,1.917,0.749,2.209L40.658,36.161z M14.348,32.855l-1.11-1.921L5.62,30.932L0.68,44.777
                c-0.126,0.4-0.212,0.603-0.341,0.994C0.219,46.14,0.078,46.413,0,46.757h46.449c-0.006-0.321-1.158-3.395-1.377-4.02
                c-0.771-2.23-1.92-5.182-2.657-7.403l-0.868-2.459c-0.237-0.596-0.441-1.413-0.718-1.943h-7.62
                c-0.061,0.256-0.382,0.736-0.513,0.963c-0.225,0.392-0.448,0.641-0.52,0.96c0,0-8.352,10.438-9.056,10.438
                C22.415,43.292,14.348,32.855,14.348,32.855z M23.15,40.399c0.299-0.082,0.193-0.09,0.36-0.307l3.365-4.695
                c1.545-2.319,4.439-6.948,5.728-9.659c0.078-0.167,0.102-0.236,0.187-0.408l0.999-2.177c0.596-1.312,1.1-2.63,1.519-4.032
                c0.902-3.037,1.273-6.298,0.133-9.359c-1.279-3.428-2.66-4.821-5.328-6.729c-0.167-0.124-0.349-0.193-0.529-0.284
                c-1.612-0.836-2.866-1.365-4.751-1.613c-0.27-0.029-0.526-0.008-0.796-0.014c-0.335-0.004-0.368-0.058-0.666-0.076l-1.548,0.076
                c-0.921,0.07-2.985,0.639-3.777,1.033c-1.532,0.76-1.793,0.906-2.967,1.84c-0.771,0.61-1.771,1.676-2.338,2.471
                c-0.635,0.895-1.285,2.125-1.659,3.149c-0.133,0.371-0.237,0.803-0.351,1.201c-0.725,2.493-0.324,5.749,0.398,8.167l0.444,1.332
                c0.603,1.757,1.37,3.513,2.185,5.139l3.498,6.263c0.725,1.316,2.515,3.874,3.396,5.186l2.011,2.796
                C22.829,39.911,23.083,40.147,23.15,40.399z M12.277,13.919c0-1.633,0.419-3.24,0.935-4.392c0.258-0.571,0.607-1.229,0.958-1.706
                c1.166-1.601,2.362-2.764,4.173-3.667c2.465-1.233,5.336-1.458,8.02-0.699c1.688,0.476,3.837,1.734,4.9,3.014
                c1.142,1.361,2.178,2.888,2.59,4.659c0.237,1.033,0.27,1.271,0.338,2.304c0.053,0.822,0.084,0.288-0.026,1.44
                c-0.253,2.601-1.109,5.094-2.166,7.452l-1.353,2.935c-0.913,1.823-2.046,3.757-3.081,5.501l-2.997,4.544
                c-0.286,0.382-1.1,1.588-1.343,1.764l-1.595-2.177c-2.986-4.527-4.608-7.033-6.84-11.873c-0.11-0.241-0.2-0.448-0.32-0.718
                l-0.906-2.274C12.878,18.239,12.277,15.819,12.277,13.919z M12.277,13.919c0,1.9,0.602,4.32,1.288,6.108l0.906,2.274
                c0.12,0.27,0.21,0.477,0.32,0.718c2.231,4.839,3.854,7.346,6.84,11.873l1.595,2.177c0.243-0.176,1.058-1.382,1.343-1.764
                l2.997-4.544c1.035-1.744,2.168-3.679,3.081-5.501l1.353-2.935c1.057-2.358,1.913-4.851,2.166-7.452
                c0.111-1.152,0.08-0.618,0.026-1.44c-0.068-1.033-0.101-1.271-0.338-2.304c-0.412-1.771-1.449-3.298-2.59-4.659
                c-1.063-1.28-3.212-2.538-4.9-3.014c-2.684-0.759-5.555-0.534-8.02,0.699c-1.811,0.902-3.007,2.065-4.173,3.667
                c-0.351,0.477-0.7,1.134-0.958,1.706C12.696,10.679,12.277,12.286,12.277,13.919 M18.268,13.919c0-1.123,0.621-2.586,1.386-3.35
                c0.468-0.468,0.76-0.67,1.365-0.999c1.334-0.726,3.163-0.726,4.483-0.002c0.82,0.448,0.698,0.417,1.29,1.001
                c0.437,0.435,0.699,0.792,0.947,1.351c0.592,1.325,0.614,2.945-0.051,4.242c-1.281,2.498-4.311,3.458-6.613,2.271
                c-0.744-0.385-1.511-1.02-1.953-1.743C18.644,15.889,18.268,15.044,18.268,13.919z"
                  />
                </svg>
              </div>
              <span>Geocercas</span>
            </button>
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'GEOPOINTS' ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('GEOPOINTS')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M31.944,13.854c0.387,1,0.603,2.089,0.603,3.226
                  c0,4.934-4.001,8.935-8.938,8.935c-4.936,0-8.937-4.001-8.937-8.935c0-0.493,0.042-0.976,0.118-1.446
                  c2.071-1.342,5.885-0.791,8.995,0.564C26.893,17.551,30.171,15.741,31.944,13.854 M23.609,6.283c5.962,0,10.796,4.835,10.796,10.797
                  s-4.833,10.794-10.796,10.794c-5.962,0-10.795-4.832-10.795-10.794S17.647,6.283,23.609,6.283z M23.609,1.138
                  c8.804,0,15.943,7.137,15.943,15.942c0,5.983-3.297,11.195-8.172,13.923c-0.424,0.667-1.632,2.707-3.721,7.237
                  C24.993,44.021,23.722,48,23.722,48s-1.988-5.086-3.794-9.267c-1.526-3.538-3.521-6.811-4.099-7.736
                  c-4.87-2.729-8.162-7.939-8.162-13.917C7.667,8.275,14.804,1.138,23.609,1.138z"
                  />
                </svg>
              </div>
              <!-- <i class="fa fa-thumb-tack" aria-hidden="true"></i> -->
              <span>Geopuntos</span>
            </button>
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'HISTORIAL' ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('HISTORIAL')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.127,34.691c0,1.739,0.252,2.298,0.997,3.997
                  c0.813,1.851,1.887,3.722,2.913,5.438c0.672,1.119,1.082,2.197,1.956,2.13c0.797-0.063,1.292-1.241,1.903-2.255
                  c1.354-2.249,3.851-6.521,3.851-9.036c0-3.875-4.103-6.844-8.222-5.134C8.734,30.57,7.127,32.666,7.127,34.691 M13.12,37.96
                  c-1.684,0-2.724-1.039-2.724-2.724c0-1.836,2.539-3.274,4.291-1.658C16.363,35.119,15.117,37.96,13.12,37.96z M36.326,0.655h0.983
                  c0.808,0.06,1.585,0.268,2.222,0.638c1.765,1.026,3.087,2.819,3.087,5.172c0,2.129-2.742,6.922-3.919,8.876
                  c-0.276,0.454-0.537,0.855-0.795,1.293c-0.285,0.479-0.422,0.902-1.185,0.902c-0.706,0-0.879-0.506-1.209-1.06
                  c-0.885-1.487-2.316-3.785-3.028-5.325c-0.597-1.293-1.571-3.333-1.571-4.779c0-2.253,1.434-4.243,3.146-5.114
                  C34.718,0.917,35.514,0.714,36.326,0.655z M36.719,9.276c-1.935,0-3.398-2.469-1.881-4.243c0.933-1.094,2.817-1.151,3.789-0.024
                  C40.144,6.77,38.846,9.276,36.719,9.276z M2.86,19.08c0,2.271-0.399,4.957,2.036,5.59C6.008,24.959,8.479,24.8,9.852,24.8
                  l11.438-0.006c1.088-0.018,0.815-0.294,0.815,3c0,1.441-0.144,3.045,1.074,4.01c1.109,0.877,2.471,0.71,4.372,0.71l4.883,0.018
                  c0.349,0.069,0.563,0.113,0.563,0.889v9.621c0,0.563-0.15,0.593-0.363,0.907H16.568c-0.298,0.57-0.598,1.039-0.935,1.606
                  c-0.579,0.965-0.608,0.897-1.062,1.571c4.326,0,8.652,0,12.979,0h5.131c0.45-0.043,0.832-0.112,1.098-0.217
                  c0.449-0.179,0.693-0.324,1.013-0.616c1.041-0.955,1.381-1.718,1.381-3.523v-9.076c0-0.74-0.075-1.442-0.283-1.984
                  c-0.443-1.14-1.162-1.82-2.311-2.229c-1.099-0.392-6.752-0.235-8.207-0.235c0-1.302,0.105-4.042-0.179-5.179
                  c-0.328-1.322-1.763-2.444-2.907-2.444H6.04V19.08l30.771-0.089c-1.535,0-2.048-0.935-2.697-2.026
                  c-0.182-0.309-0.497-0.734-0.572-1.063c-8.44,0-16.882,0-25.322,0c-1.477,0-2.918-0.148-3.909,0.359
                  C3.389,16.733,2.86,17.683,2.86,19.08z"
                  />
                </svg>
              </div>
              <!-- <i class="fa fa-history" aria-hidden="true"></i> -->
              <span>Historial</span>
            </button>
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              (click)="windowAccess.open('/reports')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M38.283,46.174c0.165,0.833,0.057,1.26,1.521,1.258h5.489
                      c0.748,0,1.205,0.121,1.609-0.139c0.219-0.141,0.354-0.239,0.452-0.526c0.241-0.727,0.115-4.344,0.115-5.402V19.474
                      c0-1.773,0.067-3.673,0.002-5.429c-0.042-1.2-0.758-1.263-1.565-1.261H40.42c-0.604,0-1.389-0.118-1.796,0.269
                      c-0.114,0.109-0.237,0.234-0.275,0.398c-0.079,0.327-0.017,27.241-0.017,30.072L38.283,46.174z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.998,46.36c0,0.776,0.586,1.08,1.283,1.072
                      c2.157-0.018,4.933,0.091,6.91,0.005l0.397-0.12c0.266-0.161,0.485-0.361,0.581-0.722l0.024-23.483
                      c0.005-0.537,0.053-0.576-0.193-1c-0.111-0.194-0.396-0.477-0.629-0.477h-7.581c-0.441,0-0.791,0.575-0.791,1.043V46.36z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M32.05,15.169c-0.226,0.249-0.433,0.696-0.785,0.59
                      c-0.11-0.032-0.107-0.08-0.285-0.121c-0.907-0.208-1.837,0.07-2.555,0.472c-0.63,0.353-0.309,0.612-1.122,0.259
                      c-0.089-0.038-0.156-0.052-0.264-0.104l-3.613-1.693c-0.11-0.055-0.066-0.058-0.234-0.131l-2.384-1.122
                      c-0.005-0.869,0.037-0.979-0.234-1.681c-0.099-0.263-0.162-0.342-0.334-0.59c-1.101-1.568-2.932-1.732-4.51-0.79
                      c-0.429,0.251-0.645,0.521-0.904,0.94l-0.313,0.623c-0.427,1.214,0.09,1.827-0.013,2.412c-0.028,0.163-0.377,0.368-0.518,0.502
                      l-0.433,0.371c-0.847,0.672-1.628,1.463-2.47,2.152c-0.852,0.694-3.615,3.271-4.334,3.738c-0.254,0.164-0.864-0.268-1.181-0.326
                      c-3.303-0.581-5.266,3.669-2.689,5.709c1.455,1.151,3.505,0.928,4.556-0.436c0.218-0.278,0.418-0.527,0.546-0.88
                      c0.818-2.274-0.857-2.01,0.789-3.208c0.447-0.327,0.756-0.651,1.237-1.048l1.928-1.706c0.187-0.158,0.343-0.261,0.515-0.416
                      c0.155-0.14,0.32-0.291,0.483-0.438c0.167-0.147,0.308-0.291,0.485-0.444c0.155-0.134,0.331-0.267,0.51-0.414l1.852-1.596
                      c0.251-0.159,0.773,0.225,1.091,0.292c1.023,0.209,2.368-0.106,3.009-0.929c0.433,0.006,2.927,1.282,3.579,1.614
                      c0.167,0.084,0.311,0.106,0.486,0.192c0.153,0.077,0.279,0.125,0.418,0.198c0.256,0.137,0.601,0.289,0.881,0.423
                      c0.332,0.161,1.483,0.584,1.695,0.842c0.163,0.317-0.086,1.276,0.602,2.274c0.609,0.885,1.745,1.442,2.832,1.397
                      c1.103-0.044,2.088-0.724,2.635-1.594c0.215-0.347,0.293-0.649,0.392-1.037c0.124-0.496,0.1-0.81-0.007-1.269
                      c-0.155-0.644-0.354-0.886-0.32-1.127c0.052-0.323,3.255-3.768,3.576-4.274c0.517-0.815,3.312-3.863,4.244-5.005
                      c1.06-1.296,0.605-0.613,1.874-0.617c0.747-0.006,1.142-0.117,1.706-0.424c0.196-0.11,0.223-0.094,0.421-0.256
                      c0.17-0.139,0.534-0.52,0.678-0.747c0.604-0.967,0.76-2.32,0.163-3.364c-0.945-1.657-3.309-2.288-4.846-1.01
                      c-0.843,0.699-1.457,1.82-1.268,2.983c0.218,1.342,0.849,0.938-0.039,1.94l-5.232,6.173c-0.237,0.294-0.411,0.469-0.657,0.76
                      L32.05,15.169z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.607,46.36c0.062,0.459,0.261,0.871,0.782,1.034
                      c0.354,0.115,4.744,0.038,5.338,0.038c0.581,0,2.129,0.122,2.582-0.159c0.138-0.084,0.338-0.298,0.403-0.454
                      c0.194-0.476,0.088-9.572,0.088-10.513c0-1.505,0.077-5.943,0-7.086c-0.035-0.504-0.313-0.863-0.765-1.017
                      c-0.269-0.088-6.145-0.027-7.118-0.033c-1.763-0.016-1.311,1.771-1.311,4.006V46.36z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.34,46.235c0.091,0.474,0.069,0.709,0.407,0.958
                      c0.428,0.318,0.498,0.239,1.078,0.239h5.305c0.958,0,1.751,0.182,2.216-0.459c0.16-0.223,0.109-0.155,0.154-0.517l0.028-1.948
                      c0-2.254,0.102-5.96,0-7.891c-0.054-0.972-0.468-1.166-1.472-1.16l-5.304,0.001c-0.981,0-1.74-0.163-2.185,0.448
                      c-0.361,0.493-0.236,1.707-0.236,2.437L0.34,46.235z"
                    />
                  </g>
                </svg>
              </div>
              <!-- <i class="fa fa-line-chart" aria-hidden="true"></i> -->
              <span>Reportes</span>
            </button>
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'DASHBOARD' ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('DASHBOARD')"
              *appHasPermission="'m-dashboard.show'"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.127,1.754h12.377c2.268,0,4.127,1.856,4.127,4.127v12.376
                  c0,2.273-1.859,4.129-4.127,4.129H4.127C1.859,22.387,0,20.531,0,18.258V5.881C0,3.611,1.859,1.754,4.127,1.754 M4.127,26.893
                  h12.377c2.268,0,4.127,1.856,4.127,4.127v12.379c0,2.268-1.859,4.125-4.127,4.125H4.127C1.859,47.524,0,45.667,0,43.399V31.02
                  C0,28.749,1.859,26.893,4.127,26.893z M29.264,1.754h14.633c2.268,0,4.125,1.856,4.125,4.127v37.513
                  c0,2.271-1.857,4.128-4.125,4.128H29.264c-2.271,0-4.127-1.857-4.127-4.128V5.881C25.136,3.611,26.993,1.754,29.264,1.754z"
                  />
                </svg>
              </div>
              <!-- <i class="fa fa-th-large" aria-hidden="true"></i> -->
              <span>Dashboard</span>
            </button>
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              (click)="windowAccess.open('/audit')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.372,33.348c-0.697-0.661-2.165-2.929-2.653-2.929
                  c-1.603,0,0.248,1.878,0.512,2.203c1.981,2.419,1.771,2.843,3.227,1.41c0.348-0.343,0.806-0.703,1.142-1.042
                  c0.436-0.451,0.659-0.673,1.125-1.053c0.251-0.208,0.923-0.844,1.049-1.173c-0.025-0.885-0.639-0.862-1.133-0.419
                  C22.086,30.84,19.782,33.225,19.372,33.348 M13.841,35.781l-2.426,2.547c-0.811,0.372-0.55-0.892-2.686,1.11
                  c-0.261,0.244-0.411,0.315-0.709,0.615l-2.803,2.503c-0.319,0.252-0.407,0.353-0.692,0.634l-1.427,1.223
                  c-0.294,0.22-0.453,0.338-0.65,0.676C1.65,46.451,2.9,48.161,4.499,47.986c0.638-0.068,1.179-0.698,1.591-1.053l4.201-3.754
                  c1.561-1.423,2.224-1.571,2.291-3.044c0.029-0.642,0.423-0.591,1.289-1.33c0.297-0.256,1.296-1.051,1.62-1.165
                  c0.759,0.371,0.817,0.685,2.211,1.144c4.537,1.492,9.25-1.817,9.4-6.697c0.168-5.55-6.534-9.591-11.52-5.776
                  c-2.127,1.626-3.113,4.148-2.673,7.049C13.072,34.428,13.53,35.011,13.841,35.781z M19.489,26.394
                  c1.879-0.354,4.373,1.024,5.392,2.938c1.493,2.812,0.444,6.047-2.283,7.588c-3.47,1.962-8.141-0.557-8.258-4.448
                  c-0.08-2.499,0.957-4.404,2.987-5.453C18.222,26.557,18.419,26.597,19.489,26.394z M3.35,46.01
                  c-0.063-0.503,0.403-1.022,0.877-1.063c0.533-0.044,1.011,0.411,1.053,0.883C5.393,47.084,3.492,47.189,3.35,46.01z M16.287,1.786
                  c-0.004,1.377-0.281,1.035,2.896,1.035l17.52-0.002c2.539-0.002,2.005,1.186,2.005,5.027c0,9.312,0,18.688,0,27.935l0.025,3.265
                  c1.721,0.13,2.526,0.258,2.518-1.842V2.261c0.004-1.336-0.143-1.987-1.515-1.996L18.331,0.269
                  C17.474,0.268,16.287,0.261,16.287,1.786z M11.566,6.048l0.009,21.979c0.461-0.317,0.852-1.943,3.072-3.504
                  c2.597-1.824,6.055-2.268,8.997-0.873c2.329,1.1,1.087,1.049,3.21,1.049l6.349-0.002c0.76,0.017,1.208,0.146,1.208,0.955
                  c0,0.617-0.528,0.7-1.121,0.7l-6.013,0.007c-0.008,0.162,0.801,1.441,1.099,2.132c0.113,0.262,0.201,0.6,0.327,0.803
                  c0.34,0.139,2.614,0.09,3.168,0.053c0.948-0.065,2.383-0.214,2.526,0.686c0.29,1.814-3.605,0.629-5.342,1.037
                  c-0.025,0.503,0.008,0.999-0.013,1.498c-0.017,0.497-0.139,0.954-0.168,1.452c1.129-0.008,2.246,0.008,3.375,0.004
                  c0.847-0.006,2.501-0.233,2.131,1.051c-0.239,0.841-1.762,0.581-2.698,0.581c-1.041,0-2.396-0.076-3.399,0.023
                  c-1.704,4.045-6.333,6.371-10.806,5.111c-0.604-0.17-1.578-0.675-2.019-0.745c-0.549,0.547-0.755,0.361-0.927,1.036
                  c-0.269,1.043-0.525,1.427-1.28,2.148c1.183,0.137,19.186,0.044,21.959,0.042c0.852,0,1.309-0.564,1.33-1.423L36.534,5.857
                  c-0.004-0.839-0.436-1.425-1.255-1.425H12.934C11.998,4.432,11.566,5.129,11.566,6.048z M30.341,8.58
                  c0.436-0.18,2.693-0.367,3.151-0.189c0.156,0.41-0.986,2.652-1.431,2.881c-0.31-0.161-0.395-0.371-0.579-0.654
                  c-0.39,0.061-3.584,1.876-4.381,2.302c-3.197,1.725-2.531,1.675-4.339,0.436c-0.538-0.367-2.208-1.638-2.623-1.781
                  c-0.881,0.59-1.75,1.291-2.652,1.962c-1.044,0.777-2.337,2.091-2.815,1.198c-0.185-0.774,0.302-0.93,0.818-1.301l2.492-1.862
                  c1.1-0.807,1.893-1.758,2.644-1.283c0.445,0.279,0.81,0.573,1.225,0.857c0.865,0.59,1.67,1.188,2.539,1.729
                  c0.461-0.147,2.589-1.349,3.164-1.672c0.482-0.271,2.891-1.506,3.118-1.748C30.488,8.988,30.361,9.149,30.341,8.58z M34.411,20.821
                  c0,1.299-1.708,0.904-2.916,0.904h-7.767c-1.591,0-3.043,0.292-3.118-0.726c-0.097-1.28,1.833-0.934,2.929-0.934h7.767
                  C32.309,20.066,34.411,19.737,34.411,20.821z"
                  />
                </svg>
              </div>
              <!-- <i class="fa fa-book" aria-hidden="true"></i> -->
              <span>Auditoría</span>
            </button>
            <ng-container *ngIf="showBtnSubcuentas">
              <button
                *appHasPermission="'m-subaccount.show'"
                ngbDropdownItem
                class="btn-navbar d-flex align-items-center"
                [ngClass]="
                  panelService.nombreComponente == 'SUBCUENTAS' ? 'active' : ''
                "
                (click)="panelService.clickShowPanel('SUBCUENTAS')"

                >
                <div class="svg-container">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20px"
                    height="20px"
                    viewBox="0 0 48 48"
                    enable-background="new 0 0 48 48"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.216,41.228c0,0.271,0.096,0.495,0.166,0.785
                      c0.355,1.532,1.751,2.407,3.107,3.028l0.915,0.405c0.118,0.046,0.214,0.077,0.323,0.112c0.447,0.148,0.919,0.299,1.344,0.411
                      l0.699,0.177c1.203,0.287,2.49,0.59,3.694,0.764l1.584,0.173c0.271,0.029,0.402,0.124,0.735,0.138
                      c0.38,0.016,0.512,0.006,0.818,0.061c1.724,0.312,6.476,0.376,8.388,0.376c1.703,0,5.313-0.04,6.848-0.241
                      c0.486-0.063,1.081-0.055,1.606-0.149c0.258-0.043,0.485-0.027,0.823-0.051c0.292-0.021,0.446-0.121,0.739-0.142l3.054-0.45
                      c0.259-0.044,0.525-0.09,0.802-0.151l0.708-0.168c1.037-0.256,2.04-0.488,3.063-0.882l0.909-0.405
                      c0.442-0.199,0.718-0.359,1.138-0.612l0.989-0.769c0.166-0.151,0.267-0.26,0.411-0.466c0.351-0.507,0.683-1.309,0.683-1.944
                      c0-1.085-0.014-1.049-0.289-2.118c-0.031-0.123-0.04-0.175-0.08-0.291c-0.074-0.236-0.018-0.057-0.1-0.265
                      c-0.345-0.88-0.616-1.751-1.076-2.578c-0.096-0.178-0.14-0.289-0.245-0.483l-0.552-0.907c-0.034-0.053-0.048-0.073-0.087-0.134
                      c-0.008-0.014-0.021-0.031-0.031-0.046l-0.817-1.081c-0.417-0.523-0.976-1-1.527-1.394c-0.306-0.217-0.574-0.354-0.933-0.531
                      c-0.324-0.164-0.651-0.332-1.02-0.44l-1.793-0.396c-0.359-0.034-0.329-0.017-0.639-0.093c-0.381-0.091-3.023-0.162-3.409-0.243
                      c-0.892-0.196-0.644,0.054-1.247,0.805l-0.236,0.276c-0.267,0.285-0.696,0.741-1.002,0.975l-2.301,1.936
                      c-1.111,0.876-3.273,2.691-4.39,3.283c-0.428-0.228-0.809-0.556-1.198-0.846l-1.724-1.274c-1.151-0.821-2.196-1.808-3.294-2.693
                      l-1.493-1.432c-0.18-0.214-0.302-0.346-0.464-0.56c-0.472-0.64-0.254-0.617-1.059-0.458l-2.039,0.154
                      c-0.679,0.088-1.269,0.074-2.004,0.186c-1.791,0.271-2.709,0.684-4.144,1.704c-1.694,1.206-3.046,3.897-3.71,5.786
                      C2.409,39.334,2.216,39.851,2.216,41.228"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M35.319,13.462c0.139-1.098,0.008-1.952-0.07-3.002
                      c-0.027-0.37-0.004-0.416-0.079-0.724c-0.197-0.815-0.324-1.648-0.635-2.435l-0.218-0.508c-0.079-0.192-0.048-0.129-0.132-0.311
                      c-0.061-0.128-0.074-0.149-0.122-0.243c-0.306-0.608-0.674-1.208-1.116-1.73l-0.136-0.161c-0.135-0.172-0.166-0.243-0.324-0.405
                      c-0.712-0.737-1.535-1.38-2.441-1.87l-2.472-0.957c-2.092-0.604-5.361-0.508-7.632,0.258c-0.039,0.013-0.118,0.046-0.157,0.062
                      l-0.106,0.039c-0.131,0.048-0.131,0.043-0.271,0.096l-2.044,1.026c-0.437,0.259-0.778,0.565-1.159,0.887
                      c-1.557,1.307-2.551,3.279-2.823,5.288c-0.025,0.174-0.052,0.409-0.091,0.64l-0.157,1.373c-0.114,0.437-0.232,0.815-0.25,1.288
                      c-0.058,1.627-0.131,1.125-0.6,1.74c-0.533,0.703-0.52,2.582-0.315,3.413l0.166,0.639c0.171,0.602,0.385,1.181,0.701,1.708
                      c0.546,0.903,0.848,0.456,1.159,1.256l0.753,1.656c0.206,0.386,0.346,0.7,0.552,1.057c0.39,0.68,0.888,1.311,1.325,1.959
                      c0.268,0.401,0.626,0.74,0.937,1.112c0.734,0.875,1.882,1.768,2.888,2.3c0.224,0.12,0.302,0.181,0.525,0.28
                      c0.506,0.222,1.891,0.709,2.502,0.709h1.098c0.346,0,0.39-0.103,0.735-0.143c0.989-0.112,2.048-0.614,2.862-1.16l1.479-1.15
                      c0-0.003,0.075-0.064,0.08-0.066l1.001-1.041c0.149-0.167,0.254-0.233,0.39-0.418c0.078-0.105,0.11-0.163,0.183-0.256
                      c0.071-0.094,0.013-0.018,0.097-0.119l0.735-1.021c0.801-1.206,1.479-2.496,2.008-3.837l0.189-0.321
                      c0.013-0.012,0.026-0.025,0.04-0.034l0.621-0.549c0.009-0.013,0.021-0.028,0.03-0.042c0.363-0.468,0.59-1.07,0.753-1.658
                      c0.03-0.116,0.048-0.141,0.083-0.28l0.166-0.642C36.251,16.025,36.232,14.077,35.319,13.462"
                      />
                    </g>
                  </svg>
                </div>
                <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
                <span>Subcuentas</span>
              </button>
            </ng-container>


            <button
              *ngIf="showBtnDrivers"
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'DRIVERS' ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('DRIVERS')"
            >
              <div class="svg-container">
                <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="666.000000pt"
                height="632.000000pt"
                viewBox="0 0 666.000000 632.000000"
                preserveAspectRatio="xMidYMid meet"
                width="25px"
                height="25px"
              >
                <g
                  transform="translate(0.000000,632.000000) scale(0.100000,-0.100000)"
                  stroke="none"
                >
                  <path
                    d="M3215 6199 c-180 -22 -343 -104 -470 -234 -112 -114 -171 -219 -211
-376 -24 -93 -22 -281 5 -381 59 -226 226 -426 431 -520 126 -57 201 -73 350
-72 123 1 143 4 230 32 156 51 292 145 387 267 282 360 209 875 -163 1141
-158 113 -369 167 -559 143z"
                  />
                  <path
                    d="M3110 4449 c-281 -18 -288 -20 -498 -102 -106 -42 -232 -95 -280
-117 l-88 -41 -215 66 c-118 37 -248 77 -289 91 -99 32 -135 31 -168 -4 -15
-16 -145 -307 -317 -707 -160 -374 -477 -1111 -703 -1638 -301 -701 -412 -968
-412 -996 0 -50 27 -74 121 -107 213 -75 965 -337 1178 -410 160 -55 259 -84
288 -84 46 0 60 2 1098 140 374 49 701 90 726 90 25 0 84 -13 130 -29 46 -16
163 -57 259 -91 96 -34 193 -68 215 -75 22 -7 137 -47 255 -88 695 -243 677
-238 718 -195 17 20 241 573 737 1823 57 143 219 550 360 905 160 401 258 659
258 682 1 28 -5 44 -23 62 -25 25 -49 33 -525 184 -1032 329 -922 301 -1129
282 l-108 -11 -202 80 c-173 68 -540 208 -673 257 -23 8 -47 22 -53 30 -10 12
-56 14 -269 13 -141 -1 -317 -6 -391 -10z m-1265 -343 l130 -43 -70 -32 c-38
-18 -93 -43 -121 -56 -99 -45 -201 -191 -247 -352 -21 -77 -22 -87 -25 -938
-2 -624 0 -875 8 -913 7 -33 23 -66 42 -88 53 -60 80 -66 299 -72 l195 -4
-148 -372 c-81 -204 -169 -424 -195 -490 -26 -65 -52 -120 -58 -122 -9 -4
-1273 431 -1284 442 -2 2 73 183 167 401 215 502 811 1888 989 2303 75 173
142 330 150 348 7 17 19 32 26 31 6 0 71 -19 142 -43z m4282 -565 c126 -40
132 -42 124 -64 -10 -25 -199 -502 -506 -1272 -114 -286 -262 -659 -330 -830
-223 -563 -407 -1020 -410 -1024 -2 -2 -23 4 -47 12 -24 8 -297 103 -608 211
-662 231 -636 221 -632 237 3 12 267 627 322 751 l20 47 448 3 448 3 49 25
c39 21 55 37 78 80 l28 54 -3 900 c-4 995 0 938 -68 1084 -28 59 -29 66 -16
97 l14 33 479 -153 c263 -84 537 -171 610 -194z m-2486 -1930 l206 -6 -164
-380 c-90 -209 -170 -385 -178 -391 -9 -6 -43 -14 -78 -18 -58 -7 -390 -50
-727 -95 -80 -11 -217 -29 -305 -41 -88 -12 -223 -30 -300 -40 -200 -27 -221
-28 -217 -10 2 8 63 166 136 350 74 184 160 401 192 482 l59 148 440 3 c242 1
505 3 585 3 80 1 238 -2 351 -5z"
                  />
                </g>
              </svg>
              </div>
              <!-- <i class="fa fa-users" aria-hidden="true"></i> -->
              <span>Conductores</span>
            </button>

            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'EVENT-USER' ? 'active' : ''
              "
              (click)="panelService.clickShowPanel('EVENT-USER')"
            >
              <div class="svg-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  enable-background="new 0 0 48 48"
                  xml:space="preserve"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.401,7.091c0.146-0.606-0.367-1.325-1.017-1.372
                  c-0.763-0.059-1.129,0.383-1.628,0.857l-0.632,0.626l-2.384,3.169c-0.078,0.155-0.105,0.251-0.201,0.377
                  c-0.132,0.184-0.142,0.167-0.237,0.39c-0.335,0.78-0.677,1.322-0.902,2.185c-0.096,0.364-0.224,0.554-0.312,0.898
                  c-0.104,0.406-0.109,0.624-0.265,0.991c-0.137,0.328-0.128,0.667-0.179,1.039c-0.058,0.444,0.046,0.641,0.257,0.936
                  c0.21,0.293,0.32,0.394,0.776,0.425c1.429,0.099,1.401-1.384,1.552-2.163c0.068-0.346,0.211-0.541,0.307-0.905
                  c0.105-0.396,0.1-0.633,0.297-0.953c0.239-0.38,0.179-0.581,0.334-0.874c0.069-0.129,0.146-0.188,0.243-0.392
                  c0.068-0.147,0.128-0.307,0.206-0.419c0.269-0.403,0.237-0.492,0.439-0.767l0.548-0.711c0.435-0.67,0.381-0.497,0.81-1.023
                  c0.348-0.42,1.122-1.194,1.519-1.571C15.182,7.599,15.305,7.487,15.401,7.091 M38.292,14.428c0.092,0.352,0.252,0.606,0.316,0.94
                  c0.127,0.658,0.15,1.32,0.553,1.908c0.252,0.366,1.299,0.585,1.771-0.016c0.293-0.379,0.283-0.268,0.283-0.901
                  c-0.004-1.155-0.293-1.403-0.416-2.027c-0.087-0.487-0.224-0.567-0.33-0.925c-0.114-0.386-0.102-0.59-0.297-0.908
                  c-0.214-0.341-0.142-0.485-0.408-0.847c-0.16-0.211-0.191-0.565-0.389-0.821l-0.695-1.185c-0.087-0.154-0.155-0.188-0.251-0.33
                  l-0.659-0.934c-0.417-0.389-0.16-0.213-0.395-0.476l-0.604-0.652c-0.526-0.56-1.317-1.523-2.154-1.54
                  c-0.362-0.007-0.778,0.169-0.896,0.382c-0.083,0.148-0.335,0.736-0.307,0.897c0.026,0.14,0.348,0.699,0.439,0.812l0.96,0.874
                  l1.41,1.683c0.152,0.24,0.152,0.101,0.284,0.344c0.242,0.439,0.366,0.481,0.476,0.729c0.127,0.291,0.479,1.037,0.627,1.206
                  c0.22,0.251,0.201,0.56,0.352,0.906C38.109,13.888,38.197,14.037,38.292,14.428z M13.09,2.021c0.321-1.002-0.801-1.94-1.619-1.462
                  l-0.509,0.361c-0.101,0.078-0.124,0.124-0.233,0.201L9.993,1.689C9.878,1.758,9.819,1.765,9.727,1.861
                  C9.595,1.998,9.701,1.953,9.549,2.065C9.394,2.178,9.416,2.09,9.306,2.259C9.169,2.465,9.325,2.36,9.105,2.49
                  c-0.179,0.105-0.11,0.005-0.243,0.195C8.615,3.039,8.675,2.811,8.424,3.112l-2.137,2.5c-0.22,0.325-0.508,0.629-0.714,1.022
                  C5.315,7.127,5.348,6.938,5.225,7.158L4.333,8.779C4.104,9.331,4.347,8.892,4.036,9.347c-0.124,0.178-0.115,0.38-0.22,0.599
                  c-0.124,0.241-0.201,0.295-0.293,0.581c-0.072,0.218-0.105,0.465-0.206,0.664c-0.105,0.226-0.169,0.325-0.248,0.618
                  c-0.069,0.249-0.101,0.412-0.205,0.622c-0.193,0.397-0.229,0.929-0.317,1.37c-0.091,0.446-0.472,1.295-0.297,2.178
                  c0.114,0.59,0.943,0.984,1.642,0.712c0.856-0.332,0.696-2,1.186-3.111c0.096-0.215,0.133-0.449,0.174-0.694
                  c0.083-0.517,0.274-0.791,0.426-1.267c0.082-0.244,0.196-0.344,0.288-0.579c0.279-0.713,1.015-2.222,1.427-2.823
                  C7.49,8.074,7.5,8.123,7.587,7.976c0.078-0.123,0.072-0.179,0.142-0.297L9,6.006c0.12-0.158,0.11-0.096,0.22-0.217l0.815-0.87
                  c0.229-0.302,1.195-1.084,1.327-1.235c0.21-0.24,0.285-0.191,0.49-0.379c0.366-0.348,0.237-0.185,0.458-0.366l0.466-0.398
                  C12.944,2.385,13.004,2.293,13.09,2.021z M30.414,43.59c0.059-0.151,0.156-0.647-0.283-0.687l-7.441,0.019
                  c-0.713-0.044-1.451,0.043-2.122-0.003c-0.353-0.024-1.877-0.084-2.046,0.077c-0.379,0.359,0.109,1.022,0.179,1.152
                  c0.068,0.124,0.105,0.251,0.164,0.369l0.883,1.239c0.216,0.209,0.354,0.308,0.572,0.495c0.092,0.085,0.169,0.185,0.265,0.265
                  c0.096,0.085,0.201,0.129,0.325,0.202c0.243,0.147,1.111,0.664,1.387,0.741c0.348,0.091,0.434,0.192,0.801,0.261
                  c0.347,0.064,0.589,0.103,0.942,0.11c0.631,0.013,1.404,0.004,1.968-0.15c0.782-0.216,1.08-0.491,1.469-0.658
                  c0.173-0.075,1.097-0.733,1.229-0.894c0.221-0.256,0.352-0.256,0.513-0.551c0.083-0.156,0.701-0.798,0.852-1.271
                  C30.194,43.931,30.3,43.883,30.414,43.59z M46.639,15.733v-0.386c0-0.352-0.521-2.649-0.586-2.784
                  c-0.278-0.595-0.247-0.931-0.43-1.26c-0.352-0.625-0.201-0.787-0.486-1.21C44.968,9.832,45,9.763,44.89,9.475
                  c-0.096-0.252-0.196-0.208-0.311-0.51c-0.114-0.305-0.11-0.334-0.289-0.581c-0.113-0.156-0.163-0.371-0.255-0.563l-1.328-2.006
                  c-0.137-0.126-0.083-0.027-0.187-0.247c-0.211-0.448-0.599-0.796-0.916-1.208L40.8,3.472c-0.164-0.191-0.234-0.14-0.417-0.401
                  c-0.206-0.279-0.54-0.515-0.828-0.821c-0.083-0.084-0.142-0.103-0.253-0.181l-1.647-1.298c-0.206-0.139-0.2-0.258-0.571-0.297
                  c-0.489-0.048-0.81,0.112-1.166,0.478c-0.201,0.208-0.184,0.68-0.184,0.97c0,0.467,0.582,0.749,0.751,0.938l2.668,2.356
                  c0.146,0.188,0.255,0.282,0.396,0.469c0.133,0.172,0.279,0.263,0.385,0.435c0.164,0.279,0.609,0.739,0.736,0.956
                  c0.161,0.282,0.224,0.306,0.371,0.5c0.146,0.199,0.151,0.347,0.307,0.512c0.141,0.144,0.512,0.841,0.607,1.033
                  c0.119,0.241,0.216,0.292,0.325,0.544l0.536,1.159c0.087,0.265,0.16,0.351,0.261,0.559c0.096,0.197,0.138,0.432,0.22,0.647
                  c0.091,0.24,0.178,0.304,0.256,0.563c0.068,0.217,0.082,0.462,0.132,0.686c0.065,0.272,0.138,0.385,0.221,0.607
                  c0.146,0.393,0.164,0.974,0.279,1.411c0.092,0.375,0.255,0.954,0.499,1.189C45.376,17.152,46.639,16.608,46.639,15.733z
                  M11.896,23.41c0.123,0.986-0.042,4.239-0.161,5.412c-0.022,0.218-0.42,1.456-0.562,1.664c-0.092,0.137-0.156,0.194-0.22,0.359
                  c-0.088,0.201-0.042,0.18-0.188,0.343c-0.229,0.254-0.27,0.473-0.458,0.699c-0.147,0.175-0.1,0.127-0.224,0.357
                  c-0.12,0.229-0.105,0.035-0.454,0.657c-0.114,0.199-0.124,0.135-0.243,0.293c-0.105,0.137-0.096,0.235-0.201,0.374l-0.683,1.01
                  c-0.073,0.156-0.085,0.251-0.177,0.397c-0.083,0.129-0.147,0.17-0.225,0.307l-0.366,0.749c-0.105,0.27-0.146,0.572-0.266,0.842
                  c-0.26,0.605-0.329,1.189-0.334,1.939c-0.018,2.515,0.751,1.796,2.38,1.911c0.677,0.046,1.51,0.004,2.209,0.004
                  c2.992,0,5.989,0,8.982,0c5.975,0,12.001,0.077,17.972,0.004c2.649-0.035,2.883,0.311,2.997-1.293
                  c0.129-1.837-0.184-2.564-0.892-4.085c-0.146-0.316-0.182-0.236-0.417-0.744l-0.658-0.983c-0.147-0.165-0.092-0.203-0.207-0.372
                  c-0.082-0.131-0.146-0.149-0.242-0.292c-0.077-0.117-0.137-0.241-0.22-0.359c-0.128-0.197-0.15-0.096-0.26-0.318
                  c-0.306-0.633-0.129-0.199-0.426-0.685c-0.147-0.235-0.045-0.148-0.224-0.352l-0.594-1.05c-0.098-0.337-0.152-0.549-0.285-0.872
                  c-0.449-1.092-0.283-6.283-0.283-7.463c0-0.76-0.024-1.483-0.028-2.219c-0.004-0.646-0.183-1.318-0.247-1.982
                  c-0.028-0.341-0.055-0.743-0.12-1.034l-0.475-1.745c-0.055-0.12-0.143-0.258-0.183-0.401c-0.239-0.763-0.197-0.393-0.522-1.167
                  c-0.289-0.688-0.586-0.874-0.686-1.005c-0.069-0.088-0.12-0.224-0.197-0.333l-0.521-0.591c-0.244-0.24-1.278-1.16-1.533-1.315
                  c-0.123-0.073-0.243-0.126-0.344-0.192c-0.685-0.466-0.343-0.179-0.723-0.382c-0.356-0.191,0.006-0.1-0.773-0.388
                  c-0.201-0.078-0.205-0.122-0.37-0.211c-0.118-0.064-0.248-0.104-0.379-0.146c-1.195-0.39-1.556-1.583-1.908-2.585
                  c-0.123-0.345-0.229-0.423-0.366-0.744c-0.128-0.315-0.288-0.441-0.457-0.652c-0.073-0.091-0.476-0.505-0.591-0.567
                  c-0.882-0.485-1.825-0.544-2.887-0.431c-0.658,0.069-1.218,0.281-1.6,0.66c-0.152,0.15-0.212,0.127-0.317,0.263
                  c-0.297,0.366-0.823,1.333-0.946,1.851c-0.105,0.43-0.193,0.441-0.325,0.789c-0.253,0.657-0.656,0.893-1.022,1.199
                  c-0.337,0.283-0.447,0.188-0.809,0.35c-0.17,0.078-0.166,0.131-0.371,0.213c-0.751,0.304-0.485,0.233-1.108,0.528
                  c-0.947,0.453-1.19,0.838-1.679,1.176c-0.128,0.089-0.179,0.121-0.284,0.242l-1.299,1.506c-0.074,0.116-0.115,0.254-0.202,0.379
                  c-0.357,0.548-0.229,0.474-0.38,0.726c-0.289,0.48-0.114-0.028-0.343,0.766c-0.095,0.337-0.265,0.41-0.348,0.771
                  c-0.082,0.368-0.086,0.569-0.201,0.952C11.992,17.608,11.759,22.31,11.896,23.41z"
                  />
                </svg>
              </div>
              <!-- <i class="fa fa-bolt" aria-hidden="true"></i> -->
              <span
                >{{
                  eventService.strUnreadCount == "0"
                    ? ""
                    : "(" + eventService.strUnreadCount + ")"
                }}
                Eventos</span
              >
            </button>
            <button
              ngbDropdownItem
              class="btn-navbar d-flex align-items-center"
              [ngClass]="
                panelService.nombreComponente == 'MULTIVIEW' ? 'active' : ''
              "
              (click)="clickShowModalMultiview()"
            >
              <div class="svg-container">
                <ng-container
                  *ngIf="panelService.nombreComponente == 'MULTIVIEW'"
                >
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 -0.5 21 21"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    fill="#ffffff"
                    stroke="#ffffff"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>grid [#ffffff]</title>
                      <desc>Created with Sketch.</desc>
                      <defs></defs>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Dribbble-Light-Preview"
                          transform="translate(-219.000000, -200.000000)"
                          fill="#ffffff"
                        >
                          <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                          >
                            <path
                              d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                              id="grid-[#ffffff]"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </ng-container>
                <ng-container
                  *ngIf="panelService.nombreComponente != 'MULTIVIEW'"
                >
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 -0.5 21 21"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                    stroke="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>grid [#000000]</title>
                      <desc>Created with Sketch.</desc>
                      <defs></defs>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Dribbble-Light-Preview"
                          transform="translate(-219.000000, -200.000000)"
                          fill="#000000"
                        >
                          <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                          >
                            <path
                              d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                              id="grid-[#000000]"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </ng-container>
              </div>
              <span> Multipantalla</span>
            </button>
            <div class="dropdown-divider"></div>
          </div>

          <button
            ngbDropdownItem
            class="btn-navbar d-flex align-items-center"
            [ngClass]="
              panelService.nombreComponente == 'USER-INFO' ? 'active' : ''
            "
            (click)="panelService.clickShowPanel('USER-INFO')"
          >
            <div class="svg-container">
              <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="24px" height="24px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.571,20.104v7.944c0.509,0.052,0.072,0.539,2.116,0.683
                l1.13,0.234c0.34,0.075,0.889,0.095,1.287,0.144c0.281,0.032,0.347,0.084,0.556,0.128c0.196,0.035,0.457,0.065,0.601,0.081
                c0.692,0.079,0.724-0.046,0.908,0.523c0.098,0.285,0.137,0.215,0.228,0.455l0.83,1.969c0.145,0.477-0.764,1.234-1.038,1.64
                c-0.144,0.206-0.117,0.206-0.281,0.405l-1.347,1.747c-0.947,1.246-0.972,1.343,0.04,2.621c0.921,1.172,1.979,2.198,3.07,3.2
                l0.079,0.076c0.02,0.012,0.064,0.059,0.078,0.071l0.542,0.52c0.012,0.009,0.026,0.022,0.039,0.036l0.157,0.146
                c0.548,0.545,0.816,0.908,1.672,0.826c0.339-0.032,0.621-0.337,0.896-0.542c0.15-0.11,0.242-0.203,0.378-0.304l0.477-0.352
                c0.052-0.046,0.086-0.063,0.138-0.096l0.985-0.748c0.02-0.013,0.066-0.057,0.085-0.069l1.51-1.137
                c0.548-0.418,0.693,0.007,1.947,0.458l1.071,0.366c0.059,0.261,0.053,0.424,0.105,0.728c0.052,0.295,0.137,0.451,0.189,0.719
                c0.091,0.493,0.104,1.022,0.189,1.544c0.052,0.305,0.085,0.43,0.15,0.687c0.073,0.278,0.033,0.522,0.079,0.829
                c0.293,2.163,0.49,2.147,3.142,2.147c1.268,0,4.071,0.124,5.227-0.069c1.242-0.209,1.013-1.731,1.281-2.653
                c0.046-0.176,0.079-0.19,0.118-0.336c0.125-0.493,0.065-1.116,0.117-1.62c0.026-0.245,0.118-0.46,0.177-0.734
                c0.281-1.31-0.354-0.984,1.006-1.562c0.242-0.105,0.47-0.102,0.692-0.212l0.621-0.292c0.92-0.44,0.633-0.149,1.431,0.366
                c0.209,0.134,0.196,0.098,0.398,0.281c0.013,0.01,0.026,0.026,0.039,0.036l2.718,2.13c0.418,0.311,0.961,0.664,1.561,0.311
                c0.021-0.01,0.073-0.049,0.092-0.063c0.052-0.032,0.085-0.055,0.13-0.091c0.144-0.098,0.092-0.06,0.216-0.161l2.64-2.433
                c0.039-0.046,0.058-0.066,0.104-0.118l0.522-0.543c0.562-0.562,1.581-1.581,1.928-2.31c0.13-0.277,0.117-0.323,0.025-0.604
                c-0.117-0.357-0.306-0.615-0.535-0.905l-2.044-2.571c-0.014-0.009-0.026-0.025-0.034-0.039c-0.013-0.01-0.025-0.03-0.032-0.043
                c-0.706-0.892-0.954-1.078-0.607-1.787c0.13-0.264,0.13-0.117,0.254-0.5c0.039-0.12,0.026-0.113,0.079-0.225l0.313-0.822
                c0.215-0.585,0.183-0.591,0.725-0.71l1.79-0.251c0.288-0.049,0.366-0.107,0.614-0.14c0.425-0.063,0.948-0.069,1.3-0.141
                c0.432-0.085,0.732-0.208,1.196-0.242c0.633-0.039,0.575-0.026,0.915-0.447c0.294-0.367,0.294-0.503,0.287-1.147v-5.673
                c0-0.513,0.073-0.875-0.131-1.231c-0.071-0.127-0.176-0.311-0.287-0.392c-0.026-0.02-0.49-0.255-0.503-0.257
                c-0.241-0.104-0.915-0.104-1.209-0.148c-0.496-0.068-0.718-0.205-1.203-0.238c-0.94-0.056-1.586-0.222-2.527-0.346
                c-0.68-0.088-0.575-0.036-0.79-0.568l-0.739-1.758c-0.451-0.761-0.215-1.026,0.249-1.594l2.24-2.904
                c0.144-0.17,0.157-0.235,0.274-0.405c0.15-0.206,0.164-0.14,0.248-0.44c0.282-0.981-0.972-1.938-1.534-2.64
                c-0.516-0.65-1.104-1.206-1.706-1.771c-0.15-0.138-0.229-0.238-0.378-0.379L38.509,5.16c-0.353-0.327-0.497-0.539-1.104-0.539
                c-0.287,0-0.575,0.133-0.751,0.283l-1.059,0.757c-0.111,0.085-0.065,0.059-0.164,0.141c-0.014,0.009-0.026,0.026-0.039,0.036
                c-0.014,0.009-0.027,0.022-0.046,0.032l-1.111,0.856c-0.144,0.117-0.026,0.039-0.209,0.166c-0.196,0.131-1.484,1.166-1.686,1.186
                c-0.386-0.033-0.092-0.009-0.386-0.144l-1.594-0.68c-0.595-0.213-0.784-0.118-0.823-0.915c-0.02-0.408-0.165-0.591-0.209-0.928
                c-0.046-0.35-0.06-0.722-0.092-1.042c-0.046-0.415-0.131-0.562-0.197-0.937c-0.149-0.84,0-2.118-0.686-2.715
                c-0.431-0.378-1.222-0.28-1.993-0.28c-1.535,0-3.077,0-4.612,0c-1.543,0-1.816-0.017-2.202,1.198
                c-0.097,0.29-0.111,0.614-0.149,0.994c-0.046,0.519-0.209,0.741-0.301,1.966c-0.02,0.251-0.084,0.751-0.145,0.986
                c-0.104,0.428-0.117,0.542-0.157,1.058c-0.045,0.579-0.529,0.52-0.954,0.709c-0.235,0.104-0.176,0.072-0.45,0.153
                c-1.372,0.418-0.914,1.082-2.267,0.014l-0.647-0.49c-0.012-0.01-0.026-0.026-0.039-0.036l-0.333-0.267
                c-0.014-0.01-0.032-0.026-0.046-0.033l-2.156-1.698c-0.392-0.268-0.634-0.392-1.195-0.317C10.22,4.734,9.372,5.496,9,5.841
                L5.954,8.919c-0.02,0.017-0.058,0.063-0.071,0.079C5.759,9.134,5.732,9.18,5.608,9.333c-1.294,1.646-1.202,1.559,0.359,3.486
                c0.182,0.229,0.339,0.406,0.516,0.621c0.177,0.219,0.32,0.408,0.502,0.63c0.119,0.144,0.119,0.164,0.223,0.307
                c0.014,0.01,0.026,0.03,0.033,0.04c0.014,0.013,0.026,0.03,0.033,0.042l0.137,0.169c0.012,0.014,0.02,0.034,0.026,0.046
                c0.013,0.013,0.026,0.033,0.033,0.049c0.248,0.385,0.856,0.845,0.666,1.44c-0.189,0.589-0.522,1.02-0.698,1.653
                c-0.274,0.98-0.765,0.912-2.319,1.084c-0.659,0.072-1.301,0.246-1.973,0.297c-0.412,0.033-0.582,0.114-0.94,0.189
                c-0.517,0.114-0.974,0.032-1.307,0.438C0.695,20.072,0.904,20.02,0.571,20.104 M15.16,21.302c0.359-1.146,0.954-2.191,1.705-3.133
                l0.55-0.587c0.006-0.013,0.019-0.03,0.032-0.042c0.013-0.01,0.026-0.026,0.039-0.036l1.94-1.542c0.621-0.458,2.09-1.006,2.842-1.091
                c0.641-0.069,1.229-0.213,1.979-0.213c0.634,0,2.007,0.151,2.627,0.398c0.176,0.07,0.32,0.083,0.483,0.125
                c0.346,0.082,0.131,0.032,0.424,0.183c0.209,0.114,0.164,0.065,0.379,0.14c0.327,0.115,1.189,0.716,1.464,0.889
                c0.188,0.121,0.169,0.088,0.358,0.249c0.046,0.039,0.112,0.104,0.157,0.143c0.008,0.01,0.027,0.026,0.033,0.036l0.079,0.075
                c0.013,0.014,0.032,0.026,0.039,0.037l1.791,1.996c0.079,0.134,0.059,0.082,0.117,0.183c0.099,0.157,0.125,0.261,0.209,0.395
                l0.836,1.813c0.008,0.024,0.046,0.249,0.046,0.255c0.17,1.02,0.183,0.327,0.268,1.474c0.15,2.071,0.007,2.711-0.595,4.563
                l-1.012,1.859c-0.014,0.017-0.026,0.032-0.033,0.042L31.61,29.89c-0.007,0.01-0.026,0.027-0.033,0.039l-0.176,0.203
                c-0.896,1.064-1.994,1.999-3.273,2.547c-1.177,0.507-0.798,0.269-1.353,0.467c-0.17,0.06-0.209,0.112-0.372,0.157
                c-1.235,0.344-3.658,0.245-4.841-0.157c-0.646-0.218-0.796-0.166-1.587-0.604c-0.15-0.085-0.229-0.101-0.347-0.18l-0.366-0.241
                c-0.405-0.202-1.214-0.856-1.542-1.183c-0.993-1-1.072-1.16-1.725-2.208l-0.392-0.739c-0.313-0.509-0.123-0.386-0.32-0.816
                c-0.078-0.177-0.092-0.108-0.157-0.372c-0.386-1.536-0.333-1.957-0.333-3.597L15.16,21.302z"/>
            </svg> -->
              <i class="fas fa-user-cog"></i>
            </div>
            <!-- <i class="fa fa-cog" aria-hidden="true"></i> -->
            <span>Información Del Usuario</span>
          </button>
          <button
            ngbDropdownItem
            class="btn-navbar d-flex align-items-center"
            [ngClass]="
              panelService.nombreComponente == 'USER-CONFIG' ? 'active' : ''
            "
            (click)="panelService.clickShowPanel('USER-CONFIG')"
          >
            <div class="svg-container">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.571,20.104v7.944c0.509,0.052,0.072,0.539,2.116,0.683
                l1.13,0.234c0.34,0.075,0.889,0.095,1.287,0.144c0.281,0.032,0.347,0.084,0.556,0.128c0.196,0.035,0.457,0.065,0.601,0.081
                c0.692,0.079,0.724-0.046,0.908,0.523c0.098,0.285,0.137,0.215,0.228,0.455l0.83,1.969c0.145,0.477-0.764,1.234-1.038,1.64
                c-0.144,0.206-0.117,0.206-0.281,0.405l-1.347,1.747c-0.947,1.246-0.972,1.343,0.04,2.621c0.921,1.172,1.979,2.198,3.07,3.2
                l0.079,0.076c0.02,0.012,0.064,0.059,0.078,0.071l0.542,0.52c0.012,0.009,0.026,0.022,0.039,0.036l0.157,0.146
                c0.548,0.545,0.816,0.908,1.672,0.826c0.339-0.032,0.621-0.337,0.896-0.542c0.15-0.11,0.242-0.203,0.378-0.304l0.477-0.352
                c0.052-0.046,0.086-0.063,0.138-0.096l0.985-0.748c0.02-0.013,0.066-0.057,0.085-0.069l1.51-1.137
                c0.548-0.418,0.693,0.007,1.947,0.458l1.071,0.366c0.059,0.261,0.053,0.424,0.105,0.728c0.052,0.295,0.137,0.451,0.189,0.719
                c0.091,0.493,0.104,1.022,0.189,1.544c0.052,0.305,0.085,0.43,0.15,0.687c0.073,0.278,0.033,0.522,0.079,0.829
                c0.293,2.163,0.49,2.147,3.142,2.147c1.268,0,4.071,0.124,5.227-0.069c1.242-0.209,1.013-1.731,1.281-2.653
                c0.046-0.176,0.079-0.19,0.118-0.336c0.125-0.493,0.065-1.116,0.117-1.62c0.026-0.245,0.118-0.46,0.177-0.734
                c0.281-1.31-0.354-0.984,1.006-1.562c0.242-0.105,0.47-0.102,0.692-0.212l0.621-0.292c0.92-0.44,0.633-0.149,1.431,0.366
                c0.209,0.134,0.196,0.098,0.398,0.281c0.013,0.01,0.026,0.026,0.039,0.036l2.718,2.13c0.418,0.311,0.961,0.664,1.561,0.311
                c0.021-0.01,0.073-0.049,0.092-0.063c0.052-0.032,0.085-0.055,0.13-0.091c0.144-0.098,0.092-0.06,0.216-0.161l2.64-2.433
                c0.039-0.046,0.058-0.066,0.104-0.118l0.522-0.543c0.562-0.562,1.581-1.581,1.928-2.31c0.13-0.277,0.117-0.323,0.025-0.604
                c-0.117-0.357-0.306-0.615-0.535-0.905l-2.044-2.571c-0.014-0.009-0.026-0.025-0.034-0.039c-0.013-0.01-0.025-0.03-0.032-0.043
                c-0.706-0.892-0.954-1.078-0.607-1.787c0.13-0.264,0.13-0.117,0.254-0.5c0.039-0.12,0.026-0.113,0.079-0.225l0.313-0.822
                c0.215-0.585,0.183-0.591,0.725-0.71l1.79-0.251c0.288-0.049,0.366-0.107,0.614-0.14c0.425-0.063,0.948-0.069,1.3-0.141
                c0.432-0.085,0.732-0.208,1.196-0.242c0.633-0.039,0.575-0.026,0.915-0.447c0.294-0.367,0.294-0.503,0.287-1.147v-5.673
                c0-0.513,0.073-0.875-0.131-1.231c-0.071-0.127-0.176-0.311-0.287-0.392c-0.026-0.02-0.49-0.255-0.503-0.257
                c-0.241-0.104-0.915-0.104-1.209-0.148c-0.496-0.068-0.718-0.205-1.203-0.238c-0.94-0.056-1.586-0.222-2.527-0.346
                c-0.68-0.088-0.575-0.036-0.79-0.568l-0.739-1.758c-0.451-0.761-0.215-1.026,0.249-1.594l2.24-2.904
                c0.144-0.17,0.157-0.235,0.274-0.405c0.15-0.206,0.164-0.14,0.248-0.44c0.282-0.981-0.972-1.938-1.534-2.64
                c-0.516-0.65-1.104-1.206-1.706-1.771c-0.15-0.138-0.229-0.238-0.378-0.379L38.509,5.16c-0.353-0.327-0.497-0.539-1.104-0.539
                c-0.287,0-0.575,0.133-0.751,0.283l-1.059,0.757c-0.111,0.085-0.065,0.059-0.164,0.141c-0.014,0.009-0.026,0.026-0.039,0.036
                c-0.014,0.009-0.027,0.022-0.046,0.032l-1.111,0.856c-0.144,0.117-0.026,0.039-0.209,0.166c-0.196,0.131-1.484,1.166-1.686,1.186
                c-0.386-0.033-0.092-0.009-0.386-0.144l-1.594-0.68c-0.595-0.213-0.784-0.118-0.823-0.915c-0.02-0.408-0.165-0.591-0.209-0.928
                c-0.046-0.35-0.06-0.722-0.092-1.042c-0.046-0.415-0.131-0.562-0.197-0.937c-0.149-0.84,0-2.118-0.686-2.715
                c-0.431-0.378-1.222-0.28-1.993-0.28c-1.535,0-3.077,0-4.612,0c-1.543,0-1.816-0.017-2.202,1.198
                c-0.097,0.29-0.111,0.614-0.149,0.994c-0.046,0.519-0.209,0.741-0.301,1.966c-0.02,0.251-0.084,0.751-0.145,0.986
                c-0.104,0.428-0.117,0.542-0.157,1.058c-0.045,0.579-0.529,0.52-0.954,0.709c-0.235,0.104-0.176,0.072-0.45,0.153
                c-1.372,0.418-0.914,1.082-2.267,0.014l-0.647-0.49c-0.012-0.01-0.026-0.026-0.039-0.036l-0.333-0.267
                c-0.014-0.01-0.032-0.026-0.046-0.033l-2.156-1.698c-0.392-0.268-0.634-0.392-1.195-0.317C10.22,4.734,9.372,5.496,9,5.841
                L5.954,8.919c-0.02,0.017-0.058,0.063-0.071,0.079C5.759,9.134,5.732,9.18,5.608,9.333c-1.294,1.646-1.202,1.559,0.359,3.486
                c0.182,0.229,0.339,0.406,0.516,0.621c0.177,0.219,0.32,0.408,0.502,0.63c0.119,0.144,0.119,0.164,0.223,0.307
                c0.014,0.01,0.026,0.03,0.033,0.04c0.014,0.013,0.026,0.03,0.033,0.042l0.137,0.169c0.012,0.014,0.02,0.034,0.026,0.046
                c0.013,0.013,0.026,0.033,0.033,0.049c0.248,0.385,0.856,0.845,0.666,1.44c-0.189,0.589-0.522,1.02-0.698,1.653
                c-0.274,0.98-0.765,0.912-2.319,1.084c-0.659,0.072-1.301,0.246-1.973,0.297c-0.412,0.033-0.582,0.114-0.94,0.189
                c-0.517,0.114-0.974,0.032-1.307,0.438C0.695,20.072,0.904,20.02,0.571,20.104 M15.16,21.302c0.359-1.146,0.954-2.191,1.705-3.133
                l0.55-0.587c0.006-0.013,0.019-0.03,0.032-0.042c0.013-0.01,0.026-0.026,0.039-0.036l1.94-1.542c0.621-0.458,2.09-1.006,2.842-1.091
                c0.641-0.069,1.229-0.213,1.979-0.213c0.634,0,2.007,0.151,2.627,0.398c0.176,0.07,0.32,0.083,0.483,0.125
                c0.346,0.082,0.131,0.032,0.424,0.183c0.209,0.114,0.164,0.065,0.379,0.14c0.327,0.115,1.189,0.716,1.464,0.889
                c0.188,0.121,0.169,0.088,0.358,0.249c0.046,0.039,0.112,0.104,0.157,0.143c0.008,0.01,0.027,0.026,0.033,0.036l0.079,0.075
                c0.013,0.014,0.032,0.026,0.039,0.037l1.791,1.996c0.079,0.134,0.059,0.082,0.117,0.183c0.099,0.157,0.125,0.261,0.209,0.395
                l0.836,1.813c0.008,0.024,0.046,0.249,0.046,0.255c0.17,1.02,0.183,0.327,0.268,1.474c0.15,2.071,0.007,2.711-0.595,4.563
                l-1.012,1.859c-0.014,0.017-0.026,0.032-0.033,0.042L31.61,29.89c-0.007,0.01-0.026,0.027-0.033,0.039l-0.176,0.203
                c-0.896,1.064-1.994,1.999-3.273,2.547c-1.177,0.507-0.798,0.269-1.353,0.467c-0.17,0.06-0.209,0.112-0.372,0.157
                c-1.235,0.344-3.658,0.245-4.841-0.157c-0.646-0.218-0.796-0.166-1.587-0.604c-0.15-0.085-0.229-0.101-0.347-0.18l-0.366-0.241
                c-0.405-0.202-1.214-0.856-1.542-1.183c-0.993-1-1.072-1.16-1.725-2.208l-0.392-0.739c-0.313-0.509-0.123-0.386-0.32-0.816
                c-0.078-0.177-0.092-0.108-0.157-0.372c-0.386-1.536-0.333-1.957-0.333-3.597L15.16,21.302z"
                />
              </svg>
            </div>
            <!-- <i class="fa fa-cog" aria-hidden="true"></i> -->
            <span>Configuración</span>
          </button>
          <!-- <div class="dropdown-divider"></div> -->
          <button
            ngbDropdownItem
            class="btn-navbar d-flex align-items-center"
            (click)="logOutModalDisplay = true"
          >
            <div class="svg-container">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 48 48"
                enable-background="new 0 0 48 48"
                xml:space="preserve"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0,23.395c0,1.234,0.057,2.717,0.22,3.904l0.573,2.729
                  c0.044,0.104,0.063,0.214,0.076,0.309l1.159,3.302c0.057,0.138,0.07,0.147,0.125,0.258c0.051,0.107,0.063,0.161,0.107,0.277
                  c0.044,0.11,0.057,0.116,0.113,0.217L4.2,37.57c0.006,0.009,0.019,0.019,0.025,0.029l0.837,1.149c0.667,0.957,2.204,2.5,3.022,3.192
                  c0.057,0.047,0.018,0.015,0.088,0.075l0.088,0.083c0.006,0.006,0.019,0.019,0.025,0.025l0.799,0.686
                  c0.17,0.113,0.284,0.211,0.435,0.333l4.25,2.575c0.12,0.073-0.007,0.009,0.151,0.073l3.211,1.187
                  c0.24,0.059,0.126,0.037,0.284,0.106c0.214,0.095,0.359,0.076,0.579,0.136c0.227,0.066,0.291,0.126,0.586,0.183
                  c0.189,0.035,0.416,0.035,0.617,0.101l1.92,0.333c0.844,0.104,2.015,0.028,2.833,0.141c0.214,0.029,0.467,0.029,0.68,0
                  c0.625-0.088,1.455-0.044,2.072-0.135c0.372-0.053,1.568-0.186,1.889-0.305c0.227-0.088,0.397-0.108,0.637-0.139l3.142-0.932
                  l1.808-0.777c0.176-0.101,0.302-0.139,0.478-0.24l0.73-0.372c0.378-0.158,1.209-0.717,1.605-0.979l0.882-0.602
                  c0.169-0.11,0.27-0.211,0.44-0.334l1.221-0.979c0.623-0.536,1.361-1.178,1.883-1.804l0.843-0.97c0.58-0.658,1.141-1.404,1.593-2.147
                  c0.063-0.101,0.094-0.128,0.157-0.226l1.361-2.333c0.056-0.16,0.012-0.116,0.1-0.284l0.573-1.24
                  c0.038-0.092,0.044-0.164,0.075-0.251l0.347-0.813c0.114-0.236,0.044-0.324,0.158-0.557c0.163-0.318,0.421-1.338,0.528-1.726
                  c0.051-0.176,0.07-0.44,0.101-0.614c0.063-0.268,0.126-0.306,0.177-0.598c0.037-0.204,0.037-0.413,0.076-0.639
                  c0.038-0.22,0.106-0.418,0.138-0.636c0.057-0.434,0.063-0.935,0.113-1.366c0.139-1.193,0.183-2.622,0.019-3.816
                  c-0.063-0.435-0.05-1-0.106-1.376l-0.359-1.844c-0.057-0.195-0.063-0.416-0.114-0.655c-0.05-0.243-0.132-0.249-0.188-0.529
                  l-0.158-0.611c-0.101-0.224-0.063-0.355-0.145-0.573c-0.037-0.101-0.075-0.151-0.119-0.268l-0.277-0.815
                  c-0.064-0.249-0.139-0.314-0.221-0.554c-0.138-0.39-0.592-1.426-0.793-1.741l-1.348-2.339c-0.056-0.101-0.094-0.15-0.144-0.236
                  l-1.26-1.715c-0.057-0.076-0.119-0.151-0.17-0.214l-0.881-0.989c-0.435-0.544-1.108-1.146-1.649-1.595L39.44,5.746
                  c-0.032-0.025-0.069-0.053-0.095-0.075l-0.083-0.082c-0.566-0.567-1.284-0.951-1.907-1.448c-0.039-0.032,0.006-0.007-0.095-0.073
                  L36.89,3.838c-0.383-0.233-0.742-0.516-1.165-0.705L35.026,2.73c-0.132-0.079-0.145-0.06-0.265-0.12
                  c-0.686-0.327-1.335-0.651-2.052-0.919c-0.315-0.12-0.769-0.346-1.077-0.412L30.499,0.93c-0.227-0.095-0.372-0.085-0.579-0.135
                  c-0.252-0.064-0.308-0.13-0.586-0.187c-0.188-0.037-0.453-0.044-0.623-0.094c-0.41-0.12-0.906-0.126-1.271-0.214
                  c-0.637-0.157-1.386-0.129-2.034-0.221c-0.453-0.063-0.982,0.029-1.442-0.041c-0.692-0.106-1.297,0.088-2.008,0.083
                  c-0.422-0.004-2.33,0.217-2.645,0.331c-0.599,0.213-1.247,0.245-1.826,0.431c-0.119,0.041-0.113,0.053-0.233,0.094
                  c-0.139,0.044-0.19,0.047-0.315,0.073c-0.284,0.063-0.34,0.16-0.555,0.21c-0.132,0.028-0.15,0.013-0.284,0.05
                  c-0.213,0.06-0.106,0.057-0.257,0.126c-0.132,0.063-0.139,0.047-0.29,0.095c-0.41,0.123-1.141,0.487-1.562,0.645
                  c-0.409,0.157-0.85,0.362-1.215,0.602c-0.366,0.246-0.818,0.431-1.183,0.686l-0.919,0.564c-0.108,0.075-0.114,0.097-0.214,0.173
                  L7.965,6.159C7.814,6.31,7.726,6.344,7.574,6.489C7.429,6.625,7.329,6.734,7.189,6.873l-3.513,4.356l-1.63,3.045l-0.648,1.612
                  C1.284,16.119,1.36,16.208,1.24,16.44c-0.158,0.318-0.421,1.337-0.528,1.725c-0.051,0.176-0.063,0.441-0.102,0.617
                  c-0.056,0.264-0.125,0.302-0.176,0.595c-0.037,0.205-0.031,0.413-0.075,0.639c-0.201,1.137-0.132,0.922-0.251,2.002
                  C0.057,22.481,0,22.878,0,23.395 M29.933,17.177v-6.491c0-0.639,0.535-0.083,0.881,0.053l0.957,0.525
                  c0.435,0.293,1.203,0.778,1.549,1.096l2.04,2.031c0.264,0.267,0.491,0.617,0.718,0.932c0.572,0.793,1.146,1.684,1.473,2.61
                  c0.044,0.116,0.077,0.151,0.126,0.246l0.742,2.175c0.17,0.696,0.227,1.396,0.34,2.138c0.139,0.96,0.132,2.119,0.007,3.029
                  c-0.038,0.283-0.069,0.438-0.088,0.736c-0.02,0.271-0.058,0.453-0.139,0.686c-0.088,0.265-0.051,0.384-0.106,0.661
                  c-0.058,0.265-0.139,0.346-0.208,0.624l-1.247,3.038l-0.573,0.918c-0.075,0.117-0.088,0.13-0.15,0.227
                  c-0.271,0.39-0.529,0.784-0.857,1.13c-0.792,0.856-0.698,0.901-1.889,1.908l-0.145,0.128c-0.522,0.488-1.14,0.872-1.744,1.225
                  c-0.095,0.053-0.057,0.025-0.139,0.082c-0.012,0.006-0.024,0.019-0.031,0.025l-0.063,0.044c-0.013,0.009-0.025,0.019-0.038,0.022
                  l-1.102,0.548c-0.265,0.111-0.536,0.312-0.813,0.4l-3.633,0.935c-1.222,0.057-2.513,0.088-3.734-0.003l-1.416-0.238
                  c-0.296-0.047-0.34-0.155-0.579-0.192c-0.479-0.069-0.511-0.142-0.952-0.258c-0.27-0.069-0.125-0.038-0.308-0.129L17.41,37.46
                  c-0.183-0.085-0.139-0.101-0.278-0.167l-0.786-0.422c-0.089-0.05-0.057-0.025-0.145-0.072c-0.422-0.233-1.209-0.81-1.562-1.086
                  c-0.592-0.463-1.152-1.033-1.668-1.574l-0.566-0.643c-0.069-0.082-0.107-0.132-0.177-0.214l-0.83-1.203
                  c-0.069-0.101-0.108-0.154-0.177-0.265c-0.068-0.101-0.1-0.123-0.156-0.223l-0.423-0.793c-0.069-0.123-0.063-0.154-0.119-0.264
                  c-0.088-0.17-0.006-0.016-0.088-0.132c-0.075-0.125-0.031-0.025-0.075-0.145l-0.668-1.756l-0.648-3.422
                  c-0.101-0.721-0.038-1.451,0.031-2.238l0.604-3.249c0.033-0.148,0.007-0.063,0.051-0.167c0.051-0.113,0.075-0.097,0.119-0.264
                  c0.031-0.104,0.044-0.249,0.088-0.355l0.208-0.558c0.068-0.173,0.075-0.123,0.138-0.249l0.196-0.413
                  c0.119-0.186,0.094-0.274,0.202-0.454c0.403-0.677,0.762-1.392,1.253-1.996c0.075-0.094,0.101-0.12,0.17-0.214
                  c0.51-0.711,1.127-1.319,1.751-1.939c0.138-0.145,0.257-0.224,0.415-0.356l2.43-1.697c0.347-0.205,0.718-0.425,1.127-0.522
                  c0.058,0.11,0.113,0.163,0.113,0.33v6.328c0,0.151-0.031,0.192-0.101,0.29l-0.321,0.337c-0.057,0.06-0.044,0.041-0.113,0.11
                  c-0.372,0.4-0.711,0.844-1.007,1.307l-0.856,1.668c-0.063,0.258,0,0.121-0.113,0.332c-0.108,0.21-0.114,0.44-0.177,0.645
                  c-0.056,0.161-0.075,0.151-0.119,0.324c-0.107,0.441-0.264,1.971-0.189,2.39c0.082,0.479,0.069,1.086,0.227,1.53l0.812,2.216
                  c0.189,0.406,0.447,0.699,0.687,1.07l0.504,0.652c0.006,0.009,0.019,0.022,0.031,0.028l1.059,1.03
                  c0.44,0.399,1.176,0.853,1.718,1.088l0.284,0.158c0.138,0.054,0.188,0.044,0.328,0.113c0.592,0.312,1.114,0.324,1.7,0.501
                  c0.39,0.113,1.184,0.16,1.548,0.132c0.567-0.05,1.121,0.038,1.625-0.085c0.258-0.063,0.377-0.106,0.718-0.161
                  c0.366-0.054,1.234-0.422,1.599-0.604c0.467-0.235,0.958-0.497,1.398-0.803l1.518-1.401c0.006-0.006,0.013-0.022,0.019-0.028
                  l0.945-1.313c0.245-0.484,0.553-0.935,0.686-1.46c0.069-0.258,0.031-0.095,0.133-0.309c0.246-0.51,0.339-1.662,0.409-2.231
                  c0.094-0.734,0.006-1.99-0.232-2.708c-0.051-0.155-0.057-0.202-0.083-0.358c-0.082-0.504-0.302-0.803-0.447-1.253l-0.328-0.667
                  c-0.006-0.009-0.019-0.022-0.025-0.028c-0.006-0.01-0.019-0.022-0.024-0.032c-0.353-0.507-0.63-1.063-1.077-1.508l-0.251-0.246
                  C30.279,17.63,29.933,17.395,29.933,17.177z M20.96,9.253c0-0.298,0.038-0.466,0.088-0.736c0.108-0.599,0.082-0.362,0.214-0.614
                  c0.157-0.296-0.037-0.069,0.24-0.419l1.133-1.067c0.724-0.362,1.189-0.413,1.977-0.261c0.302,0.06,0.202,0.06,0.51,0.204
                  c0.284,0.129,0.151,0.095,0.328,0.221c0.063,0.041,0.044,0.022,0.107,0.063c0.145,0.095,0.264,0.205,0.391,0.321
                  c0.17,0.158,0.188,0.202,0.328,0.387c0.29,0.381,0.629,1.026,0.629,1.517v12.215c0,0.463-0.264,1.068-0.504,1.426
                  c-0.257,0.394-0.106,0.211-0.497,0.598c-0.12,0.12-0.29,0.252-0.423,0.35c-0.226,0.17-0.806,0.45-1,0.481
                  c-0.271,0.044-0.428,0.048-0.617,0.026c-0.346-0.042-0.372,0.005-0.661-0.111c-0.674-0.274-1.007-0.438-1.48-0.998l-0.075-0.088
                  c-0.094-0.107-0.082-0.104-0.163-0.227c-0.315-0.481-0.523-1.023-0.523-1.678V9.253z"
                />
              </svg>
            </div>
            <!-- <i class="fa fas fa-sign-out" aria-hidden="true"></i> -->
            <span>Cerrar Sesión</span>
          </button>
        </ul>
      </div>

      <!-- <div class="p-2 bd-highlight col">
        <div ngbDropdown class="d-inline-block">
          <button class="btn" id="userDropdown" ngbDropdownToggle>
            <i class="fa fas fa-user"></i>
            <span class="p-2 bd-highlight">
              user
            </span>
          </button>
          <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu dropdown-menu-end">
            <button ngbDropdownItem routerLink="/profile">Configuración de Perfil</button>
            <button ngbDropdownItem>Opción 2</button>
            <button ngbDropdownItem>Opción 3</button>
          </div>
        </div>
      </div> -->
      <!-- <div class="p-2 bd-highlight">
        <a routerLink="/profile" class="link-secondary">
          <i class="fa fas fa-user"></i>
          <span class="p-2 bd-highlight">
            user
          </span></a>
      </div> -->
    </div>
  </div>
</nav>
<app-dialog
  [display]="showMultiviewModal"
  (onHideEvent)="onHideMultiviewModal()"
>
</app-dialog>
<p-dialog
  [(visible)]="logOutModalDisplay"
  [modal]="true"
  [resizable]="false"
  [baseZIndex]="10000"
  [closable]="false"
  appendTo="body"
  [breakpoints]="{ '720px': '75vw', '500px': '90vw' }"
  [style]="{ width: '32.5rem' }"
  styleClass="logout-dialog"
>
  <ng-template pTemplate="header">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="42px"
      height="44px"
      viewBox="0 0 21 22"
      xml:space="preserve"
    >
      <path
        d="M15,6.6c0,0.5,0.3,0.7,0.6,1l1.1,1.1c0.1,0.1,0.2,0.2,0.4,0.4l0.7,0.7c0,0.1,0,0,0,0.1c-0.1,0-5,0-5.9,0
          c-0.5,0-0.6,0-0.9,0.3c-0.3,0.3-0.3,0.9,0.1,1.2c0.1,0.1,0.3,0.2,0.5,0.2l6.2,0c0,0.1,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1
          c-0.3,0.4-0.8,0.8-1.2,1.2c-0.1,0.1-0.1,0.1-0.2,0.2C16,13.3,16,13.3,16,13.5c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.2-0.6,0.5-0.6,0.9v0.1
          c0,0.6,0.7,1,1.3,0.6c0,0,0.1-0.1,0.2-0.1l3.7-3.7c0.2-0.2,0.5-0.4,0.5-0.8c0-0.3-0.2-0.5-0.4-0.7l-3.7-3.7
          c-0.2-0.2-0.4-0.5-0.9-0.4C15.3,6,15,6.3,15,6.6 M8.7,0.1H8.5c0,0.1-0.3,0.1-0.5,0.2L4.6,1.4C4,1.6,3.3,1.9,2.7,2.1
          C2.4,2.2,2,2.3,1.7,2.4c-0.3,0.1-0.7,0.2-1,0.3C0.4,2.9,0.3,3.2,0.3,3.6v14.1c0,0.2,0,0.5,0,0.6c0,0.1,0.1,0.2,0.1,0.2
          c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0.1,1.1,0.4,1.4,0.5l4.3,1.4c0.3,0.1,0.6,0.2,1,0.3c0.2,0.1,0.3,0.1,0.5,0.2c0.4,0.1,0.7,0.3,1,0.1
          c0.6-0.2,0.6-0.7,0.6-1.3c0-0.4,0-0.9,0-1.3c0,0,0,0,0,0h1.3c0.3,0,0.6,0,0.8,0h0.9c0.1,0,0.3,0,0.5,0h0c0.1,0,0.2,0,0.3,0
          c0.3,0,0.5,0,0.8-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.5,0-4.6,0-4.8c-0.5,0-1,0-1.4,0c-0.1,0-0.1,0-0.1,0.1c0,0.2,0,0.4,0,0.5
          c0,0.4,0,3.3,0,3.5l0,0.1c0,0,0,0,0,0c-0.1,0.1-3,0-3,0v0H9.5v-0.9c0-0.4,0-0.9,0-1.3V4.8c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
          c0.2,0,2.5,0,3,0c0,0,0.1,0,0.1,0l0.1,0c0,0,0,0,0,0c0,0,0,3.3,0,3.9c0,0.1,0,0.2,0.1,0.2l0.2,0c0.3,0,1.2,0,1.3,0
          c0-0.1,0-3.5,0-3.9c0-0.3,0-1,0-1.3c-0.1-0.3-0.4-0.5-0.8-0.5l-3.3,0c-0.8,0-0.7,0.1-0.7-0.4l0-1.3c0-0.2-0.1-0.5-0.2-0.6
          C9.2,0.4,9.1,0.3,9,0.3C9,0.2,8.8,0.2,8.7,0.1z"
      />
    </svg>
  </ng-template>
  <div class="d-flex flex-column align-items-center">
    <span class="fw-bold" style="font-size: 1.75rem">Cerrar sesión</span>
    <span>¿Es seguro que deseas salir?</span>
  </div>
  <p-footer>
    <div class="row justify-content-center gap-3">
      <button
        class="btn-gl-save btn-dark-hover fw-bold col-5"
        (click)="logOut()"
      >
        Deseo Salir
      </button>
      <button
        class="btn-gl-red btn-dark-hover fw-bold col-5"
        (click)="logOutModalDisplay = false"
      >
        Cancelar
      </button>
    </div>
  </p-footer>
</p-dialog>
