<div class="d-flex" style="height: 100%; overflow:auto;">
    <div class="modal-body p-fluid flex-grow-1 p-0 overflow-auto" style="position: relative;">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" name="loadingAlertData" size="medium" color="#fff" type="ball-pulse"
            [fullScreen]="false">
            <p style="color: white" class="text-center"> Cargando información... </p>
        </ngx-spinner>

        <form (submit)="confirm()" (keydown.enter)="$event.preventDefault()" autocomplete="off">


            <div class="panel-izq-container h-100">
                <div class="panel-izq-form">
                    <div class="row my-3">
                        <div class="col-12 d-flex justify-content-center">
                            <div class="profile-picture-container">
                                <span class="one-character-circled profile-picture">{{ userDataService.firstLetter
                                    }}</span>
                            </div>
                        </div>
                        <span class="col-12 text-center w-100 mt-2">
                            {{userDataService.userEmail}}
                        </span>
                    </div>

                    <div class="panel-izq-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width: 35%;">Tipo de vehiculo</th>
                                    <th style="width: 25%;">Color de Icono</th>
                                    <th style="width: 40%;">Consumo Combustible (Gal)</th>
                                </tr>
                            </thead>
                            <tbody>


                                <ng-container *ngFor="let item of typeVehiclesList; let i = index">
                                    <tr>
                                        <td style="width: 30%;">
                                            <span>{{item.var_nombre}}</span>
                                            <input type="hidden" [(ngModel)]="item.id" name="id_{{i}}" />

                                        </td>
                                        <td style="width: 40%;">
                                            <div class="row">
                                                <div class="col-12 d-flex justify-content-center">
                                                    <input type="color" class="form-control form-control-color"
                                                        [(ngModel)]="item.code_color" name="var_color_{{i}}"
                                                        [value]="item.code_color">
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input type="text" name="var_galon_{{i}}" [(ngModel)]="item.var_galon"
                                                pInputText placeholder="Galones..."
                                                pTooltip="Ingrese la cantidad en galones" tooltipPosition="right" />
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex flex-wrap gap-4 justify-center w-full py-4 rounded-lg icon-box grid">

                        <div class="col-12 d-flex">
                            <label>
                                <p-checkbox [(ngModel)]="showDirectionVehicles"
                                    (onChange)="changeDirectionVehiclesCheckbox();"
                                    name="checkbox_changeDirectionVehicless" [binary]="true"
                                    inputId="binary"></p-checkbox>
                                &nbsp;&nbsp;<span>{{ showDirectionVehicles ? 'Desactivar' : 'Activar' }}</span>
                                Direccionamiento de vehiculos
                            </label>
                        </div>

                        <ng-container *ngIf="showDirectionVehicles == true">
                            <div class="container contenido_checkbox">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col-4 checks ">
                                        <div class="flex align-items h-5 column">
                                            <div>
                                                <object data="assets/images/user_config/VehicleDirection.gif"
                                                    style="width: 70px;"></object>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>






                        <div class="col-12 d-flex">
                            <label>
                                <p-checkbox [(ngModel)]="showChangeItem" (onChange)="changeItemCheckbox();"
                                    name="checkbox_changeItem" [binary]="true" inputId="binary"></p-checkbox>
                                &nbsp;&nbsp;<span>{{ showChangeItem ? 'Desactivar' : 'Activar' }}</span> Cambio de Color
                                según Estado
                                (En movimiento: VERDE, Exceso de Límite de velocidad: ROJO, Relenti: AZUL)
                            </label>
                        </div>

                        <ng-container *ngIf="showChangeItem == true">
                            <div class="container contenido_checkbox">
                                <div class="row" style="flex: auto;">
                                  <ng-container *appHasPermission="'configuration-state-onda.show'">
                                    <div class="col-4 checks">
                                      <div class="flex align-items h-5 column">
                                          <div class="d-flex align-items-center" style="width: 70px;height: 95px">
                                              <object data="assets/images/user_config/gifcompleto.gif"
                                                  style="height: 60px;width: 70px;"></object>
                                          </div>
                                          <div class="d-flex justify-content-center">
                                              <label class="switch ">
                                                  <input [(ngModel)]="userForm.bol_ondas" name="bol_ondas"
                                                      type="checkbox" (change)="switchActive(1);">
                                                  <span class="slider"></span>
                                              </label>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="col-4 checks">
                                        <div class="flex align-items h-5 column">
                                            <div>
                                                <object data="assets/images/user_config/flecha3colores.gif"
                                                    style="height: 90px;width: 70px"></object>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <label class="switch">
                                                    <input [(ngModel)]="userForm.bol_cursor" name="bol_cursor"
                                                        type="checkbox" (change)="switchActive(2);">
                                                    <span class="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 checks">
                                        <div class="flex align-items h-5 column position-relative">
                                            <div class="position-relative">
                                                <object data="assets/images/user_config/vista2bus.gif "
                                                    style="height: 90px;width: 70px"></object>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <label class="switch">
                                                    <input [(ngModel)]="userForm.bol_vehicle" name="bol_vehicles"
                                                        type="checkbox" (change)="switchActive(3); ">
                                                    <span class="slider"></span>
                                                </label>
                                                <span *ngIf="showIcon" class="d-flex align-items-center ps-2" style="position: absolute;translate: none;top: 70px;width: 30%;" data-bs-toggle="tooltip" data-bs-placement="right"
                                                    data-bs-title="Tenga cuidado en seleccionar un color parecido a los estados de los vehiculos">
                                                    <i class="fas fa-exclamation" style="font-size:1.5rem ;color: red; "></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                  </ng-container>
                                  <ng-container *appHasPermission="'!configuration-state-onda.show'">
                                    <div class="col-6 checks">
                                        <div class="flex align-items h-5 column">
                                            <div>
                                                <object data="assets/images/user_config/flecha3colores.gif"
                                                    style="height: 90px;width: 70px"></object>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <label class="switch">
                                                    <input [(ngModel)]="userForm.bol_cursor" name="bol_cursor"
                                                        type="checkbox" (change)="switchActive(2);">
                                                    <span class="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 checks">
                                        <div class="flex align-items h-5 column position-relative">
                                            <div class="position-relative">
                                                <object data="assets/images/user_config/vista2bus.gif "
                                                    style="height: 90px;width: 70px"></object>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <label class="switch">
                                                    <input [(ngModel)]="userForm.bol_vehicle" name="bol_vehicles"
                                                        type="checkbox" (change)="switchActive(3); ">
                                                    <span class="slider"></span>
                                                </label>
                                                <span *ngIf="showIcon" class="d-flex align-items-center ps-2" style="position: absolute;translate: none;top: 70px;width: 30%;" data-bs-toggle="tooltip" data-bs-placement="right"
                                                    data-bs-title="Tenga cuidado en seleccionar un color parecido a los estados de los vehiculos">
                                                    <i class="fas fa-exclamation" style="font-size:1.5rem ;color: red; "></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                  </ng-container>
                                </div>
                            </div>

                        </ng-container>






                    </div>
                    <p-footer>
                        <div class="row d-flex justify-content-center">
                            <div class="col-6">
                                <button (click)="onClickCancel()" _ngcontent-cic-c118="" type="button"
                                    class="btn-gl-cancel btn-dark-hover btn-sm w-100"
                                    [disabled]="loading">Cancelar</button>
                            </div>
                            <div class="col-6">
                                <button _ngcontent-cic-c118="" class="btn-gl-save btn-dark-hover btn-sm w-100"
                                    [disabled]="loading" type="submit">Guardar</button>
                            </div>
                        </div>
                        <div class="" *ngIf="loading">
                        </div>
                    </p-footer>
                </div>
            </div>
        </form>
    </div>
</div>
