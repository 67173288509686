<div class="d-flex flex-column" style="height: 100%">
  <div
    *ngIf="geoOptions == 'poligon' || geoOptions == 'line'"
    class="card-body p-fluid bg-gl-blue-dark"
    id="rowBusqueda"
    [innerHTML]="
      this.geofencesService.action == 'edit pol'
        ? 'Configurar Geocerca Poligonal'
        : 'Nueva Geocerca Poligonal'
    "
  ></div>
  <div
    *ngIf="geoOptions == 'circ'"
    class="card-body p-fluid bg-gl-blue-dark"
    id="rowBusqueda"
    [innerHTML]="
      this.geofencesService.action == 'edit cir'
        ? 'Configurar Geocerca Circular'
        : 'Nueva Geocerca Circular'
    "
  ></div>
  <div
    class="modal-body panel-izq-container p-fluid overflow-auto d-flex flex-column pt-5"
    [style]="{ position: 'relative' }"
  >
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      name="spinnerLoading"
      size="medium"
      color="#fff"
      type="ball-pulse"
      [fullScreen]="false"
      ><p style="color: white" class="text-center">
        Guardando cambios...
      </p></ngx-spinner
    >
    <!-- DATOS BASICOS DE GEOCERCA -->
    <div class="d-flex flex-column pb-4" [style]="{ gap: '2rem !important' }">
      <div class="d-flex flex-column gap-5">
        <div *ngIf="divEnabled" class="d-flex justify-content-around">
          <p-button
            [styleClass]="
              btnSelected == 1
                ? 'd-flex flex-column align-items-center gap-1'
                : 'p-button-outlined d-flex flex-column align-items-center gap-1'
            "
            label="Poligonal"
            (click)="selectBtn(1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.685.256a.5.5 0 0 1 .63 0l7.421 6.03a.5.5 0 0 1 .162.538l-2.788 8.827a.5.5 0 0 1-.476.349H3.366a.5.5 0 0 1-.476-.35L.102 6.825a.5.5 0 0 1 .162-.538l7.42-6.03Z"
              />
            </svg>
          </p-button>
          <p-button
            [styleClass]="
              btnSelected == 2
                ? 'd-flex flex-column align-items-center gap-1'
                : 'p-button-outlined d-flex flex-column align-items-center gap-1'
            "
            label="Circular"
            (click)="selectBtn(2)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          </p-button>
          <!-- <div
             *ngIf="false"
             class="border btnBorder center-icon btn-gl-save btn-dark-hover w-100"
             [class.selected]="btnSelected === 1"
             (click)="selectBtn(1)"
           >
             <button
               type="button"
               *ngIf="geofencesService.showBtnAdd"
               (click)="selectBtn(1)"
               class="pentagon btn"
             ></button>
             <span>Poligonales</span>
           </div>
           <div
             *ngIf="false"
             class="border btnBorder center-icon btn-gl-save btn-dark-hover w-100"
             [class.selected]="btnSelected === 2"
             (click)="selectBtn(2)"
           >
             <button
               type="button"
               *ngIf="circularGeofencesService.showBtnAdd"
               (click)="selectBtn(2)"
               class="circle btn"
             ></button>
             <span>Circulares</span>
           </div> -->
          <!-- <div disabled class="border btnBorder center-icon btn-gl-save btn-dark-hover w-100" [class.selected]="btnSelected === 3" (click)="selectBtn(3)">
               <button class="btnGeo" type="button" *ngIf="geofencesService.showBtnAdd"  (click)="selectBtn(3)">
                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="30.000000pt" height="30.000000pt" viewBox="0 0 30.000000 30.000000" preserveAspectRatio="xMidYMid meet">
                     <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill="#c4c2c1" stroke="none">
                     <path d="M103 265 c-70 -46 -79 -78 -33 -115 44 -36 36 -68 -25 -110 -25 -17
                     -45 -33 -45 -35 0 -3 63 -5 139 -5 l140 0 11 55 c15 74 1 102 -68 136 -85 41
                     -85 43 -17 93 19 15 18 15 -15 15 -23 0 -53 -12 -87 -34z"/>
                     </g>
                 </svg>
               </button>
               <span>Polilineas</span>
             </div> -->
        </div>
        <!-- <div>
           <div class="col-12">
             <label>NOMBRE:</label>
           </div>
         </div>
         <div class="gl-row-underline">
           <div class="col-12">
             <input
               *ngIf="geoOptions == 'poligon' || geoOptions == 'line'"
               type="text"
               class="form-control"
               id="geofence-name"
               name="nombre"
               [(ngModel)]="form.nombre"
               required
               pInputText
             />
             <input
               *ngIf="geoOptions == 'circ'"
               type="text"
               class="form-control"
               id="circular-geofence-name"
               name="nombre"
               [(ngModel)]="form.nombre"
               required
               pInputText
             />
           </div>
         </div> -->
        <div>
          <div class="p-float-label">
            <input
              id="float-input"
              type="text"
              pInputText
              [(ngModel)]="form.nombre"
            />
            <label for="float-input">Nombre de la Geocerca:</label>
          </div>
        </div>
      </div>
      <div class="">
        <div class="row">
          <div class="col p-field">
            <div class="col-12">
              <div class="d-flex align-items-center">
                <label
                  [style]="{
                    left: '0.5rem',
                    color: '#6c757d'
                  }"
                  >Estilo de la Geocerca</label
                >
              </div>
            </div>
          </div>
          <div class="col p-field">
            <div class="col-12">
              <div
                class="p-float-label"
                [style]="{
                  position: 'relative'
                }"
              >
                <input
                  type="color"
                  class="form-control form-control-color form-control-color-gl flex-grow-1 w-100"
                  (change)="
                    geoOptions == 'circ'
                      ? changeGeoColorCir(form.id)
                      : changeGeoColor(form.id)
                  "
                  [(ngModel)]="form.color"
                  id="color_picker"
                />
                <label
                  for="color_picker"
                  [style]="{
                    position: 'absolute',
                    'pointer-events': 'none',
                    top: '-.75rem',
                    left: '0.5rem',
                    'margin-top': '-.5rem',
                    color: '#6c757d',
                    'line-height': '1'
                  }"
                  >Color:</label
                >
              </div>
            </div>
          </div>
          <div class="col p-field">
            <div class="col-12">
              <div class="p-float-label">
                <p-inputNumber
                  id="float-input"
                  [ngModel]="80"
                  suffix=" %"
                  [disabled]="true"
                ></p-inputNumber>
                <label for="float-input">Transparencia:</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="row">
          <div class="col p-field">
            <div class="col-12">
              <div class="d-flex align-items-center">
                <label
                  [style]="{
                    left: '0.5rem',
                    color: '#6c757d'
                  }"
                  >Estilo del Texto</label
                >
              </div>
            </div>
          </div>
          <div class="col p-field">
            <div class="col-12">
              <div
                class="p-float-label"
                [style]="{
                  position: 'relative'
                }"
              >
                <input
                  type="color"
                  class="form-control form-control-color form-control-color-gl flex-grow-1 w-100"
                  (change)="changeGeoColor(form.id)"
                  [(ngModel)]="form.tag_name_color"
                  id="color_picker"
                />
                <label
                  for="color_picker"
                  [style]="{
                    position: 'absolute',
                    'pointer-events': 'none',
                    top: '-.75rem',
                    left: '0.5rem',
                    'margin-top': '-.5rem',
                    color: '#6c757d',
                    'line-height': '1'
                  }"
                  >Color:</label
                >
              </div>
            </div>
          </div>
          <div class="col p-field">
            <div class="col-12">
              <div class="p-float-label">
                <p-dropdown
                  id="float-input"
                  [options]="fontSizeOptions"
                  [(ngModel)]="form.tag_name_font_size"
                  optionLabel="label"
                  optionValue="value"
                  [appendTo]="'body'"
                  [ngModelOptions]="{ standalone: true }"
                ></p-dropdown>
                <label for="float-input">Tamaño:</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="row">
          <div
            class="col p-field"
            *ngIf="geoOptions == 'poligon' || geoOptions == 'line'"
          >
            <div class="col-12">
              <div class="p-float-label">
                <p-inputNumber
                  [(ngModel)]="form.perimetro"
                  suffix=" km"
                  id="float-input"
                  [disabled]="true"
                ></p-inputNumber>
                <label for="float-input">Perímetro de la Geocerca:</label>
              </div>
            </div>
          </div>
          <div class="col p-field" *ngIf="geoOptions == 'circ'">
            <div class="col-12">
              <div class="p-float-label">
                <p-inputNumber
                  [(ngModel)]="form.radio"
                  suffix=" km"
                  id="float-input"
                  [disabled]="true"
                ></p-inputNumber>
                <label for="float-input">Radio de la Geocerca:</label>
              </div>
            </div>
          </div>
          <div class="col p-field">
            <div class="col-12">
              <div class="p-float-label">
                <p-inputNumber
                  [(ngModel)]="form.area"
                  suffix=" km&#178;"
                  id="float-input"
                  [disabled]="true"
                ></p-inputNumber>
                <label for="float-input">Área de la Geocerca:</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DATOS OPCIONALES DE GEOCERCA -->
    <!-- [style]="{
          padding: '.5rem 1rem',
          border: '1px solid #d ee2e6',
          background: '#f8f9fa',
          color: '#495057',
          'border-radius': '3px 3px'
        }" -->
    <div *ngIf="!divEnabled">
      <div
        class="d-flex justify-content-between"
        [style]="{
          padding: '.5rem 0rem',
          color: '#495057'
        }"
      >
        <div
          class="flex-fill d-flex justify-content-between align-items-center"
        >
          <span class="fw-bold">Configuraciones Opcionales</span>
        </div>
        <button
          pButton
          pRipple
          type="button"
          (click)="toggleOptionalSettingsVisibility()"
          [icon]="
            areOptionalSettingsDisplayed
              ? 'pi pi-chevron-up'
              : 'pi pi-chevron-down'
          "
          class="p-button-sm p-button-rounded p-button-text"
          style="color: #6c757d"
        ></button>
      </div>
      <div
        *ngIf="areOptionalSettingsDisplayed"
        class="d-flex flex-column py-4"
        [style]="{ gap: '2rem !important' }"
      >
        <div class="">
          <div class="row">
            <div class="col p-field">
              <div class="col-12">
                <div class="p-float-label">
                  <p-dropdown
                    [options]="operations"
                    [(ngModel)]="selectedOperation"
                    [showClear]="true"
                    optionLabel="nameoperation"
                    optionValue="idoperation"
                    (onChange)="onChangeOperation()"
                    [autoDisplayFirst]="false"
                    [disabled]="disabledOperation"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </p-dropdown>
                  <label for="float-input">Operaciones:</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="d-flex flex-row gap-1">
            <div class="flex-fill">
              <div class="col">
                <div class="p-float-label">
                  <p-multiSelect
                    id="operation_label"
                    class="mb-4"
                    [filter]="true"
                    [options]="geofencesService.listTag"
                    [(ngModel)]="selectedTag"
                    filterBy="var_name"
                    [filterValue]="filterTag"
                    (onFilter)="updateFilter($event)"
                    (onChange)="onSelecTags()"
                    [ngModelOptions]="{ standalone: true }"
                    optionLabel="var_name"
                    [optionValue]="'id'"
                    display="chip"
                  >
                  </p-multiSelect>
                  <label for="operation_label">Etiquetas:</label>
                </div>
              </div>
            </div>
            <div class="">
              <div class="col-12">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-sm"
                  (click)="addNewTag()"
                  onclick="this.blur();"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DATOS AVANZADOS DE GEOCERCA -->
    <div *ngIf="!divEnabled">
      <div
        class="d-flex justify-content-between"
        [style]="{
          padding: '.5rem 0rem',
          color: '#495057'
        }"
      >
        <div
          class="flex-fill d-flex justify-content-between align-items-center"
        >
          <span class="fw-bold">Configuraciones Avanzadas</span>
        </div>
        <button
          pButton
          pRipple
          type="button"
          (click)="toggleAdvanceSettingsVisibility()"
          [icon]="
            areAdvancedSettingsDisplayed
              ? 'pi pi-chevron-up'
              : 'pi pi-chevron-down'
          "
          class="p-button-sm p-button-rounded p-button-text"
          style="color: #6c757d"
        ></button>
      </div>
      <div
        *ngIf="areAdvancedSettingsDisplayed"
        class="d-flex flex-column my-4"
        [style]="{ gap: '2rem !important' }"
      >
        <div class="">
          <div class="row">
            <div class="col p-field">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <label
                    [style]="{
                      left: '0.5rem',
                      color: '#6c757d'
                    }"
                    >Velocidad de Geocerca</label
                  >
                </div>
              </div>
            </div>
            <div class="col p-field">
              <div class="col-12">
                <div class="p-float-label">
                  <p-dropdown
                    [options]="booleanOptions"
                    [(ngModel)]="form.checkVelocidad"
                    optionLabel="label"
                    optionValue="value"
                    [appendTo]="'body'"
                    [ngModelOptions]="{ standalone: true }"
                  ></p-dropdown>
                  <label for="float-input">Activar:</label>
                </div>
              </div>
            </div>
            <div class="col p-field">
              <div class="col-12">
                <div class="p-float-label">
                  <p-inputNumber
                    id="float-input"
                    class="flex-grow-1"
                    [(ngModel)]="form.limite_velocidad"
                    suffix=" Km/h"
                    [disabled]="!form.checkVelocidad"
                    [ngModelOptions]="{ standalone: true }"
                  ></p-inputNumber>
                  <label for="float-input">Vel. Límite:</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" *ngIf="geoOptions == 'poligon'">
          <div class="row">
            <div class="col p-field">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <label
                    [style]="{
                      left: '0.5rem',
                      color: '#6c757d'
                    }"
                    >Dirección de Geocerca</label
                  >
                </div>
              </div>
            </div>
            <div class="col p-field">
              <div class="col-12">
                <div class="p-float-label">
                  <p-dropdown
                    [options]="booleanOptions"
                    [(ngModel)]="form.hasDirection"
                    optionLabel="label"
                    optionValue="value"
                    [appendTo]="'body'"
                    [autoDisplayFirst]="false"
                    (onChange)="checkHasDirectionChange($event)"
                  ></p-dropdown>
                  <label for="float-input">Activar:</label>
                </div>
              </div>
            </div>
            <!-- <div class="col p-field">
              <div class="col-12">
                <p-button
                  label="Anadir"
                  icon="pi pi-plus"
                  iconPos="right"
                  styleClass="p-button"
                  [disabled]="!areModifyingLine"
                ></p-button>
              </div>
            </div> -->
          </div>
        </div>
        <app-geocerca-direction
          *ngIf="geoOptions == 'poligon'"
          [areGeofenceAddressesEnabled]="areGeofenceAddressesEnabled"
          [idGeofence]="form.id"
          [geofenceDirectionActivated]="form.hasDirection"
          class="d-flex flex-column gap-5"
          #geoDirections
        ></app-geocerca-direction>
      </div>
    </div>
    <div>
      <div
        *ngIf="geoOptions == 'poligon' || geoOptions == 'line'"
        class="row justify-content-center panel-izq-container-footer mt-4"
      >
        <div class="col-6">
          <button
            type="button"
            class="btn-gl-cancel btn-dark-hover w-100"
            ui-sref="alertas-accesorios"
            (click)="clickCancelPol(form.id)"
          >
            Cancelar
          </button>
        </div>
        <div class="col-6">
          <button
            type="submit"
            class="btn-gl-save btn-dark-hover w-100"
            (click)="clickSavePol(form.id)"
          >
            Guardar
          </button>
        </div>
      </div>
      <div
        *ngIf="geoOptions == 'circ'"
        class="row justify-content-center panel-izq-container-footer"
      >
        <div class="col-6">
          <button
            type="button"
            class="btn-gl-cancel btn-dark-hover w-100"
            ui-sref="alertas-accesorios"
            (click)="clickCancelCir(form.id)"
          >
            Cancelar
          </button>
        </div>
        <div class="col-6">
          <button
            type="submit"
            class="btn-gl-save btn-dark-hover w-100"
            (click)="clickSaveCir(form.id)"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
    <!-- ============================ -->
    <div
      *ngIf="false"
      class="gl-middleline gl-permanent-middleline gl-row-underline"
    >
      <div class="row">
        <div class="col p-field">
          <div class="col-12">
            <label>COLOR DE GEOCERCA:</label>
          </div>
          <div class="row">
            <div class="col-12 d-flex">
              <input
                *ngIf="geoOptions == 'poligon' || geoOptions == 'line'"
                type="color"
                class="form-control form-control-color form-control-color-gl flex-grow-1"
                (change)="changeGeoColor(form.id)"
                [(ngModel)]="form.color"
                id="color_picker"
                title="Elige un color"
              />
              <input
                *ngIf="geoOptions == 'circ'"
                type="color"
                class="form-control form-control-color form-control-color-gl flex-grow-1"
                (change)="changeGeoColorCir(form.id)"
                [(ngModel)]="form.color"
                id="color_picker"
                title="Elige un color"
              />
              <label
                role="button"
                class="p-dropdown-trigger flex-none d-flex align-items-center custom-chevron"
                for="color_picker"
              >
                <span
                  class="p-dropdown-trigger-icon pi pi-chevron-down"
                  style="color: #6c757d"
                ></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col p-field">
          <div class="col-12">
            <label>TAMAÑO DE TEXTO:</label>
          </div>
          <div class="row">
            <div class="col-12">
              <p-dropdown
                [options]="fontSizeOptions"
                [(ngModel)]="form.tag_name_font_size"
                optionLabel="label"
                optionValue="value"
                [appendTo]="'body'"
                [ngModelOptions]="{ standalone: true }"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="false"
      class="gl-middleline gl-permanent-middleline gl-row-underline"
    >
      <div class="row">
        <div class="col p-field">
          <div class="col-12">
            <label>COLOR DE TEXTO:</label>
          </div>
          <div class="row">
            <div class="col-12 d-flex">
              <input
                type="color"
                class="form-control form-control-color form-control-color-gl flex-grow-1"
                (change)="changeGeoColor(form.id)"
                [(ngModel)]="form.tag_name_color"
                id="text_color_picker"
                title="Elige un color"
              />
              <label
                role="button"
                class="p-dropdown-trigger flex-none d-flex align-items-center custom-chevron"
                for="text_color_picker"
              >
                <span
                  class="p-dropdown-trigger-icon pi pi-chevron-down"
                  style="color: #6c757d"
                ></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col p-field">
          <div class="col-12">
            <label></label>
          </div>
          <div class="row">
            <div class="col-12 d-flex"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="false" id="optional">
      <div class="gl-row-underline">
        <div class="col-12">
          <div class="p-field">
            <label class="" id="operation_label">OPERACIONES:</label>
            <p-dropdown
              [options]="operations"
              [(ngModel)]="selectedOperation"
              [showClear]="true"
              optionLabel="nameoperation"
              optionValue="idoperation"
              (onChange)="onChangeOperation()"
              [placeholder]="placeholderOperation"
              [disabled]="disabledOperation"
              [ngModelOptions]="{ standalone: true }"
            >
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="gl-row-underline">
        <div class="row">
          <div class="col-10">
            <div class="p-field">
              <label for="" id="operation_label">ETIQUETAS:</label>
              <p-multiSelect
                class="mb-4"
                [filter]="true"
                [options]="geofencesService.listTag"
                [(ngModel)]="selectedTag"
                filterBy="var_name"
                [filterValue]="filterTag"
                (onFilter)="updateFilter($event)"
                placeholder="Seleccionar etiquetas"
                (onChange)="onSelecTags()"
                [ngModelOptions]="{ standalone: true }"
                optionLabel="var_name"
                [optionValue]="'id'"
                display="chip"
              >
              </p-multiSelect>
            </div>
          </div>
          <div class="col-2">
            <div class="d-flex justify-content-center align-items-center">
              <button
                (click)="addNewTag()"
                type="button"
                class="flex-none center-icon neutral-icon-color btn-circle"
                title="Agregar Etiqueta"
                data-bs-toggle="tooltip"
                data-bs-placement="auto"
                onclick="this.blur();"
              >
                <i
                  class="fa fa-plus-circle fa-2x icon-gl-blue"
                  data-placement="bottom"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="false" id="advance">
      <div class="gl-row-underline">
        <div class="col-12">
          <label>LÍMITE DE VELOCIDAD:</label>
        </div>
        <div class="col-12 d-flex">
          <p-inputNumber
            class="flex-grow-1"
            [(ngModel)]="form.limite_velocidad"
            suffix=" Km/h"
            [disabled]="!form.checkVelocidad"
            [ngModelOptions]="{ standalone: true }"
          ></p-inputNumber>
          <span class="flex-none center-icon neutral-icon-color">
            <i
              class="fa fa-question-circle fa-lg"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Establece un límite de velocidad estándar para todas las unidades que transiten dentro de la geocerca."
            ></i>
          </span>
        </div>
      </div>
      <div *ngIf="false" class="gl-row-underline">
        <div class="col-12">
          <label>TOLERABLE:</label>
        </div>
        <div class="col-12 d-flex">
          <p-inputNumber
            class="flex-grow-1"
            [(ngModel)]="form.limite_tolerable"
            suffix=" Km/h"
            [disabled]="!form.checkVelocidad"
            [ngModelOptions]="{ standalone: true }"
          ></p-inputNumber>
          <span class="flex-none center-icon neutral-icon-color">
            <i
              class="fa fa-question-circle fa-lg"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Establece un exceso de velocidad tolerable al sobrepasar el límite establecido en el presente campo. Termina hasta llegar al siguiente límite de velocidad."
            ></i>
          </span>
        </div>
      </div>
      <div *ngIf="false" class="gl-row-underline">
        <div class="col-12">
          <label>GRAVE:</label>
        </div>
        <div class="col-12 d-flex">
          <p-inputNumber
            class="flex-grow-1"
            [(ngModel)]="form.limite_grave"
            suffix=" Km/h"
            [disabled]="!form.checkVelocidad"
            [ngModelOptions]="{ standalone: true }"
          ></p-inputNumber>
          <span class="flex-none center-icon neutral-icon-color">
            <i
              class="fa fa-question-circle fa-lg"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Establece un exceso de velocidad grave al sobrepasar el límite establecido en el presente campo. Termina hasta llegar al siguiente límite de velocidad."
            ></i>
          </span>
        </div>
      </div>
      <div *ngIf="false" class="gl-row-underline">
        <div class="col-12">
          <label>MUY GRAVE:</label>
        </div>
        <div class="col-12 d-flex">
          <p-inputNumber
            class="flex-grow-1"
            [(ngModel)]="form.limite_muy_grave"
            suffix=" Km/h"
            [disabled]="!form.checkVelocidad"
            [ngModelOptions]="{ standalone: true }"
          ></p-inputNumber>
          <span class="flex-none center-icon neutral-icon-color">
            <i
              class="fa fa-question-circle fa-lg"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Establece un exceso de velocidad muy grave al sobrepasar el límite establecido en el presente campo. No posee un siguiente límite de velocidad."
            ></i>
          </span>
        </div>
      </div>

      <!-- <div class="gl-middleline gl-permanent-middleline gl-row-underline">
        <div class="row">
          <div class="col p-field">
            <div class="col-12">
              <label>DIRECCION:</label>
            </div>
            <div class="row">
              <div [class]="areGeofenceAddressesEnabled?'col-9':'col-12'">
                <p-dropdown [options]="booleanOptions" [(ngModel)]="areGeofenceAddressesEnabled" optionLabel="label"
                  optionValue="value" [appendTo]="'body'" [ngModelOptions]="{standalone: true}"></p-dropdown>
              </div>
              <div *ngIf="areGeofenceAddressesEnabled" class="col-3">
                <button pButton type="button" class="p-button-rounded p-button-sm" icon="pi pi-plus"></button>
              </div>
            </div>
          </div>
          <div class="col p-field">
            <div class="col-12">
              <label>DIRECCOINES:</label>
            </div>
            <div class="row">
              <div class="col-12">
                <p-inputNumber [ngModel]="countDirectionOfGeofence()" suffix=" direcciones" [disabled]="true"></p-inputNumber>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="" *ngIf="geoOptions == 'poligon'">
        <app-geocerca-direction
          [areGeofenceAddressesEnabled]="areGeofenceAddressesEnabled"
          [idGeofence]="form.id"
        ></app-geocerca-direction>
      </div>
    </div>
  </div>
</div>
