import {
  Component,
  OnInit,
  OnDestroy,
  ComponentRef,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { DialogModule } from 'primeng-lts/dialog';
import { ItemHistorial } from 'src/app/historial/models/vehicle';

import * as moment from 'moment';
import * as L from 'leaflet';
import 'leaflet-polylinedecorator';

import { VehicleService } from '../../../vehicles/services/vehicle.service';

import { MapServicesService } from '../../../map/services/map-services.service';
import { EventService } from 'src/app/events/services/event.service';
import { UserDataService } from 'src/app/profile-config/services/user-data.service';

import { HistorialService } from '../../services/historial.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { SliderMultimediaComponent } from 'src/app/shared/components/slider-multimedia/slider-multimedia.component';
import { getIconUrlHistory } from 'src/app/events/helpers/event-helper';
import { WaitTimeService } from 'src/app/events/services/wait-time.service';

// import { ColDef } from 'ag-grid-community';
// import { threadId } from 'worker_threads';

declare var $: any;

// interface JQueryStatic {
//   plot: any;
// }

@Component({
  selector: 'app-panel-historial',
  templateUrl: './panel-historial.component.html',
  styleUrls: ['./panel-historial.component.scss'],
})
export class PanelHistorialComponent implements OnInit, OnDestroy {
  isNaN: Function = Number.isNaN;

  // selectedCar: string='';
  // filtrarPor: string='1';
  //selectedRango: string='1';
  // fechaInicio:string='2020-01-02';
  // horaInicio:string='00';
  // minutoInicio:string='00';
  // fechaFinal:string='2020-01-01';
  // horaFin:string='00';
  // minutoFin:string='00';

  //duracionParada:string='60';
  // colorHistorial:string='#FF0000';

  // chckParada:boolean=true;
  // chckTrama:boolean=false;
  // chckGrafico:boolean=false;
  // chckEvento:boolean=false;

  // conHistorial = false;
  showEventos = false;
  nombreUnidad = '';
  imei = '';

  form: any = {};

  //colorArrayIndex = 0;
  colorArray = [
    '#3A6598', '#EFA252', '#029471', '#DD581E', '#A22D62', '#FECE5F',
    '#27758B', '#D8695B', '#447711', '#C82859', '#5D069C',
    '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];


  availableOptionsTempoParada = [
    { id: '30', name: ' a 30 seg' },
    { id: '60', name: ' a 1 min' },
    { id: '120', name: ' a 2 min' },
    { id: '300', name: ' a 5 min' },
    { id: '600', name: ' a 10 min' },
    { id: '1200', name: ' a 20 min' },
    { id: '1800', name: ' a 30 min' },
    { id: '3600', name: ' a 1 h' },
    { id: '7200', name: ' a 2 h' },
    { id: '18000', name: ' a 5 h' },
  ];

  availableOptionsDay = [
    // {id : '0' , name: 'select'},
    { id: '1', name: 'Hoy' },
    { id: '2', name: 'Ayer' },
    { id: '3', name: 'hace 2 dias' },
    { id: '4', name: 'hace 3 dias' },
    { id: '5', name: 'Esta semana' },
    { id: '6', name: 'Semana pasada' },
    { id: '7', name: 'Este mes' },
    { id: '8', name: 'Mes pasado' },
  ];

  columnDefs = [{ field: 'make' }, { field: 'model' }, { field: 'price' }];

  rowData = [
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxter', price: 72000 },
  ];

  booleanOptions = [
    { label: 'Sí', value: true },
    { label: 'No', value: false },
  ];

  //mostrarAllEvents = false;

  selectedEvents: any = [];
  chkAllEvents: boolean = false;
  chkMostrarRuta: boolean = false;
  typeEvents: any = [];
  // cities: any = [];
  // selectedCars:any = [];
  // selectedCities2:any = [];

  eventList = [
    // {
    //   label: 'Evento GPS',
    //   items: [
    //     // { name: 'Batería baja', value: false },
    //     { name: 'Batería Desconectada', value: false },
    //     { name: 'Aceleración Brusca', value: false },
    //     { name: 'Frenada Brusca', value: false },
    //     // { name: 'Bloqueo de Transmisión', value: false },
    //     { name: 'SOS', value: false },
    //     // { name: 'Remolque', value: false },
    //     // { name: 'Parada', value: false },
    //     { name: 'Motor Apagado', value: false },
    //     { name: 'Motor Encendido', value: false },

    //   ]
    // },
    {
      label: 'Evento Plataforma',
      items: [
        { name: 'Zona de Entrada', value: 'zona-de-entrada' },
        { name: 'Zona de Salida', value: 'zona-de-salida' },
        // { name: 'Tiempo de Estadía en Zona', value: false },
        // { name: 'Parad en Zona no Autorizada', value: false },
        // { name: 'Vehículo en Movimiento Sin Programación', value: false },
        // { name: 'Infracción', value: false },
        { name: 'Exceso de Velocidad', value: 'exceso-velocidad' },
        // { name: 'Anticolisión frontal', value: false },
        // { name: 'Colisión con Peatones', value: false },
        // { name: 'No Rostro', value: false },
        // { name: 'Fatiga Extrema', value: false },
        // { name: 'Desvío de carril hacia la izquierda', value: false },
        // { name: 'Desvío de carril hacia la derecha', value: false },
        // { name: 'Bloqueo de visión del mobileye', value: false },
      ],
    },
    // {
    //   label: 'Evento Seguridad Vehicular',
    //   items: [
    //     { name: 'Ausencia de Rostro', value: false },// { name: 'No Rostro', value: false },
    //     { name: 'Fatiga Extrema', value: false },
    //     { name: 'Posible Fatiga', value: false },
    //     { name: 'Distracción', value: false },
    //     { name: 'Detección de Alcohol', value: false },//  { name: 'Alcoholemia', value: false },
    //     { name: 'Anticolisión Frontal', value: false },
    //     { name: 'Colisión con Peatones', value: false },
    //     { name: 'Desvío de Carril Hacia la Izquierda', value: false },
    //     { name: 'Desvío de Carril Hacia la Derecha', value: false },
    //     { name: 'Bloqueo de Visión del Mobileye', value: false },
    //   ]
    // }
    {
      label: 'Eventos 360º',
      items: [
        {
          name: 'Conductor Adormitado 360°',
          value: 'conductor-adormitado-360',
        },
        {
          name: 'Conductor Somnoliento 360',
          value: 'conductor-somnoliento-360',
        },
        {
          name: 'Distracción Detectada 360°',
          value: 'conductor-distraido-360',
        },
        {
          name: 'Cinturón no Detectado 360°',
          value: 'cinturon-desabrochado-360',
        },
        { name: 'Celular Detectado 360°', value: 'uso-de-celular-360' },
        { name: 'Cigarro Detectado 360°', value: 'conductor-fumando-360' },
        {
          name: 'Detección de Manipulación 360°',
          value: 'deteccion-manipulacion-360',
        },
        { name: 'Error de Cámara 360°', value: 'error-de-camara-360' },
      ],
    },
    // ,
    // {
    //   label: 'Otros',
    //   items: [
    //     { name: 'Exceso de Velocidad', value: false },
    //   ]
    // }
  ];

  // campaignOne: FormGroup;
  // campaignTwo: FormGroup;

  cars: any = [];

  // vehicles = [];

  transfers = new Array();

  // message:string='';

  public expirationDate = false;

  // pngFechaIni!: Date;
  // pngHoraIni!: Date;
  // pngFechaFin!: Date;
  // pngHoraFin!: Date;

  // pngHoraIni2!: Date;
  // pngHoraFin2!: Date;

  pngColorRuta: string = '#ff0000';
  dialogDisplay: boolean = false;
  loadingHistorial: boolean = false;
  // pngHoraIni2: number = 0;
  // pngMinIni: number = 0;
  // pngHoraFin2: number = 0;
  // pngMinFin: number = 0;

  pngChkParadas: boolean = false;
  pngChkTrama: boolean = false;
  pngVelFecha: boolean = true;
  pngGrafico: boolean = false;

  soloParaMi: boolean = false;

  isHistorialTableLoaded: boolean = false;

  constructor(
    public mapService: MapServicesService,
    public historialService: HistorialService,
    private UserDataService: UserDataService,
    private VehicleService: VehicleService,
    private spinner: NgxSpinnerService,
    private EventService: EventService,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private waitTimeServices: WaitTimeService
  ) {
    // this.historialService.currentMessage.subscribe(message => this.message = message);
    // this.historialService.currentMessage.subscribe( () => {//console.log('com 1 -> gaaaaaaaaa');
    // });
  }

  // constructor() {
  //     // // const today = new Date();
  //     // // const month = today.getMonth();
  //     // // const year = today.getFullYear();

  //     // this.campaignOne = new FormGroup({
  //     // //   // start: new FormControl(new Date(year, month, 13)),
  //     // //   // end: new FormControl(new Date(year, month, 16))
  //     // });

  //     // this.campaignTwo = new FormGroup({
  //     // //   // start: new FormControl(new Date(year, month, 15)),
  //     // //   // end: new FormControl(new Date(year, month, 19))
  //     // });
  // }

  ngOnInit(): void {
    // this.UserDataService.getUserData();

    // this.cities = [
    //   {name: 'New York', code: 'NY'},
    //   {name: 'Rome', code: 'RM'},
    //   {name: 'London', code: 'LDN'},
    //   {name: 'Istanbul', code: 'IST'},
    //   {name: 'Paris', code: 'PRS'}
    //  ];

    if (!this.UserDataService.userDataInitialized) {
      console.log(
        '(Navbar) User Data no está listo. Subscribiendo para obtener data...'
      );
      this.UserDataService.userDataCompleted.subscribe({
        next: (result: boolean) => {
          if (result) {
            this.UserDataService.getUserData();
          }
        },
        error: (errMsg: any) => {
          console.log('(Navbar) Error al obtener userData: ', errMsg);
        },
      });
    }
    console.log('=================================');
    console.log(this.UserDataService);
    console.log(this.UserDataService.typeVehicles);
    console.log(this.UserDataService.typeVehiclesUserData);
    console.log(this.UserDataService.userName);

    if (this.UserDataService.userName == 'percy') {
      this.soloParaMi = true;
    }

    if (!this.VehicleService.statusDataVehicle) {
      this.spinner.show('loadingHistorialForm');
      this.VehicleService.dataCompleted.subscribe((vehicles) => {
        this.getCars(vehicles);
        this.spinner.hide('loadingHistorialForm');
      });
    } else {
      let vehicles = this.VehicleService.getVehiclesData();
      this.getCars(vehicles);
    }

    //CHANGES FOR HISTORIAL *********************
    // SE VA CAMBIAR TODO EL API DEL SERVICIO PARA JALAR DE USUARIOS DETALLES

    // console.log(this.eventList,map);
    if (this.EventService.eventsUserLoaded == false) {
      this.spinner.show('loadingHistorialForm');
      this.EventService.getEventsForUser().subscribe(
        async (data) => {
          // Aquí puedes trabajar con los datos obtenidos
          console.log('EVENTOS DEL USUARIO OBTENIDOS:', data);
          // Realiza cualquier acción con los datos recibidos
          if (data.success) {
            this.eventList = [];
            this.eventList = this.EventService.createEventList(data.data);
          } else {
            this.eventList = [];
            console.log('EL USUARIO NO TIENE EVENTOS');
          }
          this.spinner.hide('loadingHistorialForm');
        },
        (error) => {
          // Maneja los errores si ocurre alguno durante la solicitud
          console.error('Error al obtener los eventos:', error);
        }
      );
    } else {
      this.spinner.show('loadingHistorialForm');
      console.log(
        'TEST->',
        this.EventService.eventsGroupedList,
        this.eventList
      );
      this.eventList = this.EventService.eventsGroupedList;
      this.spinner.hide('loadingHistorialForm');
    }

    // this.historialService.initializeForm();

    // const hoy = Date.now();
    // this.pngFechaIni = new Date(moment(hoy).format('YYYY-MM-DDTHH:mm'));
    // this.pngFechaFin = this.pngFechaIni;
    // this.pngHoraIni = new Date('2018-03-12T00:00');
    // this.pngHoraIni2 = new Date('2018-03-12T00:00');
    // this.pngHoraFin2 = new Date('2018-03-12T00:00');

    // this.pngHoraFin = new Date('2018-03-14T00:00');
    this.form.selectedRango = '0';
    //this.form.duracionParada = '60';
    // $( "#datepicker" ).datepicker();
    // $('#datepicker').datetimepicker({
    //   language: 'pt-es'
    // });

    // this.historialService.currentMessage.subscribe(message => this.message = message)

    //   cars = [
    //     { nombre: 'ABC-676',imei:'111111111' },
    //     { nombre: 'DER-435',imei:'2222222222' },
    //     { nombre: 'DRW-345',imei:'3333333333' },
    //     { nombre: 'DRF-345',imei:'444444444' },
    // ];

    // //console.log(vehicles);

    if (this.historialService.inicio) {
      this.historialService.initialize();
      this.historialService.inicio = false;
    }

    // //console.log("se inicia xXX");
    // //console.log(this.historialService);
    //console.log('Con historial : '+this.historialService.conHistorial);

    // $( "#fechaInicial" ).datepicker({
    //   dateFormat: 'yy-mm-dd',
    // });
    // $( "#fechaFinal" ).datepicker({
    //   // appendText:'dd-mm-yy',
    //   dateFormat: 'yy-mm-dd'
    // });

    // this.fechaInicio = moment().startOf('day').format("YYYY-MM-DD");
    // this.fechaFinal = moment().startOf('day').add(1, 'days').format("YYYY-MM-DD");

    // const iconMarker = L.icon({
    //   iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png', //'./marker-icon.png',
    //   iconSize: [30, 50],
    //   iconAnchor: [15, 50]
    // });

    // const tempMarker = L.marker([0, 0], {icon: iconMarker}).addTo(this.mapService.map);
    // //console.log(this.mapService.nombreMap);
    // //console.log("XXXXXXX--------");

    // //console.log(this.historialService.tramasHistorial);

    // setTimeout(() => {
    //   const tempMarker = L.marker([0, 0], {icon: iconMarker}).addTo(this.mapService.map);
    //   //console.log(this.mapService.nombreMap);

    // }, 5000)
    // let dH = this.historialService.tramasHistorial; // Data Historial

    //this.conHistorial = this.historialService.conHistorial;

    this.form = this.historialService.dataFormulario;

    if (this.historialService.selectedEvents.length > 0) {
      this.selectedEvents = this.historialService.selectedEvents;
    }
    // console.log(this.selectedEvents);
    // console.log('=== formulario ===');
    // console.log(this.form);

    // //console.log(this.form.selectedCarC );

    // if (!(this.form.selectedCarC == null)) {
    //   this.nombreUnidad = this.cars.filter(
    //     (item: any) => item.imei == this.form.selectedCar
    //   )[0].nombre;
    //   this.imei = this.form.selectedCar;
    //   //console.log("unidad diferente de null");
    // } else {
    //   //console.log("unidad es igual a null");
    // }

    // if (this.conHistorial) {
    //   this.mostrar_tabla(dH, dH[0].index_historial);
    // }

    // (function($) {
    //   $.plot($("#placeholder"), [ [[0, 0], [1, 1]] ], { yaxis: { max: 1 } });

    //   // $['plot']($("#placeholder"), [ [[0, 0], [1, 1]] ], { yaxis: { max: 1 } });
    // })(jQuery);

    // $.plot($("#placeholder"), [ [[0, 0], [1, 1]] ], { yaxis: { max: 1 } });

    // $(function(){
    //   $.plot($("#placeholder"), [ [[0, 0], [1, 1]] ], { yaxis: { max: 1 } });

    // });

    this.EventService.pinPopupStream.subscribe((event) => {
      this.clearMultimedia(event);
      this.addMultimediaComponent(event);
    });
  }

  ngOnDestroy() {
    console.log('=========== Destroy 11111111111 =============');
    console.log(this.form);

    ////console.log('me destruire gaaa');

    this.clickEliminarHistorial();

    // this.historialService.conHistorial = this.conHistorial;

    // console.log('=========== Destroy =============');

    // console.log(this.form);

    this.historialService.dataFormulario = this.form;
    // console.log("eventos ======= this.selectedEvents");
    // console.log(this.selectedEvents);

    this.historialService.selectedEvents = this.selectedEvents;

    this.VehicleService.dataCompleted.unsubscribe;

    // this.historialService.icoGplay = this.icoGplay;
    // this.historialService.icoGclick = this.icoGclick;

    if (this.historialService.icoGplay == null) {
    } else {
      this.mapService.map.removeLayer(this.historialService.icoGplay);
    }

    if (this.historialService.icoGclick == null) {
    } else {
      this.mapService.map.removeLayer(this.historialService.icoGclick);
    }
  }

  getCars(vehicles: any) {
    console.log('======================== icono == getCars');
    console.log(vehicles);

    for (let i = 0; i < vehicles.length; i++) {
      let gaa = {
        nombre: vehicles[i].name,
        imei: vehicles[i].IMEI,
        icon: vehicles[i].icon,
        nameoperation: vehicles[i].nameoperation,
      };
      this.cars.push(gaa);
    }
  }

  // function changeColorHistorial() {
  //   //console.log("********  ACTUALIZANDO VALORES  *******");

  //   //Actualizar parametros iniciales
  //   historialDataFactory.editForm(vm.form);

  //   //Si esta mostrando una ruta, q la cambie de color
  //   if (vm.lineaHis) {
  //       // //console.log("********  CON HISTORIAL  *******");
  //       // //console.log(vm.lineaHis);
  //       vm.lineaHis.setStyle({opacity: 1, color: vm.form.colorHistorial});
  //   }

  //   //console.log("changeColorHistorial --- cambio color : "+ vm.form.colorHistorial);

  //   var data = {color:vm.form.colorHistorial};

  //   //**************************************************************************
  //   historialServices['editColorHistorial'](data).then(response => {
  //     //console.log('funcion editar color historial en la base de datos');
  //   }).catch(err => {
  //     //console.log('ERROR',err);
  //   });

  // }

  changeColorHistorial() {

    console.log('---- changeColorHistorial');

    // console.log(this.form.selectedCars);
    // console.log(this.selectedCities2);

    // console.log(this.form.selectedCar);


    // if (this.conHistorial) {
    //   let newColor = this.form.colorHistorial;
    //   let dH =  this.historialService.tramasHistorial; // Data Historial
    //   dH[0].rutaH2.setStyle({opacity: 1, color: newColor });
    // }
  }

  changeShowingEventos() {
    // console.log("Ç=======changeShowingEventos");

    // console.log(this.form.chckEvento);
    // this.showEventos = this.form.chckEvento;
    // if (this.form.chckEvento) {

    //   if (this.conHistorial) {
    //     var dH =  this.historialService.tramasHistorial; // Data Historial
    //     var iH  = dH[0].index_historial; //indices de paradas y movimientos
    //     this.mostrar_tabla(dH,iH);

    //   };
    // }

    //console.log(this.form.chckEvento);

    //this.chkAllEvents = this.selectedEvents.length == [...this.eventList[0].items, ...this.eventList[1].items, ...this.eventList[2].items, ...this.eventList[3].items].length;
    // this.chkAllEvents = this.selectedEvents.length == [...this.eventList[0].items, ...this.eventList[1].items, ...this.eventList[2].items].length;
    //this.chkAllEvents = this.selectedEvents.length == [...this.eventList[0].items, ...this.eventList[1].items].length;

    // console.log(this.chkAllEvents);
    // console.log(this.selectedEvents);

    // if (!this.form.chckEvento) {
    //   this.showEventos = false;
    // }

    // if (this.conHistorial) {
    //   var dH =  this.historialService.tramasHistorial; // Data Historial
    //   var iH  = dH[0].index_historial; //indices de paradas y movimientos
    //   this.mostrar_tabla(dH,iH);
    // }

    this.changeShowingParadasHistorial();
  }

  changeRangoFechas() {
    let fecha_desde: any = '';
    let fecha_hasta: any = '';
    switch (this.form.selectedRango) {
      case '0':
        fecha_desde = moment().startOf('day'); //.format("YYYY-MM-DD");
        fecha_hasta = moment().startOf('day'); //.format("YYYY-MM-DD");
        break;
      case '1': //hoy
        fecha_desde = moment().startOf('day'); //.format("YYYY-MM-DD");
        fecha_hasta = moment().add(1, 'days'); //.startOf('day').format("YYYY-MM-DD");
        break;
      case '2':
        fecha_desde = moment().add(-1, 'days').startOf('day'); //.format("YYYY-MM-DD");
        fecha_hasta = moment().startOf('day'); //.format("YYYY-MM-DD");
        break;
      case '3':
        fecha_desde = moment().add(-2, 'days').startOf('day'); //.format("YYYY-MM-DD");
        fecha_hasta = moment().add(-1, 'days').startOf('day'); //.format("YYYY-MM-DD");
        break;
      case '4':
        fecha_desde = moment().add(-3, 'days').startOf('day'); //.format("YYYY-MM-DD");
        fecha_hasta = moment().add(-2, 'days').startOf('day'); //.format("YYYY-MM-DD");
        break;
      case '5':
        fecha_desde = moment().startOf('isoWeek'); //.format("YYYY-MM-DD");
        fecha_hasta = moment().startOf('isoWeek').add(7, 'days'); //.format("YYYY-MM-DD");
        break;
      case '6':
        fecha_desde = moment().startOf('isoWeek').add(-7, 'days'); //.format("YYYY-MM-DD");;
        fecha_hasta = moment().startOf('isoWeek'); //.format("YYYY-MM-DD");
        break;
      case '7':
        fecha_desde = moment().startOf('month'); //.format("YYYY-MM-DD");
        fecha_hasta = moment().startOf('month').add(1, 'month'); //.format("YYYY-MM-DD");
        break;
      case '8':
        fecha_desde = moment().startOf('month').add(-1, 'month'); //.format("YYYY-MM-DD");;
        fecha_hasta = moment().startOf('month'); //.format("YYYY-MM-DD");;;
        break;
    }

    /* this.form.fecha_desde = {
        year: parseInt(fecha_desde.format("YYYY")),
        month: parseInt(fecha_desde.format("MM")),
        day: parseInt(fecha_desde.format("DD")),
      };

      this.form.fecha_hasta = {
        year: parseInt(fecha_hasta.format("YYYY")),
        month: parseInt(fecha_hasta.format("MM")),
        day: parseInt(fecha_hasta.format("DD")),
      }; */

    this.form.pngFechaIni = fecha_desde.toDate();
    this.form.pngFechaFin = fecha_hasta.toDate();

    console.log(this.form.pngFechaIni);
    console.log(this.form.pngFechaFin);

    /* this.form.hora_desde  = '00';//{id: '00', name: '00'};
      this.form.min_desde   = '00';//{id: '00', name: '00'};
      this.form.hora_hasta  = '00';//{id: '00', name: '00'};
      this.form.min_hasta   = '00';//{id: '00', name: '00'}; */

    // this.pngHoraIni = new Date('2018-03-12T00:00');
    // this.pngHoraFin = new Date('2018-03-12T00:00');

    // this.form.pngHoraIni2 = 0;
    // this.form.pngMinIni = 0;
    // this.form.pngHoraFin2 = 0;
    // this.form.pngMinFin = 0;

    this.form.pngHoraIni2 = new Date('2018-03-12T00:00');
    this.form.pngHoraFin2 = new Date('2018-03-12T00:00');
  }

  // format_mes_dia   1 -> 01
  fStrMD(str: string) {
    let str2 = ('00' + str).substring(str.length);
    return str2;
  }

  onChkMostrarRuta(key: any) {
    console.log('-----------------onChkMostrarRuta');
    console.log();

    this.historialService.arrayRecorridos[key];

    console.log(this.historialService.arrayRecorridos[key]);
    console.log(this.historialService.arrayRecorridos);

    var dH = this.historialService.arrayRecorridos[key].recorrido;
    var iH = dH[0].index_historial; //indices de paradas y movimientos

    // if (this.conHistorial) {
    //   this.mostrar_tabla(dH, dH[0].index_historial);
    // }

    if (this.historialService.arrayRecorridos[key].mostrarRuta) {
      for (let i = 0; i < iH.length; i++) {
        if (dH[iH[i]].marker == 'PARADA') {
          if (this.form.chckParada) {
            //this.transfers.push({icono:"assets/images/stop.svg", tooltip: "Parada",trama:dH[iH[i]],icono_width:"11px",icono_height:"13px",dt_tracker:dH[iH[i]].dt_tracker});
            dH[iH[i]].layer.addTo(this.mapService.map);
          }
        }
        // else {
        //   this.transfers.push({icono:"assets/images/drive.png", tooltip: "Movimiento",trama:dH[iH[i]],icono_width:"13px",icono_height:"13px",dt_tracker:dH[iH[i]].dt_tracker});
        // }
      }

      if (this.form.chckTrama) {
        for (let i = 0; i < dH.length; i++) {
          dH[i]._trama.addTo(this.mapService.map);
        }
      }
      // else {
      //   for (let i = 0; i < dH.length; i++) {
      //     this.mapService.map.removeLayer(dH[i]._trama);
      //   }
      // }

      if (this.form.chckTramaFechaVelocidad) {
        for (let i = 0; i < dH.length; i++) {
          dH[i]._trama_fecha_velocidad.addTo(this.mapService.map);
        }
      }

      //eventos
      console.log('=======================================');
      // console.log(this.historialService.arrayRecorridos);
      // console.log(idx);
      // console.log(this.historialService.arrayRecorridos[idx]);

      var EventosAll = this.historialService.arrayRecorridos[key].eventos;

      for (let index = 0; index < EventosAll.length; index++) {
        EventosAll[index].layer.addTo(this.mapService.map);
      }

      dH[0].layer0.addTo(this.mapService.map);
      dH[dH.length - 1].layerN.addTo(this.mapService.map);

      dH[0].rutaH2.addTo(this.mapService.map); // = this.get_historial_line( dH[0].rutaH , color).addTo(this.mapService.map); //Linea del historial
      dH[0].ruta_sub.addTo(this.mapService.map); // = this.get_historial_line( [] , color_sub).addTo(this.mapService.map); //Sub linea del historial
      dH[0].rutaH2_trace.addTo(this.mapService.map);
    } else {
      for (let i = 0; i < dH.length; i++) {
        if (dH[i].layer != null) {
          this.mapService.map.removeLayer(dH[i].layer);
        }
        // if(dH[i]._trama_fecha_velocidad != null){
        //     this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
        // }
        // this.mapService.map.removeLayer(dH[i]._trama);
      }

      // if (this.form.chckTrama) {
      //   for (let i = 0; i < dH.length; i++) {
      //     dH[i]._trama.addTo(this.mapService.map);
      //   }
      // }
      // else {
      for (let i = 0; i < dH.length; i++) {
        this.mapService.map.removeLayer(dH[i]._trama);
        this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
      }
      // }

      this.mapService.map.removeLayer(dH[0].layer0);
      this.mapService.map.removeLayer(dH[dH.length - 1].layerN);

      this.mapService.map.removeLayer(dH[0].rutaH2);
      this.mapService.map.removeLayer(dH[0].ruta_sub);
      this.mapService.map.removeLayer(dH[0].rutaH2_trace);
    }

    // for (let index = 0; index < this.EventService.eventsHistorial.length; index++) {
    //   const item = this.EventService.eventsHistorial[index];
    //   this.mapService.map.removeLayer(item.layer);
    // }

    var eH = this.historialService.arrayRecorridos[key].eventos;

    if (this.historialService.arrayRecorridos[key].mostrarRuta) {
      this.mostrar_tabla2(dH, iH, key);
    } else {
      for (let i = 0; i < eH.length; i++) {
        this.mapService.map.removeLayer(eH[i].layer);
      }
    }
  }

  clickEliminar(index: any, key: any) {
    console.log('-----------------clickEliminar');
    console.log(this.historialService.arrayRecorridos.length);

    // this.historialService.arrayRecorridos[index];

    console.log(this.historialService.arrayRecorridos[index]);
    console.log(this.historialService.arrayRecorridos);

    var dH = this.historialService.arrayRecorridos[index].recorrido;
    var iH = dH[0].index_historial; //indices de paradas y movimientos

    for (let i = 0; i < dH.length; i++) {
      if (dH[i].layer != null) {
        this.mapService.map.removeLayer(dH[i].layer);
      }

      this.mapService.map.removeLayer(dH[i]._trama);
      this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
    }

    this.mapService.map.removeLayer(dH[0].layer0);
    this.mapService.map.removeLayer(dH[dH.length - 1].layerN);

    this.mapService.map.removeLayer(dH[0].rutaH2);
    this.mapService.map.removeLayer(dH[0].ruta_sub);
    this.mapService.map.removeLayer(dH[0].rutaH2_trace);

    // Eliminar eventos
    var eH = this.historialService.arrayRecorridos[index].eventos;
    for (let i = 0; i < eH.length; i++) {
      this.mapService.map.removeLayer(eH[i].layer);
    }

    this.historialService.arrayRecorridos =
      this.historialService.arrayRecorridos.filter(function (obj: any) {
        return obj.key !== key; // id=23	name=Somnolencia	slug=somnolencia	type=accessories		 ==> 7.	Quitar los eventos de Somnolencia
      });
  }

  async clickVerGrafico(index: any, key: any) {
    console.log('-----------------clickVerGrafico');
    console.log('-----------index');
    console.log('click ver grafico: ', index);
    console.log('-----------key');
    console.log(key);


    // this.historialService.nombreUnidad = this.nombreUnidad = this.cars.filter(
    //   (item: any) => item.imei == this.form.selectedCar
    // )[0].nombre;
    // this.historialService.icono = this.cars.filter(
    //   (item: any) => item.imei == this.form.selectedCar
    // )[0].icon;
    // this.historialService.nameoperation = this.cars.filter(
    //   (item: any) => item.imei == this.form.selectedCar
    // )[0].nameoperation;

    this.historialService.nombreUnidad = this.historialService.arrayRecorridos[index].nombre;
    this.historialService.icono = this.historialService.arrayRecorridos[index].icono;
    this.historialService.nameoperation = this.historialService.arrayRecorridos[index].nameoperation

    console.log(this.historialService.nombreUnidad+" - "+this.historialService.icono+" - "+this.historialService.nameoperation);
    // this.historialService.modalActive=false;
    // console.log("GAAAAAAA");
    // await new Promise(f => setTimeout(f, 500));

    this.historialService.modalActive = true;

    this.historialService.keyGrafico = key;

    // this.changeShowingGrafico();

    this.historialService.changeMessage('desde com panel-historial');

    // this.historialService.arrayRecorridos[index];

    // console.log(this.historialService.arrayRecorridos[index]);
    // console.log(this.historialService.arrayRecorridos);

    // var dH = this.historialService.arrayRecorridos[index].recorrido;
    // var iH  = dH[0].index_historial; //indices de paradas y movimientos

    // for (let i = 0; i < dH.length; i++) {
    //     if (dH[i].layer != null) {
    //         this.mapService.map.removeLayer(dH[i].layer);
    //     }

    //     this.mapService.map.removeLayer(dH[i]._trama);
    //     this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);

    // }

    // this.mapService.map.removeLayer(dH[0].layer0);
    // this.mapService.map.removeLayer(dH[dH.length-1].layerN);

    // this.mapService.map.removeLayer(dH[0].rutaH2);
    // this.mapService.map.removeLayer(dH[0].ruta_sub);
    // this.mapService.map.removeLayer(dH[0].rutaH2_trace);

    // // Eliminar eventos
    // var eH = this.historialService.arrayRecorridos[index].eventos;
    // for (let i = 0; i < eH.length; i++) {
    //   this.mapService.map.removeLayer(eH[i].layer);
    // }

    // this.historialService.arrayRecorridos = this.historialService.arrayRecorridos.filter(function( obj:any ) {
    //   return obj.key !== key;  // id=23	name=Somnolencia	slug=somnolencia	type=accessories		 ==> 7.	Quitar los eventos de Somnolencia
    // });
  }

  // async clickCargarHistorial_V2() {
  //   this.spinner.show('loadingHistorial');
  //   // this.clickEliminarHistorial();

  //   this.historialService.getHistorial();
  //   // var dH =  this.historialService.tramasHistorial; // Data Historial

  //   console.log('=================================');
  //   console.log(this.UserDataService.typeVehicles);
  //   console.log(this.UserDataService.typeVehiclesUserData);

  //   /* let MDstr = "" + this.form.fecha_desde.month;
  //   let DDstr = "" + this.form.fecha_desde.day;
  //   console.log('Form', MDstr);
  //   console.log('Form', DDstr); */
  //   let MDstr = '' + moment(this.form.pngFechaIni).format('M');
  //   let DDstr = '' + moment(this.form.pngFechaIni).format('D');
  //   // let HMDstr = "" + moment(this.pngHoraIni).format("HH:mm:00");
  //   // let horaDstr = this.form.pngHoraIni2.toString();
  //   // let minDstr = this.form.pngMinIni.toString();

  //   let HMDstr = '' + moment(this.form.pngHoraIni2).format('HH:mm:00');

  //   /* console.log('Date', MDstr);
  //   console.log('Date', DDstr); */

  //   /* let MHstr = "" + this.form.fecha_hasta.month;
  //   let DHstr = "" + this.form.fecha_hasta.day; */
  //   let MHstr = '' + moment(this.form.pngFechaFin).format('M');
  //   let DHstr = '' + moment(this.form.pngFechaFin).format('D');
  //   // let HMHstr = "" + moment(this.pngHoraFin).format("HH:mm:00");
  //   // let horaHstr = this.form.pngHoraFin2.toString();
  //   // let minHstr = this.form.pngMinFin.toString();

  //   let HMHstr = '' + moment(this.form.pngHoraFin2).format('HH:mm:00');

  //   /* var M1 = this.form.fecha_desde.year+'-'+this.fStrMD(MDstr)+'-'+this.fStrMD(DDstr) + 'T' + this.form.hora_desde + ':' + this.form.min_desde + ':00-05:00';
  //   var M2 = this.form.fecha_hasta.year+'-'+this.fStrMD(MHstr)+'-'+this.fStrMD(DHstr) + 'T' + this.form.hora_hasta + ':' + this.form.min_hasta + ':00-05:00'; */
  //   var M1 =
  //     moment(this.form.pngFechaIni).format('YYYY') +
  //     '-' +
  //     this.fStrMD(MDstr) +
  //     '-' +
  //     this.fStrMD(DDstr) +
  //     'T' +
  //     HMDstr +
  //     '-05:00';
  //   var M2 =
  //     moment(this.form.pngFechaFin).format('YYYY') +
  //     '-' +
  //     this.fStrMD(MHstr) +
  //     '-' +
  //     this.fStrMD(DHstr) +
  //     'T' +
  //     HMHstr +
  //     '-05:00';

  //   var M1str =
  //     moment(this.form.pngFechaIni).format('YYYY') +
  //     '-' +
  //     this.fStrMD(MDstr) +
  //     '-' +
  //     this.fStrMD(DDstr) +
  //     ' ' +
  //     HMDstr +
  //     '';
  //   var M2str =
  //     moment(this.form.pngFechaFin).format('YYYY') +
  //     '-' +
  //     this.fStrMD(MHstr) +
  //     '-' +
  //     this.fStrMD(DHstr) +
  //     ' ' +
  //     HMHstr +
  //     '';

  //   // var M1 = moment(this.form.pngFechaIni).format("YYYY")+'-'+this.fStrMD(MDstr)+'-'+this.fStrMD(DDstr) + 'T' + this.fStrMD(horaDstr)+":"+this.fStrMD(minDstr)+':00-05:00';
  //   // var M2 = moment(this.form.pngFechaFin).format("YYYY")+'-'+this.fStrMD(MHstr)+'-'+this.fStrMD(DHstr) + 'T' + this.fStrMD(horaHstr)+":"+this.fStrMD(minHstr)+':00-05:00';

  //   console.log(this.form.pngFechaIni);
  //   // console.log( this.fStrMD(this.form.pngHoraIni2.toString()) );
  //   // console.log( this.fStrMD(this.form.pngMinIni.toString()));
  //   console.log('===========================');
  //   console.log(this.form.pngFechaFin);
  //   // console.log(this.form.pngHoraFin2);
  //   // console.log(this.form.pngMinFin);
  //   console.log(this.form.pngHoraIni2);
  //   console.log(this.form.pngHoraFin2);

  //   // console.log( this.fStrMD(this.pngHoraFin2.toString()) );
  //   // console.log( this.fStrMD(this.pngMinFin.toString()));

  //   this.historialService.nombreUnidad = this.nombreUnidad = this.cars.filter(
  //     (item: any) => item.imei == this.form.selectedCar
  //   )[0].nombre;
  //   this.historialService.icono = this.cars.filter(
  //     (item: any) => item.imei == this.form.selectedCar
  //   )[0].icon;

  //   console.log(this.historialService.icono);

  //   this.historialService.nameoperation = this.cars.filter(
  //     (item: any) => item.imei == this.form.selectedCar
  //   )[0].nameoperation;

  //   var au = this.historialService.arrayRecorridos;
  //   var key = this.nombreUnidad + '_' + M1 + '_' + M2;
  //   var icono = this.historialService.icono;
  //   var nameoperation = this.historialService.nameoperation;
  //   //verificar q no existan keys repetidas :
  //   for (let i = 0; i < au.length; i++) {
  //     console.log(au[i].key + ' - ' + key);

  //     if (au[i].key == key) {
  //       console.log('SE REPITE LA KEY ');
  //       Swal.fire({
  //         title: 'Error',
  //         text: 'Unidad y recorrido ya seleccionado.',
  //         icon: 'error',
  //       });
  //       this.spinner.hide('loadingHistorial');

  //       return;
  //     }
  //   }

  //   var param = {
  //     fecha_desde: M1,
  //     fecha_hasta: M2,
  //     imei: this.form.selectedCar,
  //     user_id: localStorage.getItem('user_id'),

  //     // , duracionParada:vm.form.duracionParada.id,
  //     // conParada:vm.form.verParadasHistorial, nombreUnidad:vm.form.selectedUnidad.value.name,
  //     // colorHistorial:vm.form.colorHistorial, colorSubHistorial:vm.form.colorSubHistorial,
  //     // icono : values[0].icon
  //   };
  //   console.log(param);
  //   // console.log("ID DEL USUARIO");
  //   // console.log(localStorage.getItem('user_id'));

  //   this.historialService.get_tramas_recorrido(param).then((res) => {
  //     this.EventService.ShowAllHistorial(param).then((res1) => {
  //       console.log('=== VERDADERO EVENTOS HISTORIAL');
  //       console.log(res1);
  //       console.log(this.EventService.eventsHistorial);

  //       const duracion = parseInt(this.form.duracionParada);
  //       //let dH = res[0];
  //       var dH = this.historialService.tramasHistorial; // Data Historial

  //       //console.log('HISTORIALLLLLL');
  //       //console.log(res);
  //       // //console.log(res[0]);

  //       //console.log('-----clickCargarHistorial');
  //       //console.log(this.form);
  //       //console.log(dH.length);
  //       //console.log(dH);

  //       // var h1 = new Array();

  //       var index_historial2 = new Array();
  //       // var poly = [];

  //       if (dH.length > 1) {
  //         this.conHistorial = true; // tenemos historial con datod

  //         var d = 0;

  //         var k = [];
  //         let l = new Array();

  //         k.length = 0;
  //         l.length = 0;

  //         var o;

  //         const h = dH.length - 1; //Ultimo indice del array

  //         var minLat = parseFloat(dH[0].lat);
  //         var maxLat = parseFloat(dH[0].lat);
  //         var minLng = parseFloat(dH[0].lng);
  //         var maxLng = parseFloat(dH[0].lng);

  //         dH[0].rutaH = [];

  //         dH[0].primero = h; // guardar el numero de elementos en la primera trama.
  //         dH[0].nombre = this.nombreUnidad; //"Nombre Unidad";//param.nombreUnidad;
  //         (dH[0].imei = this.imei = this.form.selectedCar), (dH[h].ultimo = h); // guardar el numero de elementos en la ultima trama.
  //         dH[h].nombre = this.nombreUnidad; //"Nombre Unidad";//param.nombreUnidad;

  //         for (let i = 0; i < dH.length; i++) {
  //           if (i != 0) {
  //             dH[i].distancia =
  //               this.calcularDistanciaEntreDosCoordenadas(
  //                 dH[i - 1].lat,
  //                 dH[i - 1].lng,
  //                 dH[i].lat,
  //                 dH[i].lng
  //               ) * 1000;
  //           }

  //           dH[i].dt_ss = new Date(dH[i].dt_server);
  //           dH[i].dt_js = new Date(dH[i].dt_tracker);

  //           dH[i].dt_tracker = dH[i].dt_tracker.replace(/\//g, '-');
  //           dH[i].lat = parseFloat(dH[i].lat);
  //           dH[i].lng = parseFloat(dH[i].lng);

  //           dH[i].paramsGet = this.getParams(dH[i].params);

  //           dH[i]._trama = this.get_trama_marker(dH[i]); //.addTo(this.mapService.map);
  //           dH[i]._trama_fecha_velocidad =
  //             this.get_trama_marker_fecha_velocidad(dH[i]); //.addTo(this.mapService.map);

  //           var arrayP = [];

  //           dH[0].rutaH.push([dH[i].lat, dH[i].lng]); // guardar puntos para dibujar la linea del recorrido

  //           if (dH[i].lat < minLat) {
  //             minLat = dH[i].lat;
  //           }
  //           if (dH[i].lat > maxLat) {
  //             maxLat = dH[i].lat;
  //           }
  //           if (dH[i].lng < minLng) {
  //             minLng = dH[i].lng;
  //           }
  //           if (dH[i].lng > maxLng) {
  //             maxLng = dH[i].lng;
  //           }

  //           dH[i].historial2 = 0;
  //           dH[i].indice = i;

  //           if (dH[i].speed <= 3) {
  //             if (i == h) {
  //               if (l.length == 0) {
  //                 //estado = 0;
  //                 var p = dH[0].dt_js;
  //                 var q = dH[h].dt_js;
  //                 var r = this.string_diffechas(p, q);
  //                 dH[0].historial2 = 1;
  //                 dH[0].estado = 0;
  //                 dH[0].temp = r;

  //                 dH[0].marker = 'PARADA';

  //                 index_historial2[d] = 0;
  //                 d++;
  //               } else if (l.length > 0 && k.length > 0) {
  //                 var p = dH[k[0]].dt_js;
  //                 var q = dH[i].dt_js;
  //                 var s = Math.floor((q - p) / 1000);
  //                 if (s > duracion) {
  //                   //estado = 1;
  //                   var p = dH[l[0]].dt_js;
  //                   var q = dH[k[0]].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[l[0]].historial2 = 1;
  //                   dH[l[0]].estado = 1;
  //                   dH[l[0]].temp = r;
  //                   index_historial2[d] = l[0];
  //                   d++;
  //                   //estado = 0;
  //                   var p = dH[k[0]].dt_js;
  //                   var q = dH[i].dt_js;
  //                   var t = this.string_diffechas(p, q);
  //                   dH[k[0]].historial2 = 1;
  //                   dH[k[0]].estado = 0;
  //                   dH[k[0]].temp = t;

  //                   // dH[k[0]].marker = new google.maps.Marker({
  //                   //     map: mapa,
  //                   //     visible: false,
  //                   //     position: {
  //                   //         lat: dH[k[0]].lat,
  //                   //         lng: dH[k[0]].lng
  //                   //     },
  //                   //     icon: "images/stop.png"
  //                   // });

  //                   // var icon_0 = L.icon({ iconUrl: "images/stop.png" });
  //                   // dH[k[0]].marker = L.marker([dH[k[0]].lat, dH[k[0]].lng], {icon: icon_0, opacity: 0 }).addTo(mymap);

  //                   dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png", [dH[k[0]].lat, dH[k[0]].lng], [7, 26], k[0]);
  //                   //var label = "<div id='ih"+ k[0] +"' class='infoboxICON'> <span></span> </div>";
  //                   //dH[k[0]].marker.addTo(mymap);
  //                   //$("div#ih"+k[0]).parents('div.leaflet-label').css("opacity", 0);

  //                   index_historial2[d] = k[0];
  //                   d++;
  //                   l.length = 0;
  //                   k.length = 0;
  //                 } else {
  //                   //estado = 1;
  //                   var p = dH[l[0]].dt_js;
  //                   var q = dH[i].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[l[0]].historial2 = 1;
  //                   dH[l[0]].estado = 1;
  //                   dH[l[0]].temp = r;
  //                   index_historial2[d] = l[0];
  //                   d++;
  //                 }
  //               } else if (l.length > 0 && k.length == 0) {
  //                 if (l.length >= 1) {
  //                   //estado = 1;
  //                   var p = dH[l[0]].dt_js;
  //                   var q = dH[i].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[l[0]].historial2 = 1;
  //                   dH[l[0]].estado = 1;
  //                   dH[l[0]].temp = r;
  //                   index_historial2[d] = l[0];
  //                   d++;
  //                 }
  //               }
  //             }
  //             k.push(i);
  //           } else {
  //             if (k.length > 0) {
  //               var p = dH[k[0]].dt_js;
  //               var q = dH[i].dt_js;
  //               var t = this.string_diffechas(p, q);
  //               var s = Math.floor((q - p) / 1000);
  //               if (s > duracion) {
  //                 if (o == 1) {
  //                   //estado = 1;
  //                   var p = dH[l[0]].dt_js;
  //                   var q = dH[k[0]].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[l[0]].historial2 = 1;
  //                   dH[l[0]].estado = 1;
  //                   dH[l[0]].temp = r;
  //                   index_historial2[d] = l[0];
  //                   d++;
  //                   //estado = 0;
  //                   dH[k[0]].historial2 = 1;
  //                   dH[k[0]].estado = 0;
  //                   dH[k[0]].temp = t;
  //                   // dH[k[0]].marker = new google.maps.Marker({
  //                   //     map: mapa,
  //                   //     visible: false,
  //                   //     position: {
  //                   //         lat: dH[k[0]].lat,
  //                   //         lng: dH[k[0]].lng
  //                   //     },
  //                   //     icon: "images/stop.png"
  //                   // });

  //                   // var icon_0 = L.icon({ iconUrl: "images/stop.png" });
  //                   // dH[k[0]].marker = L.marker([dH[k[0]].lat, dH[k[0]].lng], {icon: icon_0, opacity: 0 }).addTo(mymap);

  //                   dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png", [dH[k[0]].lat, dH[k[0]].lng], [7, 26], k[0]);
  //                   //var label = "<div id='ih"+ k[0] +"' class='infoboxICON'> <span></span> </div>";
  //                   //dH[k[0]].marker.addTo(mymap);
  //                   //$("div#ih"+k[0]).parents('div.leaflet-label').css("opacity", 0);

  //                   index_historial2[d] = k[0];
  //                   d++;
  //                   l.length = 0;
  //                   k.length = 0;
  //                 }
  //                 if (l.length > 0 && dH[l[0]].dt_tracker == dH[0].dt_tracker) {
  //                   //estado = 1;
  //                   var p = dH[l[0]].dt_js;
  //                   var q = dH[k[0]].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[l[0]].historial2 = 1;
  //                   dH[l[0]].estado = 1;
  //                   dH[l[0]].temp = r;
  //                   index_historial2[d] = l[0];
  //                   d++;
  //                   //estado = 0;
  //                   dH[k[0]].historial2 = 1;
  //                   dH[k[0]].estado = 0;
  //                   dH[k[0]].temp = t;
  //                   // dH[k[0]].marker = new google.maps.Marker({
  //                   //     map: mapa,
  //                   //     visible: false,
  //                   //     position: {
  //                   //         lat: dH[k[0]].lat,
  //                   //         lng: dH[k[0]].lng
  //                   //     },
  //                   //     icon: "images/stop.png"
  //                   // });

  //                   // //var icon_0 = L.icon({ iconUrl: "images/stop.png" });
  //                   // dH[k[0]].marker = L.marker([dH[k[0]].lat, dH[k[0]].lng], {icon: L.icon({ iconUrl: "images/stop.png" }), opacity: 0 }).addTo(mymap);

  //                   dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png", [dH[k[0]].lat, dH[k[0]].lng],  [7, 26], k[0]);
  //                   //var label = "<div id='ih"+ k[0] +"' class='infoboxICON'> <span></span> </div>";
  //                   //dH[k[0]].marker.addTo(mymap);
  //                   //$("div#ih"+k[0]).parents('div.leaflet-label').css("opacity", 0);

  //                   index_historial2[d] = k[0];
  //                   d++;
  //                   l.length = 0;
  //                   k.length = 0;
  //                   o = 1;
  //                 }
  //                 if (k.length > 0 && dH[k[0]].dt_tracker == dH[0].dt_tracker) {
  //                   //estado = 0;
  //                   dH[k[0]].historial2 = 1;
  //                   dH[k[0]].estado = 0;
  //                   dH[k[0]].temp = t;
  //                   // dH[k[0]].marker = new google.maps.Marker({
  //                   //     map: mapa,
  //                   //     visible: false,
  //                   //     position: {
  //                   //         lat: dH[k[0]].lat,
  //                   //         lng: dH[k[0]].lng
  //                   //     },
  //                   //     icon: "images/stop.png"
  //                   // });

  //                   // //var icon_0 = L.icon({ iconUrl: "images/stop.png" });
  //                   // dH[k[0]].marker = L.marker([dH[k[0]].lat, dH[k[0]].lng], {icon: L.icon({ iconUrl: "images/stop.png" }), opacity: 0 }).addTo(mymap);

  //                   dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png" , [dH[k[0]].lat, dH[k[0]].lng],  [7, 26], k[0]);
  //                   //var label = "<div id='ih"+ k[0] +"' class='infoboxICON'> <span></span> </div>";
  //                   //dH[k[0]].marker.addTo(mymap);
  //                   //$("div#ih"+k[0]).parents('div.leaflet-label').css("opacity", 0);

  //                   index_historial2[d] = k[0];
  //                   d++;
  //                   k.length = 0;
  //                   o = 1;
  //                 }
  //               } else {
  //                 var u = l.concat(k);
  //                 k.length = 0;
  //                 l.length = 0;
  //                 l = u;
  //                 if (i == h) {
  //                   //estado = 1;
  //                   var p = dH[l[0]].dt_js;
  //                   var q = dH[i].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[l[0]].historial2 = 1;
  //                   dH[l[0]].estado = 1;
  //                   dH[l[0]].temp = r;
  //                   index_historial2[d] = l[0];
  //                   d++;
  //                 }
  //               }
  //             } else {
  //               if (i == h) {
  //                 if (l.length == 0) {
  //                   //estado = 1;
  //                   var p = dH[0].dt_js;
  //                   var q = dH[h].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[0].historial2 = 1;
  //                   dH[0].estado = 1;
  //                   dH[0].temp = r;
  //                   index_historial2[d] = 0;
  //                   d++;
  //                 } else if (l.length > 0) {
  //                   //estado = 1;
  //                   var p = dH[l[0]].dt_js;
  //                   var q = dH[i].dt_js;
  //                   var r = this.string_diffechas(p, q);
  //                   dH[l[0]].historial2 = 1;
  //                   dH[l[0]].estado = 1;
  //                   dH[l[0]].temp = r;
  //                   index_historial2[d] = l[0];
  //                   d++;
  //                 }
  //               }
  //             }
  //             l.push(i);
  //           }
  //         } //el for termina aqui

  //         var count = 1;
  //         index_historial2.forEach((item, i) => {
  //           // //console.log("--------xD");
  //           // //console.log(item);
  //           // //console.log(dH[item]);
  //           // //console.log(i);
  //           //Es parada y tiene el check de mostrar paradas
  //           if (dH[item].marker == 'PARADA') {
  //             if (i != index_historial2.length - 1) {
  //               dH[item].paradaFin = dH[index_historial2[i + 1]].dt_tracker;
  //             } else {
  //               ////console.log("--------xD-- ULTIMO");
  //               dH[item].paradaFin = dH[dH.length - 1].dt_tracker;
  //             }
  //             //var icon = { iconUrl: 'images/stop.png', iconAnchor: [7, 26] };
  //             dH[item].count = count++;
  //             dH[item].nombre = this.nombreUnidad; //"Nombre Unidad";//param.nombreUnidad;
  //             dH[item].layer = this.get_parada_marker(dH[item]);

  //             if (this.form.chckParada) {
  //               dH[item].layer.addTo(this.mapService.map);
  //             }
  //           } else {
  //             dH[item].marker = 'MOVIMIENTO';

  //             let a1, a2; //Primer y ultimo punto de una sublinea
  //             if (i == 0) {
  //               a1 = 0;
  //               if (index_historial2.length == 1) {
  //                 a2 = dH.length - 1;
  //               } else {
  //                 a2 = index_historial2[i + 1];
  //               }
  //             } else if (i == index_historial2.length - 1) {
  //               a1 = index_historial2[i - 1];
  //               a2 = dH.length - 1;
  //             } else {
  //               a1 = index_historial2[i - 1];
  //               a2 = index_historial2[i + 1];
  //             }

  //             dH[item].cc = [a1, a2];

  //             dH[item].combustible_movimiento = this.get_combustible_movimiento(
  //               dH,
  //               a1,
  //               a2
  //             );
  //             dH[item].distancia_movimiento = this.get_distancia_movimiento(
  //               dH,
  //               a1,
  //               a2
  //             );

  //             // for (let i = a1; i <= a2; i++) {
  //             //   dH[item].cc.push([dH[i].lat, dH[i].lng]);
  //             // }
  //           }
  //         });
  //         // //console.log('------>>>>> '+index_historial2);
  //         // //console.log(index_historial2);

  //         dH[0].index_historial = index_historial2;
  //         dH[0].layer0 = this.get_inicial_marker(dH[0]).addTo(
  //           this.mapService.map
  //         );
  //         dH[h].layerN = this.get_final_marker(dH[h]).addTo(
  //           this.mapService.map
  //         );

  //         this.mapService.map.fitBounds([
  //           [minLat, minLng],
  //           [maxLat, maxLng],
  //         ]);

  //         var color = this.form.colorHistorial; //'red';
  //         var color_sub = 'blue';
  //         dH[0].rutaH2 = this.get_historial_line(dH[0].rutaH, color).addTo(
  //           this.mapService.map
  //         ); //Linea del historial
  //         dH[0].ruta_sub = this.get_historial_line([], color_sub).addTo(
  //           this.mapService.map
  //         ); //Sub linea del historial

  //         dH[0].rutaH2_trace = L.polylineDecorator(dH[0].rutaH, {
  //           patterns: [
  //             //{ offset: 12, repeat: 25, symbol: L.Symbol.dash({pixelSize: 10, pathOptions: {color: 'black', weight: 2}})},
  //             {
  //               offset: 2,
  //               repeat: 30,
  //               symbol: L.Symbol.arrowHead({
  //                 pixelSize: 4.3,
  //                 polygon: false,
  //                 pathOptions: {
  //                   stroke: true,
  //                   weight: 1.4,
  //                   color: 'black',
  //                   opacity: 1,
  //                 },
  //               }),
  //             },
  //           ],
  //         }).addTo(this.mapService.map);

  //         var combustibleTotal = this.get_combustible_movimiento(dH, 0, 'FIN'); //'100 gal.';
  //         var kilometrajeTotal = this.get_distancia_movimiento(dH, 0, 'FIN'); //'100 gal.';

  //         console.log('dH', dH);

  //         this.historialService.arrayRecorridos.push({
  //           key: this.nombreUnidad + '_' + M1 + '_' + M2,
  //           icono: icono,
  //           nameoperation: nameoperation,
  //           nombre: this.nombreUnidad,
  //           f_ini: M1str,
  //           f_fin: M2str,
  //           kilometrajeTotal: kilometrajeTotal,
  //           combustibleTotal: combustibleTotal,
  //           mostrarRuta: true,
  //           recorrido: dH,
  //           // recorrido_bol :distancia_bol,
  //           eventos: this.EventService.eventsHistorial,
  //           // tramas : [{
  //           //       tooltip: '11111',
  //           //       dato:'gaaaaa1',
  //           //       dt_tracker:'2011-10-10 11:11:11',
  //           //       temp:'ee'
  //           //     },{
  //           //       tooltip: '1112',
  //           //       dato:'gaaaaa2',
  //           //       dt_tracker:'2011-10-10 11:11:11',
  //           //       temp:'ee'

  //           //     },{
  //           //       tooltip: '1113',
  //           //       dato:'gaaaaa3',
  //           //       dt_tracker:'2011-10-10 11:11:11',
  //           //       temp:'ee'

  //           //     }]
  //         });

  //         // this.historialService.changeMessage("desde com panel-historial")

  //         // this.historialService.arrayRecorridos = [
  //         //   {
  //         //     nombre: this.nombreUnidad+'o',
  //         //     f_ini: M1,
  //         //     f_fin: M2,
  //         //     kilometrajeTotal: 20,
  //         //     tramas : [{
  //         //           tooltip: '11111',
  //         //           dato:'gaaaaa1',
  //         //           dt_tracker:'2011-10-10 11:11:11',
  //         //           temp:'ee'
  //         //         },{
  //         //           tooltip: '1112',
  //         //           dato:'gaaaaa2',
  //         //           dt_tracker:'2011-10-10 11:11:11',
  //         //           temp:'ee'

  //         //         },{
  //         //           tooltip: '1113',
  //         //           dato:'gaaaaa3',
  //         //           dt_tracker:'2011-10-10 11:11:11',
  //         //           temp:'ee'

  //         //         }]
  //         //   },
  //         //   {
  //         //     nombre: this.nombreUnidad,
  //         //     f_ini: M1,
  //         //     f_fin: M2,
  //         //     kilometrajeTotal: 30,
  //         //     tramas : [{
  //         //           tooltip: '1117',
  //         //           dato:'gaaaaa4',
  //         //           dt_tracker:'2011-10-10 11:11:11'
  //         //         },{
  //         //           tooltip: '1118',
  //         //           dato:'gaaaaa5',
  //         //           dt_tracker:'2011-10-10 11:11:11'
  //         //         },{
  //         //           tooltip: '1119',
  //         //           dato:'gaaaaa6',
  //         //           dt_tracker:'2011-10-10 11:11:11'
  //         //         },{
  //         //           tooltip: '1120',
  //         //           dato:'gaaaaa7',
  //         //           dt_tracker:'2011-10-10 11:11:11'
  //         //         }

  //         //       ]
  //         //   }

  //         // ];

  //         // var allmap = new map.Movimiento(h1,{color: param.colorHistorial }).addTo(overlay);
  //         // allmap._trace.addTo(overlay);

  //         // var misOpciones2 = {
  //         //     //color: '#E7AB1E',
  //         //     color: 'red',
  //         //     weight: 3,
  //         //     opacity: 1.0
  //         // }

  //         // poly_h2 = L.polyline([], misOpciones2).addTo(mymap);

  //         // // h1.push([dH[0].lat, dH[0].lng]);
  //         // // h1.push([dH[dH.length-1].lat, dH[dH.length-1].lng]);
  //         // // //console.log("---- FF ------- 1");
  //         // // //console.log(overlay);
  //         // // //console.log("---- FF ------- 2");
  //         // // //console.log("------");
  //         // // //console.log(overlay);
  //         // // //console.log("------");
  //         // // mapData.map.fitBounds(overlay.getBounds());
  //         // //mapData.map.fitBounds(bounds);

  //         // mapData.map.fitBounds([[minLat, minLng],[maxLat, maxLng]]);
  //         // var allmap = new map.Movimiento(h1,{color: param.colorHistorial }).addTo(overlay);
  //         // allmap._trace.addTo(overlay);

  //         // var submap = new map.Movimiento([],{color: param.colorSubHistorial});//.addTo(overlay);
  //         // var poly = [allmap, submap];
  //         // // //console.log("XXXXXXXXXXXXXXXXXXXXXX-------------------------------------");
  //         // // ////console.log(lili);
  //         // // //console.log("XXXXXXXXXXXXXXXXXXXXXX-------------------------------------");
  //         // //------------------------ MOVIMEINTO DEL GRAFICO ------------------------------
  //         // var iconc = { iconUrl: 'images/mm_20_red.png', iconAnchor: [6, 20] };
  //         // var icoG1 = new map.Parada([0, 0], { icon: new L.Icon(iconc), showTrace: true })
  //         //                   .bindPopup( {offset: new L.Point(0, -16)} );

  //         // var icons = { iconUrl: 'images/objects/nuevo/'+param.icono, iconSize: [32, 32], iconAnchor: [16, 16] };
  //         // var icoG2 = new map.Parada([0, 0], { icon: new L.Icon(icons), showTrace: true })
  //         //                   .bindPopup( {offset: new L.Point(-18, -10)} );

  //         //   // //console.log(this.getParams(this.historialService.tramasHistorial[0].params));

  //         //console.log(dH[0].paramsGet);

  //         //this.panelService.nombreComponente = nomComponent;

  //         // const item1 = (dH[0].paramsGet.filter((item:any)=> item.id == "GPRS Status"))[0].value;
  //         // const item2 = (dH[0].paramsGet.filter((item:any)=> item.id == "GPRS Status"))[0];

  //         // //console.log("===================================");

  //         // //console.log(item1);
  //         // //console.log(item2);

  //         // this.panelService.nombreCabecera =   item[0].name;

  //         this.mostrar_tabla(dH, dH[0].index_historial);

  //         this.mostrar_tabla2(
  //           dH,
  //           dH[0].index_historial,
  //           this.historialService.arrayRecorridos.length - 1
  //         );
  //         this.changeShowingTramas();
  //         this.changeShowingGrafico();

  //         // this.changeShowingTramas();
  //         this.changeShowingTramasFechaVelocidad();
  //         this.isHistorialTableLoaded = true;
  //         /* $('#tbl_fechas').floatThead({
  //                 scrollContainer: () => {
  //                   return $('.panel-izq-table-container');
  //                 },
  //               }); */
  //         this.spinner.hide('loadingHistorial');
  //       } else {
  //         this.spinner.hide('loadingHistorial');
  //         console.log('NO HAY SUFICIENTE INFORMACIÓN ' + dH.length);
  //         Swal.fire({
  //           title: 'Error',
  //           text: 'No hay suficiente información.',
  //           icon: 'error',
  //         });
  //         //this.showNotEnoughInfoDialog();
  //       }
  //     }); // fin de eventosa historial
  //   }); // fin de historial tramas

  //   // var dH22  = await this.historialService.get_tramas_recorrido(param);
  //   // //console.log('================== DDD ');
  //   // //console.log(dH22);
  // }

  //click ejecutar con array
  waitInit(msm:string = 'Por favor, revise sus opciones y asegúrese de seleccionar al menos una antes de continuar.'){
      this.spinner.hide("fullScreenSpinner");
      console.log('Hubo un error al obtener los datos');
      Swal.fire({
        title: 'Advertencia',
        text: `${msm}`,
        icon: 'warning',
        allowOutsideClick: false,
        confirmButtonText: 'Entendido',
      }).then(()=>{
      })
      return;
  }
  async clickCargarHistorial() {

    console.log("============================================== clickCargarHistorial");
    this.form.selectedCars = this.cars.filter((o:any) => o.imei === this.form.selectedCar);
    // console.log(this.form.selectedCar);
    // console.log(this.form.selectedCars);

    if (!(this.form.selectedCars.length > 0)) {
      this.waitInit('Inconsistencias en los datos enviados. Vehiculos no seleccionados, verifique que se a seleccionado al menos un vehículo.');
      return;
    }
    if (!this.form.pngFechaFin || !this.form.pngFechaIni) {
      this.waitInit('Inconsistencias en los datos enviados. Las fechas de inicio o fin no son válidas, asegúrese de que las fechas sean correctas.');
      return;
    }
    if (!this.form.pngHoraIni2 || !this.form.pngHoraFin2) {
      this.waitInit('Inconsistencias en los datos enviados. Las horas de inicio o fin no son válidas, asegúrese de que las horas sean correctas.');
      return;
    }
    const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
    const limit: Record<string,number> = {
      day: 1000*60*60*24,
      Days:15,
      Time:365,
      cars:5,
    };
    if (this.form.selectedCars.length > limit.cars) {
      this.waitInit(`Solo debes seleccionar ${limit.cars} vehículos a la vez. Por favor verifica tu selección y vuelve a intentarlo`);
      return;
    }
    if (Math.abs(this.form.pngFechaIni.getTime() - now.getTime())/limit.day > limit.Time) {
      this.waitInit(`No es posible seleccionar fechas con un intervalo superior a ${limit.Time} días. Por favor, elija fechas dentro del límite permitido.`);
      return;
    }
    if ((Math.abs(this.form.pngFechaFin.getTime() - this.form.pngFechaIni.getTime())/limit.day) > limit.Days) {
      this.waitInit(`El intervalo de tiempo seleccionado excede los ${limit.Days} días. Por favor, verifíquelo e inténtelo nuevamente.`);
      return;
    }
    this.spinner.show('loadingHistorial');

    // console.log(this.form.selectedCar);
    console.log(this.form.selectedCars);
    // console.log(this.selectedCities2);

    console.log('=================================');
    console.log(this.UserDataService.typeVehicles);
    console.log(this.UserDataService.typeVehiclesUserData);

    let MDstr = '' + moment(this.form.pngFechaIni).format('M');
    let DDstr = '' + moment(this.form.pngFechaIni).format('D');
    let HMDstr = '' + moment(this.form.pngHoraIni2).format('HH:mm:00');

    let MHstr = '' + moment(this.form.pngFechaFin).format('M');
    let DHstr = '' + moment(this.form.pngFechaFin).format('D');
    let HMHstr = '' + moment(this.form.pngHoraFin2).format('HH:mm:00');

    var M1 =
      moment(this.form.pngFechaIni).format('YYYY') +
      '-' +
      this.fStrMD(MDstr) +
      '-' +
      this.fStrMD(DDstr) +
      'T' +
      HMDstr +
      '-05:00';
    var M2 =
      moment(this.form.pngFechaFin).format('YYYY') +
      '-' +
      this.fStrMD(MHstr) +
      '-' +
      this.fStrMD(DHstr) +
      'T' +
      HMHstr +
      '-05:00';

    var M1str =
      moment(this.form.pngFechaIni).format('YYYY') +
      '-' +
      this.fStrMD(MDstr) +
      '-' +
      this.fStrMD(DDstr) +
      ' ' +
      HMDstr +
      '';
    var M2str =
      moment(this.form.pngFechaFin).format('YYYY') +
      '-' +
      this.fStrMD(MHstr) +
      '-' +
      this.fStrMD(DHstr) +
      ' ' +
      HMHstr +
      '';



    // this.historialService.nombreUnidad = this.nombreUnidad = this.cars.filter(
    //   (item: any) => item.imei == this.form.selectedCar
    // )[0].nombre;
    // this.historialService.icono = this.cars.filter(
    //   (item: any) => item.imei == this.form.selectedCar
    // )[0].icon;
    // this.historialService.nameoperation = this.cars.filter(
    //   (item: any) => item.imei == this.form.selectedCar
    // )[0].nameoperation;


    var au = this.historialService.arrayRecorridos;
    // var icono = this.historialService.icono;
    // var nameoperation = this.historialService.nameoperation;
    //verificar q no existan keys repetidas :
    for (let i = 0; i < au.length; i++) {

        for (let j = 0; j < this.form.selectedCars.length; j++) {
            var unidad = this.form.selectedCars[j];
            var key = unidad.nombre + '_' + M1 + '_' + M2;
            // console.log(au[i].key + ' - ' + key);
            if (au[i].key == key) {
                console.log('SE REPITE LA KEY ');
                Swal.fire({
                  title: 'Error',
                  text: 'Unidad y recorrido ya seleccionado :'+unidad.nombre,
                  icon: 'error',
                });
                this.spinner.hide('loadingHistorial');
                return;
            }
        }
    }


    var param = {
      fecha_desde: M1,
      fecha_hasta: M2,
      // imei: this.form.selectedCar,
      user_id: localStorage.getItem('user_id'),
      vehiculos: JSON.stringify(this.form.selectedCars)
      // , duracionParada:vm.form.duracionParada.id,
      // conParada:vm.form.verParadasHistorial, nombreUnidad:vm.form.selectedUnidad.value.name,
      // colorHistorial:vm.form.colorHistorial, colorSubHistorial:vm.form.colorSubHistorial,
      // icono : values[0].icon
    };
    console.log("===========================param");
    console.log(param);
    // console.log("ID DEL USUARIO");
    // console.log(localStorage.getItem('user_id'));

    this.historialService.get_tramas_recorrido_varios(param).then((res) => {
      this.EventService.ShowAllHistorial(param).then((res1) => {

        // $result = array();
        // foreach ($paramVehiculos as $pVehiculo) {
        //     $array_unidad = array();
        //     for ($i=0; $i < count($event) ; $i++) {
        //         if ($event[$i]->imei == $pVehiculo->imei) {
        //             $array_unidad[] = $event[$i];
        //         }
        //     }
        //     $result[] = $array_unidad;
        // }

        var eventosAll = new Array();
        for (let index = 0; index < this.form.selectedCars.length; index++) {
          const unidadOne = this.form.selectedCars[index];
          var array_unidad = new Array();
          for (let index = 0; index < this.EventService.eventsHistorial.length; index++) {

            // this.EventService.eventsHistorial[index].layer.on('click', (e:any) => {
            //   this.getReference(this.EventService.eventsHistorial[index]);
            //   console.log("----- row - icono - EVENTOSX ----");
            //   // if (mostrarRuta) {
            //   console.log(this.EventService.eventsHistorial[index]);
            //   console.log(e);
            //   // this.clearMultimedia(this.EventService.eventsHistorial[index]);
            //   // this.EventService.eventsHistorial[index].layer.openPopup();
            //   // this.addMultimediaComponent(this.EventService.eventsHistorial[index]);
            //   // }
            //   // this.mapService.map.setView([trama.lat, trama.lng], 15);
            // });


            const eventoOne = this.EventService.eventsHistorial[index];
            if (eventoOne.imei == unidadOne.imei) {
              array_unidad.push(eventoOne);
            }
          }
          eventosAll.push(array_unidad);
        }


        console.log("===========================");
        console.log(res);
        console.log(this.historialService.tramasHistorial);
        console.log("===========================EVENTOOSSS=====");
        console.log(res1);
        console.log(this.EventService.eventsHistorial);
        console.log(eventosAll);

        this.spinner.hide('loadingHistorial');

        var minLat = 0; //parseFloat(dH[0].lat);
        var maxLat = -90; //parseFloat(dH[0].lat);
        var minLng = 0; //parseFloat(dH[0].lng);
        var maxLng = -180; //parseFloat(dH[0].lng);

        var strVacio = "";

        for (let indexx = 0; indexx < this.historialService.tramasHistorial.length; indexx++) {
          // console.log("=========================");
          // console.log(indexx);

          const duracion = parseInt(this.form.duracionParada);
          var dH = this.historialService.tramasHistorial[indexx]; //this.historialService.tramasHistorial; // Data Historial
          var index_historial2 = new Array();
          console.log(dH);

          if (dH.length > 1) {

            var nombreUnidad = this.form.selectedCars.filter((item: any) => item.imei == dH[0].imei)[0].nombre;
            var icono = this.form.selectedCars.filter((item: any) => item.imei == dH[0].imei)[0].icon;
            var nameoperation = this.form.selectedCars.filter((item: any) => item.imei == dH[0].imei)[0].nameoperation;

            console.log(nombreUnidad+" -- "+icono+" -- "+nameoperation);

            var evHistorial = [];
            for (let j = 0; j < eventosAll.length; j++) {
              if (eventosAll[j].length > 0) {
                if (eventosAll[j][0].imei == dH[0].imei) {
                  evHistorial = eventosAll[j];
                }
              }
            }


            // this.conHistorial = true; // tenemos historial con datod
            var d = 0;
            var k = [];
            var l = new Array();
            k.length = 0;
            l.length = 0;
            var o = 0;
            const h = dH.length - 1; //Ultimo indice del array
            // var minLat = parseFloat(dH[0].lat);
            // var maxLat = parseFloat(dH[0].lat);
            // var minLng = parseFloat(dH[0].lng);
            // var maxLng = parseFloat(dH[0].lng);
            dH[0].rutaH = [];
            dH[0].primero = h; // guardar el numero de elementos en la primera trama.
            dH[0].nombre = nombreUnidad; //this.nombreUnidad; //"Nombre Unidad";//param.nombreUnidad;
            // (dH[0].imei = this.imei = this.form.selectedCar), (dH[h].ultimo = h); // guardar el numero de elementos en la ultima trama.
            dH[h].nombre = nombreUnidad; //this.nombreUnidad; //"Nombre Unidad";//param.nombreUnidad;


            for (let i = 0; i < dH.length; i++) {
              if (i != 0) {
                dH[i].distancia =
                  this.calcularDistanciaEntreDosCoordenadas(
                    dH[i - 1].lat,
                    dH[i - 1].lng,
                    dH[i].lat,
                    dH[i].lng
                  ) * 1000;
              }
              dH[i].dt_ss = new Date(dH[i].dt_server);
              dH[i].dt_js = new Date(dH[i].dt_tracker);
              dH[i].dt_tracker = dH[i].dt_tracker.replace(/\//g, '-');
              dH[i].lat = parseFloat(dH[i].lat);
              dH[i].lng = parseFloat(dH[i].lng);
              dH[i].paramsGet = this.getParams(dH[i].params);
              dH[i]._trama = this.get_trama_marker(dH[i]); //.addTo(this.mapService.map);
              dH[i]._trama_fecha_velocidad = this.get_trama_marker_fecha_velocidad(dH[i]); //.addTo(this.mapService.map);
              var arrayP = [];
              dH[0].rutaH.push([dH[i].lat, dH[i].lng]); // guardar puntos para dibujar la linea del recorrido
              if (dH[i].lat < minLat) {
                minLat = dH[i].lat;
              }
              if (dH[i].lat > maxLat) {
                maxLat = dH[i].lat;
              }
              if (dH[i].lng < minLng) {
                minLng = dH[i].lng;
              }
              if (dH[i].lng > maxLng) {
                maxLng = dH[i].lng;
              }
              dH[i].historial2 = 0;
              dH[i].indice = i;
              if (dH[i].speed <= 3) {
                if (i == h) {
                  if (l.length == 0) {
                    //estado = 0;
                    var p = dH[0].dt_js;
                    var q = dH[h].dt_js;
                    var r = this.string_diffechas(p, q);
                    dH[0].historial2 = 1;
                    dH[0].estado = 0;
                    dH[0].temp = r;
                    dH[0].marker = 'PARADA';
                    index_historial2[d] = 0;
                    d++;
                  } else if (l.length > 0 && k.length > 0) {
                    var p = dH[k[0]].dt_js;
                    var q = dH[i].dt_js;
                    var s = Math.floor((q - p) / 1000);
                    if (s > duracion) {
                      //estado = 1;
                      var p = dH[l[0]].dt_js;
                      var q = dH[k[0]].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[l[0]].historial2 = 1;
                      dH[l[0]].estado = 1;
                      dH[l[0]].temp = r;
                      index_historial2[d] = l[0];
                      d++;
                      //estado = 0;
                      var p = dH[k[0]].dt_js;
                      var q = dH[i].dt_js;
                      var t = this.string_diffechas(p, q);
                      dH[k[0]].historial2 = 1;
                      dH[k[0]].estado = 0;
                      dH[k[0]].temp = t;
                      dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png", [dH[k[0]].lat, dH[k[0]].lng], [7, 26], k[0]);
                      index_historial2[d] = k[0];
                      d++;
                      l.length = 0;
                      k.length = 0;
                    } else {
                      var p = dH[l[0]].dt_js;
                      var q = dH[i].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[l[0]].historial2 = 1;
                      dH[l[0]].estado = 1;
                      dH[l[0]].temp = r;
                      index_historial2[d] = l[0];
                      d++;
                    }
                  } else if (l.length > 0 && k.length == 0) {
                    if (l.length >= 1) {
                      var p = dH[l[0]].dt_js;
                      var q = dH[i].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[l[0]].historial2 = 1;
                      dH[l[0]].estado = 1;
                      dH[l[0]].temp = r;
                      index_historial2[d] = l[0];
                      d++;
                    }
                  }
                }
                k.push(i);
              } else {
                if (k.length > 0) {
                  var p = dH[k[0]].dt_js;
                  var q = dH[i].dt_js;
                  var t = this.string_diffechas(p, q);
                  var s = Math.floor((q - p) / 1000);
                  if (s > duracion) {
                    if (o == 1) {
                      var p = dH[l[0]].dt_js;
                      var q = dH[k[0]].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[l[0]].historial2 = 1;
                      dH[l[0]].estado = 1;
                      dH[l[0]].temp = r;
                      index_historial2[d] = l[0];
                      d++;
                      dH[k[0]].historial2 = 1;
                      dH[k[0]].estado = 0;
                      dH[k[0]].temp = t;
                      dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png", [dH[k[0]].lat, dH[k[0]].lng], [7, 26], k[0]);
                      index_historial2[d] = k[0];
                      d++;
                      l.length = 0;
                      k.length = 0;
                    }
                    if (l.length > 0 && dH[l[0]].dt_tracker == dH[0].dt_tracker) {
                      //estado = 1;
                      var p = dH[l[0]].dt_js;
                      var q = dH[k[0]].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[l[0]].historial2 = 1;
                      dH[l[0]].estado = 1;
                      dH[l[0]].temp = r;
                      index_historial2[d] = l[0];
                      d++;
                      //estado = 0;
                      dH[k[0]].historial2 = 1;
                      dH[k[0]].estado = 0;
                      dH[k[0]].temp = t;
                      dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png", [dH[k[0]].lat, dH[k[0]].lng],  [7, 26], k[0]);

                      index_historial2[d] = k[0];
                      d++;
                      l.length = 0;
                      k.length = 0;
                      o = 1;
                    }
                    if (k.length > 0 && dH[k[0]].dt_tracker == dH[0].dt_tracker) {
                      dH[k[0]].historial2 = 1;
                      dH[k[0]].estado = 0;
                      dH[k[0]].temp = t;
                      dH[k[0]].marker = 'PARADA'; //marker_x("images/stop.png" , [dH[k[0]].lat, dH[k[0]].lng],  [7, 26], k[0]);
                      index_historial2[d] = k[0];
                      d++;
                      k.length = 0;
                      o = 1;
                    }
                  } else {
                    var u = l.concat(k);
                    k.length = 0;
                    l.length = 0;
                    l = u;
                    if (i == h) {
                      var p = dH[l[0]].dt_js;
                      var q = dH[i].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[l[0]].historial2 = 1;
                      dH[l[0]].estado = 1;
                      dH[l[0]].temp = r;
                      index_historial2[d] = l[0];
                      d++;
                    }
                  }
                } else {
                  if (i == h) {
                    if (l.length == 0) {
                      var p = dH[0].dt_js;
                      var q = dH[h].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[0].historial2 = 1;
                      dH[0].estado = 1;
                      dH[0].temp = r;
                      index_historial2[d] = 0;
                      d++;
                    } else if (l.length > 0) {
                      var p = dH[l[0]].dt_js;
                      var q = dH[i].dt_js;
                      var r = this.string_diffechas(p, q);
                      dH[l[0]].historial2 = 1;
                      dH[l[0]].estado = 1;
                      dH[l[0]].temp = r;
                      index_historial2[d] = l[0];
                      d++;
                    }
                  }
                }
                l.push(i);
                // console.log("========== variable l =========");
                // console.log(l);
              }
            } //el for termina aqui

            var count = 1;
            index_historial2.forEach((item, i) => {
              if (dH[item].marker == 'PARADA') {
                if (i != index_historial2.length - 1) {
                  dH[item].paradaFin = dH[index_historial2[i + 1]].dt_tracker;
                } else {
                  dH[item].paradaFin = dH[dH.length - 1].dt_tracker;
                }
                dH[item].count = count++;
                dH[item].nombre = nombreUnidad; //this.nombreUnidad; //"Nombre Unidad";//param.nombreUnidad;
                dH[item].layer = this.get_parada_marker(dH[item]);
                if (this.form.chckParada) {
                  dH[item].layer.addTo(this.mapService.map);
                }
              } else {
                dH[item].marker = 'MOVIMIENTO';
                let a1, a2; //Primer y ultimo punto de una sublinea
                if (i == 0) {
                  a1 = 0;
                  if (index_historial2.length == 1) {
                    a2 = dH.length - 1;
                  } else {
                    a2 = index_historial2[i + 1];
                  }
                } else if (i == index_historial2.length - 1) {
                  a1 = index_historial2[i - 1];
                  a2 = dH.length - 1;
                } else {
                  a1 = index_historial2[i - 1];
                  a2 = index_historial2[i + 1];
                }
                dH[item].cc = [a1, a2];
                dH[item].combustible_movimiento = this.get_combustible_movimiento(
                  dH,
                  a1,
                  a2
                );
                dH[item].distancia_movimiento = this.get_distancia_movimiento(
                  dH,
                  a1,
                  a2
                );
              }
            });

            dH[0].index_historial = index_historial2;
            dH[0].layer0 = this.get_inicial_marker(dH[0]).addTo(
              this.mapService.map
            );
            dH[h].layerN = this.get_final_marker(dH[h]).addTo(
              this.mapService.map
            );

            this.mapService.map.fitBounds([
              [minLat, minLng],
              [maxLat, maxLng],
            ]);

            var color = 'red'; //this.form.colorHistorial; //'red';
            if (this.historialService.tramasHistorial.length == 1) {
                color = this.form.colorHistorial; //'red';
            } else {
                color = this.colorArray[this.historialService.colorArrayIndex++]; // 'red'; //this.form.colorHistorial; //'red';
            }

            var color_sub = 'blue';
            dH[0].rutaH2 = this.get_historial_line(dH[0].rutaH, color).addTo(this.mapService.map); //Linea del historial
            dH[0].ruta_sub = this.get_historial_line([], color_sub).addTo(this.mapService.map); //Sub linea del historial

            dH[0].rutaH2_trace = L.polylineDecorator(dH[0].rutaH, {
              patterns: [
                {
                  offset: 2,
                  repeat: 30,
                  symbol: L.Symbol.arrowHead({
                    pixelSize: 4.3,
                    polygon: false,
                    pathOptions: {
                      stroke: true,
                      weight: 1.4,
                      color: 'black',
                      opacity: 1,
                    },
                  }),
                },
              ],
            }).addTo(this.mapService.map);

            var combustibleTotal = this.get_combustible_movimiento(dH, 0, 'FIN'); //'100 gal.';
            var kilometrajeTotal = this.get_distancia_movimiento(dH, 0, 'FIN'); //'100 gal.';

            console.log('dH', dH);

            this.historialService.arrayRecorridos.push({
              key: nombreUnidad + '_' + M1 + '_' + M2, //this.nombreUnidad + '_' + M1 + '_' + M2,
              icono: icono,
              nameoperation: nameoperation,
              nombre: nombreUnidad,//this.nombreUnidad,
              f_ini: M1str,
              f_fin: M2str,
              kilometrajeTotal: kilometrajeTotal,
              combustibleTotal: combustibleTotal,
              mostrarRuta: true,
              recorrido: dH,
              eventos: evHistorial, //this.EventService.eventsHistorial,
            });

            //------FALTA
            this.mostrar_tabla2(
              dH,
              dH[0].index_historial,
              this.historialService.arrayRecorridos.length - 1
            );

            this.changeShowingTramas();
            //this.changeShowingGrafico();
            this.changeShowingTramasFechaVelocidad();
            this.isHistorialTableLoaded = true;

          } else {
            this.spinner.hide('loadingHistorial');

            console.log('========NO HAY SUFICIENTE INFORMACIÓN');
            console.log(this.form.selectedCars[indexx].nombre);

            if (strVacio == "") {
              strVacio = strVacio +"<b>"+this.form.selectedCars[indexx].nombre+"</b>"
            } else {
              strVacio = strVacio +",<br> <b>"+this.form.selectedCars[indexx].nombre+"</b>"
            }

            // Swal.fire({
            //   title: 'Error',
            //   text: 'No hay suficiente información.',
            //   icon: 'error',
            // });
          }
        }

        if (strVacio != "") {
          Swal.fire({
            title: 'Unidades sin recorrido :',
            html: '' + strVacio,
            // text: 'Unidades sin recorrido : '+strVacio,
            icon: 'warning',
          });
        }

      }).catch((error)=>{
        // console.error(error);
        const errorFlag: Record<number,string>={
        500:'Ha ocurrido un problema al intentar procesar su solicitud. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, póngase en contacto con el área de TI.',
      }
      this.errorInit(errorFlag[error.status]?? 'Ha ocurrido un problema interno en obtencion de tramas. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, póngase en contacto con el área de TI.');

        console.log('panel historial: event service error',error);
    }); // fin de consulta de eventos
    }).catch((error)=>{
      // console.error(error);
      const errorFlag: Record<number,string>={
        422:'Inconsistencias detectadas en los datos ingresados. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, póngase en contacto con el área de TI.',
        500:'Ha ocurrido un problema al intentar procesar su solicitud. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, póngase en contacto con el área de TI.',
      }
      this.errorInit(errorFlag[error.status]?? 'Ha ocurrido un problema al generar el historial. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, póngase en contacto con el área de TI.');

      console.log('panel historial: historial service error',error);
      this.historialService.sendErrorToBackendLog(JSON.stringify(error??'[]'))
      .then((r)=>{console.log('sendErrorToBackendLog: r ',r);
      }).catch((e)=>{console.log('sendErrorToBackendLog: e ',e);
      })

    }); // fin de historial tramas
    // var dH22  = await this.historialService.get_tramas_recorrido(param);
    // //console.log('================== DDD ');
    // //console.log(dH22);
  }
  errorInit(msm:string = 'Hubo un error al obtener la información. Por favor, actualiza la página. Si el problema persiste, póngase en contacto con el área de TI.'){
    console.log('Hubo un error al obtener los datos');
    Swal.fire({
      title: 'Error',
      text: `${msm}`,
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonText: 'Entendido',
    }).then((data) => {
      if (data.isConfirmed) {
          this.spinner.hide("loadingHistorial");
        }
      });
      return;
    }

  clickEnviarCorreoExcel() {
    console.log(' =========== clickEnviarCorreoExcel 1 =============== ');
    // var fecha_hoy  = moment( Date.now() ).format('YYYY-MM-DD HH:mm:ss');
    // var fecha_fin = moment( Date.now() ).format('YYYY-MM-DD 00:00:00');
    // var fecha_ini = moment( Date.now() ).add(-1, 'days').format('YYYY-MM-DD 00:00:00');
    // var params = {
    //   fecha_hoy : fecha_hoy,
    //   fecha_fin : fecha_fin,
    //   fecha_ini : fecha_ini
    // };
    // console.log(params);
    this.historialService.enviarCorreoExcel({});
    console.log(' =========== clickEnviarCorreoExcel 2 =============== ');
  }

  get_combustible_movimiento(dH: any, a1: any, a2: any) {
    // console.log('==get_combustible_movimiento');
    // console.log(dH);
    // console.log(a1);
    // console.log(a2);

    // if (dH.length > 0) {
    //   console.log('== el imei');
    //   console.log(dH[0].imei);
    // }

    if (a2 == 'FIN') {
      var h = dH.length - 1; //Ultimo indice del array
      var x1 =
        dH[0].paramsGet.filter((item: any) => item.id == 'can_fuel_used')[0] ===
        undefined
          ? '-'
          : dH[0].paramsGet.filter((item: any) => item.id == 'can_fuel_used')[0]
              .value;
      var x2 =
        dH[h].paramsGet.filter((item: any) => item.id == 'can_fuel_used')[0] ===
        undefined
          ? '-'
          : dH[h].paramsGet.filter((item: any) => item.id == 'can_fuel_used')[0]
              .value;
      var kilometrajeTotal = '-';
      // console.log('======== 0 - Fin');
      // console.log(a1+' - '+a2);
      // console.log(x1+' - '+x2);
      // console.log(x2-x1);
      if (x1 == '-' || x2 == '-') {
        kilometrajeTotal = '-';

        // console.log("========= localStorage ========");
        // console.log(this.UserDataService.typeVehicles);
        // console.log(this.UserDataService.typeVehiclesUserData);
        // console.log(this.UserDataService.typeVehicles[0].var_galon);
        // console.log("=========");
        // console.log( this.cars );
        // console.log( this.VehicleService.getVehiclesData() );
        //let vehicles = this.VehicleService.getVehiclesData();

        var tipoUnidad = this.VehicleService.getVehiclesData().filter(
          (item: any) => item.IMEI == dH[0].imei
        )[0].tipo;
        // console.log(tipoUnidad);
        // console.log(parseInt(tipoUnidad));
        var tipoU = this.UserDataService.typeVehiclesUserData.filter(
          (item: any) => item.type_vehicle_id == parseInt(tipoUnidad)
        )[0];
        // console.log(tipoU);
        // console.log(tipoU.var_galon);
        // console.log(parseInt(tipoU.var_galon));

        if (tipoU === undefined || tipoU.var_galon == null) {
        // if (tipoU.var_galon == null) {
          kilometrajeTotal = '-.';
        } else {
          // galones x kilometros
          var distancia = this.get_distancia_movimiento(dH, a1, a2); // en km
          if (distancia == '-') {
            var distancia_nueva = 0;
            for (let i = 1; i < dH.length; i++) {
              if (dH[i].speed > 1) {
                distancia_nueva =
                  distancia_nueva +
                  this.calcularDistanciaEntreDosCoordenadas(
                    dH[i - 1].lat,
                    dH[i - 1].lng,
                    dH[i].lat,
                    dH[i].lng
                  );
              }
            }
            kilometrajeTotal =
              (distancia_nueva * parseInt(tipoU.var_galon)).toFixed(2) + 'gal.';
          } else {
            kilometrajeTotal =
              (parseFloat(distancia) * parseInt(tipoU.var_galon)).toFixed(2) +
              'gal.';
          }
        }

        // this.historialService.icono = (this.cars.filter((item:any)=> item.imei == dH[0].imei))[0].icon;
      } else {
        kilometrajeTotal =
          ((x2 - x1) * 0.2641).toFixed(2) +
          'gal. (' +
          (x2 - x1).toFixed(2) +
          'l.)';
      }
      return kilometrajeTotal;
    } else {
      var x1 =
        dH[a1].paramsGet.filter(
          (item: any) => item.id == 'can_fuel_used'
        )[0] === undefined
          ? '-'
          : dH[a1].paramsGet.filter(
              (item: any) => item.id == 'can_fuel_used'
            )[0].value;
      var x2 =
        dH[a2].paramsGet.filter(
          (item: any) => item.id == 'can_fuel_used'
        )[0] === undefined
          ? '-'
          : dH[a2].paramsGet.filter(
              (item: any) => item.id == 'can_fuel_used'
            )[0].value;
      var kilometrajeTotal = '-';
      if (x1 == '-' || x2 == '-') {
        kilometrajeTotal = '';

        console.log('================= XXX =================');

        console.log(this.UserDataService.typeVehiclesUserData);
        var tipoUnidad = this.VehicleService.getVehiclesData().filter(
          (item: any) => item.IMEI == dH[0].imei
        )[0].tipo;
        var tipoU = this.UserDataService.typeVehiclesUserData.filter(
          (item: any) => item.type_vehicle_id == parseInt(tipoUnidad)
        )[0];

        console.log(tipoUnidad + ' ================ ' + tipoU);

        if (tipoU === undefined || tipoU.var_galon == null) {
          kilometrajeTotal = '-.';
        } else {
          console.log(' ================ 111');

          var distancia = this.get_distancia_movimiento(dH, a1, a2); // en km
          console.log(' ================ 222');

          if (distancia == '-') {
            var distancia_nueva = 0;
            for (let i = a1 + 1; i <= a2; i++) {
              if (dH[i].speed > 1) {
                distancia_nueva =
                  distancia_nueva +
                  this.calcularDistanciaEntreDosCoordenadas(
                    dH[i - 1].lat,
                    dH[i - 1].lng,
                    dH[i].lat,
                    dH[i].lng
                  );
                console.log(' ================ ' + distancia_nueva);
              }
            }
            kilometrajeTotal =
              (distancia_nueva * parseInt(tipoU.var_galon)).toFixed(2) + 'gal.';
          } else {
            kilometrajeTotal =
              (parseFloat(distancia) * parseInt(tipoU.var_galon)).toFixed(2) +
              'gal.';
          }
        }
      } else {
        kilometrajeTotal =
          ((x2 - x1) * 0.2641).toFixed(2) +
          'gal. (' +
          (x2 - x1).toFixed(2) +
          'l.)';
      }
      return kilometrajeTotal;
    }
  }

  get_distancia_movimiento(dH: any, a1: any, a2: any) {
    // console.log("------- 11");
    if (a2 == 'FIN') {
      // console.log("------- 12 fin");
      var h = dH.length - 1; //Ultimo indice del array
      var x1 =
        dH[0].paramsGet.filter((item: any) => item.id == 'can_dist')[0] ===
        undefined
          ? '-'
          : dH[0].paramsGet.filter((item: any) => item.id == 'can_dist')[0]
              .value;
      var x2 =
        dH[h].paramsGet.filter((item: any) => item.id == 'can_dist')[0] ===
        undefined
          ? '-'
          : dH[h].paramsGet.filter((item: any) => item.id == 'can_dist')[0]
              .value;
      var kilometrajeTotal = '-';
      // console.log('======== 0 - Fin');
      // console.log(a1+' - '+a2);
      // console.log(x1+' - '+x2);
      // console.log(x2-x1);
      if (x1 == '-' || x2 == '-') {
        kilometrajeTotal = '-';

        var distancia_nueva = 0;
        for (let i = 1; i < dH.length; i++) {
          if (dH[i].speed > 1) {
            // console.log(dH[i - 1]);

            distancia_nueva =
              distancia_nueva +
              this.calcularDistanciaEntreDosCoordenadas(
                dH[i - 1].lat,
                dH[i - 1].lng,
                dH[i].lat,
                dH[i].lng
              );
          }
        }
        kilometrajeTotal = distancia_nueva.toFixed(2) + ' km';
      } else {
        kilometrajeTotal = (x2 - x1).toFixed(2) + ' km';
      }
      return kilometrajeTotal;
    } else {
      // console.log("------- 22 fin");

      var x1 =
        dH[a1].paramsGet.filter((item: any) => item.id == 'can_dist')[0] ===
        undefined
          ? '-'
          : dH[a1].paramsGet.filter((item: any) => item.id == 'can_dist')[0]
              .value;
      var x2 =
        dH[a2].paramsGet.filter((item: any) => item.id == 'can_dist')[0] ===
        undefined
          ? '-'
          : dH[a2].paramsGet.filter((item: any) => item.id == 'can_dist')[0]
              .value;
      var kilometrajeTotal = '-';
      if (x1 == '-' || x2 == '-') {
        kilometrajeTotal = '-';

        var distancia_nueva = 0;

        for (let i = a1 + 1; i <= a2; i++) {
          if (dH[i].speed > 1) {
            // console.log(a1);
            // console.log(a2);
            // console.log(dH);

            distancia_nueva =
              distancia_nueva +
              this.calcularDistanciaEntreDosCoordenadas(
                dH[i - 1].lat,
                dH[i - 1].lng,
                dH[i].lat,
                dH[i].lng
              );
          }
        }
        kilometrajeTotal = distancia_nueva.toFixed(2) + ' km';
      } else {
        kilometrajeTotal = (x2 - x1).toFixed(2) + 'km';
      }
      return kilometrajeTotal;
    }
  }

  add_geocerca_movimineto(trama: any, trama2trama: any, key: any) {
    console.log('____________add_geocerca_movimineto');
    // console.log(key);
    // console.log(trama);
    // console.log(trama2trama);
    // console.log(trama2trama.cc);
    // console.log(trama2trama.cc[0]);
    // console.log(trama2trama.cc[1]);
    var recorrido_unidad = this.historialService.arrayRecorridos.filter(
      function (obj: any) {
        return obj.key == key; // id=23	name=Somnolencia	slug=somnolencia	type=accessories		 ==> 7.	Quitar los eventos de Somnolencia
      }
    );
    // console.log(recorrido_unidad[0]);
    //var dH =  this.historialService.tramasHistorial; // Data Historial
    // console.log(recorrido_unidad[0].recorrido);

    var LL = recorrido_unidad[0].recorrido;
    var arrCoordenadas = [];
    for (let i = trama2trama.cc[0]; i <= trama2trama.cc[1]; i++) {
      arrCoordenadas.push({
        lat: LL[i].lat,
        lng: LL[i].lng,
        speed: LL[i].speed,
      });
    }
    console.log('==== arrCoordenadas');
    console.log(arrCoordenadas);

    // console.log(recorrido_unidad[0].recorrido[trama2trama.cc[0]]);
    // console.log(recorrido_unidad[0].recorrido[trama2trama.cc[1]]);
  }

  showNotEnoughInfoDialog() {
    this.dialogDisplay = true;
  }

  clickEliminarHistorial() {
    //if (this.conHistorial) {
    console.log("------------- clickEliminarHistorial ");
    console.log(this.historialService.arrayRecorridos.length);

    if (this.historialService.arrayRecorridos.length > 0) {
      Swal.fire({
        title: 'Confirmación ...',
        text: '¿Desea seguir visualizando el historial?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        confirmButtonColor: '#30a9d9',
        cancelButtonText: 'No',
        cancelButtonColor: '#e3e3e3',
        showCloseButton: true,
      }).then((result) => {

        if (result.isConfirmed) {
          // console.log('Sí');

        } else if (result.dismiss === Swal.DismissReason.cancel) {
            console.log('No');

            // if (this.historialService.tramasHistorial.length > 0) {
              $('#btnStopConsola').trigger('click');
              var rdH = this.historialService.arrayRecorridos;
              for (let i = 0; i < rdH.length; i++) {
                  var dH = rdH[i].recorrido;
                  rdH[i].mostrarRuta = false;

                  // var dH = this.historialService.tramasHistorial; // Data Historial
                  this.mapService.map.removeLayer(dH[0].layer0);
                  this.mapService.map.removeLayer(dH[dH.length - 1].layerN);
                  this.mapService.map.removeLayer(dH[0].rutaH2);
                  this.mapService.map.removeLayer(dH[0].rutaH2_trace);
                  this.mapService.map.removeLayer(dH[0].ruta_sub);
                  for (let i = 0; i < dH.length; i++) {
                    if (dH[i].layer != null) {
                      this.mapService.map.removeLayer(dH[i].layer);
                    }
                    if (dH[i]._trama_fecha_velocidad != null) {
                      this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
                    }
                    this.mapService.map.removeLayer(dH[i]._trama);
                  }

                  //======= eventos ========
                  var eH = rdH[i].eventos;
                  for (let i = 0; i < eH.length; i++) {
                    this.mapService.map.removeLayer(eH[i].layer);
                  }
                  //======= eventos ========

              }

              // for (let index = 0; index < this.EventService.eventsHistorial.length; index++) {
              //   const item = this.EventService.eventsHistorial[index];
              //   this.mapService.map.removeLayer(item.layer);
              // }

              // this.historialService.tramasHistorial = [];
              // this.conHistorial = false;
              // this.transfers = [];
              // $('#graficohistorial').css('display', 'none');
            // }

        } else {
            //CUANDO ES ESC O X
            console.log('Cancel');

        }

      });
    }


    this.isHistorialTableLoaded = false;
  }

  changeShowingParadasHistorial() {
    // if (this.conHistorial) {
    //   var dH =  this.historialService.tramasHistorial; // Data Historial
    //   var iH  = dH[0].index_historial; //indices de paradas y movimientos

    //   if (this.form.chckParada) {
    //     for (let i = 0; i < dH.length; i++) {
    //       if (dH[i].layer != null) {
    //         dH[i].layer.addTo(this.mapService.map);
    //       }
    //     }
    //   } else {
    //     for (let i = 0; i < dH.length; i++) {
    //       if (dH[i].layer != null) {
    //         this.mapService.map.removeLayer(dH[i].layer);
    //       }
    //     }
    //   }

    //   this.mostrar_tabla(dH,iH);
    // }

    //===================================  ARRAY DE HISTORIALES ============================================

    var rdH = this.historialService.arrayRecorridos;

    for (let i = 0; i < rdH.length; i++) {
      var dH = rdH[i].recorrido;
      var iH = dH[0].index_historial; //indices de paradas y movimientos

      this.mostrar_tabla2(dH, iH, i);

      for (let j = 0; j < iH.length; j++) {
        if (dH[iH[j]].marker == 'PARADA') {
          if (this.form.chckParada && rdH[i].mostrarRuta) {
            dH[iH[j]].layer.addTo(this.mapService.map);
          } else {
            this.mapService.map.removeLayer(dH[iH[j]].layer);
          }
        }
      }
    }
  }

  calcularDistanciaEntreDosCoordenadas(
    lat1: any,
    lon1: any,
    lat2: any,
    lon2: any
  ) {
    // Convertir todas las coordenadas a radianes
    lat1 = this.gradosARadianes(lat1);
    lon1 = this.gradosARadianes(lon1);
    lat2 = this.gradosARadianes(lat2);
    lon2 = this.gradosARadianes(lon2);
    // Aplicar fórmula
    const RADIO_TIERRA_EN_KILOMETROS = 6371;
    let diferenciaEntreLongitudes = lon2 - lon1;
    let diferenciaEntreLatitudes = lat2 - lat1;
    let a =
      Math.pow(Math.sin(diferenciaEntreLatitudes / 2.0), 2) +
      Math.cos(lat1) *
        Math.cos(lat2) *
        Math.pow(Math.sin(diferenciaEntreLongitudes / 2.0), 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return RADIO_TIERRA_EN_KILOMETROS * c;
  }

  gradosARadianes(grados: any) {
    return (grados * Math.PI) / 180;
  }

  // mostrar_tabla(dH: any, iH: any, idx: any = -1) {
  //   if (this.conHistorial) {
  //     // console.log("========================== HISTORIAL ===========================");
  //     this.transfers = [];
  //     this.transfers.push({
  //       icono: 'assets/images/route_start.svg',
  //       tooltip: 'Inicio',
  //       trama: dH[0],
  //       icono_width: '13px',
  //       icono_height: '13px',
  //       dt_tracker: dH[0].dt_tracker,
  //     });

  //     for (let i = 0; i < iH.length; i++) {
  //       console.log('Procesando trama en tabla1: ', dH[iH[i]].marker);
  //       if (dH[iH[i]].marker == 'PARADA') {
  //         if (this.form.chckParada) {
  //           this.transfers.push({
  //             icono: 'assets/images/stop.svg',
  //             tooltip: 'Parada',
  //             trama: dH[iH[i]],
  //             icono_width: '11px',
  //             icono_height: '13px',
  //             dt_tracker: dH[iH[i]].dt_tracker,
  //           });
  //         }
  //       } else {
  //         this.transfers.push({
  //           icono: 'assets/images/drive.svg',
  //           tooltip: 'Movimiento',
  //           trama: dH[iH[i]],
  //           icono_width: '13px',
  //           icono_height: '13px',
  //           dt_tracker: dH[iH[i]].dt_tracker,
  //         });
  //       }
  //     }

  //     for (
  //       let index = 0;
  //       index < this.EventService.eventsHistorial.length;
  //       index++
  //     ) {
  //       const item = this.EventService.eventsHistorial[index];
  //       var activar = true; //false;

  //       if (activar) {
  //         item.layer.addTo(this.mapService.map); //.openPopup();
  //         item.lat = parseFloat(item.latitud);
  //         item.lng = parseFloat(item.longitud);
  //         item.dt_tracker = item.fecha_tracker.replace(/\//g, '-');
  //         this.transfers.push({
  //           icono: item.layer.options.icon.options.iconUrl,
  //           tooltip: item.evento,
  //           trama: item,
  //           icono_width: '17px',
  //           icono_height: '18px',
  //           dt_tracker: item.dt_tracker,
  //         });
  //       } else {
  //         this.mapService.map.removeLayer(item.layer);
  //       }
  //     } // fin del for
  //     this.transfers = this.sortByKey(this.transfers, 'dt_tracker');

  //     this.transfers.push({
  //       icono: 'assets/images/end.png',
  //       tooltip: 'Fin',
  //       trama: dH[dH.length - 1],
  //       icono_width: '13px',
  //       icono_height: '13px',
  //       dt_tracker: dH[dH.length - 1].dt_tracker,
  //     });

  //   }
  // }

  mostrar_tabla2(dH: any, iH: any, idx: any = -1) {
    console.log('==== mostrar_tabla2 ===>>');
    //if (this.conHistorial) {
    if (this.historialService.arrayRecorridos.length > 0) {
      this.transfers = [];
      this.transfers.push({
        icono: 'assets/images/route_start.png',
        tooltip: 'Inicio',
        trama: dH[0],
        icono_width: '13px',
        icono_height: '13px',
        dt_tracker: dH[0].dt_tracker,
      });

      for (let i = 0; i < iH.length; i++) {
        //===== obtener la distacia con en can
        //console.log('Procesando trama en tabla2: ', dH[iH[i]].marker);
        if (dH[iH[i]].marker == 'PARADA') {
          //console.log('parara = '+iH[i]+' - '+x1);

          if (this.form.chckParada) {
            this.transfers.push({
              icono: 'assets/images/stop.svg',
              tooltip: 'Parada',
              trama: dH[iH[i]],
              icono_width: '11px',
              icono_height: '13px',
              dt_tracker: dH[iH[i]].dt_tracker,
            });
          }
        } else {
          // var x1 = (dH[iH[i]].paramsGet.filter((item:any)=> item.id == "can_dist"))[0] === undefined ? "-" : (dH[iH[i]].paramsGet.filter((item:any)=> item.id == "can_dist"))[0].value;
          // console.log('movimi = '+iH[i]+' - '+x1);
          // console.log(dH[iH[i]]);
          this.transfers.push({
            icono: 'assets/images/drive.svg',
            tooltip: 'Movimiento',
            trama: dH[iH[i]],
            icono_width: '13px',
            icono_height: '13px',
            dt_tracker: dH[iH[i]].dt_tracker,
          });
        }
      }
      // console.log(this.eventList);
      // console.log(this.EventService.eventsHistorial);
      var EventosAll = [];

      if (idx == -1) {
        EventosAll = this.EventService.eventsHistorial;
      } else {
        console.log('=======================================');
        console.log("VER DATOS DE AQUI GENERALES DE LA ROW PRINCIPAL ",this.historialService.arrayRecorridos);
        console.log(idx);
        console.log("VER DATOS DE LAS ROW DEBAJO DEL PRINCIPAL ", this.historialService.arrayRecorridos[idx]);

        EventosAll = this.historialService.arrayRecorridos[idx].eventos;

      }
      console.log("LOS EVENTOS", EventosAll);

      for (let index = 0; index < EventosAll.length; index++) {
        const item = EventosAll[index];
        var activar = false;
        if (this.chkAllEvents) {
          activar = true;
        } else {
          for (let j = 0; j < this.selectedEvents.length; j++) {
            const opEve = this.selectedEvents[j];
            //console.log(EventosAll[index].tipo+" -  "+opEve.value);
            if (EventosAll[index].tipo == opEve.value) {
              //console.log("SII APARECE");
              var activar = true; // false;
            }
          }
        }

        if ( activar ) {
          item.lat = parseFloat(item.latitud);
          item.lng = parseFloat(item.longitud);
          item.dt_tracker = item.fecha_tracker.replace(/\//g, '-');
          this.transfers.push({
            icono: item.layer.options.icon.options.iconUrl,
            tooltip: item.evento,
            trama: item,
            icono_width: '17px',
            icono_height: '18px',
            dt_tracker: item.dt_tracker,
          });

          if (this.historialService.arrayRecorridos[idx].mostrarRuta) {
            item.layer.addTo(this.mapService.map); //.openPopup();
          }
        } else {
          this.mapService.map.removeLayer(item.layer);
        }
        // console.log("LAYER", item.layer.options.icon.options.iconUrl,)
      } // fin del for

      this.transfers = this.sortByKey(this.transfers, 'dt_tracker');

      dH[dH.length - 1].distancia = '- km';
      this.transfers.push({
        icono: 'assets/images/end.png',
        tooltip: 'Fin',
        trama: dH[dH.length - 1],
        icono_width: '13px',
        icono_height: '13px',
        dt_tracker: dH[dH.length - 1].dt_tracker,
      });

      this.historialService.arrayRecorridos[idx].tramas = this.transfers;

      // if (idx == -1) {
      //   if (this.conHistorial && this.transfers.length > 0) {
      //     console.log(
      //       'LLENAR TRAMAS ================================================ - ' +
      //         idx
      //     );
      //     this.historialService.arrayRecorridos[
      //       this.historialService.arrayRecorridos.length - 1
      //     ].tramas = this.transfers;
      //   }
      // } else {
      //   if (this.conHistorial && this.transfers.length > 0) {
      //     console.log(
      //       'LLENAR TRAMAS ================================================ - ' +
      //         idx
      //     );
      //     this.historialService.arrayRecorridos[idx].tramas = this.transfers;
      //   }
      // }
    }
  }

  sortByKey(array: any, key: any) {
    return array.sort((a: any, b: any) => {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  clickLocate(row: any, key: any = -1) {
    this.waitTimeServices.startTime({
      id: row.trama.uuid_event,
      start: Date.now(),
      itemsId: [],
      end: [],
      event: [],
      type: [],
      size: [],
      duration: []
    });
    console.log('=======  clickLocate =========');
    console.log('VERIFICACION DE LAS ROW -> ', row);
    console.log(key);
    console.log('fila de datos ', row);

    console.log("Conseguir solo su URL de ICONO" ,row.icono)
    var mostrarRuta = false;
    if (key == -1) {
      var dH = this.historialService.tramasHistorial; // Data Historial
    } else {
      //=======================================================================================
      var rdH = this.historialService.arrayRecorridos;

      for (let i = 0; i < rdH.length; i++) {
        //console.log(rdH[i].key+"  -  -  "+key);
        if (rdH[i].key == key) {
          var dH = rdH[i].recorrido;
          mostrarRuta = rdH[i].mostrarRuta;
        }

      }
      //=======================================================================================
    }

    let trama = row.trama;
    // console.log("VERIFICACION DE LAS");
    // console.log(trama);

    /* const iconUrl = getIconUrlHistory(row.icono); */

    // this.clearMultimedia(trama);
    // this.addMultimediaComponent(trama);

    // if (row.icono) {
    //   console.log("----- row - icono ----");
    //   this.clearMultimedia(trama);
    //   trama.layer.openPopup();
    //   this.addMultimediaComponent(trama);
    //   this.mapService.map.setView([trama.lat, trama.lng], 15);
    // } else
    // if (row.icono == 'assets/images/start.png') {
    if (row.icono == 'assets/images/route_start.png') {
      // console.log("--primero ----");
      trama.layer0.fireEvent('click');
      this.mapService.map.setView([trama.lat, trama.lng], 15);
    } else if (row.icono == 'assets/images/end.png') {
      // console.log("--ultimo ----");
      trama.layerN.fireEvent('click');
      this.mapService.map.setView([trama.lat, trama.lng], 15);
    } else if (row.icono == 'assets/images/stop.svg') {
      // console.log("-----parada ----");
      trama.layer.fireEvent('click');
      this.mapService.map.setView([trama.lat, trama.lng], 15);
    } else if (row.icono == 'assets/images/drive.svg') {
      // console.log("-----movimiento ----");

      // var rdH = this.historialService.arrayRecorridos;

      // for (let i = 0; i < rdH.length; i++) {

      //     var dH = rdH[i].recorrido;
      //     var iH  = dH[0].index_historial; //indices de paradas y movimientos

      //     this.mostrar_tabla2(dH,iH,i);

      //     for (let i = 0; i < iH.length; i++) {
      //       if (dH[iH[i]].marker == "PARADA") {
      //         if (this.form.chckParada ) {
      //           dH[iH[i]].layer.addTo(this.mapService.map);
      //         } else {
      //           this.mapService.map.removeLayer(dH[iH[i]].layer);
      //         }
      //       }
      //     }
      // }

      //console.log("-----movimiento ----");

      let t1 = trama.cc[0];
      let t2 = trama.cc[1];
      let cc = [];

      let minLat = dH[t1].lat;
      let maxLat = dH[t1].lat;
      let minLng = dH[t1].lng;
      let maxLng = dH[t1].lng;

      for (let i = t1; i <= t2; i++) {
        cc.push([dH[i].lat, dH[i].lng]);

        if (dH[i].lat < minLat) {
          minLat = dH[i].lat;
        }
        if (dH[i].lat > maxLat) {
          maxLat = dH[i].lat;
        }
        if (dH[i].lng < minLng) {
          minLng = dH[i].lng;
        }
        if (dH[i].lng > maxLng) {
          maxLng = dH[i].lng;
        }
      }

      //this.mapService.map.fitBounds(cc, { padding: [30, 30] });
      this.mapService.map.fitBounds(
        [
          [minLat, minLng],
          [maxLat, maxLng],
        ],
        { padding: [30, 30] }
      );
      // console.log("log ==> cc");
      // console.log(cc);
      // console.log(dH[0].ruta_sub);
      dH[0].ruta_sub.setLatLngs(cc); //.addTo(this.mapService.map);
      dH[0].ruta_sub.setStyle({ opacity: 1, color: 'blue' });
    } else {
      console.log("----- row - icono - EVENTOSX ----");
      if (mostrarRuta) {
        this.clearMultimedia(trama);
        trama.layer.openPopup();
        this.addMultimediaComponent(trama);
      }
      this.mapService.map.setView([trama.lat, trama.lng], 15);
    }
  }

  changeShowingTramas() {
    // if (this.conHistorial) {
    //   var dH =  this.historialService.tramasHistorial; // Data Historial
    //   if (this.form.chckTrama) {
    //     for (let i = 0; i < dH.length; i++) {
    //       dH[i]._trama.addTo(this.mapService.map);
    //     }
    //   } else {
    //     for (let i = 0; i < dH.length; i++) {
    //       this.mapService.map.removeLayer(dH[i]._trama);
    //     }
    //   }
    // }

    //=================================================================================

    this.historialService.dataFormulario.chckTrama = this.form.chckTrama;

    console.log(this.mapService.map.getZoom());

    var lvlzoom = this.mapService.map.getZoom();
    var nivel = 1000; // todo
    var ccont = 0;
    switch (lvlzoom) {
      case 12:
        nivel = 1000; //todo
        console.log('-------12 - 1000');
        break;
      case 13:
        nivel = 600; //todo
        console.log('-------13 - 600');
        break;
      case 14:
        nivel = 400; //todo
        console.log('-------14 - 400');
        break;
      case 15:
        nivel = 300; //todo
        console.log('-------15 - 200');
        break;
      case 16:
        nivel = 200; //todo
        console.log('-------16 - 100');
        break;
      case 17:
        nivel = 100; //todo
        console.log('-------17 - 50');
        break;
      case 18:
        nivel = 1; //todo
        console.log('-------18 - 1');
        break;
      default:
        nivel = 1000; // todo
        console.log('-------default');
        break;
    }

    var acum1 = 0.0;
    var acum2 = nivel;

    if (lvlzoom >= 12) {
      var allH = this.historialService.arrayRecorridos;

      for (let j = 0; j < allH.length; j++) {
        var dH = allH[j].recorrido;
        var mostrarR = allH[j].mostrarRuta;

        for (let i = 0; i < dH.length; i++) {
          this.mapService.map.removeLayer(dH[i]._trama);
          this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
          // this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
        }

        if (this.form.chckTrama && mostrarR) {
          for (let i = 0; i < dH.length; i++) {
            dH[i]._trama.addTo(this.mapService.map);

            // acum1 = acum1 + dH[i].distancia;
            // // if (dH[i].distancia > nivel) {
            // if (acum1 > acum2) {
            //   acum2 = acum2 + nivel;
            //   // if (ccont == i) {
            //   // console.log(ccont);
            //   dH[i]._trama.addTo(this.mapService.map);
            //   //   ccont = ccont + nivel;
            //   // }
            // }
          }
        }
      }
    }
  }

  changeShowingTramasFechaVelocidad() {
    // // : marker.bindTooltip(tooltipText, {permanent:false} )
    // if (this.conHistorial) {
    //   var dH =  this.historialService.tramasHistorial; // Data Historial

    //   if (this.form.chckTrama) {
    //     if (this.form.chckTramaFechaVelocidad) {
    //         for (let i = 0; i < dH.length; i++) {
    //           dH[i]._trama_fecha_velocidad.addTo(this.mapService.map);
    //           // dH[i]._trama.bindTooltip( "gaaaaaaa1",{permanent:true})
    //         }
    //     } else {
    //         for (let i = 0; i < dH.length; i++) {
    //           this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
    //           // dH[i]._trama.bindTooltip("gaaaaaaa2",{permanent:false})
    //         }
    //     }
    //   } else {
    //     for (let i = 0; i < dH.length; i++) {
    //       this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
    //       // dH[i]._trama.bindTooltip("gaaaaaaa3",{permanent:false})
    //     }
    //   }
    // }

    ///==============================================================

    this.historialService.dataFormulario.chckTramaFechaVelocidad =
      this.form.chckTramaFechaVelocidad;

    // console.log(this.mapService.map.getZoom());

    var lvlzoom = this.mapService.map.getZoom();
    var nivel = 100; // todo
    var ccont = 0;
    switch (lvlzoom) {
      case 12:
        nivel = 1000; //todo
        console.log('-------12 - 1000');
        break;
      case 13:
        nivel = 600; //todo
        console.log('-------13 - 600');
        break;
      case 14:
        nivel = 400; //todo
        console.log('-------14 - 400');
        break;
      case 15:
        nivel = 300; //todo
        console.log('-------15 - 200');
        break;
      case 16:
        nivel = 200; //todo
        console.log('-------16 - 100');
        break;
      case 17:
        nivel = 100; //todo
        console.log('-------17 - 50');
        break;
      case 18:
        nivel = 1; //todo
        console.log('-------18 - 1');
        break;
      default:
        nivel = 1000; // todo
        console.log('-------default');
        break;
    }

    var acum1 = 0.0;
    var acum2 = nivel;

    //movimiento
    //https://stackoverflow.com/questions/35800775/leaflet-js-fire-event-when-map-pans
    if (lvlzoom >= 12) {
      var allH = this.historialService.arrayRecorridos;
      for (let j = 0; j < allH.length; j++) {
        var dH = allH[j].recorrido;
        var mostrarR = allH[j].mostrarRuta;

        for (let i = 0; i < dH.length; i++) {
          // this.mapService.map.removeLayer(dH[i]._trama);
          this.mapService.map.removeLayer(dH[i]._trama_fecha_velocidad);
        }

        if (this.form.chckTramaFechaVelocidad && mostrarR) {
          for (let i = 0; i < dH.length; i++) {
            if (isNaN(parseFloat(dH[i].distancia))) {
              // console.log("----------DAA---------");
            } else {
              acum1 = acum1 + parseFloat(dH[i].distancia);
              // console.log(acum1 +"  -  "+acum2+"  -  "+parseFloat(dH[i].distancia));
            }

            if (acum1 > acum2) {
              acum1 = 0;
              // console.log(acum1 +"  -  "+ acum2);
              if (
                this.mapService.map
                  .getBounds()
                  .contains(dH[i]._trama.getLatLng())
              ) {
                dH[i]._trama_fecha_velocidad.addTo(this.mapService.map);
              }
            }
          }
        }
      }
    }
  }

  changeShowingGrafico() {
    console.log('--changeShowingGrafico');
    // console.log(this.conHistorial);

    // if (this.conHistorial) {
    //   if (this.form.chckGrafico) {
    //     $('#graficohistorial').css('display', 'block');
    //   } else {
    //     $('#graficohistorial').css('display', 'none');
    //   }
    // }
  }

  // Busacdor de vehiculos en el historial
  customSearch(term: string, item: ItemHistorial) {
    term = term.toLocaleLowerCase();
    return (
      item['nombre'].toLowerCase().indexOf(term) > -1 ||
      item['imei'].toString().indexOf(term) > -1
    );
  }

  //creacion del icono
  marker_x(feature: string, latlng: L.LatLngTuple, anchor: L.PointTuple) {
    // //console.log(latlng);

    var marker = L.marker(latlng, {
      icon: L.icon({
        iconUrl: feature,
        //iconSize:     [50, 45],
        iconAnchor: anchor, //[16, 32],
        popupAnchor: [0, 0],
      }),
      //clickable: true
    }).bindPopup('<xXXXxxxXXXxXXxx ');
    return marker;
  }

  //retorna una Objeto con los valores en un array

  // options = new Array(
  //   { id:'GEOPOINTS' , name:"Geopunto"},
  //   { id:'HISTORIAL' , name:"Historial"},
  //   { id:'VEHICLES' , name:"Vehículos"},
  // );
  getParams(params: string) {
    var arrayParam = params.split('|'); // explode('|', params);
    var paramsObj = new Array();

    //var arrayParam = a[i].params.split("|");
    for (var j = 0; j < arrayParam.length; j++) {
      //======= ==============
      if (arrayParam[j].indexOf('=') > -1) {
        var temp = arrayParam[j].split('=');
        const v1 = temp[0].trim();
        const v2 = temp[1].trim();
        ////console.log(v1,v2);
        if (isNaN(parseFloat(v2))) {
          paramsObj.push({ id: v1, value: v2 });
        } else {
          paramsObj.push({ id: v1, value: parseFloat(v2) });
        }
      }
      //=======  ==============
      if (arrayParam[j].indexOf(':') > -1) {
        var temp = arrayParam[j].split(':');
        const v1 = temp[0].trim();
        const v2 = temp[1].trim();
        ////console.log(v1,v2);
        if (isNaN(parseFloat(v2))) {
          paramsObj.push({ id: v1, value: v2 });
        } else {
          paramsObj.push({ id: v1, value: parseFloat(v2) });
        }
      }
      ////console.log(paramsObj);
    }

    // //console.log("**** parametros ****");
    // //console.log(paramsObj);
    return paramsObj;
  }

  get_historial_line(path: any, color: string) {
    var opciones = {
      color: color,
      weight: 3,
      opacity: 1.0,
    };
    var linea = L.polyline(path, opciones);
    return linea;
  }

  get_trama_marker(trama: any) {
    //============= LEGENDA

    // color: #FFF; -> BLANCO   sin señal
    // color: #000; -> NEGRO    con señal
    // color: #FF0; -> AMARILLO sin GPRS Status y diferencia entre fecha tracker y fecha de servidor  >= 180s  (3min)
    // color: #00E; -> AZUL     sin GPRS Status y diferencia entre fecha tracker y fecha de servidor  < 180s  (3min)
    // color: #0EE; -> TURQUESA ERROR

    let gprsStatus = trama.paramsGet.filter(
      (item: any) => item.id == 'GPRS Status'
    )[0];
    let color = '#0EE';
    if (gprsStatus == null) {
      let m_server = moment(new Date(trama.dt_server)); //new Date(trama.dt_server);
      let m_tracker = moment(new Date(trama.dt_tracker)); //new Date(trama.dt_tracker)
      let timeo = m_server.diff(m_tracker, 'seconds');
      ////console.log(timeo)
      if (timeo >= 180) {
        color = '#FF0';
      } else {
        color = '#00E';
      }
      //conParam = false;
    } else if (gprsStatus.value == 0) {
      color = '#FFF';
    } else if (gprsStatus.value == 1) {
      color = '#000';
    }

    var xlat = parseFloat(trama.lat).toFixed(6);
    var xlng = parseFloat(trama.lng).toFixed(6);
    var xdt_tracker = trama.dt_tracker.replace(/\//g, '-');

    var contenido =
      '<table class="dl-horizontal">' +
      '<tr><td>Posición</td><td>:<a href="https://maps.google.com/maps?q=' +
      trama.lat +
      ',' +
      trama.lng +
      '&amp;t=m" target="_blank">' +
      xlat +
      '°,' +
      xlng +
      '°</a></td></tr>' +
      '<tr><td>Altitud</td><td>:' +
      trama.altitude +
      ' m</td></tr>' +
      '<tr><td>Angulo</td><td>:' +
      trama.angle +
      '&#160;&#176;</td></tr>' +
      '<tr><td>Velocidad</td><td>:' +
      trama.speed +
      ' km/h</td></tr>' +
      '<tr><td>Tiempo</td><td>:' +
      xdt_tracker +
      ' </td></tr>' +
      '</table>';

    // var marker = L.marker([trama.lat, trama.lng],
    //       { icon: L.icon({
    //           iconUrl: feature,
    //           //iconSize:     [50, 45],
    //           iconAnchor:   [4.5, 4.5], //[16, 32],
    //           popupAnchor:  [0, 0]
    //       }),
    //       //clickable: true
    // }).bindPopup(contenido,{offset: new L.Point(0, -13)});

    var marker = L.circleMarker([trama.lat, trama.lng], {
      // pane: 'markers1',
      radius: 4,
      fillColor: color,
      fillOpacity: 1,
      color: '#000', //color,
      weight: 1,
      opacity: 1,
    }).bindPopup(contenido, { offset: new L.Point(0, -13) });
    // .bindTooltip(
    //   // "<div style='background:blue;'><b>" + this.geofences[i].zone_name+ "</b></div>",//,
    //   /* '<b class="" style="-webkit-text-stroke: 0.5px black; color: '+this.geopoints[i].geopunto_color+';">'+this.geopoints[i].geopunto_name+'</b>', */
    //   '<b class="" style=" background-color: '+ this.mapService.hexToRGBA('#F00') +'; color: '+ this.mapService.getContrastYIQ('#F00') +';">'+trama.dt_tracker+'<br>'+trama.speed+' km/h</b>',
    //   { permanent: true,
    //     offset: [20, 20],
    //     direction: 'center',
    //     className: 'leaflet-tooltip-own',
    //   });

    return marker;
  }

  get_trama_marker_fecha_velocidad(trama: any) {
    //============= LEGENDA
    let marker = L.circleMarker([trama.lat, trama.lng], {
      // pane: 'markers1',
      radius: 0,
      fillColor: '#000', //color,
      fillOpacity: 1,
      color: '#000', //color,
      weight: 1,
      opacity: 1,
    }).bindTooltip(
      // "<div style='background:blue;'><b>" + this.geofences[i].zone_name+ "</b></div>",//,
      /* '<b class="" style="-webkit-text-stroke: 0.5px black; color: '+this.geopoints[i].geopunto_color+';">'+this.geopoints[i].geopunto_name+'</b>', */
      '<b class="" style=" background-color: ' +
        this.mapService.hexToRGBA('#6633FF') +
        '; color: ' +
        this.mapService.getContrastYIQ('#6633FF') +
        ';">' +
        trama.dt_tracker +
        ' -> ' +
        trama.speed +
        ' km/h</b>',
      {
        permanent: true,
        offset: [20, 20],
        direction: 'center',
        className: 'leaflet-tooltip-own',
      }
    );

    return marker;
  }

  get_parada_marker(trama: any) {
    // new map.Parada([dH[item].lat, dH[item].lng], { icon: new L.Icon(icon), showTrace: true })
    // .bindPopup(historialHelper.getContentPopup(dH[item]), {offset: new L.Point(0, -13)} );

    var xlat = parseFloat(trama.lat).toFixed(6);
    var xlng = parseFloat(trama.lng).toFixed(6);
    var xdt_tracker = trama.dt_tracker.replace(/\//g, '-');
    var xparadaFin = trama.paradaFin.replace(/\//g, '-');

    // return (
    var contenido =
      '<table class="dl-horizontal">' +
      '<tr><td width="57px">Parada</td><td>:</td><td width="140px">' +
      trama.count +
      '</td></tr>' +
      '<tr><td>Objeto</td><td>:</td><td>' + trama.nombre +'</td></tr>' +
      '<tr><td>Direcci&#243n</td><td>:</td><td><div id="direccionPopup"></div></td></tr>' +
      // '<tr><td>P.Cercano</td><td>: </td></tr>' +
      '<tr><td>Posición</td><td>:</td><td><a href="https://maps.google.com/maps?q=' +
      trama.lat +
      ',' +
      trama.lng +
      '&amp;t=m" target="_blank">' +
      xlat +
      '°,' +
      xlng +
      '°</a></td></tr>' +
      '<tr><td>Altitud</td><td>:</td><td>' +
      trama.altitude +
      'm</td></tr>' +
      '<tr><td>Angulo</td><td>:</td><td>' +
      trama.angle +
      '&#160;&#176;</td></tr>' +
      '<tr><td>Tiempo</td><td>:</td><td>' +
      xdt_tracker +
      '</td></tr>' +
      '<tr><td>Restante</td><td>:</td><td>' +
      xparadaFin +
      '</td></tr>' +
      '<tr><td>Duraci&#243n</td><td>:</td><td>' +
      trama.temp +
      '</td></tr>' +
      '</table>';
    // );

    var marker = L.marker([trama.lat, trama.lng], {
      icon: L.icon({
        iconUrl: 'assets/images/stop.svg',
        iconAnchor: [26, 26],
      }),
      //clickable: true
    }).bindPopup(contenido, { offset: new L.Point(0, -13) });

    marker.on('click', (e:any) => {
      this.getReference(trama);
    });

    return marker;
  }

  async getReference(trama:any) {
    console.log('------ getReference ');

    let reference = await this.historialService.getReference(trama.lat, trama.lng);
    var referencia = reference.referencia;
    $("#direccionPopup").html(referencia);

    // this.historialService.direccionPopup = referencia;
    // console.log('-------------------------');
    // console.log( this.historialService.direccionPopup );
    // $("#direccionPopup").html(this.historialService.direccionPopup );

    // console.log(referencia);
    // this.sliderValueReferencia = referencia;
    // return referencia;
  }

  get_inicial_marker(trama: any) {
    var xlat = parseFloat(trama.lat).toFixed(6);
    var xlng = parseFloat(trama.lng).toFixed(6);
    var xdt_tracker = trama.dt_tracker.replace(/\//g, '-');

    var contenido =
      '<table class="dl-horizontal">' +
      '<tr><td>Objeto</td><td>:</td><td>' +
      trama.nombre +
      '</td></tr>' +
      '<tr><td>Direcci&#243n</td><td>:</td><td><div id="direccionPopup"></div></td></tr>' +
      // '<tr><td>P.Cercano</td><td>: </td></tr>' +
      '<tr><td>Posición</td><td>:</td><td><a href="https://maps.google.com/maps?q=' +
      trama.lat +
      ',' +
      trama.lng +
      '&amp;t=m" target="_blank">' +
      xlat +
      '°,' +
      xlng +
      '°</a></td></tr>' +
      '<tr><td>Altitud</td><td>:</td><td>' +
      trama.altitude +
      'm</td></tr>' +
      '<tr><td>Angulo</td><td>:</td><td>' +
      trama.angle +
      '&#160;&#176;</td></tr>' +
      '<tr><td>Velocidad</td><td>:</td><td>' +
      trama.speed +
      'km/h</td></tr>' +
      '<tr><td>Tiempo</td><td>:</td><td>' +
      xdt_tracker +
      '</td></tr>' +
      '</table>';

    var marker = L.marker([trama.lat, trama.lng], {
      icon: L.icon({
        iconUrl: 'assets/images/route_start.png',
        iconAnchor: [16, 32],
      }),
      //clickable: true
    }).bindPopup(contenido, { offset: new L.Point(0, -13) });

    marker.on('click', (e:any) => {
        // console.log("================================>>>>>> marker.on(click ");
        // console.log(e);
        this.getReference(trama);
      });

    return marker;
  }

  get_final_marker(trama: any) {
    var xlat = parseFloat(trama.lat).toFixed(6);
    var xlng = parseFloat(trama.lng).toFixed(6);
    var xdt_tracker = trama.dt_tracker.replace(/\//g, '-');

    var contenido =
      '<table class="dl-horizontal">' +
      '<tr><td>Objeto</td><td>:</td><td>' +
      trama.nombre +
      '</td></tr>' +
      '<tr><td>Direcci&#243n</td><td>:</td><td><div id="direccionPopup"></div></td></tr>' +
      // '<tr><td>P.Cercano</td><td>: </td></tr>' +
      '<tr><td>Posición</td><td>:</td><td><a href="https://maps.google.com/maps?q=' +
      trama.lat +
      ',' +
      trama.lng +
      '&amp;t=m" target="_blank">' +
      xlat +
      '°,' +
      xlng +
      '°</a></td></tr>' +
      '<tr><td>Altitud</td><td>:</td><td>' +
      trama.altitude +
      'm</td></tr>' +
      '<tr><td>Angulo</td><td>:</td><td>' +
      trama.angle +
      '&#160;&#176;</td></tr>' +
      '<tr><td>Velocidad</td><td>:</td><td>' +
      trama.speed +
      'km/h</td></tr>' +
      '<tr><td>Tiempo</td><td>:</td><td>' +
      xdt_tracker +
      '</td></tr>' +
      '</table>';

    var marker = L.marker([trama.lat, trama.lng], {
      icon: L.icon({
        iconUrl: 'assets/images/route_end.png',
        iconAnchor: [16, 32],
      }),
      //clickable: true
    }).bindPopup(contenido, { offset: new L.Point(0, -13) });

    marker.on('click', (e:any) => {
      this.getReference(trama);
    });

    return marker;
  }

  string_diffechas(a: any, b: any) {
    var c = Math.floor((b - a) / 1000) % 60;
    var d = Math.floor((b - a) / 60000) % 60;
    var e = Math.floor((b - a) / 3600000) % 24;
    var f = Math.floor((b - a) / 86400000);
    var g = '';
    if (f > 0) {
      g = '' + f + ' d ' + e + ' h ' + d + ' min ' + c + ' s';
    } else if (e > 0) {
      g = '' + e + ' h ' + d + ' min ' + c + ' s';
    } else if (d > 0) {
      g = '' + d + ' min ' + c + ' s';
    } else if (c >= 0) {
      g = '' + c + ' s';
    }
    return g;
  }

  onChkAllEvents() {
    //this.mostrarAllEvents = true;
    // if (this.chkAllEvents) {
    //   this.changeShowingParadasHistorial()
    // }
    // this.selectedEvents = this.chkAllEvents? [...this.eventList[0].items, ...this.eventList[1].items, ...this.eventList[2].items, ...this.eventList[3].items]: [];
    // this.selectedEvents = this.chkAllEvents? [...this.eventList[0].items, ...this.eventList[1].items, ...this.eventList[2].items]: [];

    // this.selectedEvents = this.chkAllEvents? [...this.eventList[0].items, ...this.eventList[1].items]: []; // SOLO PARA LA DEMO
    if (this.chkAllEvents) {
      this.selectedEvents = this.eventList.reduce(
        (accumulator: { name: string; value: string }[], currentValue) => {
          return accumulator.concat(currentValue.items);
        },
        []
      );
    } else {
      this.selectedEvents = [];
    }
  }
  addMultimediaComponent(event: any) {
    if (
      event.parametros &&
      event.parametros.gps == 'cipia' &&
      (event.parametros.has_video != '0' || event.parametros.has_image != '0')
    ) {
      console.log('adding multimedia: ', event);

      const factory = this.resolver.resolveComponentFactory(
        SliderMultimediaComponent
      );
      const componentRef: ComponentRef<any> =
        this.container.createComponent(factory);
      const params: any = {
        event: event!,
        driver:
          this.VehicleService.vehicles.find((vh) => vh.IMEI == event.imei)
            ?.namedriver ?? '',
        showMultimediaFirst: true,
        hasMultimedia: true,
        showTitle: false,
        showFooter: false,
      };
      console.log('eventRowSelected panel historial');
      // Asignar datos al componente si existen

      Object.keys(params).forEach((key) => {
        componentRef.instance[key] = params[key];
      });
      // Agregar el componente directamente al contenedor del popup
      console.log(
        'componentRef.location.nativeElement',
        componentRef.location.nativeElement
      );

      const divContainer = document.getElementById(
        'multimedia-' + event.uuid_event
      )!;
      divContainer.innerHTML = '';
      console.log('divContainer', divContainer);
      divContainer.appendChild(componentRef.location.nativeElement);
    }
  }

  clearMultimedia(event: any) {
    if (this.EventService.activeEvent) {
      if (
        this.EventService.activeEvent.id == event.id &&
        event.layer.isPopupOpen()
      ) {
        console.log('no hacer nada');
        return;
      }
      this.EventService.activeEvent.layer.closePopup();
      this.EventService.activeEvent.layer.unbindPopup();
      this.EventService.activeEvent.layer.off();
      this.mapService.map.removeLayer(event.layer);
      this.EventService.activeEvent = false;
    }
  }
}

